export const LOG_IN = 'LOG_IN';
export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS';
export const LOG_IN_FAILED = 'LOG_IN_FAILED';
export const LOG_IN_FAILED_CLEAR_ALERT = 'LOG_IN_FAILED_CLEAR_ALERT';
export const LOG_OUT = 'LOG_OUT';
export const LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS';
export const LOG_OUT_FAILED = 'LOG_OUT_FAILED';
export const RESTORE_LOGIN_DATA = 'RESTORE_LOGIN_DATA';

//ROLE
export const ROLE_SEARCH = 'ROLE_SEARCH';
export const ROLE_SEARCH_SUCCESS = 'ROLE_SEARCH_SUCCESS';
export const ROLE_SEARCH_FAILED = 'ROLE_SEARCH_FAILED';
export const ROLE_GET_SELECTED_ROLE = 'ROLE_GET_SELECTED_ROLE';
export const ROLE_GET_SELECTED_ROLE_SUCCESS = 'ROLE_GET_SELECTED_ROLE_SUCCESS';
export const ROLE_GET_SELECTED_ROLE_FAILED = 'ROLE_GET_SELECTED_ROLE_FAILED';
export const ROLE_ADD_NEW_ROLE = 'ROLE_GET_SELECTED_ROLE_FAILED';
export const SAVE_UPDATE_ROLE = 'SAVE_UPDATE_ROLE';
export const SAVE_UPDATE_ROLE_SUCCESS = 'SAVE_UPDATE_ROLE_SUCCESS';
export const SAVE_UPDATE_ROLE_FAILED = 'SAVE_UPDATE_ROLE_FAILED';

//Master Data
export const MASTER_DATA_GET_PRIVILEGES = 'MASTER_DATA_GET_PRIVILEGES';
export const MASTER_DATA_GET_PRIVILEGES_SUCCESS = 'MASTER_DATA_GET_PRIVILEGES_SUCCESS';
export const MASTER_DATA_GET_PRIVILEGES_FAILED = 'MASTER_DATA_GET_PRIVILEGES_FAILED';

export const MASTER_DATA_GET_ELIGIBLE_COMPANY = 'MASTER_DATA_GET_ELIGIBLE_COMPANY';
export const MASTER_DATA_GET_ELIGIBLE_COMPANY_SUCCESS = 'MASTER_DATA_GET_ELIGIBLE_COMPANY_SUCCESS';
export const MASTER_DATA_GET_ELIGIBLE_COMPANY_FAILED = 'MASTER_DATA_GET_ELIGIBLE_COMPANY_FAILED';

export const MASTER_DATA_GET_USER_ROLES = 'MASTER_DATA_GET_USER_ROLES';
export const MASTER_DATA_GET_USER_ROLES_SUCCESS = 'MASTER_DATA_GET_USER_ROLES_SUCCESS';
export const MASTER_DATA_GET_USER_ROLES_FAILED = 'MASTER_DATA_GET_USER_ROLES_FAILED';

export const MASTER_DATA_GET_ALL_COMPANIES = 'MASTER_DATA_GET_ALL_COMPANIES';
export const MASTER_DATA_GET_ALL_COMPANIES_SUCCESS = 'MASTER_DATA_GET_ALL_COMPANIES_SUCCESS';
export const MASTER_DATA_GET_ALL_COMPANIES_FAILED = 'MASTER_DATA_GET_ALL_COMPANIES_FAILED';

export const MASTER_DATA_GET_MATH_OPERATORS = 'MASTER_DATA_GET_MATH_OPERATORS';
export const MASTER_DATA_GET_MATH_OPERATORS_SUCCESS = 'MASTER_DATA_GET_MATH_OPERATORS_SUCCESS';
export const MASTER_DATA_GET_MATH_OPERATORS_FAILED = 'MASTER_DATA_GET_MATH_OPERATORS_FAILED';

export const MASTER_DATA_GET_PROCESS_FREQUENCIES = 'MASTER_DATA_GET_PROCESS_FREQUENCIES';
export const MASTER_DATA_GET_PROCESS_FREQUENCIES_SUCCESS = 'MASTER_DATA_GET_PROCESS_FREQUENCIES_SUCCESS';
export const MASTER_DATA_GET_PROCESS_FREQUENCIES_FAILED = 'MASTER_DATA_GET_PROCESS_FREQUENCIES_FAILED';

export const MASTER_DATA_GET_WIDGET_TYPES = 'MASTER_DATA_GET_WIDGET_TYPES';
export const MASTER_DATA_GET_WIDGET_TYPES_SUCCESS = 'MASTER_DATA_GET_WIDGET_TYPES_SUCCESS';
export const MASTER_DATA_GET_WIDGET_TYPES_FAILED = 'MASTER_DATA_GET_WIDGET_TYPES_FAILED';

export const MASTER_DATA_GET_FACTORY_DOWN_TIME_CATEGORIES = 'MASTER_DATA_GET_FACTORY_DOWN_TIME_CATEGORIES';
export const MASTER_DATA_GET_FACTORY_DOWN_TIME_CATEGORIES_SUCCESS = 'MASTER_DATA_GET_FACTORY_DOWN_TIME_CATEGORIES_SUCCESS';
export const MASTER_DATA_GET_FACTORY_DOWN_TIME_CATEGORIES_FAILED = 'MASTER_DATA_GET_FACTORY_DOWN_TIME_CATEGORIES_FAILED';

export const MASTER_DATA_GET_FACTORY_WASTE_CATEGORIES = 'MASTER_DATA_GET_FACTORY_WASTE_CATEGORIES';
export const MASTER_DATA_GET_FACTORY_WASTE_CATEGORIES_SUCCESS = 'MASTER_DATA_GET_FACTORY_WASTE_CATEGORIES_SUCCESS';
export const MASTER_DATA_GET_FACTORY_WASTE_CATEGORIES_FAILED = 'MASTER_DATA_GET_FACTORY_WASTE_CATEGORIES_FAILED';

export const MASTER_DATA_GET_WIDGET_RULE_OPERATORS = 'MASTER_DATA_GET_WIDGET_RULE_OPERATORS';
export const MASTER_DATA_GET_WIDGET_RULE_OPERATORS_SUCCESS = 'MASTER_DATA_GET_WIDGET_RULE_OPERATORS_SUCCESS';
export const MASTER_DATA_GET_WIDGET_RULE_OPERATORS_FAILED = 'MASTER_DATA_GET_WIDGET_RULE_OPERATORS_FAILED';

export const MASTER_DATA_GET_PRODUCT_CATEGORY_WISE_VARIANTS = 'MASTER_DATA_GET_PRODUCT_CATEGORY_WISE_VARIANTS';
export const MASTER_DATA_GET_PRODUCT_CATEGORY_WISE_VARIANTS_SUCCESS = 'MASTER_DATA_GET_PRODUCT_CATEGORY_WISE_VARIANTS_SUCCESS';
export const MASTER_DATA_GET_PRODUCT_CATEGORY_WISE_VARIANTS_FAILED = 'MASTER_DATA_GET_PRODUCT_CATEGORY_WISE_VARIANTS_FAILED';

export const MASTER_DATA_GET_DATA_FORM_VARIABLES = 'MASTER_DATA_GET_DATA_FORM_VARIABLES';
export const MASTER_DATA_GET_DATA_FORM_VARIABLES_SUCCESS = 'MASTER_DATA_GET_DATA_FORM_VARIABLES_SUCCESS';
export const MASTER_DATA_GET_DATA_FORM_VARIABLES_FAILED = 'MASTER_DATA_GET_DATA_FORM_VARIABLES_FAILED';

export const MASTER_DATA_GET_WASTE_CATEGORY_WISE_SUBCATEGORIES = 'MASTER_DATA_GET_WASTE_CATEGORY_WISE_SUBCATEGORIES';
export const MASTER_DATA_GET_WASTE_CATEGORY_WISE_SUBCATEGORIES_SUCCESS = 'MASTER_DATA_GET_WASTE_CATEGORY_WISE_SUBCATEGORIES_SUCCESS';
export const MASTER_DATA_GET_WASTE_CATEGORY_WISE_SUBCATEGORIES_FAILED = 'MASTER_DATA_GET_WASTE_CATEGORY_WISE_SUBCATEGORIES_FAILED';

export const MASTER_DATA_GET_DOWN_TIME_CAUSE_CATEGORY_WISE_SUBCATEGORIES_DATA = 'MASTER_DATA_GET_DOWN_TIME_CAUSE_CATEGORY_WISE_SUBCATEGORIES_DATA';
export const MASTER_DATA_GET_DOWN_TIME_CAUSE_CATEGORY_WISE_SUBCATEGORIES_DATA_SUCCESS = 'MASTER_DATA_GET_DOWN_TIME_CAUSE_CATEGORY_WISE_SUBCATEGORIES_DATA_SUCCESS';
export const MASTER_DATA_GET_DOWN_TIME_CAUSE_CATEGORY_WISE_SUBCATEGORIES_DATA_FAILED = 'MASTER_DATA_GET_DOWN_TIME_CAUSE_CATEGORY_WISE_SUBCATEGORIES_DATA_FAILED';

export const MASTER_DATA_GET_COMPANY_VICE_MEASURE_UNIT_CATEGORY = 'MASTER_DATA_GET_COMPANY_VICE_MEASURE_UNIT_CATEGORY';
export const MASTER_DATA_GET_COMPANY_VICE_MEASURE_UNIT_CATEGORY_SUCCESS = 'MASTER_DATA_GET_COMPANY_VICE_MEASURE_UNIT_CATEGORY_SUCCESS';
export const MASTER_DATA_GET_COMPANY_VICE_MEASURE_UNIT_CATEGORY_FAILED = 'MASTER_DATA_GET_COMPANY_VICE_MEASURE_UNIT_CATEGORY_FAILED';

export const MASTER_DATA_GET_MEASURE_UNIT_CATEGORY_VICE_MEASURE_UNIT = 'MASTER_DATA_GET_MEASURE_UNIT_CATEGORY_VICE_MEASURE_UNIT';
export const MASTER_DATA_GET_MEASURE_UNIT_CATEGORY_VICE_MEASURE_UNIT_SUCCESS = 'MASTER_DATA_GET_MEASURE_UNIT_CATEGORY_VICE_MEASURE_UNIT_SUCCESS';
export const MASTER_DATA_GET_MEASURE_UNIT_CATEGORY_VICE_MEASURE_UNIT_FAILED = 'MASTER_DATA_GET_MEASURE_UNIT_CATEGORY_VICE_MEASURE_UNIT_FAILED';

export const MASTER_DATA_SET_SELECTED_LANGUAGE = 'MASTER_DATA_MASTER_DATA_SET_SELECTED_LANGUAGE';

//USER
export const USER_SEARCH = 'USER_SEARCH';
export const USER_SEARCH_SUCCESS = 'USER_SEARCH_SUCCESS';
export const USER_SEARCH_FAILED = 'USER_SEARCH_FAILED';
export const USER_ADD_NEW_USER = 'USER_ADD_NEW_USER';
export const USER_GET_SELECTED_USER = 'USER_GET_SELECTED_USER';
export const USER_GET_SELECTED_USER_FAILED = 'USER_GET_SELECTED_USER_FAILED';
export const USER_GET_SELECTED_USER_SUCCESS = 'USER_GET_SELECTED_USER_SUCCESS';
export const USER_SAVE_UPDATE = 'USER_SAVE_UPDATE';
export const USER_SAVE_UPDATE_SUCCESS = 'USER_SAVE_UPDATE_SUCCESS';
export const USER_SAVE_UPDATE_FAILED = 'USER_SAVE_UPDATE_FAILED';
export const USER_PASSWORD_RESET = 'USER_PASSWORD_RESET';
export const USER_PASSWORD_RESET_SUCCESS = 'USER_PASSWORD_RESET_SUCCESS';
export const USER_PASSWORD_RESET_FAILED = 'USER_PASSWORD_RESET_FAILED';
export const USER_AFTER_PASSWORD_RESET_SUCCESS = 'USER_AFTER_PASSWORD_RESET_SUCCESS';
export const USER_CHANGE_PASSWORD = "USER_CHANGE_PASSWORD";
export const USER_CHANGE_PASSWORD_SUCCESS = "USER_CHANGE_PASSWORD_SUCCESS";
export const USER_CHANGE_PASSWORD_FAILED = "USER_CHANGE_PASSWORD_FAILED";
export const USER_COMPANY_VICE_FACTORIES = "USER_COMPANY_VICE_FACTORIES";
export const USER_COMPANY_VICE_FACTORIES_SUCCESS = "USER_COMPANY_VICE_FACTORIES_SUCCESS";
export const USER_COMPANY_VICE_FACTORIES_FAILED = "USER_COMPANY_VICE_FACTORIES_FAILED";
export const USER_FACTORIES_VICE_DEPARTMENTS = "USER_FACTORIES_VICE_DEPARTMENTS";
export const USER_FACTORIES_VICE_DEPARTMENTS_SUCCESS = "USER_FACTORIES_VICE_DEPARTMENTS_SUCCESS";
export const USER_FACTORIES_VICE_DEPARTMENTS_FAILED = "USER_FACTORIES_VICE_DEPARTMENTS_FAILED";
export const USER_DEPARTMENTS_VICE_PRODUCTION_LINES = "USER_DEPARTMENTS_VICE_PRODUCTION_LINES";
export const USER_DEPARTMENTS_VICE_PRODUCTION_LINES_SUCCESS = "USER_DEPARTMENTS_VICE_PRODUCTION_LINES_SUCCESS";
export const USER_DEPARTMENTS_VICE_PRODUCTION_LINES_FAILED = "USER_DEPARTMENTS_VICE_PRODUCTION_LINES_FAILED";
export const USER_RESET_SELECTED_USER = "USER_RESET_SELECTED_USER";

export const USER_GET_DATA_ENTRY_FORMS = "USER_GET_DATA_ENTRY_FORMS";
export const USER_GET_DATA_ENTRY_FORMS_SUCCESS = "USER_GET_DATA_ENTRY_FORMS_SUCCESS";
export const USER_GET_DATA_ENTRY_FORMS_FAILED = "USER_GET_DATA_ENTRY_FORMS_FAILED";

export const USER_FORGOT_PASSWORD_RESET = "USER_FORGOT_PASSWORD_RESET";
export const USER_FORGOT_PASSWORD_RESET_SUCCESS = "USER_FORGOT_PASSWORD_RESET_SUCCESS";
export const USER_FORGOT_PASSWORD_RESET_FAILED = "USER_FORGOT_PASSWORD_RESET_FAILED";

//LOCATION
export const LOCATION_SEARCH = 'LOCATION_SEARCH';
export const LOCATION_SEARCH_SUCCESS = 'LOCATION_SEARCH_SUCCESS';
export const LOCATION_SEARCH_FAILED = 'LOCATION_SEARCH_FAILED';
export const LOCATION_ADD_NEW_LOCATION = 'LOCATION_ADD_NEW_LOCATION';
export const LOCATION_GET_SELECTED_LOCATION = 'LOCATION_GET_SELECTED_LOCATION';
export const LOCATION_GET_SELECTED_LOCATION_FAILED = 'LOCATION_GET_SELECTED_LOCATION_FAILED';
export const LOCATION_GET_SELECTED_LOCATION_SUCCESS = 'LOCATION_GET_SELECTED_LOCATION_SUCCESS';
export const SAVE_UPDATE_LOCATION = 'SAVE_UPDATE_LOCATION';
export const SAVE_UPDATE_LOCATION_SUCCESS = 'SAVE_UPDATE_LOCATION_SUCCESS';
export const SAVE_UPDATE_LOCATION_FAILED = 'SAVE_UPDATE_LOCATION_FAILED';
export const LOCATION_RESET_SELECTED_LOCATION = 'LOCATION_RESET_SELECTED_LOCATION';

export const LOCATION_ON_SELECT_TEMP_BUSINESS_UNITS = 'LOCATION_ON_SELECT_TEMP_BUSINESS_UNITS';
export const LOCATION_ON_SELECT_BUSINESS_UNITS = 'LOCATION_ON_SELECT_BUSINESS_UNITS';
export const LOCATION_ON_REMOVE_SELECTED_BUSINESS_UNITS = 'LOCATION_ON_REMOVE_SELECTED_BUSINESS_UNITS';
export const LOCATION_ON_SELECT_TEMP_PRODUCTS = 'LOCATION_ON_SELECT_TEMP_PRODUCTS';
export const LOCATION_ON_SELECT_PRODUCTS = 'LOCATION_ON_SELECT_PRODUCTS';
export const LOCATION_ON_REMOVE_SELECTED_PRODUCT = 'LOCATION_ON_REMOVE_SELECTED_PRODUCT';
export const LOCATION_SHOW_SUPERVISOR_SELECT_MODAL_OPEN = 'LOCATION_SHOW_SUPERVISOR_SELECT_MODAL_OPEN';
export const LOCATION_SHOW_SUPERVISOR_SELECT_MODAL_CLOSE = 'LOCATION_SHOW_SUPERVISOR_SELECT_MODAL_CLOSE';
export const LOCATION_ON_SELECT_TEMP_SUPERVISORS = 'LOCATION_ON_SELECT_TEMP_SUPERVISORS';
export const LOCATION_ON_SELECT_SUPERVISORS = 'LOCATION_ON_SELECT_SUPERVISORS';

//SUPERVISOR
export const SUPERVISOR_SEARCH = 'SUPERVISOR_SEARCH';
export const SUPERVISOR_SEARCH_SUCCESS = 'SUPERVISOR_SEARCH_SUCCESS';
export const SUPERVISOR_SEARCH_FAILED = 'SUPERVISOR_SEARCH_FAILED';
export const SUPERVISOR_GET_SELECTED_SUPERVISOR = 'SUPERVISOR_GET_SELECTED_SUPERVISOR';
export const SUPERVISOR_ADD_NEW_SUPERVISOR = 'SUPERVISOR_ADD_NEW_SUPERVISOR';
export const SUPERVISOR_GET_SELECTED_SUPERVISOR_SUCCESS = 'SUPERVISOR_GET_SELECTED_SUPERVISOR_SUCCESS';
export const SUPERVISOR_SAVE_UPDATE_SUCCESS = 'SUPERVISOR_SAVE_UPDATE_SUCCESS';
export const SUPERVISOR_GET_SELECTED_SUPERVISOR_FAILED = 'SUPERVISOR_GET_SELECTED_SUPERVISOR_FAILED';
export const SAVE_UPDATE_SUPERVISOR = 'SAVE_UPDATE_SUPERVISOR';
export const SAVE_UPDATE_SUPERVISOR_SUCCESS = 'SAVE_UPDATE_SUPERVISOR_SUCCESS';
export const SAVE_UPDATE_SUPERVISOR_FAILED = 'SAVE_UPDATE_SUPERVISOR_FAILED';
export const SUPERVISOR_RESET_SELECTED_SUPERVISOR = 'SUPERVISOR_RESET_SELECTED_SUPERVISOR';
export const SUB_BUSINESS_UNIT_ADD_EDIT_SELECT_SUB_BUSINESS_UNIT_MODAL_OPEN = 'SUB_BUSINESS_UNIT_ADD_EDIT_SELECT_SUB_BUSINESS_UNIT_MODAL_OPEN';
export const SUPERVISOR_ADD_EDIT_UPDATE_SELECTED_SUB_BUSINESS_UNIT = 'SUPERVISOR_ADD_EDIT_UPDATE_SELECTED_SUB_BUSINESS_UNIT';
export const SUPERVISOR_ADD_EDIT_UPDATE_TMP_SELECTED_SUB_BUSINESS_UNIT = 'SUPERVISOR_ADD_EDIT_UPDATE_TMP_SELECTED_SUB_BUSINESS_UNIT';
export const SUPERVISOR_ADD_EDIT_SELECT_SUB_BUSINESS_UNIT_MODAL_CLOSE = 'SUPERVISOR_ADD_EDIT_SELECT_SUB_BUSINESS_UNIT_MODAL_CLOSE';
export const SUPERVISOR_ADD_EDIT_REMOVE_TMP_SELECTED_SUB_BUSINESS_UNIT = 'SUPERVISOR_ADD_EDIT_REMOVE_TMP_SELECTED_SUB_BUSINESS_UNIT';

//Business Unit
export const BUSINESS_UNIT_SEARCH = 'BUSINESS_UNIT_SEARCH';
export const BUSINESS_UNIT_SEARCH_SUCCESS = 'BUSINESS_UNIT_SEARCH_SUCCESS';
export const BUSINESS_UNIT_SEARCH_FAILED = 'BUSINESS_UNIT_SEARCH_FAILED';
export const BUSINESS_UNIT_ADD_NEW_BUSINESS_UNIT = 'BUSINESS_UNIT_ADD_NEW_BUSINESS_UNIT';
export const BUSINESS_UNIT_GET_SELECTED_BUSINESS_UNIT = 'BUSINESS_UNIT_GET_SELECTED_BUSINESS_UNIT';
export const BUSINESS_UNIT_GET_SELECTED_BUSINESS_UNIT_FAILED = 'BUSINESS_UNIT_GET_SELECTED_BUSINESS_UNIT_FAILED';
export const BUSINESS_UNIT_GET_SELECTED_BUSINESS_UNIT_SUCCESS = 'BUSINESS_UNIT_GET_SELECTED_BUSINESS_UNIT_SUCCESS';
export const SAVE_UPDATE_BUSINESS_UNIT = 'SAVE_UPDATE_BUSINESS_UNIT';
export const SAVE_UPDATE_BUSINESS_UNIT_SUCCESS = 'SAVE_UPDATE_BUSINESS_UNIT_SUCCESS';
export const SAVE_UPDATE_BUSINESS_UNIT_FAILED = 'SAVE_UPDATE_BUSINESS_UNIT_FAILED';
export const BUSINESS_UNIT_RESET_SELECTED_BUSINESS_UNIT = 'BUSINESS_UNIT_RESET_SELECTED_BUSINESS_UNIT';

export const BUSINESS_UNIT_PRODUCT_SEARCH = 'BUSINESS_UNIT_PRODUCT_SEARCH';
export const BUSINESS_UNIT_PRODUCT_SEARCH_SUCCESS = 'BUSINESS_UNIT_PRODUCT_SEARCH_SUCCESS';
export const BUSINESS_UNIT_PRODUCT_SEARCH_FAILED = 'BUSINESS_UNIT_PRODUCT_SEARCH_FAILED';

//Sub Business Unit
export const SUB_BU_SEARCH = 'SUB_BU_SEARCH';
export const SUB_BU_SEARCH_SUCCESS = 'SUB_BU_SEARCH_SUCCESS';
export const SUB_BU_SEARCH_FAILED = 'SUB_BU_SEARCH_FAILED';
export const SUB_BU_ADD_NEW_SUB_BU = 'SUB_BU_ADD_NEW_SUB_BU';
export const SUB_BU_GET_SELECTED_SUB_BU = 'SUB_BU_GET_SELECTED_SUB_BU';
export const SUB_BU_GET_SELECTED_SUB_BU_FAILED = 'SUB_BU_GET_SELECTED_SUB_BU_FAILED';
export const SUB_BU_GET_SELECTED_SUB_BU_SUCCESS = 'SUB_BU_GET_SELECTED_SUB_BU_SUCCESS';
export const SAVE_UPDATE_SUB_BU = 'SAVE_UPDATE_SUB_BU';
export const SAVE_UPDATE_SUB_BU_SUCCESS = 'SAVE_UPDATE_SUB_BU_SUCCESS';
export const SAVE_UPDATE_SUB_BU_FAILED = 'SAVE_UPDATE_SUB_BU_FAILED';
export const SUB_BU_RESET_SELECTED_SUB_BU = 'SUB_BU_RESET_SELECTED_SUB_BU';
export const SUB_BU_ADD_EDIT_UPDATE_SELECTED_SUPERVISOR = 'SUB_BU_ADD_EDIT_UPDATE_SELECTED_SUPERVISOR';
export const SUB_BU_ADD_EDIT_UPDATE_TMP_SELECTED_SUPERVISORS = 'SUB_BU_ADD_EDIT_UPDATE_TMP_SELECTED_SUPERVISORS';
export const SUB_BU_ADD_EDIT_SELECT_SUPERVISOR_MODAL_CLOSE = 'SUB_BU_ADD_EDIT_SELECT_SUPERVISOR_MODAL_CLOSE';
export const SUB_BU_ADD_EDIT_SELECT_SUPERVISOR_MODAL_OPEN = 'SUB_BU_ADD_EDIT_SELECT_SUPERVISOR_MODAL_OPEN';
export const SUB_BU_ADD_EDIT_REMOVE_TMP_SELECTED_SUPERVISORS = 'SUB_BU_ADD_EDIT_REMOVE_TMP_SELECTED_SUPERVISORS';
export const LOCATION_ON_REMOVE_SELECTED_SUPERVISORS = 'LOCATION_ON_REMOVE_SELECTED_SUPERVISORS';


//PRODUCTS
export const PRODUCT_SEARCH = 'PRODUCT_SEARCH';
export const PRODUCT_SEARCH_SUCCESS = 'PRODUCT_SEARCH_SUCCESS';
export const PRODUCT_SEARCH_FAILED = 'PRODUCT_SEARCH_FAILED';
export const PRODUCT_ADD_NEW_PRODUCT = 'PRODUCT_ADD_NEW_PRODUCT';
export const PRODUCT_GET_SELECTED_PRODUCT = 'PRODUCT_GET_SELECTED_PRODUCT';
export const PRODUCT_GET_SELECTED_PRODUCT_FAILED = 'PRODUCT_GET_SELECTED_PRODUCT_FAILED';
export const PRODUCT_GET_SELECTED_PRODUCT_SUCCESS = 'PRODUCT_GET_SELECTED_PRODUCT_SUCCESS';
export const SAVE_UPDATE_PRODUCT = 'SAVE_UPDATE_PRODUCT';
export const SAVE_UPDATE_PRODUCT_SUCCESS = 'SAVE_UPDATE_PRODUCT_SUCCESS';
export const SAVE_UPDATE_PRODUCT_FAILED = 'SAVE_UPDATE_PRODUCT_FAILED';
export const PRODUCT_RESET_SELECTED_PRODUCT = 'PRODUCT_RESET_SELECTED_PRODUCT';
export const UPLOAD_BULK_PRODUCT = 'UPLOAD_BULK_PRODUCT';
export const UPLOAD_BULK_PRODUCT_FAILED = 'UPLOAD_BULK_PRODUCT_FAILED';
export const UPLOAD_BULK_PRODUCT_SUCCESS = 'UPLOAD_BULK_PRODUCT_SUCCESS';

export const PRODUCT_ADD_CATEGORY_MODAL_OPEN = 'PRODUCT_ADD_CATEGORY_MODAL_OPEN';
export const PRODUCT_ADD_CATEGORY_MODAL_CLOSE = 'PRODUCT_ADD_CATEGORY_MODAL_CLOSE';
export const PRODUCT_SELECT_CATEGORY_WISE_VARIANT = 'PRODUCT_SELECT_CATEGORY_WISE_VARIANT';
export const PRODUCT_UPDATE_CATEGORY_WISE_VARIANT = 'PRODUCT_UPDATE_CATEGORY_WISE_VARIANT';
export const PRODUCT_GET_SELECTED_CATEGORY_WISE_VARIANT_DATA = 'PRODUCT_GET_SELECTED_CATEGORY_WISE_VARIANT_DATA';
export const PRODUCT_CLEAR_GET_SELECTED_CATEGORY_WISE_VARIANT_DATA = 'PRODUCT_CLEAR_GET_SELECTED_CATEGORY_WISE_VARIANT_DATA';
export const PRODUCT_MANIPULATE_PRODUCT_CATEGORY_DATA = 'PRODUCT_MANIPULATE_PRODUCT_CATEGORY_DATA';
export const PRODUCT_REMOVE_PRE_SELECTED_VARIANT = 'PRODUCT_REMOVE_PRE_SELECTED_VARIANT';
export const PRODUCT_REMOVE_PRE_SELECTED_CATEGORY = 'PRODUCT_REMOVE_PRE_SELECTED_CATEGORY';
export const PRODUCT_REMOVE_UPDATE_CATEGORY_WISE_VARIANT = 'PRODUCT_REMOVE_UPDATE_CATEGORY_WISE_VARIANT';

//Company
export const COMPANY_SEARCH = 'COMPANY_SEARCH';
export const COMPANY_SEARCH_SUCCESS = 'COMPANY_SEARCH_SUCCESS';
export const COMPANY_SEARCH_FAILED = 'COMPANY_SEARCH_FAILED';
export const COMPANY_GET_SELECTED_COMPANY = 'COMPANY_GET_SELECTED_COMPANY';
export const COMPANY_GET_SELECTED_COMPANY_SUCCESS = 'COMPANY_GET_SELECTED_COMPANY_SUCCESS';
export const COMPANY_GET_SELECTED_COMPANY_FAILED = 'COMPANY_GET_SELECTED_COMPANY_FAILED';
export const COMPANY_ADD_NEW_COMPANY = 'COMPANY_ADD_NEW_COMPANY';
export const SAVE_UPDATE_COMPANY = 'SAVE_UPDATE_COMPANY';
export const SAVE_UPDATE_COMPANY_SUCCESS = 'SAVE_UPDATE_COMPANY_SUCCESS';
export const SAVE_UPDATE_COMPANY_FAILED = 'SAVE_UPDATE_COMPANY_FAILED';
export const COMPANY_RESET_SELECTED_COMPANY = 'COMPANY_RESET_SELECTED_COMPANY';

export const COMPANY_ADD_EDIT_SELECT_SUBSIDIARY_MODAL_OPEN = 'COMPANY_ADD_EDIT_SELECT_SUBSIDIARY_MODAL_OPEN';
export const COMPANY_ADD_EDIT_SELECT_SUBSIDIARY_MODAL_CLOSE = 'COMPANY_ADD_EDIT_SELECT_SUBSIDIARY_MODAL_CLOSE';
export const COMPANY_ADD_EDIT_UPDATE_TMP_SELECTED_SUBSIDIARY = 'COMPANY_ADD_EDIT_UPDATE_TMP_SELECTED_SUBSIDIARY';
export const COMPANY_ADD_EDIT_UPDATE_SELECTED_SUBSIDIARY = 'COMPANY_ADD_EDIT_UPDATE_SELECTED_SUBSIDIARY';
export const COMPANY_SEARCH_FOR_SUBSIDIARY = 'COMPANY_SEARCH_FOR_SUBSIDIARY';
export const COMPANY_SEARCH_FOR_SUBSIDIARY_SUCCESS = 'COMPANY_SEARCH_FOR_SUBSIDIARY_SUCCESS';
export const COMPANY_SEARCH_FOR_SUBSIDIARY_FAILED = 'COMPANY_SEARCH_FOR_SUBSIDIARY_FAILED';
export const COMPANY_SET_SELECTED_COMPANY = 'COMPANY_SET_SELECTED_COMPANY';
export const COMPANY_ADD_EDIT_UPDATE_REMOVED_SUBSIDIARY = 'COMPANY_ADD_EDIT_UPDATE_REMOVED_SUBSIDIARY';

//MeasureUnitCategory
export const MEASURE_UNIT_CATEGORY_SEARCH = 'MEASURE_UNIT_CATEGORY_SEARCH';
export const MEASURE_UNIT_CATEGORY_SEARCH_SUCCESS = 'MEASURE_UNIT_CATEGORY_SEARCH_SUCCESS';
export const MEASURE_UNIT_CATEGORY_SEARCH_FAILED = 'MEASURE_UNIT_CATEGORY_SEARCH_FAILED';
export const MEASURE_UNIT_CATEGORY_ADD_NEW_MEASURE_UNIT_CATEGORY = 'MEASURE_UNIT_CATEGORY_ADD_NEW_MEASURE_UNIT_CATEGORY';
export const MEASURE_UNIT_CATEGORY_GET_SELECTED_MEASURE_UNIT_CATEGORY = 'MEASURE_UNIT_CATEGORY_GET_SELECTED_MEASURE_UNIT_CATEGORY';
export const MEASURE_UNIT_CATEGORY_GET_SELECTED_MEASURE_UNIT_CATEGORY_FAILED = 'MEASURE_UNIT_CATEGORY_GET_SELECTED_MEASURE_UNIT_CATEGORY_FAILED';
export const MEASURE_UNIT_CATEGORY_GET_SELECTED_MEASURE_UNIT_CATEGORY_SUCCESS = 'MEASURE_UNIT_CATEGORY_GET_SELECTED_MEASURE_UNIT_CATEGORY_SUCCESS';
export const SAVE_UPDATE_MEASURE_UNIT_CATEGORY = 'SAVE_UPDATE_MEASURE_UNIT_CATEGORY';
export const SAVE_UPDATE_MEASURE_UNIT_CATEGORY_SUCCESS = 'SAVE_UPDATE_MEASURE_UNIT_CATEGORY_SUCCESS';
export const SAVE_UPDATE_MEASURE_UNIT_CATEGORY_FAILED = 'SAVE_UPDATE_MEASURE_UNIT_CATEGORY_FAILED';
export const MEASURE_UNIT_CATEGORY_RESET_SELECTED_MEASURE_UNIT_CATEGORY = 'MEASURE_UNIT_CATEGORY_RESET_SELECTED_MEASURE_UNIT_CATEGORY';


//MEASURE
export const MEASURE_SEARCH = 'MEASURE_SEARCH';
export const MEASURE_SEARCH_SUCCESS = 'MEASURE_SEARCH_SUCCESS';
export const MEASURE_SEARCH_FAILED = 'MEASURE_SEARCH_FAILED';
export const MEASURE_ADD_NEW_MEASURE = 'MEASURE_ADD_NEW_MEASURE';
export const MEASURE_GET_SELECTED_MEASURE = 'MEASURE_GET_SELECTED_MEASURE';
export const MEASURE_GET_SELECTED_MEASURE_FAILED = 'MEASURE_GET_SELECTED_MEASURE_FAILED';
export const MEASURE_GET_SELECTED_MEASURE_SUCCESS = 'MEASURE_GET_SELECTED_MEASURE_SUCCESS';
export const MEASURE_GET_SELECTED_MEASURE_SUMMERY = 'MEASURE_GET_SELECTED_MEASURE_SUMMERY';
export const MEASURE_GET_SELECTED_MEASURE_SUMMERY_FAILED = 'MEASURE_GET_SELECTED_MEASURE_SUMMERY_FAILED';
export const MEASURE_GET_SELECTED_MEASURE__SUMMERY_SUCCESS = 'MEASURE_GET_SELECTED_MEASURE__SUMMERY_SUCCESS';
export const SAVE_UPDATE_MEASURE = 'SAVE_UPDATE_MEASURE';
export const SAVE_UPDATE_MEASURE_SUCCESS = 'SAVE_UPDATE_MEASURE_SUCCESS';
export const SAVE_UPDATE_MEASURE_FAILED = 'SAVE_UPDATE_MEASURE_FAILED';
export const MEASURE_RESET_SELECTED_MEASURE = 'MEASURE_RESET_SELECTED_MEASURE';
export const ADV_MEASURE_SELECT_MEASURES = 'ADV_MEASURE_SELECT_MEASURES';
export const ADV_MEASURE_SELECT_STANDARDS = 'ADV_MEASURE_SELECT_STANDARDS';
export const ADV_MEASURE_REMOVE_SELECTED_MEASURE = 'ADV_MEASURE_REMOVE_SELECTED_MEASURE';
export const ADV_MEASURE_SELECT_MEASURE_UPDATE_NAME = 'ADV_MEASURE_SELECT_MEASURE_UPDATE_NAME';
export const ADV_MEASURE_SELECT_STANDARDS_UPDATE_NAME = 'ADV_MEASURE_SELECT_STANDARDS_UPDATE_NAME';
export const ADV_MEASURE_SELECT_MEASURE_UPDATE_FORMULA = 'ADV_MEASURE_SELECT_MEASURE_UPDATE_FORMULA';
export const ADV_MEASURE_SELECT_MEASURE_TEST_VALUES = 'ADV_MEASURE_SELECT_MEASURE_TEST_VALUES';
export const ADV_MEASURE_SELECT_MEASURE_TEST_EXPRESSION = 'ADV_MEASURE_SELECT_MEASURE_TEST_EXPRESSION';
export const ADV_MEASURE_SELECT_MEASURE_TEST_EXPRESSION_SUCCESS = 'ADV_MEASURE_SELECT_MEASURE_TEST_EXPRESSION_SUCCESS';
export const ADV_MEASURE_SELECT_MEASURE_TEST_EXPRESSION_FAILED = 'ADV_MEASURE_SELECT_MEASURE_TEST_EXPRESSION_FAILED';
export const ADV_MEASURE_SELECT_OPERATOR = 'ADV_MEASURE_SELECT_OPERATOR';
export const ADV_MEASURE_RE_POPULATE_SELECTED_MEASURE = 'ADV_MEASURE_RE_POPULATE_SELECTED_MEASURE';
export const ADV_MEASURE_LOAD_SELECTED_MEASURES_BY_IDS = 'ADV_MEASURE_LOAD_SELECTED_MEASURES_BY_IDS';
export const ADV_MEASURE_LOAD_SELECTED_MEASURES_BY_IDS_SUCCESS = 'ADV_MEASURE_LOAD_SELECTED_MEASURES_BY_IDS_SUCCESS';
export const ADV_MEASURE_LOAD_SELECTED_MEASURES_BY_IDS_FAILED = 'ADV_MEASURE_LOAD_SELECTED_MEASURES_BY_IDS_FAILED';
export const ADV_MEASURE_RESET = 'ADV_MEASURE_LOAD_SELECTED_MEASURES_BY_IDS_FAILED';
export const ADV_MEASURE_SELECTED_COMPANY_ID = 'ADV_MEASURE_SELECTED_COMPANY_ID';
export const ADV_MEASURE_SELECTED_MEASURE_CATEGORY = 'ADV_MEASURE_SELECTED_MEASURE_CATEGORY';
export const ADV_MEASURE_SELECT_STANDARD_TEST_VALUES = 'ADV_MEASURE_SELECT_STANDARD_TEST_VALUES';
export const SET_SELECTED_STANDARD_TEST_VALUE = 'SET_SELECTED_STANDARD_TEST_VALUE';
export const ADV_MEASURE_LOAD_SELECTED_STANDARDS_BY_IDS = 'ADV_MEASURE_LOAD_SELECTED_STANDARDS_BY_IDS';
export const ADV_MEASURE_LOAD_SELECTED_STANDARDS_BY_IDS_SUCCESS = 'ADV_MEASURE_LOAD_SELECTED_STANDARDS_BY_IDS_SUCCESS';
export const ADV_MEASURE_LOAD_SELECTED_STANDARDS_BY_IDS_FAILED = 'ADV_MEASURE_LOAD_SELECTED_STANDARDS_BY_IDS_FAILED';
export const UPLOAD_BULK_MEASURES = "UPLOAD_BULK_MEASURES";
export const UPLOAD_BULK_MEASURES_SUCCESS = "UPLOAD_BULK_MEASURES_SUCCESS";
export const UPLOAD_BULK_MEASURES_FAILED = "UPLOAD_BULK_MEASURES_FAILED";

//MEASURE GROUP
export const MEASURE_GROUP_SEARCH = 'MEASURE_GROUP_SEARCH';
export const MEASURE_GROUP_SEARCH_SUCCESS = 'MEASURE_GROUP_SEARCH_SUCCESS';
export const MEASURE_GROUP_SEARCH_FAILED = 'MEASURE_GROUP_SEARCH_FAILED';
export const MEASURE_GROUP_ADD_NEW_MEASURE_GROUP = 'MEASURE_GROUP_ADD_NEW_MEASURE_GROUP';
export const MEASURE_GROUP_GET_SELECTED_MEASURE_GROUP = 'MEASURE_GROUP_GET_SELECTED_MEASURE_GROUP';
export const MEASURE_GROUP_GET_SELECTED_MEASURE_GROUP_FAILED = 'MEASURE_GROUP_GET_SELECTED_MEASURE_GROUP_FAILED';
export const MEASURE_GROUP_GET_SELECTED_MEASURE_GROUP_SUCCESS = 'MEASURE_GROUP_GET_SELECTED_MEASURE_GROUP_SUCCESS';
export const SAVE_UPDATE_MEASURE_GROUP = 'SAVE_UPDATE_MEASURE_GROUP';
export const SAVE_UPDATE_MEASURE_GROUP_SUCCESS = 'SAVE_UPDATE_MEASURE_GROUP_SUCCESS';
export const SAVE_UPDATE_MEASURE_GROUP_FAILED = 'SAVE_UPDATE_MEASURE_GROUP_FAILED';
export const MEASURE_GROUP_RESET_SELECTED_MEASURE_GROUP = 'MEASURE_GROUP_RESET_SELECTED_MEASURE_GROUP';
export const MEASURE_GROUP_ADD_EDIT_REMOVE_TMP_SELECTED_MEASURES = 'MEASURE_GROUP_ADD_EDIT_REMOVE_TMP_SELECTED_MEASURES';
export const MEASURE_GROUP_SELECTED_MEASURE_GROUP_RESET = 'MEASURE_GROUP_SELECTED_MEASURE_GROUP_RESET';
export const MEASURE_GROUP_SELECT_MEASURE_CATEGORY = 'MEASURE_GROUP_SELECT_MEASURE_CATEGORY';
export const MEASURE_GROUP_MEASURE_ORDER_CHANGED = 'MEASURE_GROUP_MEASURE_ORDER_CHANGED';


//MeasureUnit
export const MEASURE_UNIT_SEARCH = 'MEASURE_UNIT_SEARCH';
export const MEASURE_UNIT_SEARCH_SUCCESS = 'MEASURE_UNIT_SEARCH_SUCCESS';
export const MEASURE_UNIT_SEARCH_FAILED = 'MEASURE_UNIT_SEARCH_FAILED';
export const MEASURE_UNIT_PAGE_SEARCH = 'MEASURE_UNIT_PAGE_SEARCH';
export const MEASURE_UNIT_PAGE_SEARCH_SUCCESS = 'MEASURE_UNIT_PAGE_SEARCH_SUCCESS';
export const MEASURE_UNIT_PAGE_SEARCH_FAILED = 'MEASURE_UNIT_PAGE_SEARCH_FAILED';
export const MEASURE_UNIT_ADD_NEW_MEASURE_UNIT = 'MEASURE_UNIT_ADD_NEW_MEASURE_UNIT';
export const MEASURE_UNIT_GET_SELECTED_MEASURE_UNIT = 'MEASURE_UNIT_GET_SELECTED_MEASURE_UNIT';
export const MEASURE_UNIT_GET_SELECTED_MEASURE_UNIT_FAILED = 'MEASURE_UNIT_GET_SELECTED_MEASURE_UNIT_FAILED';
export const MEASURE_UNIT_GET_SELECTED_MEASURE_UNIT_SUCCESS = 'MEASURE_UNIT_GET_SELECTED_MEASURE_UNIT_SUCCESS';
export const SAVE_UPDATE_MEASURE_UNIT = 'SAVE_UPDATE_MEASURE_UNIT';
export const SAVE_UPDATE_MEASURE_UNIT_SUCCESS = 'SAVE_UPDATE_MEASURE_UNIT_SUCCESS';
export const SAVE_UPDATE_MEASURE_UNIT_FAILED = 'SAVE_UPDATE_MEASURE_UNIT_FAILED';
export const MEASURE_UNIT_RESET_SELECTED_MEASURE_UNIT = 'MEASURE_UNIT_RESET_SELECTED_MEASURE_UNIT';
export const MEASURE_GROUP_ADD_EDIT_UPDATE_SELECTED_MEASURES = 'MEASURE_GROUP_ADD_EDIT_UPDATE_SELECTED_MEASURES';
export const MEASURE_GROUP_ADD_EDIT_SELECT_MEASURES_MODAL_OPEN = 'MEASURE_GROUP_ADD_EDIT_SELECT_MEASURES_MODAL_OPEN';
export const MEASURE_GROUP_ADD_EDIT_SELECT_MEASURES_MODAL_CLOSE = 'MEASURE_GROUP_ADD_EDIT_SELECT_MEASURES_MODAL_CLOSE';
export const MEASURE_GROUP_ADD_EDIT_UPDATE_TMP_SELECTED_MEASURES = 'MEASURE_GROUP_ADD_EDIT_UPDATE_TMP_SELECTED_MEASURES';
export const MEASURE_GROUP_GET_LOAD_MEASURE_GROUPS = 'MEASURE_GROUP_GET_LOAD_MEASURE_GROUPS';
export const MEASURE_GROUP_GET_LOAD_MEASURE_GROUPS_SUCCESS = 'MEASURE_GROUP_GET_LOAD_MEASURE_GROUPS_SUCCESS';
export const MEASURE_GROUP_GET_LOAD_MEASURE_GROUPS_FAILED = 'MEASURE_GROUP_GET_LOAD_MEASURE_GROUPS_FAILED';


//PROCESS
export const PROCESS_LOAD_DATA_ENTRY_FORM = 'PROCESS_LOAD_DATA_ENTRY_FORM';
export const PROCESS_SET_SELECTED_PROCESS = 'PROCESS_SET_SELECTED_PROCESS';
export const PROCESS_RESET_SELECTED_PROCESS = 'PROCESS_RESET_SELECTED_PROCESS';
export const PROCESS_LOAD_DATA_ENTRY_FORM_SUCCESS = 'PROCESS_LOAD_DATA_ENTRY_FORM_SUCCESS';
export const PROCESS_LOAD_DATA_ENTRY_FORM_FAILED = 'PROCESS_LOAD_DATA_ENTRY_FORM_FAILED';
export const PROCESS_LOAD_DATA_ENTRY_FORM_TABS = 'PROCESS_LOAD_DATA_ENTRY_FORM_TABS';
export const PROCESS_LOAD_DATA_ENTRY_FORM_TABS_SUCCESS = 'PROCESS_LOAD_DATA_ENTRY_FORM_TABS_SUCCESS';
export const PROCESS_LOAD_DATA_ENTRY_FORM_TABS_FAILED = 'PROCESS_LOAD_DATA_ENTRY_FORM_TABS_FAILED';
export const PROCESS_DATA_INPUTS_SAVE_UPDATE = 'PROCESS_DATA_INPUTS_SAVE_UPDATE';
export const PROCESS_DATA_INPUTS_SAVE_UPDATE_SUCCESS = 'PROCESS_DATA_INPUTS_SAVE_UPDATE_SUCCESS';
export const PROCESS_DATA_INPUTS_SAVE_UPDATE_FAILED = 'PROCESS_DATA_INPUTS_SAVE_UPDATE_FAILED';
export const PROCESS_DATA_TARGET_BUDGET_ROLL_FORWARD = 'PROCESS_DATA_TARGET_BUDGET_ROLL_FORWARD';
export const PROCESS_DATA_TARGET_BUDGET_ROLL_FORWARD_SUCCESS = 'PROCESS_DATA_TARGET_BUDGET_ROLL_FORWARD_SUCCESS';
export const PROCESS_DATA_TARGET_BUDGET_ROLL_FORWARD_FAILED = 'PROCESS_DATA_TARGET_BUDGET_ROLL_FORWARD_FAILED';
export const PROCESS_DATA_PRV_NEXT_PROCESS_DATA_GET = 'PROCESS_DATA_PRV_NEXT_PROCESS_DATA_GET';
export const PROCESS_DATA_PRV_NEXT_PROCESS_DATA_GET_SUCCESS = 'PROCESS_DATA_PRV_NEXT_PROCESS_DATA_GET_SUCCESS';
export const PROCESS_DATA_PRV_NEXT_PROCESS_DATA_GET_FAILED = 'PROCESS_DATA_PRV_NEXT_PROCESS_DATA_GET_FAILED';
export const PROCESS_DATA_CALCULATE_ADV_MEASURE_DATA = 'PROCESS_DATA_CALCULATE_ADV_MEASURE_DATA';
export const PROCESS_DATA_CALCULATE_ADV_MEASURE_DATA_SUCCESS = 'PROCESS_DATA_CALCULATE_ADV_MEASURE_DATA_SUCCESS';
export const PROCESS_DATA_CALCULATE_ADV_MEASURE_DATA_FAILED = 'PROCESS_DATA_CALCULATE_ADV_MEASURE_DATA_FAILED';


//Process Templates
export const PROCESS_TEMPLATE_SEARCH = 'PROCESS_TEMPLATE_SEARCH';
export const PROCESS_TEMPLATE_SEARCH_SUCCESS = 'PROCESS_TEMPLATE_SEARCH_SUCCESS';
export const PROCESS_TEMPLATE_SEARCH_FAILED = 'PROCESS_TEMPLATE_SEARCH_FAILED';
export const SUB_BUSINESS_UNIT_SEARCH = 'SUB_BUSINESS_UNIT_SEARCH';
export const SUB_BUSINESS_UNIT_SEARCH_SUCCESS = 'SUB_BUSINESS_UNIT_SEARCH_SUCCESS';
export const SUB_BUSINESS_UNIT_SEARCH_FAILED = 'SUB_BUSINESS_UNIT_SEARCH_FAILED';
export const PROCESS_TEMPLATE_GET_SELECTED_PROCESS_TEMPLATE = 'PROCESS_TEMPLATE_GET_SELECTED_PROCESS_TEMPLATE';
export const PROCESS_TEMPLATE_ADD_NEW_PROCESS_TEMPLATE = 'PROCESS_TEMPLATE_ADD_NEW_PROCESS_TEMPLATE';
export const SAVE_UPDATE_PROCESS_TEMPLATE = 'SAVE_UPDATE_PROCESS_TEMPLATE';
export const SAVE_UPDATE_PROCESS_TEMPLATE_SUCCESS = 'SAVE_UPDATE_PROCESS_TEMPLATE_SUCCESS';
export const SAVE_UPDATE_PROCESS_TEMPLATE_FAILED = 'SAVE_UPDATE_PROCESS_TEMPLATE_FAILED';
export const PROCESS_TEMPLATE_GET_SELECTED_PROCESS_TEMPLATE_SUCCESS = 'PROCESS_TEMPLATE_GET_SELECTED_PROCESS_TEMPLATE_SUCCESS';
export const PROCESS_TEMPLATE_GET_SELECTED_PROCESS_TEMPLATE_FAILED = 'PROCESS_TEMPLATE_GET_SELECTED_PROCESS_TEMPLATE_FAILED';
export const PROCESS_TEMPLATE_RESET_SELECTED_PROCESS_TEMPLATE = 'PROCESS_TEMPLATE_RESET_SELECTED_PROCESS_TEMPLATE';
export const PROCESS_TEMPLATE_ADD_EDIT_SELECT_MEASURE_GROUPS_MODAL_OPEN = 'PROCESS_TEMPLATE_ADD_EDIT_SELECT_MEASURE_GROUPS_MODAL_OPEN';
export const PROCESS_TEMPLATE_ADD_EDIT_SELECT_MEASURE_GROUPS_MODAL_CLOSE = 'PROCESS_TEMPLATE_ADD_EDIT_SELECT_MEASURE_GROUPS_MODAL_CLOSE';
export const PROCESS_TEMPLATE_ADD_EDIT_UPDATE_TMP_SELECTED_MEASURE_GROUPS = 'PROCESS_TEMPLATE_ADD_EDIT_UPDATE_TMP_SELECTED_MEASURE_GROUPS';
export const PROCESS_TEMPLATE_ADD_EDIT_UPDATE_SELECTED_MEASURE_GROUP = 'PROCESS_TEMPLATE_ADD_EDIT_UPDATE_SELECTED_MEASURE_GROUP';
export const PROCESS_TEMPLATE_REPLICATE_PROCESS = 'PROCESS_TEMPLATE_REPLICATE_PROCESS';
export const PROCESS_TEMPLATE_REPLICATE_PROCESS_SUCCESS = 'PROCESS_TEMPLATE_REPLICATE_PROCESS_SUCCESS';
export const PROCESS_TEMPLATE_REPLICATE_PROCESS_FAILED = 'PROCESS_TEMPLATE_REPLICATE_PROCESS_FAILED';
export const PROCESS_TEMPLATE_REPLICATE_ACTION_MODAL_OPEN = 'PROCESS_TEMPLATE_REPLICATE_ACTION_MODAL_OPEN';
export const PROCESS_TEMPLATE_REPLICATE_ACTION_MODAL_CLOSE = 'PROCESS_TEMPLATE_REPLICATE_ACTION_MODAL_CLOSE';
export const PROCESS_TEMPLATE_ADD_EDIT_REMOVE_TEMP_SELECTED_MEASURES_GROUPS = 'PROCESS_TEMPLATE_ADD_EDIT_REMOVE_TEMP_SELECTED_MEASURES_GROUPS';
export const PROCESS_TEMPLATE_GET_DATA_ENTRY_FORM_TEMPLATE = "PROCESS_TEMPLATE_GET_DATA_ENTRY_FORM_TEMPLATE";
export const PROCESS_TEMPLATE_GET_DATA_ENTRY_FORM_TEMPLATE_SUCCESS = "PROCESS_TEMPLATE_GET_DATA_ENTRY_FORM_TEMPLATE_SUCCESS";
export const PROCESS_TEMPLATE_GET_DATA_ENTRY_FORM_TEMPLATE_FAILED = "PROCESS_TEMPLATE_GET_DATA_ENTRY_FORM_TEMPLATE_FAILED";
export const PROCESS_TEMPLATE_DATA_ENTRY_FORM_UPLOAD_WITHOUT_OVERRIDE_PERMISSION = "PROCESS_TEMPLATE_DATA_ENTRY_FORM_UPLOAD_WITHOUT_OVERRIDE_PERMISSION";
export const PROCESS_TEMPLATE_DATA_ENTRY_FORM_UPLOAD_WITHOUT_OVERRIDE_PERMISSION_SUCCESS = "PROCESS_TEMPLATE_DATA_ENTRY_FORM_UPLOAD_WITHOUT_OVERRIDE_PERMISSION_SUCCESS";
export const PROCESS_TEMPLATE_DATA_ENTRY_FORM_UPLOAD_WITHOUT_OVERRIDE_PERMISSION_FAILED = "PROCESS_TEMPLATE_DATA_ENTRY_FORM_UPLOAD_WITHOUT_OVERRIDE_PERMISSION_FAILED";
export const PROCESS_TEMPLATE_DATA_ENTRY_FORM_UPLOAD_WITH_OVERRIDE_PERMISSION = "PROCESS_TEMPLATE_DATA_ENTRY_FORM_UPLOAD_WITH_OVERRIDE_PERMISSION";
export const PROCESS_TEMPLATE_DATA_ENTRY_FORM_UPLOAD_WITH_OVERRIDE_PERMISSION_SUCCESS = "PROCESS_TEMPLATE_DATA_ENTRY_FORM_UPLOAD_WITH_OVERRIDE_PERMISSION_SUCCESS";
export const PROCESS_TEMPLATE_DATA_ENTRY_FORM_UPLOAD_WITH_OVERRIDE_PERMISSION_FAILED = "PROCESS_TEMPLATE_DATA_ENTRY_FORM_UPLOAD_WITH_OVERRIDE_PERMISSION_FAILED";
export const PROCESS_TEMPLATE_NOTIFICATION_SET_LATE_DATA_INPUT = "PROCESS_TEMPLATE_NOTIFICATION_SET_LATE_DATA_INPUT";
export const PROCESS_TEMPLATE_NOTIFICATION_RESET_LATE_DATA_INPUT = "PROCESS_TEMPLATE_NOTIFICATION_RESET_LATE_DATA_INPUT";

export const PROCESS_TEMPLATE_PAST_DATA_MISSING_PUSH_NOTIFICATION_RESET = "PROCESS_TEMPLATE_PAST_DATA_MISSING_PUSH_NOTIFICATION_RESET";
export const PROCESS_TEMPLATE_PAST_DATA_MISSING_EMAIL_NOTIFICATION_RESET = "PROCESS_TEMPLATE_PAST_DATA_MISSING_EMAIL_NOTIFICATION_RESET";
export const PROCESS_TEMPLATE_PAST_DATA_MISSING_RESET = "PROCESS_TEMPLATE_PAST_DATA_MISSING_RESET";
export const PROCESS_TEMPLATE_ALLOW_NOTIFICATION_RESET = "PROCESS_TEMPLATE_ALLOW_NOTIFICATION_RESET";
export const PROCESS_TEMPLATE_ALLOW_NOTIFICATION = "PROCESS_TEMPLATE_ALLOW_NOTIFICATION";
export const PROCESS_TEMPLATE_PAST_DATA_MISSING_IS_PAST_DATA_MISSING = "PROCESS_TEMPLATE_PAST_DATA_MISSING_IS_PAST_DATA_MISSING";
export const PROCESS_TEMPLATE_PAST_DATA_MISSING_NOTIFY_FREQUNECY = "PROCESS_TEMPLATE_PAT_DATA_MISSING_NOTIFY_FREQUNECY";
export const PROCESS_TEMPLATE_PAST_DATA_MISSING_FREQUENCY_TO_LOOK_BACK = "PROCESS_TEMPLATE_PAST_DATA_MISSING_FREQUENCY_TO_LOOK_BACK";
export const PROCESS_TEMPLATE_PAST_DATA_MISSING_IS_EMAIL_NOTIFICATION = "PROCESS_TEMPLATE_PAST_DATA_MISSING_IS_EMAIL_NOTIFICATION";
export const PROCESS_TEMPLATE_PAST_DATA_MISSING_EMAIL_NOTIFICATION_CONTENT = "PROCESS_TEMPLATE_PAST_DATA_MISSING_EMAIL_NOTIFICATION_CONTENT";
export const PROCESS_TEMPLATE_PAST_DATA_MISSING_IS_PUSH_NOTIFICATION = "PROCESS_TEMPLATE_PAST_DATA_MISSING_IS_PUSH_NOTIFICATION";
export const PROCESS_TEMPLATE_PAST_DATA_MISSING_PUSH_NOTIFICATION_CONTENT = "PROCESS_TEMPLATE_PAST_DATA_MISSING_PUSH_NOTIFICATION_CONTENT";
export const PROCESS_TEMPLATE_NOTIFICATION_PICK_USER_GROUP_MODEL_IS_OPEN = "PROCESS_TEMPLATE_NOTIFICATION_PICK_USER_GROUP_MODEL_IS_OPEN";
export const PROCESS_TEMPLATE_NOTIFICATION_USER_GROUP_TEMP_SELECTED = "PROCESS_TEMPLATE_NOTIFICATION_USER_GROUP_TEMP_SELECTED";
export const PROCESS_TEMPLATE_GET_NOTIFICATION_TEMPLATES = "PROCESS_TEMPLATE_GET_NOTIFICATION_TEMPLATES";
export const PROCESS_TEMPLATE_GET_PROCESS_TEMPLATE_GET_NOTIFICATION_TEMPLATES_SUCCESS = "PROCESS_TEMPLATE_GET_PROCESS_TEMPLATE_GET_NOTIFICATION_TEMPLATES_SUCCESS";
export const PROCESS_TEMPLATE_GET_PROCESS_TEMPLATE_GET_NOTIFICATION_TEMPLATES_FAILED = "PROCESS_TEMPLATE_GET_PROCESS_TEMPLATE_GET_NOTIFICATION_TEMPLATES_FAILED";
export const PROCESS_TEMPLATE_NOTIFICATION_SET_FREQUENCY_LOOK_BACK_INVALID = "PROCESS_TEMPLATE_NOTIFICATION_SET_FREQUENCY_LOOK_BACK_INVALID";
export const PROCESS_TEMPLATE_NOTIFICATION_SET_LATE_DATA_FREQUENCY_INVALID = "PROCESS_TEMPLATE_NOTIFICATION_SET_LATE_DATA_FREQUENCY_INVALID";

//FREQUENCY
export const FREQUENCY_DATA_GET_ALL_FREQUENCY = 'FREQUENCY_DATA_GET_ALL_FREQUENCY';
export const FREQUENCY_DATA_GET_ALL_FREQUENCY_SUCCESS = 'FREQUENCY_DATA_GET_ALL_FREQUENCY_SUCCESS';
export const FREQUENCY_DATA_GET_ALL_FREQUENCY_FAILED = 'FREQUENCY_DATA_GET_ALL_FREQUENCY_FAILED';

//ADD-EDIT WIDGET

export const ADD_EDIT_WIDGET_USER_SEARCH = 'ADD_EDIT_WIDGET_USER_SEARCH';
export const ADD_EDIT_WIDGET_USER_SEARCH_SUCCESS = 'ADD_EDIT_WIDGET_USER_SEARCH_SUCCESS';
export const ADD_EDIT_WIDGET_USER_SEARCH_FAILED = 'ADD_EDIT_WIDGET_USER_SEARCH_FAILED';

export const ADD_EDIT_WIDGET_LOAD_SELECTED_WIDGET_SUMMERY = 'ADD_EDIT_WIDGET_LOAD_SELECTED_WIDGET_SUMMERY';
export const ADD_EDIT_WIDGET_LOAD_SELECTED_WIDGET_SUMMERY_SUCCESS = 'ADD_EDIT_WIDGET_LOAD_SELECTED_WIDGET_SUMMERY_SUCCESS';
export const ADD_EDIT_WIDGET_LOAD_SELECTED_WIDGET_SUMMERY_FAILED = 'ADD_EDIT_WIDGET_LOAD_SELECTED_WIDGET_SUMMERY_FAILED';

export const ADD_EDIT_WIDGET_PROCESS_TEMPLATE_SEARCH = 'ADD_EDIT_WIDGET_PROCESS_TEMPLATE_SEARCH';
export const ADD_EDIT_WIDGET_PROCESS_TEMPLATE_SEARCH_SUCCESS = 'ADD_EDIT_WIDGET_PROCESS_TEMPLATE_SEARCH_SUCCESS';
export const ADD_EDIT_WIDGET_PROCESS_TEMPLATE_SEARCH_FAILED = 'ADD_EDIT_WIDGET_PROCESS_TEMPLATE_SEARCH_FAILED';
export const ADD_EDIT_WIDGET_SUB_BUSINESS_UNIT_SEARCH = 'ADD_EDIT_WIDGET_SUB_BUSINESS_UNIT_SEARCH';
export const ADD_EDIT_WIDGET_SUB_BUSINESS_UNIT_SEARCH_SUCCESS = 'ADD_EDIT_WIDGET_SUB_BUSINESS_UNIT_SEARCH_SUCCESS';
export const ADD_EDIT_WIDGET_SUB_BUSINESS_UNIT_SEARCH_FAILED = 'ADD_EDIT_WIDGET_SUB_BUSINESS_UNIT_SEARCH_FAILED';

export const ADD_EDIT_WIDGET_SELECT_ELIGIBLE_USER_TYPE = 'ADD_EDIT_WIDGET_SELECT_ELIGIBLE_USER_TYPE';
export const ADD_EDIT_WIDGET_SELECT_USERS = 'ADD_EDIT_WIDGET_SELECT_USERS';
export const ADD_EDIT_WIDGET_SELECT_USERS_ADD = 'ADD_EDIT_WIDGET_SELECT_USERS_UPDATE';
export const ADD_EDIT_WIDGET_SELECT_PROCESS_IDS = 'ADD_EDIT_WIDGET_SELECT_PROCESS_IDS';
export const ADD_EDIT_WIDGET_SELECT_PROCESS_ITEMS = 'ADD_EDIT_WIDGET_SELECT_PROCESS_ITEMS';

export const WIDGET_RESET_SELECTED_WIDGET_TYPE = "WIDGET_RESET_SELECTED_WIDGET_TYPE";

export const ADD_EDIT_WIDGET_LOAD_PROCESS_MEASURES = 'ADD_EDIT_WIDGET_LOAD_PROCESS_MEASURES';
export const ADD_EDIT_WIDGET_LOAD_PROCESS_MEASURES_SUCCESS = 'ADD_EDIT_WIDGET_LOAD_PROCESS_MEASURES_SUCCESS';
export const ADD_EDIT_WIDGET_LOAD_PROCESS_MEASURES_FAILED = 'ADD_EDIT_WIDGET_LOAD_PROCESS_MEASURES_FAILED';

export const ADD_EDIT_WIDGET_SELECT_PROCESS_MEASURE = 'ADD_EDIT_WIDGET_SELECT_PROCESS_MEASURE';
export const ADD_EDIT_WIDGET_REPLACE_PROCESS_MEASURE = 'ADD_EDIT_WIDGET_REPLACE_PROCESS_MEASURE';
export const ADD_EDIT_WIDGET_SELECT_PROCESS_MEASURE_COLOR_CHANGE = 'ADD_EDIT_WIDGET_SELECT_PROCESS_MEASURE_COLOR_CHANGE';
export const ADD_EDIT_WIDGET_SELECT_PROCESS_MEASURE_CHECK_OPTIONS_CHANGE = 'ADD_EDIT_WIDGET_SELECT_PROCESS_MEASURE_CHECK_OPTIONS_CHANGE';
export const ADD_EDIT_WIDGET_SELECT_PROCESS_MEASURE_BUDGET_COLOR_CHANGE = 'ADD_EDIT_WIDGET_SELECT_PROCESS_MEASURE_BUDGET_COLOR_CHANGE';
export const ADD_EDIT_WIDGET_SELECT_PROCESS_MEASURE_TARGET_COLOR_CHANGE = 'ADD_EDIT_WIDGET_SELECT_PROCESS_MEASURE_TARGET_COLOR_CHANGE';
export const ADD_EDIT_WIDGET_SELECT_PROCESS_MEASURE_SET_SORTED_POSITION = 'ADD_EDIT_WIDGET_SELECT_PROCESS_MEASURE_SET_SORTED_POSITION';
export const ADD_EDIT_WIDGET_SELECT_PROCESS_MEASURE_ENABLE_MAIN_MEASURE_SORT = 'ADD_EDIT_WIDGET_SELECT_PROCESS_MEASURE_ENABLE_MAIN_MEASURE_SORT';

export const ADD_EDIT_WIDGET_SAVE_UPDATE_WIDGET = 'ADD_EDIT_WIDGET_SAVE_UPDATE_WIDGET';
export const ADD_EDIT_WIDGET_SAVE_UPDATE_WIDGET_SUCCESS = 'ADD_EDIT_WIDGET_SAVE_UPDATE_WIDGET_SUCCESS';
export const ADD_EDIT_WIDGET_SAVE_UPDATE_WIDGET_FAILED = 'ADD_EDIT_WIDGET_SAVE_UPDATE_WIDGET_FAILED';

export const ADD_EDIT_WIDGET_REPLICATE_WIDGET = 'ADD_EDIT_WIDGET_REPLICATE_WIDGET';
export const ADD_EDIT_WIDGET_REPLICATE_WIDGET_SUCCESS = 'ADD_EDIT_WIDGET_REPLICATE_WIDGET_SUCCESS';
export const ADD_EDIT_WIDGET_REPLICATE_WIDGET_FAILED = 'ADD_EDIT_WIDGET_REPLICATE_WIDGET_FAILED';

export const ADD_EDIT_WIDGET_CLEAR_DATA = 'ADD_EDIT_WIDGET_CLEAR_DATA';

export const ADD_EDIT_WIDGET_ON_SELECT_COMPANY_ID = 'ADD_EDIT_WIDGET_ON_SELECT_COMPANY_ID';
export const ADD_EDIT_WIDGET_ON_SELECT_LOCATION_ID = 'ADD_EDIT_WIDGET_ON_SELECT_LOCATION_ID';
export const ADD_EDIT_WIDGET_ON_SELECT_CATEGORY = 'ADD_EDIT_WIDGET_ON_SELECT_CATEGORY';

export const ADD_EDIT_WIDGET_LOAD_SELECTED_WIDGET = 'ADD_EDIT_WIDGET_LOAD_SELECTED_WIDGET';
export const ADD_EDIT_WIDGET_LOAD_SELECTED_WIDGET_SUCCESS = 'ADD_EDIT_WIDGET_LOAD_SELECTED_WIDGET_SUCCESS';
export const ADD_EDIT_WIDGET_LOAD_SELECTED_WIDGET_FAILED = 'ADD_EDIT_WIDGET_LOAD_SELECTED_WIDGET_FAILED';

export const ADD_EDIT_WIDGET_ADD_PROCESS_MEASURE_RULE = 'ADD_EDIT_WIDGET_ADD_PROCESS_MEASURE_RULE';
export const ADD_EDIT_WIDGET_EDIT_PROCESS_MEASURE_RULE = 'ADD_EDIT_WIDGET_EDIT_PROCESS_MEASURE_RULE';
export const ADD_EDIT_WIDGET_REMOVE_PROCESS_MEASURE_RULE = 'ADD_EDIT_WIDGET_REMOVE_PROCESS_MEASURE_RULE';

export const ADD_EDIT_WIDGET_MANIPULATE_SELECTED_WIDGET_STATE = 'ADD_EDIT_WIDGET_MANIPULATE_SELECTED_WIDGET_STATE';
export const ADD_EDIT_WIDGET_LOAD_SELECTED_WIDGET_PROCESS_TEMPLATES = 'ADD_EDIT_WIDGET_LOAD_SELECTED_WIDGET_PROCESS_TEMPLATES';
export const ADD_EDIT_WIDGET_LOAD_SELECTED_WIDGET_PROCESS_TEMPLATES_SUCCESS = 'ADD_EDIT_WIDGET_LOAD_SELECTED_WIDGET_PROCESS_TEMPLATES_SUCCESS';
export const ADD_EDIT_WIDGET_LOAD_SELECTED_WIDGET_PROCESS_TEMPLATES_FAILED = 'ADD_EDIT_WIDGET_LOAD_SELECTED_WIDGET_PROCESS_TEMPLATES_FAILED';
export const ADD_EDIT_WIDGET_LOAD_SELECTED_WIDGET_PROCESS_WISE_MEASURE_DATA = 'ADD_EDIT_WIDGET_LOAD_SELECTED_WIDGET_PROCESS_WISE_MEASURE_DATA';
export const ADD_EDIT_WIDGET_LOAD_SELECTED_WIDGET_PROCESS_WISE_MEASURE_DATA_SUCCESS = 'ADD_EDIT_WIDGET_LOAD_SELECTED_WIDGET_PROCESS_WISE_MEASURE_DATA_SUCCESS';
export const ADD_EDIT_WIDGET_LOAD_SELECTED_WIDGET_PROCESS_WISE_MEASURE_DATA_FAILED = 'ADD_EDIT_WIDGET_LOAD_SELECTED_WIDGET_PROCESS_WISE_MEASURE_DATA_FAILED';

export const ADD_EDIT_WIDGET_REMOVE_SELECTED_MEASURE = 'ADD_EDIT_WIDGET_REMOVE_SELECTED_MEASURE';

export const ADD_EDIT_WIDGET_SELECT_AXIS_MEASURE = 'ADD_EDIT_WIDGET_SELECT_AXIS_MEASURE';

export const ADD_EDIT_WIDGET_UPDATE_WIDGET_INCLUDES = 'ADD_EDIT_WIDGET_UPDATE_WIDGET_INCLUDES';
export const ADD_EDIT_WIDGET_SET_SELECTED_MEASURE_RECALCULATE = 'ADD_EDIT_WIDGET_SET_SELECTED_MEASURE_RECALCULATE';

export const ADD_EDIT_WIDGET_SELECT_MEASURES_MODAL_OPEN = 'ADD_EDIT_WIDGET_SELECT_MEASURES_MODAL_OPEN';
export const ADD_EDIT_WIDGET_SELECT_MEASURES_MODAL_CLOSE = 'ADD_EDIT_WIDGET_SELECT_MEASURES_MODAL_CLOSE';
export const ADD_EDIT_WIDGET_SELECT_MEASURES = 'ADD_EDIT_WIDGET_SELECT_MEASURES';
export const ADD_EDIT_WIDGET_UPDATE_TMP_SELECTED_MEASURES = 'ADD_EDIT_WIDGET_UPDATE_TMP_SELECTED_MEASURES';
export const ADD_EDIT_WIDGET_SEARCH = 'ADD_EDIT_WIDGET_SEARCH';
export const ADD_EDIT_WIDGET_SEARCH_SUCCESS = 'ADD_EDIT_WIDGET_SEARCH_SUCCESS';
export const ADD_EDIT_WIDGET_SEARCH_FAILED = 'ADD_EDIT_WIDGET_SEARCH_FAILED';

export const ADD_EDIT_WIDGET_SELECT_DATA_ENTRY_MODAL_OPEN = 'ADD_EDIT_WIDGET_SELECT_DATA_ENTRY_MODAL_OPEN';
export const ADD_EDIT_WIDGET_SELECT_DATA_ENTRY_MODAL_CLOSE = 'ADD_EDIT_WIDGET_SELECT_DATA_ENTRY_MODAL_CLOSE';
export const ADD_EDIT_WIDGET_SELECT_DATA_ENTRY_FORMS = 'ADD_EDIT_WIDGET_SELECT_DATA_ENTRY_FORMS';
export const ADD_EDIT_WIDGET_UPDATE_TMP_DATA_ENTRY_FORMS = 'ADD_EDIT_WIDGET_UPDATE_TMP_DATA_ENTRY_FORMS';

export const ADD_EDIT_WIDGET_DATA_FORM_SEARCH = 'ADD_EDIT_WIDGET_DATA_FORM_SEARCH';
export const ADD_EDIT_WIDGET_DATA_FORM_SEARCH_SUCCESS = 'ADD_EDIT_WIDGET_DATA_FORM_SEARCH_SUCCESS';
export const ADD_EDIT_WIDGET_DATA_FORM_SEARCH_FAILED = 'ADD_EDIT_WIDGET_DATA_FORM_SEARCH_FAILED';

export const ADD_EDIT_WIDGET_DATA_ENTRY_FORM_MEASURE_MODAL_OPEN = 'ADD_EDIT_WIDGET_DATA_ENTRY_FORM_MEASURE_MODAL_OPEN';
export const ADD_EDIT_WIDGET_DATA_ENTRY_FORM_MEASURE_MODAL_CLOSE = 'ADD_EDIT_WIDGET_DATA_ENTRY_FORM_MEASURE_MODAL_CLOSE';

export const ADD_EDIT_WIDGET_DATA_ENTRY_FORM__MEASURES_LIST = "ADD_EDIT_WIDGET_DATA_ENTRY_FORM__MEASURES_LIST";
export const ADD_EDIT_WIDGET_DATA_ENTRY_FORM__MEASURES_LIST_SUCCESS = "ADD_EDIT_WIDGET_DATA_ENTRY_FORM__MEASURES_LIST_SUCCESS";
export const ADD_EDIT_WIDGET_DATA_ENTRY_FORM__MEASURES_LIST_FAILED = "ADD_EDIT_WIDGET_DATA_ENTRY_FORM__MEASURES_LIST_FAILED";
export const ADD_EDIT_WIDGET_UPDATE_TMP_DATA_ENTRY_FORM_MEASURES = 'ADD_EDIT_WIDGET_UPDATE_TMP_DATA_ENTRY_FORM_MEASURES';
export const ADD_EDIT_WIDGET_UPDATE_DATA_ENTRY_FORM_MEASURES = 'ADD_EDIT_WIDGET_UPDATE_DATA_ENTRY_FORM_MEASURES';
export const ADD_EDIT_WIDGET_UPDATE_DATA_ENTRY_FORM_MEASURE_RE_CALCULATION = 'ADD_EDIT_WIDGET_UPDATE_DATA_ENTRY_FORM_MEASURE_RE_CALCULATION';
export const ADD_EDIT_WIDGET_UPDATE_DATA_ENTRY_FORM_MEASURE_AGGREGATE_TYPE = 'ADD_EDIT_WIDGET_UPDATE_DATA_ENTRY_FORM_MEASURE_AGGREGATE_TYPE';
export const ADD_EDIT_WIDGET_UPDATE_ADD_EDIT_WIDGET_DATA_STRUCTURE = 'ADD_EDIT_WIDGET_UPDATE_ADD_EDIT_WIDGET_DATA_STRUCTURE';

export const ADD_EDIT_WIDGET_LOAD_MEASURES_BY_MEASURE_ID = 'ADD_EDIT_WIDGET_LOAD_MEASURES_BY_MEASURE_ID';
export const ADD_EDIT_WIDGET_LOAD_MEASURES_BY_MEASURE_ID_SUCCESS = 'ADD_EDIT_WIDGET_LOAD_MEASURES_BY_MEASURE_ID';
export const ADD_EDIT_WIDGET_LOAD_MEASURES_BY_MEASURE_ID_FAILED = 'ADD_EDIT_WIDGET_LOAD_MEASURES_BY_MEASURE_ID_FAILED';

export const ADD_EDIT_WIDGET_LOAD_PROCESS_TEMPLATES_BY_ID = 'ADD_EDIT_WIDGET_LOAD_PROCESS_TEMPLATES_BY_ID';
export const ADD_EDIT_WIDGET_LOAD_PROCESS_TEMPLATES_BY_ID_SUCCESS = 'ADD_EDIT_WIDGET_LOAD_PROCESS_TEMPLATES_BY_ID_SUCCESS';
export const ADD_EDIT_WIDGET_LOAD_PROCESS_TEMPLATES_BY_ID_FAILED = 'ADD_EDIT_WIDGET_LOAD_PROCESS_TEMPLATES_BY_ID_FAILED';

export const ADD_EDIT_WIDGET_REMOVE_DATA_ENTRY_FORM_MEASURE = 'ADD_EDIT_WIDGET_REMOVE_DATA_ENTRY_FORM_MEASURE';
export const ADD_EDIT_WIDGET_REMOVE_DATA_ENTRY_FORM = 'ADD_EDIT_WIDGET_REMOVE_DATA_ENTRY_FORM';
export const ADD_EDIT_WIDGET_REMOVE_MEASURE = 'ADD_EDIT_WIDGET_REMOVE_MEASURE';

export const ADD_EDIT_WIDGET_TOGGLE_SORT = 'ADD_EDIT_WIDGET_TOGGLE_SORT';
export const ADD_EDIT_WIDGET_SORT = 'ADD_EDIT_WIDGET_SORT';
export const ADD_EDIT_WIDGET_UPDATE_MEASURE_WISE_EXPANDED = 'ADD_EDIT_WIDGET_UPDATE_MEASURE_WISE_EXPANDED';

export const ADD_EDIT_WIDGET_STACK_SORT = 'ADD_EDIT_WIDGET_STACK_SORT';

export const ADD_EDIT_WIDGET_UPDATE_MEASURE_WISE_DISPLAY_IN_WIDGET = 'ADD_EDIT_WIDGET_UPDATE_MEASURE_WISE_DISPLAY_IN_WIDGET';
export const ADD_EDIT_WIDGET_UPDATE_MEASURE_WISE_DISPLAY_TREND_LINE = 'ADD_EDIT_WIDGET_UPDATE_MEASURE_WISE_DISPLAY_TREND_LINE';

//WEB AUDIT
export const WEB_AUDIT_SEARCH = 'WEB_AUDIT_SEARCH';
export const WEB_AUDIT_SEARCH_SUCCESS = 'WEB_AUDIT_SEARCH_SUCCESS';
export const WEB_AUDIT_SEARCH_FAILED = 'WEB_AUDIT_SEARCH_FAILED';
export const WEB_AUDIT_GET_CONSTANTS = 'WEB_AUDIT_GET_CONSTANTS';
export const WEB_AUDIT_GET_CONSTANTS_SUCCESS = 'WEB_AUDIT_GET_CONSTANTS_SUCCESS';
export const WEB_AUDIT_GET_CONSTANTS_FAILED = 'WEB_AUDIT_GET_CONSTANTS_FAILED';
export const WEB_AUDIT_SET_SELECTED_WEB_AUDIT = 'WEB_AUDIT_SET_SELECTED_WEB_AUDIT';
export const WEB_AUDIT_OPEN_MODEL = 'WEB_AUDIT_OPEN_MODEL';
export const WEB_AUDIT_CLOSE_MODEL = 'WEB_AUDIT_CLOSE_MODEL';

//WIDGETS
export const WIDGET_SEARCH = 'WIDGET_SEARCH';
export const WIDGET_SEARCH_SUCCESS = 'WIDGET_SEARCH_SUCCESS';
export const WIDGET_SEARCH_FAILED = 'WIDGET_SEARCH_FAILED';
export const WIDGET_RESET_SELECTED_WIDGET = "WIDGET_RESET_SELECTED_WIDGET";

//DASHBOARD
export const DASHBOARD_CREATED = 'DASHBOARD_CREATED';
export const DASHBOARD_GET_WIDGET_SUCCESS = 'DASHBOARD_GET_WIDGET_SUCCESS';
export const SAVE_UPDATE_DASHBOARD_SUCCESS = 'SAVE_UPDATE_DASHBOARD_SUCCESS';
export const DASHBOARD_GET_WIDGET = 'DASHBOARD_GET_WIDGET';
export const DASHBOARD_GET_WIDGET_FAILED = 'DASHBOARD_GET_WIDGET_FAILED';
export const SAVE_UPDATE_DASHBOARD = 'SAVE_UPDATE_DASHBOARD';
export const SAVE_UPDATE_DASHBOARD_FAILED = 'SAVE_UPDATE_DASHBOARD_FAILED';
export const DASHBOARD_GET_DASHBOARD_BY_USER_ID = 'DASHBOARD_GET_DASHBOARD_BY_USER_ID';
export const DASHBOARD_GET_DASHBOARD_BY_USER_ID_SUCCESS = 'DASHBOARD_GET_DASHBOARD_BY_USER_ID_SUCCESS';
export const DASHBOARD_GET_DASHBOARD_BY_USER_ID_FAILED = 'DASHBOARD_GET_DASHBOARD_BY_USER_ID_FAILED';
export const DASHBOARD_RESET = 'DASHBOARD_RESET';
export const DASHBOARD_SET_LAYOUTS = 'DASHBOARD_SET_LAYOUTS';
export const DASHBOARD_GET_LAYOUTS = 'DASHBOARD_GET_LAYOUTS';
export const DASHBOARD_UPDATE_DASHBOARD_DTOS = 'DASHBOARD_UPDATE_DASHBOARD_DTOS';
export const DASHBOARD_SET_DASHBOARD_NAME = 'DASHBOARD_SET_DASHBOARD_NAME';
export const DASHBOARD_GET_WIDGET_DATA = 'DASHBOARD_GET_WIDGET_DATA';
export const DASHBOARD_GET_WIDGET_DATA_SUCCESS = 'DASHBOARD_GET_WIDGET_DATA_SUCCESS';
export const DASHBOARD_GET_WIDGET_DATA_FAILED = 'DASHBOARD_GET_WIDGET_DATA_FAILED';
export const DASHBOARD_SET_DASHBOARD_DATE_RANGE = 'DASHBOARD_SET_DASHBOARD_DATE_RANGE';
export const DASHBOARD_SET_DASHBOARD_ID = 'DASHBOARD_SET_DASHBOARD_ID';
export const DASHBOARD_TOGGLE_IS_EDITABLE = 'DASHBOARD_TOGGLE_IS_EDITABLE';
export const DASHBOARD_SET_NEW_DASHBOARD_CREATED = 'DASHBOARD_SET_NEW_DASHBOARD_CREATED';
export const DASHBOARD_COPY_CREATED = 'DASHBOARD_COPY_CREATED';
export const DASHBOARD_GET_ELIGIBLE_USERS = 'DASHBOARD_GET_ELIGIBLE_USERS_SUCCESS';
export const DASHBOARD_GET_ELIGIBLE_USERS_SUCCESS = 'DASHBOARD_GET_ELIGIBLE_USERS_SUCCESS';
export const DASHBOARD_GET_ELIGIBLE_USERS_FAILED = 'DASHBOARD_GET_ELIGIBLE_USERS_FAILED';
export const DASHBOARD_SET_SELECTED_USERS = 'DASHBOARD_SET_SELECTED_USERS';
export const DASHBOARD_SHARE_DASHBOARD = 'DASHBOARD_SHARE_DASHBOARD';
export const DASHBOARD_SHARE_DASHBOARD_SUCCESS = 'DASHBOARD_SHARE_DASHBOARD_SUCCESS';
export const DASHBOARD_SHARE_DASHBOARD_FAILED = 'DASHBOARD_SHARE_DASHBOARD_FAILED';
export const DASHBOARD_GET_SHARED_USERS_BY_DASHBOARD_ID = 'DASHBOARD_GET_SHARED_USERS_BY_DASHBOARD_ID';
export const DASHBOARD_GET_SHARED_USERS_BY_DASHBOARD_ID_SUCCESS = 'DASHBOARD_GET_SHARED_USERS_BY_DASHBOARD_ID_SUCCESS';
export const DASHBOARD_GET_SHARED_USERS_BY_DASHBOARD_ID_FAILED = 'DASHBOARD_GET_SHARED_USERS_BY_DASHBOARD_ID_FAILED';
export const DASHBOARD_GET_SELECTED_WIDGET = 'DASHBOARD_GET_SELECTED_WIDGET';
export const DASHBOARD_GET_SELECTED_WIDGET_SUCCESS = 'DASHBOARD_GET_SELECTED_WIDGET_SUCCESS';
export const DASHBOARD_GET_SELECTED_WIDGET_FAILED = 'DASHBOARD_GET_SELECTED_WIDGET_FAILED';
export const DASHBOARD_SET_DASHBOARD_UPDATED_WIDGET_COLOR = 'DASHBOARD_SET_DASHBOARD_UPDATED_WIDGET_COLOR';
export const DASHBOARD_RESET_SHARED_DASHBOARD = 'DASHBOARD_RESET_SHARED_DASHBOARD';
export const DASHBOARD_SET_CACHED_DATE_RANGE_TO_REDUCER = 'DASHBOARD_SET_CACHED_DATE_RANGE_TO_REDUCER';
export const DASHBOARD_CLEAR_DATE_RANGE = "DASHBOARD_CLEAR_DATE_RANGE";

export const DASHBOARD_SHOW_FILTER_MODAL = "DASHBOARD_SHOW_FILTER_MODAL";
export const DASHBOARD_HIDE_FILTER_MODAL = "DASHBOARD_HIDE_FILTER_MODAL";
export const DASHBOARD_WIDGET_DATA_AGGREGATE = "DASHBOARD_WIDGET_DATA_AGGREGATE";
export const DASHBOARD_SET_FILTER_APPLIED_WIDGET_ID = "DASHBOARD_SET_FILTER_APPLIED_WIDGET_ID";
export const DASHBOARD_ON_APPLY_FILTER_TO_WIDGET = "DASHBOARD_ON_APPLY_FILTER_TO_WIDGET";

export const DASHBOARD_GET_FACTORY_PRODUCTS = 'DASHBOARD_GET_FACTORY_PRODUCTS';
export const DASHBOARD_GET_FACTORY_PRODUCTS_SUCCESS = 'DASHBOARD_GET_FACTORY_PRODUCTS_SUCCESS';
export const DASHBOARD_GET_FACTORY_PRODUCTS_FAILED = 'DASHBOARD_GET_FACTORY_PRODUCTS_FAILED';

export const DASHBOARD_GET_FACTORY_PRODUCTION_LINES = 'DASHBOARD_GET_FACTORY_PRODUCTION_LINES';
export const DASHBOARD_GET_FACTORY_PRODUCTION_LINES_SUCCESS = 'DASHBOARD_GET_FACTORY_PRODUCTION_LINES_SUCCESS';
export const DASHBOARD_GET_FACTORY_PRODUCTION_LINES_FAILED = 'DASHBOARD_GET_FACTORY_PRODUCTION_LINES_FAILED';


export const DASHBOARD_GET_FACTORY_SHIFTS = 'DASHBOARD_GET_FACTORY_SHIFTS';
export const DASHBOARD_GET_FACTORY_SHIFTS_SUCCESS = 'DASHBOARD_GET_FACTORY_SHIFTS_SUCCESS';
export const DASHBOARD_GET_FACTORY_SHIFTS_FAILED = 'DASHBOARD_GET_FACTORY_SHIFTS_FAILED';

export const DASHBOARD_GET_FACTORY_SUPERVISORS = 'DASHBOARD_GET_FACTORY_SUPERVISORS';
export const DASHBOARD_GET_FACTORY_SUPERVISORS_SUCCESS = 'DASHBOARD_GET_FACTORY_SUPERVISORS_SUCCESS';
export const DASHBOARD_GET_FACTORY_SUPERVISORS_FAILED = 'DASHBOARD_GET_FACTORY_SUPERVISORS_FAILED';

export const DASHBOARD_GET_DOWNTIME_DATA = 'DASHBOARD_GET_DOWNTIME_DATA';
export const DASHBOARD_GET_DOWNTIME_DATA_SUCCESS = 'DASHBOARD_GET_DOWNTIME_DATA_SUCCESS';
export const DASHBOARD_GET_DOWNTIME_DATA_FAILED = 'DASHBOARD_GET_DOWNTIME_DATA_FAILED';

export const DASHBOARD_GET_WASTAGE_DATA = 'DASHBOARD_GET_WASTAGE_DATA';
export const DASHBOARD_GET_WASTAGE_DATA_SUCCESS = 'DASHBOARD_GET_WASTAGE_DATA_SUCCESS';
export const DASHBOARD_GET_WASTAGE_DATA_FAILED = 'DASHBOARD_GET_WASTAGE_DATA_FAILED';

export const DASHBOARD_GET_WIDGET_DATA_NEW = 'DASHBOARD_GET_WIDGET_DATA_NEW';
export const DASHBOARD_GET_WIDGET_DATA_NEW_SUCCESS = 'DASHBOARD_GET_WIDGET_DATA_NEW_SUCCESS';
export const DASHBOARD_GET_WIDGET_DATA_NEW_FAILED = 'DASHBOARD_GET_WIDGET_DATA_NEW_FAILED';

export const DASHBOARD_SAVE_DASHBOARD_REDUCER = 'DASHBOARD_SAVE_DASHBOARD_REDUCER';
export const DASHBOARD_RESTORE_DASHBOARD_REDUCER = 'DASHBOARD_RESTORE_DASHBOARD_REDUCER';

export const DASHBOARD_UPDATE_FILTER_DATES_GLOBAL = 'DASHBOARD_UPDATE_FILTER_DATES_GLOBAL';

export const DASHBOARD_UPDATE_DOWNTIME_WASTAGE_WIDGET_FILTERS = 'DASHBOARD_UPDATE_DOWNTIME_WASTAGE_WIDGET_FILTERS';

//REPORT
export const REPORT_GET_REPORT_TEMPLATE_TYPE = "REPORT_GET_REPORT_TEMPLATE_TYPE";
export const REPORT_GET_REPORT_TEMPLATE_TYPE_SUCCESS = "REPORT_GET_REPORT_TEMPLATE_TYPE_SUCCESS";
export const REPORT_GET_REPORT_TEMPLATE_TYPE_FAILED = "REPORT_GET_REPORT_TEMPLATE_TYPE_FAILED";
export const REPORT_GET_SUB_BUSINESS_UNIT = "REPORT_GET_SUB_BUSINESS_UNIT";
export const REPORT_GET_SUB_BUSINESS_UNIT_SUCCESS = "REPORT_GET_SUB_BUSINESS_UNIT_SUCCESS";
export const REPORT_GET_SUB_BUSINESS_UNIT_FAILED = "REPORT_GET_SUB_BUSINESS_UNIT_FAILED";
export const REPORT_ON_SELECT_COMPANY_ID = "REPORT_ON_SELECT_COMPANY_ID";
export const REPORT_ON_SELECT_BUSINESS_UNIT_ID = "REPORT_ON_SELECT_BUSINESS_UNIT_ID";
export const REPORT_ON_SELECT_BUSINESS_UNIT_LOCATION_IDS_SUB_BUSINESS_UNIT_IDS = "REPORT_ON_SELECT_BUSINESS_UNIT_LOCATION_IDS_SUB_BUSINESS_UNIT_IDS";
export const REPORT_SELECT_ELIGIBLE_USER_TYPE = "REPORT_SELECT_ELIGIBLE_USER_TYPE";
export const REPORT_SELECT_USERS = "REPORT_SELECT_USERS";
export const REPORT_GET_USER_SEARCH = "REPORT_GET_USER_SEARCH";
export const REPORT_GET_USER_SEARCH_SUCCESS = "REPORT_GET_USER_SEARCH_SUCCESS";
export const REPORT_GET_USER_SEARCH_FAILED = "REPORT_GET_USER_SEARCH_FAILED";
export const REPORT_PROCESS_TEMPLATE_SEARCH = "REPORT_PROCESS_TEMPLATE_SEARCH";
export const REPORT_PROCESS_TEMPLATE_SEARCH_SUCCESS = "REPORT_PROCESS_TEMPLATE_SEARCH_SUCCESS";
export const REPORT_PROCESS_TEMPLATE_SEARCH_FAILED = "REPORT_PROCESS_TEMPLATE_SEARCH_FAILED";
export const REPORT_SUB_BUSINESS_UNIT_SEARCH = "REPORT_SUB_BUSINESS_UNIT_SEARCH";
export const REPORT_SUB_BUSINESS_UNIT_SEARCH_SUCCESS = "REPORT_SUB_BUSINESS_UNIT_SEARCH_SUCCESS";
export const REPORT_SUB_BUSINESS_UNIT_SEARCH_FAILED = "REPORT_SUB_BUSINESS_UNIT_SEARCH_FAILED";
export const REPORT_SELECT_PROCESS_IDS = "REPORT_SELECT_PROCESS_IDS";
export const REPORT_SELECT_PROCESS_ITEMS = "REPORT_SELECT_PROCESS_ITEMS";
export const REPORT_LOAD_PROCESS_MEASURES = "REPORT_LOAD_PROCESS_MEASURES";
export const REPORT_LOAD_PROCESS_MEASURES_SUCCESS = "REPORT_LOAD_PROCESS_MEASURES_SUCCESS";
export const REPORT_LOAD_PROCESS_MEASURES_FAILED = "REPORT_LOAD_PROCESS_MEASURES_FAILED";
export const REPORT_SELECT_PROCESS_MEASURE_COLOR_CHANGE = "REPORT_SELECT_PROCESS_MEASURE_COLOR_CHANGE";
export const REPORT_REMOVE_SELECTED_MEASURE = "REPORT_REMOVE_SELECTED_MEASURE";
export const REPORT_SELECT_PROCESS_MEASURE_CHECK_OPTIONS_CHANGE = "REPORT_SELECT_PROCESS_MEASURE_CHECK_OPTIONS_CHANGE";
export const REPORT_SELECT_PROCESS_MEASURE_BUDGET_COLOR_CHANGE = "REPORT_SELECT_PROCESS_MEASURE_BUDGET_COLOR_CHANGE";
export const REPORT_SELECT_PROCESS_MEASURE_TARGET_COLOR_CHANGE = "REPORT_SELECT_PROCESS_MEASURE_TARGET_COLOR_CHANGE";
export const REPORT_SELECT_PROCESS_MEASURE = "REPORT_SELECT_PROCESS_MEASURE";
export const REPORT_ADD_PROCESS_MEASURE_RULE = "REPORT_ADD_PROCESS_MEASURE_RULE";
export const REPORT_EDIT_PROCESS_MEASURE_RULE = "REPORT_EDIT_PROCESS_MEASURE_RULE";
export const REPORT_REMOVE_PROCESS_MEASURE_RULE = "REPORT_REMOVE_PROCESS_MEASURE_RULE";
export const REPORT_CREATE_UPDATE_BASIC_REPORT_TEMPLATE = "REPORT_CREATE_UPDATE_BASIC_REPORT_TEMPLATE";
export const REPORT_CREATE_UPDATE_BASIC_REPORT_TEMPLATE_SUCCESS = "REPORT_CREATE_UPDATE_BASIC_REPORT_TEMPLATE_SUCCESS";
export const REPORT_CREATE_UPDATE_BASIC_REPORT_TEMPLATE_FAILED = "REPORT_CREATE_UPDATE_BASIC_REPORT_TEMPLATE_FAILED";
export const REPORT_GET_CALCULATION_OPERATORS = "REPORT_GET_CALCULATION_OPERATORS";
export const REPORT_GET_CALCULATION_OPERATORS_SUCCESS = "REPORT_GET_CALCULATION_OPERATORS_SUCCESS";
export const REPORT_GET_CALCULATION_OPERATORS_FAILED = "REPORT_GET_CALCULATION_OPERATORS_FAILED";
export const REPORT_GET_PAGED_DATE = "REPORT_GET_PAGED_DATE";
export const REPORT_GET_PAGED_DATE_SUCCESS = "REPORT_GET_PAGED_DATE_SUCCESS";
export const REPORT_GET_PAGED_DATE_FAILED = "REPORT_GET_PAGED_DATE_FAILED";
export const REPORT_ADD_NEW_SUPERVISOR = "REPORT_ADD_NEW_SUPERVISOR";
export const REPORT_GET_REPORT_TEMPLATE_BY_ID = "REPORT_GET_REPORT_TEMPLATE_BY_ID";
export const REPORT_GET_REPORT_TEMPLATE_BY_ID_SUCCESS = "REPORT_GET_REPORT_TEMPLATE_BY_ID_SUCCESS";
export const REPORT_GET_REPORT_TEMPLATE_BY_ID_FAILED = "REPORT_GET_REPORT_TEMPLATE_BY_ID_FAILED";
export const REPORT_GET_REPORT_TEMPLATE_BY_ID_SUMMERY = "REPORT_GET_REPORT_TEMPLATE_BY_ID_SUMMERY";
export const REPORT_GET_REPORT_TEMPLATE_BY_ID_SUMMERY_SUCCESS = "REPORT_GET_REPORT_TEMPLATE_BY_ID_SUMMERY_SUCCESS";
export const REPORT_GET_REPORT_TEMPLATE_BY_ID_SUMMERY_FAILED = "REPORT_GET_REPORT_TEMPLATE_BY_ID_SUMMERY_FAILED";
export const REPORT_CLEAR_DATA = "REPORT_CLEAR_DATA";
export const REPORT_MANIPULATE_SELECTED_WIDGET_STATE = "REPORT_MANIPULATE_SELECTED_WIDGET_STATE";
export const REPORT_LOAD_SELECTED_WIDGET_PROCESS_TEMPLATES = "REPORT_LOAD_SELECTED_WIDGET_PROCESS_TEMPLATES";
export const REPORT_LOAD_SELECTED_WIDGET_PROCESS_TEMPLATES_SUCCESS = "REPORT_LOAD_SELECTED_WIDGET_PROCESS_TEMPLATES_SUCCESS";
export const REPORT_LOAD_SELECTED_WIDGET_PROCESS_TEMPLATES_FAILED = "REPORT_LOAD_SELECTED_WIDGET_PROCESS_TEMPLATES_FAILED";
export const REPORT_LOAD_SELECTED_REPORT_PROCESS_WISE_MEASURE_DATA = "REPORT_LOAD_SELECTED_REPORT_PROCESS_WISE_MEASURE_DATA";
export const REPORT_LOAD_SELECTED_REPORT_PROCESS_WISE_MEASURE_DATA_SUCCESS = "REPORT_LOAD_SELECTED_REPORT_PROCESS_WISE_MEASURE_DATA_SUCCESS";
export const REPORT_LOAD_SELECTED_REPORT_PROCESS_WISE_MEASURE_DATA_FAILED = "REPORT_LOAD_SELECTED_REPORT_PROCESS_WISE_MEASURE_DATA_FAILED";
export const REPORT_ON_SELECT_FREQUENCY_ID = "REPORT_ON_SELECT_FREQUENCY_ID";
export const REPORT_LOAD_COMMON_FREQUENCY_FOR_PROCESS = "REPORT_LOAD_COMMON_FREQUENCY_FOR_PROCESS";
export const REPORT_LOAD_COMMON_FREQUENCY_FOR_PROCESS_SUCCESS = "REPORT_LOAD_COMMON_FREQUENCY_FOR_PROCESS_SUCCESS";
export const REPORT_LOAD_COMMON_FREQUENCY_FOR_PROCESS_FAILED = "REPORT_LOAD_COMMON_FREQUENCY_FOR_PROCESS_FAILED";
export const REPORT_ON_CHANGE_COMMON_FREQUENCY_LIST = "REPORT_ON_CHANGE_COMMON_FREQUENCY_LIST";
export const REPORT_ON_CHANGE_AGGREGATE_TYPE = "REPORT_ON_CHANGE_AGGREGATE_TYPE";
export const REPORT_RESET_REPORT_SCHEDULE_PARAMS = "REPORT_RESET_REPORT_SCHEDULE_PARAMS";
export const REPORT_RESET_REPORT_CONTEXT = "REPORT_RESET_REPORT_CONTEXT";
export const REPORT_RESET_EMAIL_SENDER_TEMP_SELECTED = "REPORT_RESET_EMAIL_SENDER_TEMP_SELECTED";
export const REPORT_RESET_SCHEDULER_SAVED = "REPORT_RESET_SCHEDULER_SAVED";
export const REPORT_SET_REPORT_TEMPLATE_SCHEDULER_ID = "REPORT_SET_REPORT_TEMPLATE_SCHEDULER_ID";
export const REPORT_SET_REPORT_TEMPLATE_ID = "REPORT_SET_REPORT_TEMPLATE_ID";
export const REPORT_SET_SCHEDULER_NAME = "REPORT_SET_SCHEDULER_NAME";
export const REPORT_SET_SCHEDULER_FROM_DATE = "REPORT_SET_SCHEDULER_FROM_DATE";
export const REPORT_SET_SCHEDULER_TO_DATE = "REPORT_SET_SCHEDULER_TO_DATE";
export const REPORT_SET_SCHEDULER_FREQUENCY = "REPORT_SET_SCHEDULER_FREQUENCY";
export const REPORT_SET_SCHEDULER_TIME = "REPORT_SET_SCHEDULER_TIME";
export const REPORT_SET_SCHEDULER_DATE_OF_WEEK = "REPORT_SET_SCHEDULER_DATE_OF_WEEK";
export const REPORT_SET_SCHEDULER_DATE = "REPORT_SET_SCHEDULER_DATE";
export const REPORT_SET_SCHEDULER_MONTH = "REPORT_SET_SCHEDULER_MONTH";
export const REPORT_SET_SCHEDULER_STATUS = "REPORT_SET_SCHEDULER_STATUS";
export const REPORT_SET_SCHEDULER_REPORT_EXPORT_FORMAT = "REPORT_SET_SCHEDULER_REPORT_EXPORT_FORMAT";
export const REPORT_SET_SCHEDULER_EMAIL_DTO_LIST = "REPORT_SET_SCHEDULER_EMAIL_DTO_LIST";
export const REPORT_SET_SCHEDULER_USER_GROUP_DTO_LIST = "REPORT_SET_SCHEDULER_USER_GROUP_DTO_LIST";
export const REPORT_SET_SCHEDULER_EMAIL_SENDER_TEMP_SELECTED = "REPORT_SET_SCHEDULER_EMAIL_SENDER_TEMP_SELECTED";
export const REPORT_SET_SCHEDULER_SAVED_SCHEDULER = "REPORT_SET_SCHEDULER_SAVED_SCHEDULER";
export const REPORT_SET_SCHEDULER_TOGGLE_ADD_SCHEDULER = "REPORT_SET_SCHEDULER_TOGGLE_ADD_SCHEDULER";
export const REPORT_SCHEDULER_UPLOAD = "REPORT_SCHEDULER_UPLOAD";
export const REPORT_SCHEDULER_UPLOAD_SUCCESS = "REPORT_SCHEDULER_UPLOAD_SUCCESS";
export const REPORT_SCHEDULER_UPLOAD_FAILED = "REPORT_SCHEDULER_UPLOAD_FAILED";
export const REPORT_SCHEDULER_DELETE = "REPORT_SCHEDULER_DELETE";
export const REPORT_SCHEDULER_DELETE_SUCCESS = "REPORT_SCHEDULER_DELETE_SUCCESS";
export const REPORT_SCHEDULER_DELETE_FAILED = "REPORT_SCHEDULER_DELETE_FAILED";
export const REPORT_SET_SCHEDULER_TOGGLE_SCHEDULER_NAME_PRESENT = "REPORT_SET_SCHEDULER_TOGGLE_SCHEDULER_NAME_PRESENT";

export const REPORT_USER_GROUP_MODEL_IS_OPEN = "REPORT_USER_GROUP_MODEL_IS_OPEN";
export const REPORT_SET_TEMP_SELECTED_USER_GROUPS = "REPORT_SET_TEMP_SELECTED_USER_GROUPS";

export const REPORT_LOAD_PROCESS_MEASURES_LIST = "REPORT_LOAD_PROCESS_MEASURES_LIST";
export const REPORT_LOAD_PROCESS_MEASURES_LIST_SUCCESS = "REPORT_LOAD_PROCESS_MEASURES_LIST_SUCCESS";
export const REPORT_LOAD_PROCESS_MEASURES_LIST_FAILED = "REPORT_LOAD_PROCESS_MEASURES_LIST_FAILED";

export const REPORT_SET_CREATE_SECTION = "REPORT_SET_CREATE_SECTION";
export const REPORT_SET_SECTION_SELECTED_MEASURE = "REPORT_SET_SECTION_SELECTED_MEASURE";
export const REPORT_SET_SECTION_SELECTED_MEASURE_RULE = "REPORT_SET_SECTION_SELECTED_MEASURE_RULE";
export const REPORT_SET_SECTION_SELECTED_AGGREGATE = "REPORT_SET_SECTION_SELECTED_AGGREGATE";
export const REPORT_SET_SECTION_SELECTED_MEASURE_DATA = "REPORT_SET_SECTION_SELECTED_MEASURE_DATA";
export const REPORT_SET_SECTION_SELECTED_DISPLAY_MEASURE_SORT = "REPORT_SET_SECTION_SELECTED_DISPLAY_MEASURE_SORT";
export const REPORT_SET_SECTION_SELECTED_DISPLAY_MEASURES_INIT = "REPORT_SET_SECTION_SELECTED_DISPLAY_MEASURES_INIT";

export const REPORT_SET_SELECTED_MEASURE_AGGREGATE = "REPORT_SET_SELECTED_MEASURE_AGGREGATE";
export const REPORT_SET_SELECTED_MEASURE_RECALCULATE = "REPORT_SET_SELECTED_MEASURE_RECALCULATE";
export const REPORT_SET_SELECTED_MEASURE_BEST_EVER_TYPE = "REPORT_SET_SELECTED_MEASURE_BEST_EVER_TYPE";
export const REPORT_SET_SELECTED_MEASURE_BEST_EVER_TYPE_ALL = "REPORT_SET_SELECTED_MEASURE_BEST_EVER_TYPE_ALL";
export const REPORT_SET_SELECTED_REPORT_TYPE = "REPORT_SET_SELECTED_REPORT_TYPE";

export const REPORT_MEASURE_SELECT_MEASURES_MODAL_OPEN = 'REPORT_MEASURE_SELECT_MEASURES_MODAL_OPEN';
export const REPORT_MEASURE_SELECT_MEASURES_MODAL_CLOSE = 'REPORT_MEASURE_SELECT_MEASURES_MODAL_CLOSE';
export const REPORT_MEASURE_SELECT_MEASURES = 'REPORT_MEASURE_SELECT_MEASURES';
export const REPORT_MEASURE_UPDATE_TMP_SELECTED_MEASURES = 'REPORT_MEASURE_UPDATE_TMP_SELECTED_MEASURES';
export const REPORT_MEASURE_SEARCH = 'REPORT_MEASURE_SEARCH';
export const REPORT_MEASURE_SEARCH_SUCCESS = 'REPORT_MEASURE_SEARCH_SUCCESS';
export const REPORT_MEASURE_SEARCH_FAILED = 'REPORT_MEASURE_SEARCH_FAILED';

export const REPORT_MEASURE_SELECT_DATA_ENTRY_MODAL_OPEN = 'REPORT_MEASURE_SELECT_DATA_ENTRY_MODAL_OPEN';
export const REPORT_MEASURE_SELECT_DATA_ENTRY_MODAL_CLOSE = 'REPORT_MEASURE_SELECT_DATA_ENTRY_MODAL_CLOSE';
export const REPORT_MEASURE_SELECT_DATA_ENTRY_FORMS = 'REPORT_MEASURE_SELECT_DATA_ENTRY_FORMS';
export const REPORT_MEASURE_UPDATE_TMP_DATA_ENTRY_FORMS = 'REPORT_MEASURE_UPDATE_TMP_DATA_ENTRY_FORMS';

export const REPORT_MEASURE_DATA_FORM_SEARCH = 'REPORT_MEASURE_DATA_FORM_SEARCH';
export const REPORT_MEASURE_DATA_FORM_SEARCH_SUCCESS = 'REPORT_MEASURE_DATA_FORM_SEARCH_SUCCESS';
export const REPORT_MEASURE_DATA_FORM_SEARCH_FAILED = 'REPORT_MEASURE_DATA_FORM_SEARCH_FAILED';

export const REPORT_MEASURE_DATA_ENTRY_FORM_MEASURE_MODAL_OPEN = 'REPORT_MEASURE_DATA_ENTRY_FORM_MEASURE_MODAL_OPEN';
export const REPORT_MEASURE_DATA_ENTRY_FORM_MEASURE_MODAL_CLOSE = 'REPORT_MEASURE_DATA_ENTRY_FORM_MEASURE_MODAL_CLOSE';

export const REPORT_MEASURE_DATA_ENTRY_FORM__MEASURES_LIST = "REPORT_MEASURE_DATA_ENTRY_FORM__MEASURES_LIST";
export const REPORT_MEASURE_DATA_ENTRY_FORM__MEASURES_LIST_SUCCESS = "REPORT_MEASURE_DATA_ENTRY_FORM__MEASURES_LIST_SUCCESS";
export const REPORT_MEASURE_DATA_ENTRY_FORM__MEASURES_LIST_FAILED = "REPORT_MEASURE_DATA_ENTRY_FORM__MEASURES_LIST_FAILED";
export const REPORT_MEASURE_UPDATE_TMP_DATA_ENTRY_FORM_MEASURES = 'REPORT_MEASURE_UPDATE_TMP_DATA_ENTRY_FORM_MEASURES';
export const REPORT_MEASURE_UPDATE_DATA_ENTRY_FORM_MEASURES = 'REPORT_MEASURE_UPDATE_DATA_ENTRY_FORM_MEASURES';
export const REPORT_MEASURE_UPDATE_DATA_ENTRY_FORM_MEASURE_RE_CALCULATION = 'REPORT_MEASURE_UPDATE_DATA_ENTRY_FORM_MEASURE_RE_CALCULATION';
export const REPORT_MEASURE_UPDATE_DATA_ENTRY_FORM_MEASURE_AGGREGATE_TYPE = 'REPORT_MEASURE_UPDATE_DATA_ENTRY_FORM_MEASURE_AGGREGATE_TYPE';
export const REPORT_MEASURE_UPDATE_REPORT_MEASURE_DATA_STRUCTURE = 'REPORT_MEASURE_UPDATE_REPORT_MEASURE_DATA_STRUCTURE';

export const REPORT_MEASURE_LOAD_MEASURES_BY_MEASURE_ID = 'REPORT_MEASURE_LOAD_MEASURES_BY_MEASURE_ID';
export const REPORT_MEASURE_LOAD_MEASURES_BY_MEASURE_ID_SUCCESS = 'REPORT_MEASURE_LOAD_MEASURES_BY_MEASURE_ID';
export const REPORT_MEASURE_LOAD_MEASURES_BY_MEASURE_ID_FAILED = 'REPORT_MEASURE_LOAD_MEASURES_BY_MEASURE_ID_FAILED';

export const REPORT_MEASURE_LOAD_PROCESS_TEMPLATES_BY_ID = 'REPORT_MEASURE_LOAD_PROCESS_TEMPLATES_BY_ID';
export const REPORT_MEASURE_LOAD_PROCESS_TEMPLATES_BY_ID_SUCCESS = 'REPORT_MEASURE_LOAD_PROCESS_TEMPLATES_BY_ID_SUCCESS';
export const REPORT_MEASURE_LOAD_PROCESS_TEMPLATES_BY_ID_FAILED = 'REPORT_MEASURE_LOAD_PROCESS_TEMPLATES_BY_ID_FAILED';

export const REPORT_MEASURE_REMOVE_DATA_ENTRY_FORM_MEASURE = 'REPORT_MEASURE_REMOVE_DATA_ENTRY_FORM_MEASURE';
export const REPORT_MEASURE_REMOVE_DATA_ENTRY_FORM = 'REPORT_MEASURE_REMOVE_DATA_ENTRY_FORM';
export const REPORT_MEASURE_REMOVE_MEASURE = 'REPORT_MEASURE_REMOVE_MEASURE';

export const REPORT_MEASURE_TOGGLE_SORT = 'REPORT_MEASURE_TOGGLE_SORT';
export const REPORT_MEASURE_SORT = 'REPORT_MEASURE_SORT';
export const REPORT_MEASURE_UPDATE_MEASURE_WISE_EXPANDED = 'REPORT_MEASURE_UPDATE_MEASURE_WISE_EXPANDED';

//REPORT_GENERATE
export const REPORT_GENERATE_SET_INCLUDES_VIEW_DATA = "REPORT_GENERATE_SET_INCLUDES_VIEW_DATA";
export const REPORT_GENERATE_DATA_CLEAR = "REPORT_GENERATE_DATA_CLEAR";
export const REPORT_GENERATE_ON_OUTPUT_TYPE_SELECT = "REPORT_GENERATE_ON_OUTPUT_TYPE_SELECT";
export const REPORT_GENERATE_ON_EXPORT_TYPE_SELECT = "REPORT_GENERATE_ON_EXPORT_TYPE_SELECT";
export const REPORT_GENERATE_ON_EMAIL_DETAIL_SET = "REPORT_GENERATE_ON_EMAIL_DETAIL_SET";

export const REPORT_GENERATE_DOWNLOAD_REPORT = "REPORT_GENERATE_DOWNLOAD_REPORT";
export const REPORT_GENERATE_DOWNLOAD_REPORT_SUCCESS = "REPORT_GENERATE_DOWNLOAD_REPORT_SUCCESS";
export const REPORT_GENERATE_DOWNLOAD_REPORT_FAILED = "REPORT_GENERATE_DOWNLOAD_REPORT_FAILED";
export const REPORT_GENERATE_HTML_REPORT = "REPORT_GENERATE_HTML_REPORT";
export const REPORT_GENERATE_HTML_REPORT_SUCCESS = "REPORT_GENERATE_HTML_REPORT_SUCCESS";
export const REPORT_GENERATE_HTML_REPORT_FAILED = "REPORT_GENERATE_HTML_REPORT_FAILED";
export const REPORT_GENERATE_EMAIL_REPORT = "REPORT_GENERATE_EMAIL_REPORT";
export const REPORT_GENERATE_EMAIL_REPORT_SUCCESS = "REPORT_GENERATE_EMAIL_REPORT_SUCCESS";
export const REPORT_GENERATE_EMAIL_REPORT_FAILED = "REPORT_GENERATE_EMAIL_REPORT_FAILED";
export const REPORT_GENERATE_SET_EMAIL_TO_TEMP = "REPORT_GENERATE_SET_EMAIL_TO_TEMP";

export const REPORT_GENERATE_SET_TEMP_SELECTED_USER_GROUPS = "REPORT_GENERATE_SET_TEMP_SELECTED_USER_GROUPS";
export const REPORT_GENERATE_USER_GROUP_MODEL_IS_OPEN = "REPORT_GENERATE_USER_DROUP_MODEL_IS_OPEN";


export const REPORT_GENERATE_REQUEST_REPORT_JSON = 'REPORT_GENERATE_REQUEST_REPORT_JSON';
export const REPORT_GENERATE_REQUEST_REPORT_JSON_SUCCESS = 'REPORT_GENERATE_REQUEST_REPORT_JSON_SUCCESS';
export const REPORT_GENERATE_REQUEST_REPORT_JSON_FAILED = 'REPORT_GENERATE_REQUEST_REPORT_JSON_FAILED';

export const REPORT_GENERATE_REQUEST_KPI_REPORT_JSON = 'REPORT_GENERATE_REQUEST_KPI_REPORT_JSON';
export const REPORT_GENERATE_REQUEST_KPI_REPORT_JSON_SUCCESS = 'REPORT_GENERATE_REQUEST_KPI_REPORT_JSON_SUCCESS';
export const REPORT_GENERATE_REQUEST_KPI_REPORT_JSON_FAILED = 'REPORT_GENERATE_REQUEST_KPI_REPORT_JSON_FAILED';

export const REPORT_GENERATE_REQUEST_FACTORY_SHIFTS = 'REPORT_GENERATE_REQUEST_FACTORY_SHIFTS';
export const REPORT_GENERATE_REQUEST_FACTORY_SHIFTS_SUCCESS = 'REPORT_GENERATE_REQUEST_FACTORY_SHIFTS_SUCCESS';
export const REPORT_GENERATE_REQUEST_FACTORY_SHIFTS_FAILED = 'REPORT_GENERATE_REQUEST_FACTORY_SHIFTS_FAILED';

export const REPORT_GENERATE_XLSX_CONTENT_READY = 'REPORT_GENERATE_XLSX_CONTENT_READY';
export const REPORT_GENERATE_XLSX_CONTENT_CLEAR = 'REPORT_GENERATE_XLSX_CONTENT_CLEAR';

//USER_GROUP
export const USER_GROUP_SEARCH = 'USER_GROUP_SEARCH';
export const USER_GROUP_SEARCH_SUCCESS = 'USER_GROUP_SEARCH_SUCCESS';
export const USER_GROUP_SEARCH_FAILED = 'USER_GROUP_SEARCH_FAILED';
export const USER_GROUP_ADD_NEW_USER_GROUP = 'USER_GROUP_ADD_NEW_USER_GROUP';
export const USER_GROUP_GET_SELECTED_USER_GROUP = 'USER_GROUP_GET_SELECTED_USER_GROUP';
export const USER_GROUP_GET_SELECTED_USER_GROUP_FAILED = 'USER_GROUP_GET_SELECTED_USER_GROUP_FAILED';
export const USER_GROUP_GET_SELECTED_USER_GROUP_SUCCESS = 'USER_GROUP_GET_SELECTED_USER_GROUP_SUCCESS';
export const SAVE_UPDATE_USER_GROUP = 'SAVE_UPDATE_USER_GROUP';
export const SAVE_UPDATE_USER_GROUP_SUCCESS = 'SAVE_UPDATE_USER_GROUP_SUCCESS';
export const SAVE_UPDATE_USER_GROUP_FAILED = 'SAVE_UPDATE_USER_GROUP_FAILED';
export const USER_ADD_EDIT_SELECT_USER_MODAL_OPEN = 'USER_ADD_EDIT_SELECT_USER_MODAL_OPEN';
export const USER_ADD_EDIT_SELECT_USER_MODAL_CLOSE = 'USER_ADD_EDIT_SELECT_USER_MODAL_CLOSE';
export const USER_GROUP_ADD_EDIT_UPDATE_SELECTED_USER = 'USER_GROUP_ADD_EDIT_UPDATE_SELECTED_USER';
export const USER_GROUP_ADD_EDIT_UPDATE_TMP_SELECTED_USER = 'USER_GROUP_ADD_EDIT_UPDATE_TMP_SELECTED_USER';
export const USER_GROUP_SET_SELECTED_COMPANY = 'USER_GROUP_SET_SELECTED_COMPANY';

//Notification
export const NOTIFICATION_SEARCH = "NOTIFICATION_SEARCH";
export const NOTIFICATION_SEARCH_SUCCESS = "NOTIFICATION_SEARCH_SUCCESS";
export const NOTIFICATION_SEARCH_FAILED = "NOTIFICATION_SEARCH_FAILED";
export const NOTIFICATION_UPDATE = "NOTIFICATION_UPDATE";
export const NOTIFICATION_UPDATE_SUCCESS = "NOTIFICATION_UPDATE_SUCCESS";
export const NOTIFICATION_UPDATE_FAILED = "NOTIFICATION_UPDATE_FAILED";
export const NOTIFICATION_SET_SELECT_NOTIFICATION = "NOTIFICATION_SET_SELECT_NOTIFICATION";
export const NOTIFICATION_SET_IS_NOTIFICATION_SELECTED = "NOTIFICATION_SET_IS_NOTIFICATION_SELECTED";
export const NOTIFICATION_GET_PUSHED_NOTIFICATION_COUNT = "NOTIFICATION_GET_PUSHED_NOTIFICATION_COUNT";
export const NOTIFICATION_GET_PUSHED_NOTIFICATION_COUNT_SUCCESS = "NOTIFICATION_GET_PUSHED_NOTIFICATION_COUNT_SUCCESS";
export const NOTIFICATION_GET_PUSHED_NOTIFICATION_COUNT_FAILED = "NOTIFICATION_GET_PUSHED_NOTIFICATION_COUNT_FAILED";

// Labour Classification
export const LABOUR_CLASSIFICATION_SEARCH = "LABOUR_CLASSIFICATION_SEARCH";
export const LABOUR_CLASSIFICATION_SEARCH_SUCCESS = "LABOUR_CLASSIFICATION_SEARCH_SUCCESS";
export const LABOUR_CLASSIFICATION_SEARCH_ERROR = "LABOUR_CLASSIFICATION_SEARCH_ERROR";
export const SELECTED_LABOUR_CLASSIFICATION_SEARCH = "SELECTED_LABOUR_CLASSIFICATION_SEARCH";
export const SELECTED_LABOUR_CLASSIFICATION_SEARCH_ERROR = "SELECTED_LABOUR_CLASSIFICATION_SEARCH_ERROR";
export const SELECTED_LABOUR_CLASSIFICATION_SEARCH_SUCCESS = "SELECTED_LABOUR_CLASSIFICATION_SEARCH_SUCCESS";

export const LABOUR_CLASSIFICATION_SAVE = "LABOUR_CLASSIFICATION_SAVE";
export const SAVE_UPDATE_LABOUR_CLASSIFICATION_SUCCESS = 'SAVE_UPDATE_LABOUR_CLASSIFICATION_SUCCESS';
export const SAVE_UPDATE_LABOUR_CLASSIFICATION_FAILED = 'SAVE_UPDATE_LABOUR_CLASSIFICATION_FAILED';
export const LABOUR_CLASSIFICATION_RESET_SELECTED_LABOUR_CLASSIFICATION = 'LABOUR_CLASSIFICATION_RESET_SELECTED_LABOUR_CLASSIFICATION';
export const LABOUR_CLASSIFICATION_ADD_NEW_LABOUR_CLASSIFICATION = 'LABOUR_CLASSIFICATION_ADD_LABOUR_CLASSIFICATION';
export const LABOUR_CLASSIFICATION_GET_SELECTED_LABOUR_CLASSIFICATION = 'LABOUR_CLASSIFICATION_GET_SELECTED_LABOUR_CLASSIFICATION';
export const LABOUR_CLASSIFICATION_GET_SELECTED_LABOUR_CLASSIFICATION_SUCCESS = 'LABOUR_CLASSIFICATION_GET_SELECTED_LABOUR_CLASSIFICATION_SUCCESS';
export const LABOUR_CLASSIFICATION_GET_SELECTED_LABOUR_CLASSIFICATION_FAILED = 'LABOUR_CLASSIFICATION_GET_SELECTED_LABOUR_CLASSIFICATION_FAILED';
export const LABOUR_CLASSIFICATION_ADD_EMPLOYEE_COUNTS_MODAL_OPEN = 'LABOUR_CLASSIFICATION_ADD_EMPLOYEE_COUNTS_MODAL_OPEN';
export const LABOUR_CLASSIFICATION_ADD_EMPLOYEE_COUNTS_MODAL_CLOSE = 'LABOUR_CLASSIFICATION_ADD_EMPLOYEE_COUNTS_MODAL_CLOSE';
export const LABOUR_CLASSIFICATION_RESET_DATA = 'LABOUR_CLASSIFICATION_RESET_DATA';
export const LABOUR_CLASSIFICATION_DETAIL_DATA_SAVE = "LABOUR_CLASSIFICATION_DETAIL_DATA_SAVE";
export const LABOUR_CLASSIFICATION_DETAIL_DATA_SAVE_SUCCESS = 'LABOUR_CLASSIFICATION_DETAIL_DATA_SAVE_SUCCESS';
export const LABOUR_CLASSIFICATION_DETAIL_DATA_SAVE_FAILED = 'LABOUR_CLASSIFICATION_DETAIL_DATA_SAVE_FAILED';

export const WASTE_SEARCH = 'WASTE_SEARCH';
export const WASTE_SEARCH_SUCCESS = 'WASTE_SEARCH_SUCCESS';
export const WASTE_SEARCH_FAILED = 'WASTE_SEARCH_FAILED';

export const WASTE_ADD_NEW_WASTE = 'WASTE_ADD_NEW_WASTE';
export const WASTE_GET_SELECTED_WASTE = 'WASTE_GET_SELECTED_WASTE';
export const WASTE_GET_SELECTED_WASTE_FAILED = 'WASTE_GET_SELECTED_WASTE_FAILED';
export const WASTE_GET_SELECTED_WASTE_SUCCESS = 'WASTE_GET_SELECTED_WASTE_SUCCESS';
export const WASTE_UPDATE_WASTE_CAUSE_SUB_CATEGORIES = 'WASTE_UPDATE_WASTE_CAUSE_SUB_CATEGORIES';

export const SAVE_UPDATE_WASTE = 'SAVE_UPDATE_WASTE';
export const SAVE_UPDATE_WASTE_SUCCESS = 'SAVE_UPDATE_WASTE_SUCCESS';
export const SAVE_UPDATE_WASTE_FAILED = 'SAVE_UPDATE_WASTE_FAILED';
export const WASTE_REST = 'SAVE_UPDATE_WASTE_FAILED';

//Standards
export const STANDARD_LOCATION_VICE_PRODUCT = "STANDARD_LOCATION_VICE_PRODUCT";
export const STANDARD_LOCATION_VICE_PRODUCT_SUCCESS = "STANDARD_LOCATION_VICE_PRODUCT_SUCCESS";
export const STANDARD_LOCATION_VICE_PRODUCT_FAILED = "STANDARD_LOCATION_VICE_PRODUCT_FAILED";
export const STANDARD_LOCATION_VICE_SUB_BUSINESS_UNIT = "STANDARD_LOCATION_VICE_SUB_BUSINESS_UNIT";
export const STANDARD_LOCATION_VICE_SUB_BUSINESS_UNIT_SUCCESS = "STANDARD_LOCATION_VICE_SUB_BUSINESS_UNIT_SUCCESS";
export const STANDARD_LOCATION_VICE_SUB_BUSINESS_UNIT_FAILED = "STANDARD_LOCATION_VICE_SUB_BUSINESS_UNIT_FAILED";
export const STANDARD_SAVE_UPDATE_STANDARD_DATA = "STANDARD_SAVE_UPDATE_STANDARD_DATA";
export const STANDARD_SAVE_UPDATE_STANDARD_DATA_SUCCESS = "STANDARD_SAVE_UPDATE_STANDARD_DATA_SUCCESS";
export const STANDARD_SAVE_UPDATE_STANDARD_DATA_FAILED = "STANDARD_SAVE_UPDATE_STANDARD_DATA_FAILED";
export const STANDARD_SAVE_UPDATE_STANDARD = "STANDARD_SAVE_UPDATE_STANDARD";
export const STANDARD_SAVE_UPDATE_STANDARD_SUCCESS = "STANDARD_SAVE_UPDATE_STANDARD_SUCCESS";
export const STANDARD_SAVE_UPDATE_STANDARD_FAILED = "STANDARD_SAVE_UPDATE_STANDARD_FAILED";
export const STANDARD_SEARCH_STANDARD = "STANDARD_SEARCH_STANDARD";
export const STANDARD_SEARCH_STANDARD_SUCCESS = "STANDARD_SEARCH_STANDARD_SUCCESS";
export const STANDARD_SEARCH_STANDARD_FAILED = "STANDARD_SEARCH_STANDARD_FAILED";
export const STANDARD_SEARCH_STANDARD_DATA = "STANDARD_SEARCH_STANDARD_DATA";
export const STANDARD_SEARCH_STANDARD_DATA_SUCCESS = "STANDARD_SEARCH_STANDARD_DATA_SUCCESS";
export const STANDARD_SEARCH_STANDARD_DATA_FAILED = "STANDARD_SEARCH_STANDARD_DATA_FAILED";
export const STANDARD_GET_MAX_EFFECTIVE_DATE = "STANDARD_GET_MAX_EFFECTIVE_DATE";
export const STANDARD_GET_MAX_EFFECTIVE_DATE_SUCCESS = "STANDARD_GET_MAX_EFFECTIVE_DATE_SUCCESS";
export const STANDARD_GET_MAX_EFFECTIVE_DATE_FAILED = "STANDARD_GET_MAX_EFFECTIVE_DATE_FAILED";
export const STANDARD_RESET_STANDARD_DATA_SCREEN = "STANDARD_RESET_STANDARD_DATA_SCREEN";
export const STANDARD_RESET_STANDARD_SCREEN = "STANDARD_RESET_STANDARD_SCREEN";
export const STANDARD_SET_SELECTED_STANDARD_DATA = "STANDARD_SET_SELECTED_STANDARD_DATA";
export const STANDARD_SET_SELECTED_STANDARD = "STANDARD_SET_SELECTED_STANDARD";
export const STANDARD_GET_SELECTED_STANDARD = "STANDARD_GET_SELECTED_STANDARD";
export const STANDARD_GET_SELECTED_STANDARD_SUCCESS = "STANDARD_GET_SELECTED_STANDARD_SUCCESS";
export const STANDARD_GET_SELECTED_STANDARD_FAILED = "STANDARD_GET_SELECTED_STANDARD_FAILED";
export const UPLOAD_BULK_STANDARD = "UPLOAD_BULK_STANDARD";
export const UPLOAD_BULK_STANDARD_SUCCESS = "UPLOAD_BULK_STANDARD_SUCCESS";
export const UPLOAD_BULK_STANDARD_FAILED = "UPLOAD_BULK_STANDARD_FAILED";

export const UPLOAD_BULK_STANDARD_VALUES = "UPLOAD_BULK_STANDARD_VALUES";
export const UPLOAD_BULK_STANDARD_VALUES_SUCCESS = "UPLOAD_BULK_STANDARD_VALUES_SUCCESS";
export const UPLOAD_BULK_STANDARD_VALUES_FAILED = "UPLOAD_BULK_STANDARD_VALUES_FAILED";

export const STANDARD_DOWNLOAD_STANDARD_VALUES_CSV = "STANDARD_DOWNLOAD_STANDARD_VALUES_CSV";
export const STANDARD_DOWNLOAD_STANDARD_VALUES_CSV_SUCCESS = "STANDARD_DOWNLOAD_STANDARD_VALUES_CSV_SUCCESS";
export const STANDARD_DOWNLOAD_STANDARD_VALUES_CSV_FAILED = "STANDARD_DOWNLOAD_STANDARD_VALUES_CSV_FAILED";

export const STANDARD_SHOW_HIDE_ADD_EDIT_MODAL = "STANDARD_SHOW_HIDE_ADD_EDIT_MODAL";
export const STANDARD_DO_MANUAL_SEARCH = "STANDARD_DO_MANUAL_SEARCH";

// Shift
export const SHIFT_SEARCH = "SHIFT_SEARCH";
export const SHIFT_SEARCH_SUCCESS = "SHIFT_SEARCH_SUCCESS";
export const SHIFT_SEARCH_FAILED = "SHIFT_SEARCH_FAILED";

export const SAVE_UPDATE_FACTORY_OPERATION_SCHEDULE = "SAVE_UPDATE_FACTORY_OPERATION_SCHEDULE";
export const SAVE_UPDATE_FACTORY_OPERATION_SCHEDULE_SUCCESS = "SAVE_UPDATE_FACTORY_OPERATION_SCHEDULE_SUCCESS";
export const SAVE_UPDATE_FACTORY_OPERATION_SCHEDULE_FAILED = "SAVE_UPDATE_FACTORY_OPERATION_SCHEDULE_FAILED";
export const SHIFT_GET_SELECTED_SHIFT = "SHIFT_GET_SELECTED_SHIFT";
export const SHIFT_GET_SELECTED_SHIFT_SUCCESS = "SHIFT_GET_SELECTED_SHIFT_SUCCESS";
export const SHIFT_GET_SELECTED_SHIFT_FAILED = "SHIFT_GET_SELECTED_SHIFT_FAILED";
export const SHIFT_ADD_NEW_SHIFT = "SHIFT_ADD_NEW_SHIFT";
export const SHIFT_SET_SELECTED_SHIFT = "SHIFT_SET_SELECTED_SHIFT";
export const SHIFT_ADD_TIME_SLOT_MODAL_OPEN = "SHIFT_ADD_TIME_SLOT_MODAL_OPEN";
export const SHIFT_ADD_TIME_SLOT_MODAL_OPEN_ID = "SHIFT_ADD_TIME_SLOT_MODAL_OPEN_ID";
export const SHIFT_ADD_TIME_SLOT_MODAL_CLOSE = "SHIFT_ADD_TIME_SLOT_MODAL_CLOSE";
export const SHIFT_SET_FACTORY_OPERATION_DAYS = "SHIFT_SET_FACTORY_OPERATION_DAYS";
export const SHIFT_UPDATE_FACTORY_OPERATION_DAYS = "SHIFT_UPDATE_FACTORY_OPERATION_DAYS";
export const SHIFT_APPEND_FACTORY_OPERATION_DAYS = "SHIFT_APPEND_FACTORY_OPERATION_DAYS";
export const SHIFT_SET_FACTORY_OPERATION_DAYTIME_FOR_FACTORY_OPERATION_DAYS = "SHIFT_SET_FACTORY_OPERATION_DAYTIME_FOR_FACTORY_OPERATION_DAYS";
export const SHIFT_UPDATE_FACTORY_OPERATION_DAYTIME_FOR_FACTORY_OPERATION_DAYS = "SHIFT_UPDATE_FACTORY_OPERATION_DAYTIME_FOR_FACTORY_OPERATION_DAYS";
export const SHIFT_CLEAR_DATA = "SHIFT_CLEAR_DATA";
export const SHIFT_SET_FACTORY_OPERATION_SCHEDULE = "SHIFT_SET_FACTORY_OPERATION_SCHEDULE";
export const SHIFT_SET_EDIT_DATA_FOR_MODAL = "SHIFT_SET_EDIT_DATA_FOR_MODAL";
export const SHIFT_ON_REMOVE_ADDED_TIME_DTO = "SHIFT_ON_REMOVE_ADDED_TIME_DTO";

// Down Time Causes
export const DOWN_TIME_CAUSE_SEARCH = 'DOWN_TIME_CAUSE_SEARCH';
export const DOWN_TIME_CAUSE_SEARCH_SUCCESS = "DOWN_TIME_CAUSE_SEARCH_SUCCESS";
export const DOWN_TIME_CAUSE_SEARCH_ERROR = "DOWN_TIME_CAUSE_SEARCH_ERROR";
export const DOWN_TIME_CAUSE_RESET_DOWN_TIME_CAUSE = "DOWN_TIME_CAUSE_RESET_DOWN_TIME_CAUSE";
export const SAVE_UPDATE_DOWN_TIME_CAUSE = "SAVE_UPDATE_DOWN_TIME_CAUSE";
export const SAVE_UPDATE_DOWN_TIME_CAUSE_SUCCESS = "SAVE_UPDATE_DOWN_TIME_CAUSE_SUCCESS";
export const SAVE_UPDATE_DOWN_TIME_CAUSE_ERROR = "SAVE_UPDATE_DOWN_TIME_CAUSE_ERROR";
export const DOWN_TIME_CAUSE_ADD_NEW_DOWN_TIME_CAUSE = 'DOWN_TIME_CAUSE_ADD_NEW_DOWN_TIME_CAUSE';
export const DOWN_TIME_CAUSE_GET_SELECTED_DOWN_TIME_CAUSE = 'DOWN_TIME_CAUSE_GET_SELECTED_DOWN_TIME_CAUSE';
export const DOWN_TIME_CAUSE_GET_SELECTED_DOWN_TIME_CAUSE_SUCCESS = 'DOWN_TIME_CAUSE_GET_SELECTED_DOWN_TIME_CAUSE_SUCCESS';
export const DOWN_TIME_CAUSE_GET_SELECTED_DOWN_TIME_CAUSE_FAILED = 'DOWN_TIME_CAUSE_GET_SELECTED_DOWN_TIME_CAUSE_FAILED';
export const DOWN_TIME_CAUSE_RESET_DATA = 'DOWN_TIME_CAUSE_RESET_DATA';
export const DOWN_TIME_UPDATE_DOWN_TIME_CAUSE_SUB_CATEGORIES = 'DOWN_TIME_UPDATE_DOWN_TIME_CAUSE_SUB_CATEGORIES';

//Data form creation

export const GET_DATA_FORM_CREATION_FACTORY_PRODUCTS = 'GET_DATA_FORM_CREATION_FACTORY_PRODUCTS';
export const GET_DATA_FORM_CREATION_FACTORY_PRODUCTS_SUCCESS = 'GET_DATA_FORM_CREATION_FACTORY_PRODUCTS_SUCCESS';
export const GET_DATA_FORM_CREATION_FACTORY_PRODUCTS_FAILED = 'GET_DATA_FORM_CREATION_FACTORY_PRODUCTS_FAILED';

export const GET_DATA_FORM_CREATION_FACTORY_PRODUCTION_LINES = 'GET_DATA_FORM_CREATION_FACTORY_PRODUCTION_LINES';
export const GET_DATA_FORM_CREATION_FACTORY_PRODUCTION_LINES_SUCCESS = 'GET_DATA_FORM_CREATION_FACTORY_PRODUCTION_LINES_SUCCESS';
export const GET_DATA_FORM_CREATION_FACTORY_PRODUCTION_LINES_FAILED = 'GET_DATA_FORM_CREATION_FACTORY_PRODUCTION_LINES_FAILED';

export const GET_DATA_FORM_CREATION_FACTORY_SAVE = 'GET_DATA_FORM_CREATION_FACTORY_SAVE';
export const GET_DATA_FORM_CREATION_FACTORY_SAVE_SUCCESS = 'GET_DATA_FORM_CREATION_FACTORY_SAVE_SUCCESS';
export const GET_DATA_FORM_CREATION_FACTORY_SAVE_FAILED = 'GET_DATA_FORM_CREATION_FACTORY_SAVE_FAILED';

export const GET_DATA_FORM_CREATION_DATA_FORM = 'GET_DATA_FORM_CREATION_DATA_FORM';
export const GET_DATA_FORM_CREATION_DATA_FORM_SUCCESS = 'GET_DATA_FORM_CREATION_DATA_FORM_SUCCESS';
export const GET_DATA_FORM_CREATION_DATA_FORM_FAILED = 'GET_DATA_FORM_CREATION_DATA_FORM_FAILED';

export const DATA_FORM_CREATION_SELECT_SITE_PERFORMANCE_MODAL_OPEN = 'DATA_FORM_CREATION_SELECT_SITE_PERFORMANCE_MODAL_OPEN';
export const DATA_FORM_CREATION_SELECT_SITE_PERFORMANCE_MODAL_CLOSE = 'DATA_FORM_CREATION_SELECT_SITE_PERFORMANCE_MODAL_CLOSE';
export const DATA_FORM_CREATION_DEFAULT__VALUE__PICKER_MODAL_OPEN = 'DATA_FORM_CREATION_DEFAULT__VALUE__PICKER_MODAL_OPEN';
export const DATA_FORM_CREATION_DEFAULT__VALUE__PICKER_MODAL_CLOSE = 'DATA_FORM_CREATION_DEFAULT__VALUE__PICKER_MODAL_CLOSE';
export const DATA_FORM_CREATION_SELECT_SITE_PERFORMANCE_TEMP = 'DATA_FORM_CREATION_SELECT_SITE_PERFORMANCE_TEMP';
export const DATA_FORM_CREATION_SELECT_SITE_PERFORMANCE = 'DATA_FORM_CREATION_SELECT_SITE_PERFORMANCE';
export const DATA_FORM_CREATION_GET_LOAD_MEASURE_GROUPS = 'DATA_FORM_CREATION_GET_LOAD_MEASURE_GROUPS';
export const DATA_FORM_CREATION_GET_LOAD_MEASURE_GROUPS_SUCCESS = 'DATA_FORM_CREATION_GET_LOAD_MEASURE_GROUPS_SUCCESS';
export const DATA_FORM_CREATION_GET_LOAD_MEASURE_GROUPS_FAILED = 'DATA_FORM_CREATION_GET_LOAD_MEASURE_GROUPS_FAILED';
export const DATA_FORM_CREATION_REMOVE_SELECTED_SITE_PERFORMANCE = 'DATA_FORM_CREATION_REMOVE_SELECTED_SITE_PERFORMANCE';
export const DATA_FORM_CREATION_RESET_DATA = 'DATA_FORM_CREATION_RESET_DATA';
export const DATA_FORM_CREATION_CLEAR_SELECTED_SITE_PERFORMANCE = 'DATA_FORM_CREATION_CLEAR_SELECTED_SITE_PERFORMANCE';

export const DATA_FORM_CREATION_SEARCH = 'DATA_FORM_CREATION_SEARCH';
export const DATA_FORM_CREATION_SEARCH_SUCCESS = 'DATA_FORM_CREATION_SEARCH_SUCCESS';
export const DATA_FORM_CREATION_SEARCH_FAILED = 'DATA_FORM_CREATION_SEARCH_FAILED';

export const DATA_FORM_VALUE_RE_CALCULATION = 'DATA_FORM_VALUE_RE_CALCULATION';
export const DATA_FORM_VALUE_RE_CALCULATION_SUCCESS = 'DATA_FORM_VALUE_RE_CALCULATION_SUCCESS';
export const DATA_FORM_VALUE_RE_CALCULATION_FAILED = 'DATA_FORM_VALUE_RE_CALCULATION_FAILED';
//DATA ENTRY FORM

export const DATA_ENTRY_LOAD_INIT = 'DATA_ENTRY_LOAD_INIT';
export const DATA_ENTRY_LOAD_INIT_SUCCESS = 'DATA_ENTRY_LOAD_INIT_SUCCESS';
export const DATA_ENTRY_LOAD_INIT_FAILED = 'DATA_ENTRY_LOAD_INIT_FAILED';

export const DATA_ENTRY_GET_TEMPLATE = 'DATA_ENTRY_GET_TEMPLATE';
export const DATA_ENTRY_GET_TEMPLATE_SUCCESS = 'DATA_ENTRY_GET_TEMPLATE_SUCCESS';
export const DATA_ENTRY_GET_TEMPLATE_FAILED = 'DATA_ENTRY_GET_TEMPLATE_FAILED';

export const DATA_ENTRY_SET_SELECTED_FREQUENCY = 'DATA_ENTRY_SET_SELECTED_FREQUENCY';

export const DATA_ENTRY_UPDATE_MEASURE_VALUE = 'DATA_ENTRY_UPDATE_MEASURE_VALUE';
export const DATA_ENTRY_UPDATE_VARIABLE_VALUE = 'DATA_ENTRY_UPDATE_VARIABLE_VALUE';
export const DATA_ENTRY_ADD_NEW_FORM_ROW = 'DATA_ENTRY_ADD_NEW_FORM_ROW';
export const DATA_ENTRY_REMOVE_NEW_FORM_ROW = 'DATA_ENTRY_REMOVE_NEW_FORM_ROW';

export const DATA_ENTRY_ADD_NEW_DOWNTIME_ROW = 'DATA_ENTRY_ADD_NEW_DOWNTIME_ROW';
export const DATA_ENTRY_REMOVE_NEW_DOWNTIME_ROW = 'DATA_ENTRY_REMOVE_NEW_DOWNTIME_ROW';
export const DATA_ENTRY_UPDATE_DOWNTIME_ROW = 'DATA_ENTRY_UPDATE_DOWNTIME_ROW';
export const DATA_ENTRY_ADD_NEW_DOWNTIME_ROW_SET = 'DATA_ENTRY_ADD_NEW_DOWNTIME_ROW_SET';

export const DATA_ENTRY_ADD_NEW_WASTAGE_ROW = 'DATA_ENTRY_ADD_NEW_WASTAGE_ROW';
export const DATA_ENTRY_ADD_NEW_WASTAGE_ROW_SET = 'DATA_ENTRY_ADD_NEW_WASTAGE_ROW_SET';
export const DATA_ENTRY_REMOVE_NEW_WASTAGE_ROW = 'DATA_ENTRY_REMOVE_NEW_WASTAGE_ROW';
export const DATA_ENTRY_UPDATE_WASTAGE_ROW = 'DATA_ENTRY_UPDATE_WASTAGE_ROW';

export const DATA_ENTRY_GET_FACTORY_PRODUCTS = 'DATA_ENTRY_GET_FACTORY_PRODUCTS';
export const DATA_ENTRY_GET_FACTORY_PRODUCTS_SUCCESS = 'DATA_ENTRY_GET_FACTORY_PRODUCTS_SUCCESS';
export const DATA_ENTRY_GET_FACTORY_PRODUCTS_FAILED = 'DATA_ENTRY_GET_FACTORY_PRODUCTS_FAILED';

export const DATA_ENTRY_GET_FACTORY_PRODUCTION_LINES = 'DATA_ENTRY_GET_FACTORY_PRODUCTION_LINES';
export const DATA_ENTRY_GET_FACTORY_PRODUCTION_LINES_SUCCESS = 'DATA_ENTRY_GET_FACTORY_PRODUCTION_LINES_SUCCESS';
export const DATA_ENTRY_GET_FACTORY_PRODUCTION_LINES_FAILED = 'DATA_ENTRY_GET_FACTORY_PRODUCTION_LINES_FAILED';


export const DATA_ENTRY_GET_FACTORY_SHIFTS = 'DATA_ENTRY_GET_FACTORY_SHIFTS';
export const DATA_ENTRY_GET_FACTORY_SHIFTS_SUCCESS = 'DATA_ENTRY_GET_FACTORY_SHIFTS_SUCCESS';
export const DATA_ENTRY_GET_FACTORY_SHIFTS_FAILED = 'DATA_ENTRY_GET_FACTORY_SHIFTS_FAILED';

export const DATA_ENTRY_GET_FACTORY_SUPERVISORS = 'DATA_ENTRY_GET_FACTORY_SUPERVISORS';
export const DATA_ENTRY_GET_FACTORY_SUPERVISORS_SUCCESS = 'DATA_ENTRY_GET_FACTORY_SUPERVISORS_SUCCESS';
export const DATA_ENTRY_GET_FACTORY_SUPERVISORS_FAILED = 'DATA_ENTRY_GET_FACTORY_SUPERVISORS_FAILED';

export const DATA_ENTRY_GET_FACTORY_PRODUCTS_CATEGORIES = 'DATA_ENTRY_GET_FACTORY_PRODUCTS_CATEGORIES';
export const DATA_ENTRY_GET_FACTORY_PRODUCTS_CATEGORIES_SUCCESS = 'DATA_ENTRY_GET_FACTORY_PRODUCTS_CATEGORIES_SUCCESS';
export const DATA_ENTRY_GET_FACTORY_PRODUCTS_CATEGORIES_FAILED = 'DATA_ENTRY_GET_FACTORY_PRODUCTS_CATEGORIES_FAILED';

export const DATA_ENTRY_SAVE_TAB = 'DATA_ENTRY_SAVE_TAB';
export const DATA_ENTRY_SAVE_TAB_SUCCESS = 'DATA_ENTRY_SAVE_TAB_SUCCESS';
export const DATA_ENTRY_SAVE_TAB_FAILED = 'DATA_ENTRY_SAVE_TAB_FAILED';

export const DATA_ENTRY_DOWNLOAD_ENTRY_CSV = 'DATA_ENTRY_DOWNLOAD_ENTRY_CSV';
export const DATA_ENTRY_DOWNLOAD_ENTRY_CSV_SUCCESS = 'DATA_ENTRY_DOWNLOAD_ENTRY_CSV_SUCCESS';
export const DATA_ENTRY_DOWNLOAD_ENTRY_CSV_FAILED = 'DATA_ENTRY_DOWNLOAD_ENTRY_CSV_FAILED';

export const DATA_ENTRY_DOWNLOAD_ENTRY_CSV_WITH_DATA = 'DATA_ENTRY_DOWNLOAD_ENTRY_CSV_WITH_DATA';
export const DATA_ENTRY_DOWNLOAD_ENTRY_CSV_WITH_DATA_SUCCESS = 'DATA_ENTRY_DOWNLOAD_ENTRY_CSV_WITH_DATA_SUCCESS';
export const DATA_ENTRY_DOWNLOAD_ENTRY_CSV_WITH_DATA_FAILED = 'DATA_ENTRY_DOWNLOAD_ENTRY_CSV_WITH_DATA_FAILED';

export const DATA_ENTRY_UPLOAD_ENTRY_CSV = 'DATA_ENTRY_UPLOAD_ENTRY_CSV';
export const DATA_ENTRY_UPLOAD_ENTRY_CSV_SUCCESS = 'DATA_ENTRY_UPLOAD_ENTRY_CSV_SUCCESS';
export const DATA_ENTRY_UPLOAD_ENTRY_CSV_FAILED = 'DATA_ENTRY_UPLOAD_ENTRY_CSV_FAILED';

export const DATA_ENTRY_RESET_DATA = 'DATA_ENTRY_RESET_DATA';

// export const DATA_ENTRY_GET_FACTORY_PRODUCTION_LINES = 'DATA_ENTRY_GET_FACTORY_PRODUCTION_LINES';
// export const DATA_ENTRY_GET_FACTORY_PRODUCTION_LINES_SUCCESS = 'DATA_ENTRY_GET_FACTORY_PRODUCTION_LINES_SUCCESS';
// export const DATA_ENTRY_GET_FACTORY_PRODUCTION_LINES_FAILED = 'DATA_ENTRY_GET_FACTORY_PRODUCTION_LINES_FAILED';

//Projection
export const PROJECTION_GET_DATA_FACTORY_PRODUCTS = 'PROJECTION_GET_DATA_FACTORY_PRODUCTS';
export const PROJECTION_GET_DATA_FACTORY_PRODUCTS_SUCCESS = 'PROJECTION_GET_DATA_FACTORY_PRODUCTS_SUCCESS';
export const PROJECTION_GET_DATA_FACTORY_PRODUCTS_FAILED = 'PROJECTION_GET_DATA_FACTORY_PRODUCTS_FAILED';

export const PROJECTION_GET_DATA_FACTORY_PRODUCTION_LINES = 'PROJECTION_GET_DATA_FACTORY_PRODUCTION_LINES';
export const PROJECTION_GET_DATA_FACTORY_PRODUCTION_LINES_SUCCESS = 'PROJECTION_GET_DATA_FACTORY_PRODUCTION_LINES_SUCCESS';
export const PROJECTION_GET_DATA_FACTORY_PRODUCTION_LINES_FAILED = 'PROJECTION_GET_DATA_FACTORY_PRODUCTION_LINES_FAILED';

export const PROJECTION_GET_PROJECTION = 'PROJECTION_GET_PROJECTION';
export const PROJECTION_GET_PROJECTION_SUCCESS = 'PROJECTION_GET_PROJECTION_SUCCESS';
export const PROJECTION_GET_PROJECTION_FAILED = 'PROJECTION_GET_PROJECTION_FAILED';

export const PROJECTION_GET_PROJECTION_MEASURES = 'PROJECTION_GET_PROJECTION_MEASURES';
export const PROJECTION_GET_PROJECTION_MEASURES_SUCCESS = 'PROJECTION_GET_PROJECTION_MEASURES_SUCCESS';
export const PROJECTION_GET_PROJECTION_MEASURES_FAILED = 'PROJECTION_GET_PROJECTION_MEASURES_FAILED';

export const PROJECTION_SAVE_BASE_PROJECTION = 'PROJECTION_SAVE_BASE_PROJECTION';
export const PROJECTION_SAVE_BASE_PROJECTION_SUCCESS = 'PROJECTION_SAVE_BASE_PROJECTION_SUCCESS';
export const PROJECTION_SAVE_BASE_PROJECTION_FAILED = 'PROJECTION_SAVE_BASE_PROJECTION_FAILED';

export const PROJECTION_SAVE_PROJECTION_MEASURE = 'PROJECTION_SAVE_PROJECTION_MEASURE';
export const PROJECTION_SAVE_PROJECTION_MEASURE_SUCCESS = 'PROJECTION_SAVE_PROJECTION_MEASURE_SUCCESS';
export const PROJECTION_SAVE_PROJECTION_MEASURE_FAILED = 'PROJECTION_SAVE_PROJECTION_MEASURE_FAILED';

export const PROJECTION_SAVE_PROJECTION_MEASURE_VALUES = 'PROJECTION_SAVE_PROJECTION_MEASURE_VALUES';
export const PROJECTION_SAVE_PROJECTION_MEASURE_VALUES_SUCCESS = 'PROJECTION_SAVE_PROJECTION_MEASURE_VALUES_SUCCESS';
export const PROJECTION_SAVE_PROJECTION_MEASURE_VALUES_FAILED = 'PROJECTION_SAVE_PROJECTION_MEASURE_VALUES_FAILED';

export const PROJECTION_GET_PAGED_PROJECTIONS = 'PROJECTION_GET_PAGED_PROJECTIONS';
export const PROJECTION_GET_PAGED_PROJECTIONS_SUCCESS = 'PROJECTION_GET_PAGED_PROJECTIONS_SUCCESS';
export const PROJECTION_GET_PAGED_PROJECTIONS_FAILED = 'PROJECTION_GET_PAGED_PROJECTIONS_FAILED';

export const PROJECTION_RESET_DATA = 'PROJECTION_RESET_DATA';

export const PROJECTION_NEW_SAVE_BASE = 'PROJECTION_NEW_SAVE_BASE';
export const PROJECTION_NEW_SAVE_BASE_SUCCESS = 'PROJECTION_NEW_SAVE_BASE_SUCCESS';
export const PROJECTION_NEW_SAVE_BASE_FAILED = 'PROJECTION_NEW_SAVE_BASE_FAILED';

export const PROJECTION_OPEN_ADD_EDIT_MEASURE_VALUE_MODAL = 'PROJECTION_OPEN_ADD_EDIT_MEASURE_VALUE_MODAL';
export const PROJECTION_CLOSE_ADD_EDIT_MEASURE_VALUE_MODAL = 'PROJECTION_CLOSE_ADD_EDIT_MEASURE_VALUE_MODAL';

export const PROJECTION_SELECT_COMPANY = 'PROJECTION_SELECT_COMPANY';
export const PROJECTION_SELECT_MEASURES = 'PROJECTION_SELECT_MEASURES'; //TODO check
export const PROJECTION_UPDATE_MEASURE_VALUES = 'PROJECTION_UPDATE_MEASURE_VALUES';

export const PROJECTION_NEW_LOAD_PROJECTION = 'PROJECTION_NEW_LOAD_PROJECTION';
export const PROJECTION_NEW_LOAD_PROJECTION_SUCCESS = 'PROJECTION_NEW_LOAD_PROJECTION_SUCCESS';
export const PROJECTION_NEW_LOAD_PROJECTION_FAILED = 'PROJECTION_NEW_LOAD_PROJECTION_FAILED';

export const PROJECTION_SEARCH_NEW_PROJECTION_DATA = "PROJECTION_SEARCH_NEW_PROJECTION_DATA";
export const PROJECTION_SEARCH_NEW_PROJECTION_DATA_SUCCESS = "PROJECTION_SEARCH_NEW_PROJECTION_DATA_SUCCESS";
export const PROJECTION_SEARCH_NEW_PROJECTION_DATA_FAILED = "PROJECTION_SEARCH_NEW_PROJECTION_DATA_FAILED";
export const SET_SELECTED_PROJECTION = "SET_SELECTED_PROJECTION";

export const PROJECTION_CLEAR_DATA = "PROJECTION_CLEAR_DATA";

//Accounting Period

export const ACCOUNTING_PERIOD_SAVE_BASE = 'ACCOUNTING_PERIOD_SAVE_BASE';
export const ACCOUNTING_PERIOD_SAVE_BASE_SUCCESS = 'ACCOUNTING_PERIOD_SAVE_BASE_SUCCESS';
export const ACCOUNTING_PERIOD_SAVE_BASE_FAILED = 'ACCOUNTING_PERIOD_SAVE_BASE_FAILED';

export const ACCOUNTING_PERIOD_TOGGLE_WEEK_ADD_EDIT = 'ACCOUNTING_PERIOD_TOGGLE_WEEK_ADD_EDIT';

export const ACCOUNTING_PERIOD_GET_SELECTED_ACCOUNTING_PERIOD = "ACCOUNTING_PERIOD_GET_SELECTED_ACCOUNTING_PERIOD";
export const ACCOUNTING_PERIOD_GET_SELECTED_ACCOUNTING_PERIOD_SUCCESS = "ACCOUNTING_PERIOD_GET_SELECTED_ACCOUNTING_PERIOD_SUCCESS";
export const ACCOUNTING_PERIOD_GET_SELECTED_ACCOUNTING_PERIOD_FAILED = "ACCOUNTING_PERIOD_GET_SELECTED_ACCOUNTING_PERIOD_FAILED";

export const ACCOUNTING_PERIOD_SET_SELECTED_PERIOD = "ACCOUNTING_PERIOD_SET_SELECTED_PERIOD";
export const ACCOUNTING_PERIOD_SET_SELECTED_WEEK = "ACCOUNTING_PERIOD_SET_SELECTED_WEEK";

export const ACCOUNTING_PERIOD_SEARCH_ACCOUNTING_PERIODS = "ACCOUNTING_PERIOD_SEARCH_ACCOUNTING_PERIODS";
export const ACCOUNTING_PERIOD_SEARCH_ACCOUNTING_PERIODS_SUCCESS = "ACCOUNTING_PERIOD_SEARCH_ACCOUNTING_PERIODS_SUCCESS";
export const ACCOUNTING_PERIOD_SEARCH_ACCOUNTING_PERIODS_FAILED = "ACCOUNTING_PERIOD_SEARCH_ACCOUNTING_PERIODS_FAILED";

export const ACCOUNTING_PERIOD_BULK_UPLOAD = "ACCOUNTING_PERIOD_BULK_UPLOAD";
export const ACCOUNTING_PERIOD_BULK_UPLOAD_SUCCESS = "ACCOUNTING_PERIOD_BULK_UPLOAD_SUCCESS";
export const ACCOUNTING_PERIOD_BULK_UPLOAD_FAILED = "ACCOUNTING_PERIOD_BULK_UPLOAD_FAILED";
