import {
    NOTIFICATION_GET_PUSHED_NOTIFICATION_COUNT_SUCCESS,
    NOTIFICATION_SEARCH_SUCCESS,
    NOTIFICATION_SET_IS_NOTIFICATION_SELECTED,
    NOTIFICATION_SET_SELECT_NOTIFICATION
} from "../actions/types";
import {getPagedData} from "../../utils/DataExtractHelper";


const initialState = {
    pageInfo: {},
    notification: [],
    selectedNotification: {},
    isNotificationSelected: false,

    notificationCount: 0

};

export default (state = initialState, action) => {
    switch (action.type) {
        case NOTIFICATION_SEARCH_SUCCESS:
            return {
                ...state,
                pageInfo: getPagedData(action.payload),
                notification: action.payload.pageData
            };
        case NOTIFICATION_SET_SELECT_NOTIFICATION:
            return {
                ...state,
                selectedNotification: action.payload
            };
        case NOTIFICATION_SET_IS_NOTIFICATION_SELECTED:
            return {
                ...state,
                isNotificationSelected: action.payload
            };
        case NOTIFICATION_GET_PUSHED_NOTIFICATION_COUNT_SUCCESS :
            return {
                ...state,
                notificationCount: action.payload
            };
        default:
            return {
                ...state
            }
    }
}