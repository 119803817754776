export const getDownTimeWastageInputConfig = (frequencyData) => {
    let config = [];

    if (frequencyData) {
        frequencyData.forEach((data, rowNumber) => {
            let rowData = {
                displayOrder: data.displayOrder,
                downTimeData: [],
                wastageData: []
            };

            let downTimeCount = (data.processDataDownTimeDTOs && data.processDataDownTimeDTOs.length > 0)
                ? data.processDataDownTimeDTOs.length : 1;
            let wastageCount = (data.processDataWastageDTOs && data.processDataWastageDTOs.length > 0)
                ? data.processDataWastageDTOs.length : 1;

            for (let i = 0; i < downTimeCount; i++) {
                let singleDownTimeInputConfig = getSingleDownTimeInputConfig();
                rowData.downTimeData.push(singleDownTimeInputConfig);
            }

            for (let i = 0; i < wastageCount; i++) {
                let singleWasteInputConfig = getSingleWastageInputConfig();
                rowData.wastageData.push(singleWasteInputConfig);
            }

            config.push(rowData);
        });
    }

    return config;
};


export const getSingleDownTimeInputConfig = () => {
    let config = [];

    config.push({
        id: "d-1",
        code: "DOWN_TIME_1",
        name: "Down Time Category",
        dataType: "STRING"
    });

    config.push({
        id: "d-2",
        code: "DOWN_TIME_2",
        name: "Down Time Sub Category",
        dataType: "STRING"
    });

    config.push({
        id: "d-3",
        code: "DOWN_TIME_3",
        name: "Down Time Duration (Mins)",
        dataType: "NUMERIC"
    });

    config.push({
        id: "d-4",
        code: "DOWN_TIME_4",
        name: "Down Time Remark",
        dataType: "STRING"
    });

    return config;
};

export const getSingleWastageInputConfig = () => {
    let config = [];

    config.push({
        id: "w-1",
        code: "WASTAGE_1",
        name: "Wastage Category",
        dataType: "STRING"
    });

    config.push({
        id: "w-2",
        code: "WASTAGE_2",
        name: "Wastage Sub Category",
        dataType: "STRING"
    });

    config.push({
        id: "w-3",
        code: "WASTAGE_3",
        name: "Wastage Weight (Kg)",
        dataType: "NUMERIC"
    });

    config.push({
        id: "w-4",
        code: "WASTAGE_4",
        name: "Wastage Remark",
        dataType: "STRING"
    });

    return config;
};
