import {
    WEB_AUDIT_CLOSE_MODEL,
    WEB_AUDIT_GET_CONSTANTS_SUCCESS,
    WEB_AUDIT_OPEN_MODEL,
    WEB_AUDIT_SEARCH,
    WEB_AUDIT_SEARCH_SUCCESS,
    WEB_AUDIT_SET_SELECTED_WEB_AUDIT
} from "../actions/types";
import {getPagedData} from "../../utils/DataExtractHelper";


const initialState = {
    pageInfo: {},
    webAudits: [],
    selectedWebAudit: {},
    webAuditConstants: [],
    openModel: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case WEB_AUDIT_SEARCH:
            return {
                ...state
            };
        case WEB_AUDIT_SEARCH_SUCCESS:
            return {
                ...state,
                webAudits: action.payload.pageData,
                pageInfo: getPagedData(action.payload)
            };
        case WEB_AUDIT_GET_CONSTANTS_SUCCESS :
            return {
                ...state,
                webAuditConstants: action.payload
            }
        case WEB_AUDIT_SET_SELECTED_WEB_AUDIT :
            return {
                ...state,
                selectedWebAudit: action.payload
            }
        case WEB_AUDIT_OPEN_MODEL:
            return {
                ...state,
                openModel: true
            }
        case WEB_AUDIT_CLOSE_MODEL:
            return {
                ...state,
                openModel: false
            }
        default:
            return state;
    }

};