import {
    MEASURE_ADD_NEW_MEASURE,
    MEASURE_GET_SELECTED_MEASURE,
    MEASURE_GET_SELECTED_MEASURE_SUCCESS,
    MEASURE_GET_SELECTED_MEASURE_SUMMERY,
    MEASURE_GET_SELECTED_MEASURE__SUMMERY_SUCCESS,
    MEASURE_RESET_SELECTED_MEASURE,
    MEASURE_SEARCH,
    MEASURE_SEARCH_SUCCESS,
    SAVE_UPDATE_MEASURE_SUCCESS,
} from '../actions/types';
import {getPagedData} from '../../utils/DataExtractHelper';


const initialState = {
    pageInfo: {},
    measures: [],
    selectedMeasure: {},
    selectedMeasureSummery:{},
};

export default (state = initialState, action) => {
    switch (action.type) {
        case MEASURE_SEARCH :
            return {
                ...state
            };

        case MEASURE_SEARCH_SUCCESS :
            return {
                ...state,
                measures: action.payload.pageData,
                pageInfo: getPagedData(action.payload)
            };

        case MEASURE_GET_SELECTED_MEASURE :
            return {
                ...state
            };
        case MEASURE_GET_SELECTED_MEASURE_SUMMERY :
            return {
                ...state
            };
        case MEASURE_GET_SELECTED_MEASURE_SUCCESS :
        case SAVE_UPDATE_MEASURE_SUCCESS :
            return {
                ...state,
                selectedMeasure: action.payload
            };
        case MEASURE_GET_SELECTED_MEASURE__SUMMERY_SUCCESS :
            return {
                ...state,
                selectedMeasureSummery: action.payload
            };
        case MEASURE_ADD_NEW_MEASURE :
            return {
                ...state,
                selectedMeasure: action.payload
            };
        case MEASURE_RESET_SELECTED_MEASURE :
            return {
                ...state,
                selectedMeasure: action.payload
            };

        default :
            return state;
    }
}
