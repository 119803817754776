import {
    DATA_FORM_CREATION_CLEAR_SELECTED_SITE_PERFORMANCE,
    DATA_FORM_CREATION_GET_LOAD_MEASURE_GROUPS,
    DATA_FORM_CREATION_GET_LOAD_MEASURE_GROUPS_SUCCESS,
    DATA_FORM_CREATION_REMOVE_SELECTED_SITE_PERFORMANCE,
    DATA_FORM_CREATION_RESET_DATA,
    DATA_FORM_CREATION_SEARCH,
    DATA_FORM_CREATION_SEARCH_SUCCESS,
    DATA_FORM_CREATION_SELECT_SITE_PERFORMANCE,
    DATA_FORM_CREATION_SELECT_SITE_PERFORMANCE_MODAL_CLOSE,
    DATA_FORM_CREATION_SELECT_SITE_PERFORMANCE_MODAL_OPEN,
    DATA_FORM_CREATION_DEFAULT__VALUE__PICKER_MODAL_OPEN,
    DATA_FORM_CREATION_DEFAULT__VALUE__PICKER_MODAL_CLOSE,
    DATA_FORM_CREATION_SELECT_SITE_PERFORMANCE_TEMP,
    GET_DATA_FORM_CREATION_DATA_FORM,
    GET_DATA_FORM_CREATION_DATA_FORM_SUCCESS,
    GET_DATA_FORM_CREATION_FACTORY_PRODUCTION_LINES,
    GET_DATA_FORM_CREATION_FACTORY_PRODUCTION_LINES_SUCCESS,
    GET_DATA_FORM_CREATION_FACTORY_PRODUCTS,
    GET_DATA_FORM_CREATION_FACTORY_PRODUCTS_SUCCESS,
    GET_DATA_FORM_CREATION_FACTORY_SAVE,
    GET_DATA_FORM_CREATION_FACTORY_SAVE_SUCCESS
} from "../actions/types";
import {getPagedData} from "../../utils/DataExtractHelper";

const initialState = {
    pageInfo: {},
    dataCreationFormPageData: [],

    factoryProducts: [],
    factoryProductionLines: [],
    dataFormData: {},

    sitePerformancesSelectModalState: false,
    measureDefaultValuePickerState: false,
    sitePerformancesTemp: [],
    selectedSitePerformanceIDs: [],
    sitePerformanceData: [],
};

export default (state = initialState, action) => {
    switch (action.type) {

        case GET_DATA_FORM_CREATION_FACTORY_PRODUCTS :
        case GET_DATA_FORM_CREATION_FACTORY_PRODUCTION_LINES:
        case GET_DATA_FORM_CREATION_FACTORY_SAVE:
        case GET_DATA_FORM_CREATION_DATA_FORM:
        case DATA_FORM_CREATION_GET_LOAD_MEASURE_GROUPS:
        case DATA_FORM_CREATION_SEARCH:
            return {
                ...state
            };

        case DATA_FORM_CREATION_SEARCH_SUCCESS:
            return {
                ...state,
                pageInfo: getPagedData(action.payload),
                dataCreationFormPageData: action.payload.pageData,
            };

        case GET_DATA_FORM_CREATION_FACTORY_PRODUCTS_SUCCESS :
            return {
                ...state,
                factoryProducts: action.payload,
            };

        case GET_DATA_FORM_CREATION_FACTORY_PRODUCTION_LINES_SUCCESS :
            return {
                ...state,
                factoryProductionLines: action.payload,
            };

        case GET_DATA_FORM_CREATION_FACTORY_SAVE_SUCCESS :
            return {
                ...state,
                dataFormData: action.payload,
                selectedSitePerformanceIDs: action.payload.measureGroupIDs
            };

        case GET_DATA_FORM_CREATION_DATA_FORM_SUCCESS :
            return {
                ...state,
                dataFormData: action.payload,
                selectedSitePerformanceIDs: action.payload.measureGroupIDs
            };

        case DATA_FORM_CREATION_SELECT_SITE_PERFORMANCE_MODAL_OPEN :
            return {
                ...state,
                sitePerformancesSelectModalState: true,
            };

        case DATA_FORM_CREATION_SELECT_SITE_PERFORMANCE_MODAL_CLOSE :
            return {
                ...state,
                sitePerformancesSelectModalState: false,
            };

        case DATA_FORM_CREATION_DEFAULT__VALUE__PICKER_MODAL_OPEN :
            return {
                ...state,
                measureDefaultValuePickerState: true,
            };

        case DATA_FORM_CREATION_DEFAULT__VALUE__PICKER_MODAL_CLOSE :
            return {
                ...state,
                measureDefaultValuePickerState: false,
            };

        case DATA_FORM_CREATION_SELECT_SITE_PERFORMANCE_TEMP :
            return {
                ...state,
                sitePerformancesTemp: action.payload
            };

        case DATA_FORM_CREATION_SELECT_SITE_PERFORMANCE :

            let selectedTempIDs = state.sitePerformancesTemp.map((sitePerformance) => sitePerformance.measureGroupID);

            return {
                ...state,
                selectedSitePerformanceIDs: [...state.selectedSitePerformanceIDs, ...selectedTempIDs]
            };

        case DATA_FORM_CREATION_REMOVE_SELECTED_SITE_PERFORMANCE:

            let removedSelectedTempIDs = state.selectedSitePerformanceIDs.slice();
            let removeID = removedSelectedTempIDs.splice(action.payload, 1);

            let newSitePerformanceData = [];
            state.sitePerformanceData.map((data) => {
                if (data.measureGroupID != removeID[0]) {
                    newSitePerformanceData.push(data)
                }
            });

            return {
                ...state,
                selectedSitePerformanceIDs: removedSelectedTempIDs,
                sitePerformanceData: newSitePerformanceData
            };

        case DATA_FORM_CREATION_GET_LOAD_MEASURE_GROUPS_SUCCESS:
            return {
                ...state,
                sitePerformanceData: action.payload.measureGroupDTOs
            };

        case DATA_FORM_CREATION_RESET_DATA:
            return {
                ...state,
                factoryProducts: [],
                factoryProductionLines: [],
                dataFormData: {},

                sitePerformancesSelectModalState: false,
                sitePerformancesTemp: [],
                selectedSitePerformanceIDs: [],
                sitePerformanceData: [],
            };

        case DATA_FORM_CREATION_CLEAR_SELECTED_SITE_PERFORMANCE:
            return {
                ...state,
                selectedSitePerformanceIDs: [],
                sitePerformanceData: [],
            };

        default :
            return state;
    }
}
