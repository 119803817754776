import {WIDGET_SEARCH, WIDGET_SEARCH_SUCCESS} from '../actions/types';
import {getPagedData} from '../../utils/DataExtractHelper';


const initialState = {
    pageInfo: {},
    widgets: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case WIDGET_SEARCH :
            return {
                ...state
            };
        case WIDGET_SEARCH_SUCCESS :
            return {
                ...state,
                widgets: action.payload.pageData,
                pageInfo: getPagedData(action.payload)
            };
        default :
            return state;
    }
}
