import {
    COMPANY_ADD_EDIT_SELECT_SUBSIDIARY_MODAL_CLOSE,
    COMPANY_ADD_EDIT_SELECT_SUBSIDIARY_MODAL_OPEN, COMPANY_ADD_EDIT_UPDATE_REMOVED_SUBSIDIARY,
    COMPANY_ADD_EDIT_UPDATE_SELECTED_SUBSIDIARY,
    COMPANY_ADD_EDIT_UPDATE_TMP_SELECTED_SUBSIDIARY,
    COMPANY_ADD_NEW_COMPANY,
    COMPANY_GET_SELECTED_COMPANY,
    COMPANY_GET_SELECTED_COMPANY_SUCCESS,
    COMPANY_SEARCH,
    COMPANY_SEARCH_FOR_SUBSIDIARY,
    COMPANY_SEARCH_FOR_SUBSIDIARY_SUCCESS,
    COMPANY_SEARCH_SUCCESS,
    COMPANY_SET_SELECTED_COMPANY,
    SAVE_UPDATE_COMPANY_SUCCESS
} from "../actions/types";
import {getPagedData} from '../../utils/DataExtractHelper';

const initialState = {
    pageInfo: {},
    companies: [],
    selectedCompany: {},

    showAddSubsidiaryModal: false,
    selectedSubsidiaries: [],
    selectedSubsidiariesTem: [],
    pageInfoForSubsidiary: {},
    companiesForSubsidiary: [],
    selectedCompanyID: '',
    selectedAndRemovedSubsidiary: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case COMPANY_SEARCH :
        case COMPANY_SEARCH_FOR_SUBSIDIARY:
            return {
                ...state
            };

        case COMPANY_SEARCH_SUCCESS :
            return {
                ...state,
                companies: action.payload.pageData,
                pageInfo: getPagedData(action.payload)
            };
        case COMPANY_GET_SELECTED_COMPANY:
            return {
                ...state,
                selectedAndRemovedSubsidiary: []
            };

        case COMPANY_GET_SELECTED_COMPANY_SUCCESS:
        case SAVE_UPDATE_COMPANY_SUCCESS:
            return {
                ...state,
                selectedCompany: action.payload,
                selectedSubsidiaries: action.payload.companySubsidiaries,
                selectedAndRemovedSubsidiary: []
            };

        case COMPANY_ADD_NEW_COMPANY :
            return {
                ...state,
                selectedCompany: action.payload,
                selectedSubsidiaries: [],
                selectedAndRemovedSubsidiary: []
            };

        case COMPANY_ADD_EDIT_SELECT_SUBSIDIARY_MODAL_OPEN:
            return {
                ...state,
                showAddSubsidiaryModal: true
            };

        case COMPANY_ADD_EDIT_SELECT_SUBSIDIARY_MODAL_CLOSE:
            return {
                ...state,
                showAddSubsidiaryModal: false
            };

        case COMPANY_ADD_EDIT_UPDATE_TMP_SELECTED_SUBSIDIARY:
            return {
                ...state,
                selectedSubsidiariesTem: action.payload
            };

        case COMPANY_SEARCH_FOR_SUBSIDIARY_SUCCESS :
            return {
                ...state,
                companiesForSubsidiary: action.payload.pageData,
                pageInfoForSubsidiary: getPagedData(action.payload)
            };

        case COMPANY_ADD_EDIT_UPDATE_SELECTED_SUBSIDIARY :
            return {
                ...state,
                selectedSubsidiaries: action.payload
            };

        case COMPANY_SET_SELECTED_COMPANY:
            return {
                ...state,
                selectedCompanyID: action.payload
            };

        case COMPANY_ADD_EDIT_UPDATE_REMOVED_SUBSIDIARY:
            return {
                ...state,
                selectedAndRemovedSubsidiary: [...state.selectedAndRemovedSubsidiary, action.payload]
            };

        default:
            return state
    }
};
