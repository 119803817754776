import {
    USER_ADD_NEW_USER,
    USER_AFTER_PASSWORD_RESET_SUCCESS,
    USER_COMPANY_VICE_FACTORIES_SUCCESS,
    USER_DEPARTMENTS_VICE_PRODUCTION_LINES_SUCCESS,
    USER_FACTORIES_VICE_DEPARTMENTS_SUCCESS,
    USER_FORGOT_PASSWORD_RESET,
    USER_FORGOT_PASSWORD_RESET_SUCCESS,
    USER_GET_DATA_ENTRY_FORMS_SUCCESS,
    USER_GET_SELECTED_USER,
    USER_GET_SELECTED_USER_SUCCESS,
    USER_PASSWORD_RESET_SUCCESS,
    USER_RESET_SELECTED_USER,
    USER_SAVE_UPDATE_SUCCESS,
    USER_SEARCH,
    USER_SEARCH_SUCCESS
} from '../actions/types';
import {getPagedData} from '../../utils/DataExtractHelper';

const initialState = {
    pageInfo: {},
    users: [],
    selectedUser: {},
    isPasswordRest:false,
    companyViceFactories:{},
    factoriesViceDepartments:{},
    departmentsViceProductionLines:{},
    dataEntryForms : {},
};

export default (state = initialState, action) => {
    switch (action.type) {
        case USER_SEARCH :
        case USER_FORGOT_PASSWORD_RESET:
            return {
                ...state
            };
        case USER_SEARCH_SUCCESS :
            return {
                ...state,
                users: action.payload.pageData,
                pageInfo: getPagedData(action.payload)
            };

        case USER_GET_SELECTED_USER :
            return {
                ...state
            };

        case USER_GET_SELECTED_USER_SUCCESS :
        case USER_SAVE_UPDATE_SUCCESS :
            return {
                ...state,
                selectedUser: action.payload
            };

        case USER_ADD_NEW_USER :
            return {
                ...state,
                selectedUser: {}
            };

        case USER_AFTER_PASSWORD_RESET_SUCCESS:
            return{
                ...state,
                isPasswordRest:!state.isPasswordRest
            };

        case USER_COMPANY_VICE_FACTORIES_SUCCESS:
            return {
                ...state,
                companyViceFactories: action.payload
            };

        case USER_FACTORIES_VICE_DEPARTMENTS_SUCCESS:
            return {
                ...state,
                factoriesViceDepartments: action.payload
            };

        case USER_DEPARTMENTS_VICE_PRODUCTION_LINES_SUCCESS:
            return {
                ...state,
                departmentsViceProductionLines: action.payload
            };

        case USER_GET_DATA_ENTRY_FORMS_SUCCESS:
            return {
                ...state,
                dataEntryForms: action.payload
            };

        case USER_RESET_SELECTED_USER:
            return {
              ...state,
                selectedUser: {},
                companyViceFactories:{},
                factoriesViceDepartments:{},
                departmentsViceProductionLines:{},
                dataEntryForms : []
            };

        case USER_FORGOT_PASSWORD_RESET_SUCCESS:
            return {
                ...state,
                forgetPasswordStatus: action.payload
            };

        default:
            return state;

    }
}

