import {
    SAVE_UPDATE_USER_GROUP_SUCCESS,
    USER_ADD_EDIT_SELECT_USER_MODAL_CLOSE,
    USER_ADD_EDIT_SELECT_USER_MODAL_OPEN,
    USER_GROUP_ADD_EDIT_UPDATE_SELECTED_USER,
    USER_GROUP_ADD_EDIT_UPDATE_TMP_SELECTED_USER,
    USER_GROUP_ADD_NEW_USER_GROUP,
    USER_GROUP_GET_SELECTED_USER_GROUP,
    USER_GROUP_GET_SELECTED_USER_GROUP_SUCCESS,
    USER_GROUP_SEARCH,
    USER_GROUP_SEARCH_SUCCESS,
    USER_GROUP_SET_SELECTED_COMPANY,
} from "../actions/types";
import {getPagedData} from '../../utils/DataExtractHelper';

const initialState = {
    pageInfo: {},
    userGroups: [],
    selectedUserGroups: {},

    showAddUserModal: false,
    selectedUsers: [],
    selectedUsersTem: [],
    selectedCompanyID: ''
};

export default (state = initialState, action) => {
    switch (action.type) {
        case USER_GROUP_SEARCH :
            return {
                ...state
            };

        case USER_GROUP_SEARCH_SUCCESS :
            return {
                ...state,
                pageInfo: getPagedData(action.payload),
                userGroups: action.payload.pageData
            };

        case USER_GROUP_GET_SELECTED_USER_GROUP :
            return {
                ...state
            };

        case USER_GROUP_GET_SELECTED_USER_GROUP_SUCCESS :
        case SAVE_UPDATE_USER_GROUP_SUCCESS :
            return {
                ...state,
                selectedUserGroups: action.payload,
                selectedUsers: action.payload.userDTOS
            };

        case USER_GROUP_ADD_NEW_USER_GROUP :
            return {
                ...state,
                selectedUserGroups: action.payload,
                selectedUsers: []
            };

        case USER_ADD_EDIT_SELECT_USER_MODAL_OPEN :
            return {
                ...state,
                showAddUserModal: true
            };

        case USER_ADD_EDIT_SELECT_USER_MODAL_CLOSE :
            return {
                ...state,
                showAddUserModal: false
            };

        case USER_GROUP_ADD_EDIT_UPDATE_TMP_SELECTED_USER :
            return {
                ...state,
                selectedUsersTem: action.payload
            };

        case USER_GROUP_ADD_EDIT_UPDATE_SELECTED_USER :
            return {
                ...state,
                selectedUsers: action.payload
            };

        case USER_GROUP_SET_SELECTED_COMPANY:
            return {
                ...state,
                selectedCompanyID: action.payload
            };

        default:
            return {
                ...state
            }
    }
}





