import {
    LOCATION_ADD_NEW_LOCATION,
    LOCATION_GET_SELECTED_LOCATION,
    LOCATION_GET_SELECTED_LOCATION_SUCCESS,
    LOCATION_ON_REMOVE_SELECTED_BUSINESS_UNITS,
    LOCATION_ON_REMOVE_SELECTED_PRODUCT, LOCATION_ON_REMOVE_SELECTED_SUPERVISORS,
    LOCATION_ON_SELECT_BUSINESS_UNITS,
    LOCATION_ON_SELECT_PRODUCTS, LOCATION_ON_SELECT_SUPERVISORS,
    LOCATION_ON_SELECT_TEMP_BUSINESS_UNITS,
    LOCATION_ON_SELECT_TEMP_PRODUCTS, LOCATION_ON_SELECT_TEMP_SUPERVISORS,
    LOCATION_RESET_SELECTED_LOCATION,
    LOCATION_SEARCH,
    LOCATION_SEARCH_SUCCESS,
    LOCATION_SHOW_SUPERVISOR_SELECT_MODAL_CLOSE,
    LOCATION_SHOW_SUPERVISOR_SELECT_MODAL_OPEN,
    SAVE_UPDATE_LOCATION_SUCCESS,
} from '../actions/types';
import {getPagedData} from '../../utils/DataExtractHelper';


const initialState = {
    pageInfo: {},
    locations: [],
    selectedLocation: {},

    selectedBusinessUnitsTemp: [],
    selectedBusinessUnits: [],
    selectedProductsTemp: [],
    selectedProducts: [],
    supervisorSelectModalState: false,
    selectedSupervisorsTemp: [],
    selectedSupervisors: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOCATION_SEARCH :
            return {
                ...state
            };

        case LOCATION_SEARCH_SUCCESS :
            return {
                ...state,
                locations: action.payload.pageData,
                pageInfo: getPagedData(action.payload),
                selectedLocation: {}
            };

        case LOCATION_GET_SELECTED_LOCATION :
            return {
                ...state,
                selectedLocation: {}
            };

        case LOCATION_GET_SELECTED_LOCATION_SUCCESS :
        case SAVE_UPDATE_LOCATION_SUCCESS :
            return {
                ...state,
                selectedLocation: action.payload,
                selectedBusinessUnits: action.payload.businessUnitLocationDTOs,
                selectedProducts: action.payload.locationProductDTOs,
                selectedSupervisors: action.payload.supervisorDTOs,
            };

        case LOCATION_RESET_SELECTED_LOCATION :
            return {
                ...state,
                selectedLocation: action.payload,
                selectedBusinessUnits: action.payload.businessUnitLocationDTOs,
                selectedProducts: action.payload.locationProductDTOs,
                selectedSupervisors: action.payload.supervisorDTOs,
            };

        case LOCATION_ADD_NEW_LOCATION:
            return {
                ...state,
                selectedLocation: {},
                selectedBusinessUnitsTemp: [],
                selectedBusinessUnits: [],
                selectedProductsTemp: [],
                selectedProducts: [],
                selectedSupervisorsTemp: [],
                selectedSupervisors: [],
            };

        case LOCATION_ON_SELECT_TEMP_BUSINESS_UNITS:
            return {
                ...state,
                selectedBusinessUnitsTemp: action.payload
            };

        case LOCATION_ON_SELECT_BUSINESS_UNITS:
            let selectedBusinessUnits = [...state.selectedBusinessUnits, ...state.selectedBusinessUnitsTemp];
            return {
                ...state,
                selectedBusinessUnits: selectedBusinessUnits,
            };

        case LOCATION_ON_REMOVE_SELECTED_BUSINESS_UNITS:
            let removeSelectedBusinessUnits = [...state.selectedBusinessUnits];
            removeSelectedBusinessUnits.splice(action.payload, 1);
            return {
                ...state,
                selectedBusinessUnits: removeSelectedBusinessUnits,
            };

        case LOCATION_ON_SELECT_TEMP_PRODUCTS:
            return {
                ...state,
                selectedProductsTemp: action.payload
            };

        case LOCATION_ON_SELECT_PRODUCTS:
            let selectedProducts = [...state.selectedProducts, ...state.selectedProductsTemp];
            return {
                ...state,
                selectedProducts: selectedProducts,
            };

        case LOCATION_ON_REMOVE_SELECTED_PRODUCT:
            let removeSelectedProducts = [...state.selectedProducts];
            removeSelectedProducts.splice(action.payload, 1);
            return {
                ...state,
                selectedProducts: removeSelectedProducts,
            };

        case LOCATION_SHOW_SUPERVISOR_SELECT_MODAL_OPEN:
            return {
                ...state,
                supervisorSelectModalState: true,
            };

        case LOCATION_SHOW_SUPERVISOR_SELECT_MODAL_CLOSE:
            return {
                ...state,
                supervisorSelectModalState: false,
            };

        case LOCATION_ON_SELECT_TEMP_SUPERVISORS:
            return {
                ...state,
                selectedSupervisorsTemp: action.payload
            };

        case LOCATION_ON_SELECT_SUPERVISORS:
            let selectedSupervisors = [...state.selectedSupervisors, ...state.selectedSupervisorsTemp];
            return {
                ...state,
                selectedSupervisors: selectedSupervisors,
            };

        case LOCATION_ON_REMOVE_SELECTED_SUPERVISORS:
            let removeSelectedSupervisor = [...state.selectedSupervisors];
            removeSelectedSupervisor.splice(action.payload, 1);
            return {
                ...state,
                selectedSupervisors: removeSelectedSupervisor,
            };

        default :
            return state;
    }
}
