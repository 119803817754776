import React, {Component} from 'react';
import Loader from "react-loader-spinner";
import {Trans, withTranslation} from 'react-i18next';

class LoadingComponent extends Component {
    render() {
        return (
            <div style={{
                position: 'fixed',
                top: '0px',
                left: '0px',
                bottom: '0px',
                right: '0px',
                display: 'flex',
                alignItem: 'center',
                overflow: 'auto',
                backgroundColor: '#a0a0a03b',
                zIndex: 999999
            }}>
                <div style={{
                    margin: 'auto',
                    maxHeight: '100%'
                }}>
                    <Loader
                        type="Oval"
                        color="#00BFFF"
                        height={100}
                        width={100}
                    />
                </div>
            </div>
        );
    }
}

export default withTranslation()(LoadingComponent);
