import {combineReducers} from "redux";
import authReducer from './authReducer';
import roleReducer from "./roleReducer";
import masterDataReducer from "./masterDataReducer";
import userReducer from './userReducer';
import locationReducer from "./locationReducer";
import companyReducer from './companyReducer';
import businessUnitReducer from './businessUnitReducer'
import productReducer from "./productReducer";
import SupervisorReducer from "./supervisorReducer";
import measureUnitCategoryReducer from "./measureUnitCategoryReducer";
import subBusinessUnitReducer from './subBusinessUnitReducer'
import measureReducer from "./measureReducer";
import measureGroupReducer from "./measureGroupReducer";
import measureUnitReducer from "./measureUnitReducer";
import dataEntryFormReducer from "./dataEntryFormReducer";
import processTemplateReducer from "./processTemplateReducer";
import advancedMeasureReducer from "./advancedMeasureReducer";
import frequencyReducer from "./frequencyReducer";
import webAuditReducer from "./webAuditReducer";
import widgetReducer from "./widgetReducer";
import widgetAddEditReducer from "./widgetAddEditReducer";
import dashboardReducer from "./dashboardReducer";
import reportReducer from "./reportReducer";
import reportGenerateReducer from "./reportGenerateReducer";
import userGroupReducer from "./userGroupReducer";
import notificationReducer from "./notificationReducer";
import LabourClassificationReducer from "./labourClassificationReducer";
import wastsReducer from './wasteReducer';
import shiftReducer from "./shiftReducer";
import standardReducer from "./standardReducer";
import downTimeReducer from "./downTimeCausesReducer";
import dataFormCreationReducer from "./dataFormCreationReducer";
import dataEntryReducer from "./dataEntryReducer";
import projectionReducer from "./projectionReducer";
import reportReducerNew from "./reportReducerNew";
import accountingPeriodReducer from "./accountingPeriodReducer";

export default combineReducers({
    auth: authReducer,
    role: roleReducer,
    masterData: masterDataReducer,
    user: userReducer,
    locations: locationReducer,
    businessUnits: businessUnitReducer,
    company: companyReducer,
    products: productReducer,
    supervisors: SupervisorReducer,
    measureUnitCategory: measureUnitCategoryReducer,
    subBusinessUnits: subBusinessUnitReducer,
    measures: measureReducer,
    measureGroups: measureGroupReducer,
    measureUnit: measureUnitReducer,
    processTemplates: processTemplateReducer,
    frequencies: frequencyReducer,
    dataEntryForm: dataEntryFormReducer,
    widgets: widgetReducer,
    widgetAddEdit: widgetAddEditReducer,
    dashboard: dashboardReducer,
    webAudit: webAuditReducer,
    advancedMeasure: advancedMeasureReducer,
    report: reportReducer,
    reportGenerate: reportGenerateReducer,
    userGroup: userGroupReducer,
    notification: notificationReducer,
    labourClassifications: LabourClassificationReducer,
    downTimeCauses: downTimeReducer,
    shifts: shiftReducer,
    wasts: wastsReducer,
    standard: standardReducer,
    dataFormCreation: dataFormCreationReducer,
    dataEntry: dataEntryReducer,
    projection: projectionReducer,
    accountPeriod: accountingPeriodReducer,
});
