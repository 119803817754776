import {
    LOG_IN,
    LOG_IN_FAILED,
    LOG_IN_FAILED_CLEAR_ALERT,
    LOG_IN_SUCCESS,
    LOG_OUT,
    RESTORE_LOGIN_DATA
} from '../actions/types';


const initialState = {
    user: null,
    logInError: {},
    accessToken: null,
    refreshToken: null
};

export default (state = initialState, action) => {
    switch (action.type) {

        case LOG_IN :
            return {
                ...state,
            };

        case LOG_IN_SUCCESS :
            return {
                ...state,
                logInError: {},
                user: action.payload.user,
                accessToken: action.payload.accessToken,
                refreshToken: action.payload.refreshToken,
            };

        case LOG_IN_FAILED :
            return {
                ...state,
                user: null,
                accessToken: null,
                refreshToken: null,
                logInError: {
                    error: true,
                    message: action.payload
                }
            };

        case LOG_IN_FAILED_CLEAR_ALERT :
            return {
                ...state,
                logInError: {
                    error: false,
                    message: ''
                }
            };

        case LOG_OUT :
            return {
                ...initialState
            };

        case  RESTORE_LOGIN_DATA :
            return {
                ...state,
                ...action.payload
            };

        default :
            return state;
    }
}
