import {
    ADD_EDIT_WIDGET_SELECT_PROCESS_MEASURE_ENABLE_MAIN_MEASURE_SORT,
    ADD_EDIT_WIDGET_SELECT_PROCESS_MEASURE_SET_SORTED_POSITION,
    REPORT_ADD_NEW_SUPERVISOR,
    REPORT_ADD_PROCESS_MEASURE_RULE,
    REPORT_CLEAR_DATA,
    REPORT_CREATE_UPDATE_BASIC_REPORT_TEMPLATE_SUCCESS,
    REPORT_EDIT_PROCESS_MEASURE_RULE,
    REPORT_GET_CALCULATION_OPERATORS_SUCCESS,
    REPORT_GET_PAGED_DATE_SUCCESS,
    REPORT_GET_REPORT_TEMPLATE_BY_ID_SUCCESS,
    REPORT_GET_REPORT_TEMPLATE_BY_ID_SUMMERY_SUCCESS,
    REPORT_GET_REPORT_TEMPLATE_TYPE_SUCCESS,
    REPORT_GET_SUB_BUSINESS_UNIT_SUCCESS,
    REPORT_GET_USER_SEARCH,
    REPORT_GET_USER_SEARCH_SUCCESS,
    REPORT_LOAD_COMMON_FREQUENCY_FOR_PROCESS_SUCCESS,
    REPORT_LOAD_PROCESS_MEASURES,
    REPORT_LOAD_PROCESS_MEASURES_LIST_SUCCESS,
    REPORT_LOAD_PROCESS_MEASURES_SUCCESS,
    REPORT_LOAD_SELECTED_REPORT_PROCESS_WISE_MEASURE_DATA,
    REPORT_LOAD_SELECTED_REPORT_PROCESS_WISE_MEASURE_DATA_SUCCESS,
    REPORT_LOAD_SELECTED_WIDGET_PROCESS_TEMPLATES_SUCCESS,
    REPORT_MANIPULATE_SELECTED_WIDGET_STATE,
    REPORT_MEASURE_DATA_ENTRY_FORM__MEASURES_LIST_SUCCESS,
    REPORT_MEASURE_DATA_ENTRY_FORM_MEASURE_MODAL_CLOSE,
    REPORT_MEASURE_DATA_ENTRY_FORM_MEASURE_MODAL_OPEN,
    REPORT_MEASURE_DATA_FORM_SEARCH_SUCCESS,
    REPORT_MEASURE_LOAD_MEASURES_BY_MEASURE_ID_SUCCESS,
    REPORT_MEASURE_LOAD_PROCESS_TEMPLATES_BY_ID_SUCCESS,
    REPORT_MEASURE_REMOVE_DATA_ENTRY_FORM,
    REPORT_MEASURE_REMOVE_DATA_ENTRY_FORM_MEASURE,
    REPORT_MEASURE_REMOVE_MEASURE,
    REPORT_MEASURE_SEARCH_SUCCESS,
    REPORT_MEASURE_SELECT_DATA_ENTRY_FORMS,
    REPORT_MEASURE_SELECT_DATA_ENTRY_MODAL_CLOSE,
    REPORT_MEASURE_SELECT_DATA_ENTRY_MODAL_OPEN,
    REPORT_MEASURE_SELECT_MEASURES,
    REPORT_MEASURE_SELECT_MEASURES_MODAL_CLOSE,
    REPORT_MEASURE_SELECT_MEASURES_MODAL_OPEN,
    REPORT_MEASURE_SORT,
    REPORT_MEASURE_TOGGLE_SORT,
    REPORT_MEASURE_UPDATE_DATA_ENTRY_FORM_MEASURE_AGGREGATE_TYPE,
    REPORT_MEASURE_UPDATE_DATA_ENTRY_FORM_MEASURE_RE_CALCULATION,
    REPORT_MEASURE_UPDATE_DATA_ENTRY_FORM_MEASURES,
    REPORT_MEASURE_UPDATE_MEASURE_WISE_EXPANDED,
    REPORT_MEASURE_UPDATE_REPORT_MEASURE_DATA_STRUCTURE,
    REPORT_MEASURE_UPDATE_TMP_DATA_ENTRY_FORM_MEASURES,
    REPORT_MEASURE_UPDATE_TMP_DATA_ENTRY_FORMS,
    REPORT_MEASURE_UPDATE_TMP_SELECTED_MEASURES,
    REPORT_ON_CHANGE_AGGREGATE_TYPE,
    REPORT_ON_CHANGE_COMMON_FREQUENCY_LIST,
    REPORT_ON_SELECT_BUSINESS_UNIT_ID,
    REPORT_ON_SELECT_BUSINESS_UNIT_LOCATION_IDS_SUB_BUSINESS_UNIT_IDS,
    REPORT_ON_SELECT_COMPANY_ID,
    REPORT_ON_SELECT_FREQUENCY_ID,
    REPORT_PROCESS_TEMPLATE_SEARCH,
    REPORT_PROCESS_TEMPLATE_SEARCH_SUCCESS,
    REPORT_REMOVE_PROCESS_MEASURE_RULE,
    REPORT_REMOVE_SELECTED_MEASURE,
    REPORT_RESET_EMAIL_SENDER_TEMP_SELECTED,
    REPORT_RESET_REPORT_CONTEXT,
    REPORT_RESET_REPORT_SCHEDULE_PARAMS,
    REPORT_RESET_SCHEDULER_SAVED,
    REPORT_SCHEDULER_DELETE_SUCCESS,
    REPORT_SCHEDULER_UPLOAD_SUCCESS,
    REPORT_SELECT_ELIGIBLE_USER_TYPE,
    REPORT_SELECT_PROCESS_IDS,
    REPORT_SELECT_PROCESS_ITEMS,
    REPORT_SELECT_PROCESS_MEASURE,
    REPORT_SELECT_PROCESS_MEASURE_BUDGET_COLOR_CHANGE,
    REPORT_SELECT_PROCESS_MEASURE_CHECK_OPTIONS_CHANGE,
    REPORT_SELECT_PROCESS_MEASURE_COLOR_CHANGE,
    REPORT_SELECT_PROCESS_MEASURE_TARGET_COLOR_CHANGE,
    REPORT_SELECT_USERS,
    REPORT_SET_CREATE_SECTION,
    REPORT_SET_REPORT_TEMPLATE_ID,
    REPORT_SET_REPORT_TEMPLATE_SCHEDULER_ID,
    REPORT_SET_SCHEDULER_DATE,
    REPORT_SET_SCHEDULER_DATE_OF_WEEK,
    REPORT_SET_SCHEDULER_EMAIL_DTO_LIST,
    REPORT_SET_SCHEDULER_EMAIL_SENDER_TEMP_SELECTED,
    REPORT_SET_SCHEDULER_FREQUENCY,
    REPORT_SET_SCHEDULER_FROM_DATE,
    REPORT_SET_SCHEDULER_MONTH,
    REPORT_SET_SCHEDULER_NAME,
    REPORT_SET_SCHEDULER_REPORT_EXPORT_FORMAT,
    REPORT_SET_SCHEDULER_SAVED_SCHEDULER,
    REPORT_SET_SCHEDULER_STATUS,
    REPORT_SET_SCHEDULER_TIME,
    REPORT_SET_SCHEDULER_TO_DATE,
    REPORT_SET_SCHEDULER_TOGGLE_ADD_SCHEDULER,
    REPORT_SET_SCHEDULER_TOGGLE_SCHEDULER_NAME_PRESENT,
    REPORT_SET_SCHEDULER_USER_GROUP_DTO_LIST,
    REPORT_SET_SECTION_SELECTED_AGGREGATE,
    REPORT_SET_SECTION_SELECTED_DISPLAY_MEASURE_SORT,
    REPORT_SET_SECTION_SELECTED_DISPLAY_MEASURES_INIT,
    REPORT_SET_SECTION_SELECTED_MEASURE,
    REPORT_SET_SECTION_SELECTED_MEASURE_DATA,
    REPORT_SET_SECTION_SELECTED_MEASURE_RULE,
    REPORT_SET_SELECTED_MEASURE_AGGREGATE,
    REPORT_SET_SELECTED_MEASURE_BEST_EVER_TYPE,
    REPORT_SET_SELECTED_MEASURE_BEST_EVER_TYPE_ALL,
    REPORT_SET_SELECTED_MEASURE_RECALCULATE,
    REPORT_SET_SELECTED_REPORT_TYPE,
    REPORT_SET_TEMP_SELECTED_USER_GROUPS,
    REPORT_SUB_BUSINESS_UNIT_SEARCH,
    REPORT_SUB_BUSINESS_UNIT_SEARCH_SUCCESS,
    REPORT_USER_GROUP_MODEL_IS_OPEN
} from "../actions/types";
import {getPagedData} from "../../utils/DataExtractHelper";
import * as _ from "lodash";
import Constants from "../../utils/Constants";

const initialState = {
    reportTemplateTypes: [],
    businessLocationWiseSubBusinessUnit: [],
    calculationOperators: [],
    selectedCompanyID: null,
    selectedBusinessUnitID: null,
    selectedBusinessUnitLocations: [],
    selectedSubBusinessUnits: [],
    selectedReport: {},
    eligibleUserType: '',
    selectedUserIDs: [],

    users: [],
    userPageInfo: {},

    processTemplates: [],
    processTemplatePageInfo: {},
    processTemplatePageFilteredSubBusinessUnits: [],
    selectedProcessIDs: [],
    selectedProcessItems: {},

    selectedFrequencyID: null,
    commonFrequency: '',
    commonFrequencyList: [],
    aggregateType: Constants.REPORT_AGGREGATE_TYPE_ARRAY[0],

    processWiseMeasureData: {},
    processWiseSelectedMeasureData: {},
    processWiseMeasureWiseSelectedColor: {},
    processWiseMeasureWiseCheckOptions: {},
    processWiseMeasureWiseRules: {},
    processWiseSelectedMeasureWiseAggregate: {},
    processWiseSelectedMeasureWiseRecalculate: {},
    processWiseSelectedMeasureWiseBestEver: {},

    pageInfo: {},
    reports: [],
    selectedReportTemplate: {},
    selectedReportSummeryTemplate: {},
    selectedReportType: '',

    reportSchedulerParams: {
        reportTemplateSchedulerID: null,
        reportTemplateID: null,
        schedulerName: '',
        scheduleFrom: 0,
        scheduleTo: 0,
        frequency: Constants.FREQUENCY_TYPE.DAILY,
        time: '',
        dateOfWeek: 'MON',
        date: '1',
        month: '',
        status: 'ACT',
        userGroupIDs: [],
        userGroupDTOS: []
    },

    reportContext: {
        reportExportFormat: Constants.Report_Export_Format_CONST.XLS,
        emailRecipientDTOList: []
    },

    emailSenderTempSelected: [],
    schedulerSaved: [],
    isAddScheduler: false,
    isSchedulerNameNotPresent: false,

    userGroupsTempSelected: [],
    userGroupModelIsOpen: false,

    sortedList: [],
    enableMainMeasureSort: false,

    //Section Data
    createdSection: {},
    sectionSelectedMeasures: {},
    sectionSelectedMeasureRules: {},
    sectionSelectedAggregate: {},
    sectionSelectedMeasureData: {},
    sectionDisplayMeasureSort: {},

    //Report Measure Section
    showAddMeasureModal: false,
    selectedMeasures: [],
    selectedMeasuresTmp: [],
    measurePageInfo: {},
    reportMeasures: [],

    selectedDataEntryFormMeasureID: null,
    measureWiseDataEntryForms: {},
    showAddDataEntryFormModal: false,
    selectedDataEntryFormsTmp: [],
    dataFormPageInfo: {},
    dataFormPageData: [],

    measureWiseDataEntryFormWiseMeasures: {},
    showDataEntryFormMeasureSelectBox: false,
    dataEntryFormMeasuresTmp: [],
    dataEntryFormMeasures: [],
    selectedDataEntryFormTemplateID: null,
    parentMeasureID: null,
    measureWiseDataEntryFormWiseReCalculateMeasures: {},
    measureWiseDataEntryFormWiseMeasureAggregateType: {},

    sortModeEnabled: false,
    measureWiseSectionExpanded: {},
};

export default (state = initialState, action) => {
    switch (action.type) {
        case REPORT_GET_USER_SEARCH:
        case REPORT_PROCESS_TEMPLATE_SEARCH:
        case REPORT_LOAD_PROCESS_MEASURES:
        case REPORT_LOAD_SELECTED_REPORT_PROCESS_WISE_MEASURE_DATA:
            return {
                ...state
            };
        case REPORT_GET_REPORT_TEMPLATE_TYPE_SUCCESS:
            return {
                ...state,
                reportTemplateTypes: action.payload
            };

        case REPORT_GET_SUB_BUSINESS_UNIT_SUCCESS:
            return {
                ...state,
                businessLocationWiseSubBusinessUnit: action.payload
            };
        case REPORT_GET_CALCULATION_OPERATORS_SUCCESS:
            return {
                ...state,
                calculationOperators: action.payload
            };
        case REPORT_GET_PAGED_DATE_SUCCESS:
            return {
                ...state,
                pageInfo: getPagedData(action.payload),
                reports: action.payload.pageData
            };
        case REPORT_ADD_NEW_SUPERVISOR:
            return {
                ...state,
                selectedReportTemplate: {}
            };
        case REPORT_CREATE_UPDATE_BASIC_REPORT_TEMPLATE_SUCCESS:
        case REPORT_GET_REPORT_TEMPLATE_BY_ID_SUCCESS:
            return {
                ...state,
                selectedReportTemplate: action.payload
            };
        case REPORT_GET_REPORT_TEMPLATE_BY_ID_SUMMERY_SUCCESS:
            return {
                ...state,
                selectedReportSummeryTemplate: action.payload
            };
        case REPORT_ON_SELECT_COMPANY_ID:
            return {
                ...state,
                selectedCompanyID: action.payload,
                selectedBusinessUnitID: null,
                selectedBusinessUnitLocations: [],
                selectedSubBusinessUnits: [],
                selectedReport: {},
                eligibleUserType: '',
                selectedUserIDs: [],
                selectedProcessIDs: [],
                selectedProcessItems: {},

                selectedFrequencyID: null,

                processWiseMeasureData: {},
                processWiseSelectedMeasureData: {},
                processWiseMeasureWiseSelectedColor: {},
                processWiseMeasureWiseCheckOptions: {},
                processWiseMeasureWiseRules: {},
            };

        case REPORT_ON_SELECT_BUSINESS_UNIT_ID:
            return {
                ...state,
                selectedBusinessUnitID: action.payload,
                selectedReport: {},
                selectedProcessIDs: [],
                selectedProcessItems: {},

                processWiseMeasureData: {},
                processWiseSelectedMeasureData: {},
                processWiseMeasureWiseSelectedColor: {},
                processWiseMeasureWiseCheckOptions: {},
                processWiseMeasureWiseRules: {},
            };

        case REPORT_ON_SELECT_BUSINESS_UNIT_LOCATION_IDS_SUB_BUSINESS_UNIT_IDS:
            return {
                ...state,
                selectedReport: action.payload,
            };

        case REPORT_CLEAR_DATA:
            return {
                ...state,
                reportTemplateTypes: [],
                businessLocationWiseSubBusinessUnit: [],
                calculationOperators: [],
                selectedCompanyID: null,
                selectedBusinessUnitID: null,
                selectedBusinessUnitLocations: [],
                selectedSubBusinessUnits: [],
                selectedReport: {},
                eligibleUserType: '',
                selectedUserIDs: [],

                users: [],
                userPageInfo: {},

                processTemplates: [],
                processTemplatePageInfo: {},
                processTemplatePageFilteredSubBusinessUnits: [],
                selectedProcessIDs: [],
                selectedProcessItems: {},

                selectedFrequencyID: null,
                commonFrequency: '',
                commonFrequencyList: [],
                aggregateType: Constants.REPORT_AGGREGATE_TYPE_ARRAY[0],

                processWiseMeasureData: {},
                processWiseSelectedMeasureData: {},
                processWiseMeasureWiseSelectedColor: {},
                processWiseMeasureWiseCheckOptions: {},
                processWiseMeasureWiseRules: {},
                selectedReportTemplate: {},
                selectedReportSummeryTemplate: {},
                processWiseSelectedMeasureWiseAggregate: {},
                processWiseSelectedMeasureWiseRecalculate: {},
                processWiseSelectedMeasureWiseBestEver: {},
                sortedList: [],
                enableMainMeasureSort: false,
                selectedReportType: '',

                userGroupsTempSelected: [],
                userGroupModelIsOpen: false,

                createdSection: {},
                sectionSelectedMeasures: {},
                sectionSelectedMeasureRules: {},
                sectionSelectedAggregate: {},
                sectionDisplayMeasureSort: {},

                showAddMeasureModal: false,
                selectedMeasures: [],
                selectedMeasuresTmp: [],
                measurePageInfo: {},
                reportMeasures: [],

                selectedDataEntryFormMeasureID: null,
                measureWiseDataEntryForms: {},
                showAddDataEntryFormModal: false,
                selectedDataEntryFormsTmp: [],
                dataFormPageInfo: {},
                dataFormPageData: [],

                measureWiseDataEntryFormWiseMeasures: {},
                showDataEntryFormMeasureSelectBox: false,
                dataEntryFormMeasuresTmp: [],
                dataEntryFormMeasures: [],
                selectedDataEntryFormTemplateID: null,
                parentMeasureID: null,
                measureWiseDataEntryFormWiseReCalculateMeasures: {},
                measureWiseDataEntryFormWiseMeasureAggregateType: {},

                sortModeEnabled: false,
                measureWiseSectionExpanded: {}
            };

        case REPORT_MANIPULATE_SELECTED_WIDGET_STATE :
            return {
                ...state,
                ...action.payload
            };

        case REPORT_LOAD_SELECTED_WIDGET_PROCESS_TEMPLATES_SUCCESS:
            let items = {};
            action.payload.forEach((item) => {
                items[item.processTemplateID] = item
            });
            return {
                ...state,
                selectedProcessItems: items
            };

        case REPORT_LOAD_SELECTED_REPORT_PROCESS_WISE_MEASURE_DATA_SUCCESS:
            return {
                ...state,
                processWiseSelectedMeasureData: action.payload
            };

        case REPORT_SELECT_ELIGIBLE_USER_TYPE:
            return {
                ...state,
                eligibleUserType: action.payload
            };

        case REPORT_SELECT_USERS:
            return {
                ...state,
                selectedUserIDs: action.payload
            };

        case REPORT_GET_USER_SEARCH_SUCCESS:
            return {
                ...state,
                users: action.payload.pageData,
                userPageInfo: getPagedData(action.payload)
            };

        case REPORT_PROCESS_TEMPLATE_SEARCH_SUCCESS:
            return {
                ...state,
                processTemplatePageInfo: getPagedData(action.payload),
                processTemplates: action.payload.pageData,
            };

        case REPORT_SUB_BUSINESS_UNIT_SEARCH:
            return {
                ...state,
                processTemplatePageFilteredSubBusinessUnits: []
            };

        case REPORT_SUB_BUSINESS_UNIT_SEARCH_SUCCESS:
            return {
                ...state,
                processTemplatePageFilteredSubBusinessUnits: action.payload.subBusinessUnitDTOs,
            };

        case REPORT_SELECT_PROCESS_IDS:
            return {
                ...state,
                selectedProcessIDs: action.payload
            };
        case REPORT_SELECT_PROCESS_ITEMS:
            return {
                ...state,
                selectedProcessItems: action.payload
            };

        case REPORT_LOAD_PROCESS_MEASURES_SUCCESS:
            let processWiseMeasureData = Object.assign({}, state.processWiseMeasureData);
            processWiseMeasureData[action.payload.processTemplateID] = action.payload.measures;
            return {
                ...state,
                processWiseMeasureData: processWiseMeasureData
            };

        case REPORT_SELECT_PROCESS_MEASURE:
            let processTemplateID = action.payload.processTemplateID;
            let selectedMeasure = action.payload.measure;

            let processWiseSelectedMeasureData = Object.assign({}, state.processWiseSelectedMeasureData);
            let measureWiseAggregate = Object.assign({}, state.processWiseSelectedMeasureWiseAggregate);
            let measureWiseRecalculate = Object.assign({}, state.processWiseSelectedMeasureWiseRecalculate);
            let measureWiseBestEver = Object.assign({}, state.processWiseSelectedMeasureWiseBestEver);
            let measures = _.cloneDeep(state.processWiseSelectedMeasureData[processTemplateID]);

            if (measures) {
                let measureIDs = measures.map((measure) => measure.measureID);
                let index = _.indexOf(measureIDs, selectedMeasure.measureID);
                if (index != -1) {
                    measures.splice(index, 1);

                    //Aggregate Data
                    measureWiseAggregate[processTemplateID] = _.omit(
                        measureWiseAggregate[processTemplateID], [selectedMeasure.measureID]
                    );

                    if (_.isEmpty(measureWiseAggregate[processTemplateID])) {
                        measureWiseAggregate = _.omit(
                            measureWiseAggregate, [processTemplateID]
                        );
                    }
                    // Aggregate Data Finished

                    //Recalculate Data
                    measureWiseRecalculate[processTemplateID] = _.omit(
                        measureWiseRecalculate[processTemplateID], [selectedMeasure.measureID]
                    );

                    if (_.isEmpty(measureWiseRecalculate[processTemplateID])) {
                        measureWiseRecalculate = _.omit(
                            measureWiseRecalculate, [processTemplateID]
                        );
                    }
                    //Recalculate Data FINISHED

                } else {
                    measures.push(selectedMeasure);

                    //Aggregate Data
                    if (!_.isEmpty(measureWiseAggregate)) {
                        if (!_.isEmpty(measureWiseAggregate[processTemplateID])) {
                            measureWiseAggregate = {
                                ...measureWiseAggregate,
                                [processTemplateID]: {
                                    ...measureWiseAggregate[processTemplateID],
                                    [selectedMeasure.measureID]: Constants.REPORT_AGGREGATE_TYPE_CONST.SUMMATION
                                }
                            }
                        } else {
                            measureWiseAggregate = {
                                ...measureWiseAggregate,
                                [processTemplateID]: {
                                    [selectedMeasure.measureID]: Constants.REPORT_AGGREGATE_TYPE_CONST.SUMMATION
                                }
                            }
                        }
                    } else {
                        measureWiseAggregate = {
                            [processTemplateID]: {
                                [selectedMeasure.measureID]: Constants.REPORT_AGGREGATE_TYPE_CONST.SUMMATION
                            }
                        }
                    }

                    // Aggregate Data Finished

                    //Recalculate Data
                    let recalculatePresent = measureWiseRecalculate
                        && measureWiseRecalculate[processTemplateID]
                        && measureWiseRecalculate[processTemplateID][selectedMeasure.measureID];

                    if (recalculatePresent) {
                        measureWiseRecalculate[processTemplateID][selectedMeasure.measureID] = false;
                    } else {
                        measureWiseRecalculate = _.merge(measureWiseRecalculate, {
                            [processTemplateID]: {
                                [selectedMeasure.measureID]: false
                            }
                        });
                    }
                    //Recalculate Data FINISHED

                }

            } else {
                measures = [selectedMeasure];

                //Aggregate Data
                if (!_.isEmpty(measureWiseAggregate)) {
                    if (!_.isEmpty(measureWiseAggregate[processTemplateID])) {
                        measureWiseAggregate = {
                            ...measureWiseAggregate,
                            [processTemplateID]: {
                                ...measureWiseAggregate[processTemplateID],
                                [selectedMeasure.measureID]: Constants.REPORT_AGGREGATE_TYPE_CONST.SUMMATION
                            }
                        }
                    } else {
                        measureWiseAggregate = {
                            ...measureWiseAggregate,
                            [processTemplateID]: {
                                [selectedMeasure.measureID]: Constants.REPORT_AGGREGATE_TYPE_CONST.SUMMATION
                            }
                        }
                    }
                } else {
                    measureWiseAggregate = {
                        [processTemplateID]: {
                            [selectedMeasure.measureID]: Constants.REPORT_AGGREGATE_TYPE_CONST.SUMMATION
                        }
                    }
                }
                // Aggregate Data Finished

                //Recalculate Data
                let recalculatePresent = measureWiseRecalculate
                    && measureWiseRecalculate[processTemplateID]
                    && measureWiseRecalculate[processTemplateID][selectedMeasure.measureID]

                if (recalculatePresent) {
                    measureWiseRecalculate[processTemplateID][selectedMeasure.measureID] = false;
                } else {
                    measureWiseRecalculate = _.merge(measureWiseRecalculate, {
                        [processTemplateID]: {
                            [selectedMeasure.measureID]: false
                        }
                    });
                }
                //Recalculate Data FINISHED

            }

            processWiseSelectedMeasureData[processTemplateID] = measures;
            if (!measureWiseBestEver[processTemplateID]) {
                measureWiseBestEver[processTemplateID] = {};
            }
            measureWiseBestEver[processTemplateID][selectedMeasure.measureID] = Constants.KPI_REPORT_BEST_EVER_TYPE.MAXIMUM;

            return {
                ...state,
                processWiseSelectedMeasureData: processWiseSelectedMeasureData,
                processWiseSelectedMeasureWiseAggregate: measureWiseAggregate,
                processWiseSelectedMeasureWiseRecalculate: measureWiseRecalculate,
                processWiseSelectedMeasureWiseBestEver: measureWiseBestEver,
            };

        case REPORT_SELECT_PROCESS_MEASURE_COLOR_CHANGE:
            let pTemplateID = action.payload.processTemplateID;
            let measureID = action.payload.measureID;
            let colorCode = action.payload.colorCode;

            let processWiseMeasureWiseSelectedColor = Object.assign({}, state.processWiseMeasureWiseSelectedColor);
            if (!processWiseMeasureWiseSelectedColor[pTemplateID]) {
                processWiseMeasureWiseSelectedColor[pTemplateID] = {};
            }
            if (!processWiseMeasureWiseSelectedColor[pTemplateID][measureID]) {
                processWiseMeasureWiseSelectedColor[pTemplateID][measureID] = {};
            }

            processWiseMeasureWiseSelectedColor[pTemplateID][measureID]['colorCode'] = colorCode;

            return {
                ...state,
                processWiseMeasureWiseSelectedColor: processWiseMeasureWiseSelectedColor
            };

        case REPORT_SELECT_PROCESS_MEASURE_BUDGET_COLOR_CHANGE:
            let pTemplateIDForBudget = action.payload.processTemplateID;
            let measureIDForBudget = action.payload.measureID;
            let colorCodeForBudget = action.payload.colorCode;

            let processWiseMeasureWiseSelectedColorForBudget = Object.assign({}, state.processWiseMeasureWiseSelectedColor);
            if (!processWiseMeasureWiseSelectedColorForBudget[pTemplateIDForBudget]) {
                processWiseMeasureWiseSelectedColorForBudget[pTemplateIDForBudget] = {};
            }
            if (!processWiseMeasureWiseSelectedColorForBudget[pTemplateIDForBudget][measureIDForBudget]) {
                processWiseMeasureWiseSelectedColorForBudget[pTemplateIDForBudget][measureIDForBudget] = {};
            }

            processWiseMeasureWiseSelectedColorForBudget[pTemplateIDForBudget][measureIDForBudget]['budgetColorCode'] = colorCodeForBudget;

            return {
                ...state,
                processWiseMeasureWiseSelectedColor: processWiseMeasureWiseSelectedColorForBudget
            };

        case REPORT_SELECT_PROCESS_MEASURE_TARGET_COLOR_CHANGE:
            let pTemplateIDForTarget = action.payload.processTemplateID;
            let measureIDForTarget = action.payload.measureID;
            let colorCodeForTarget = action.payload.colorCode;

            let processWiseMeasureWiseSelectedColorForTarget = Object.assign({}, state.processWiseMeasureWiseSelectedColor);
            if (!processWiseMeasureWiseSelectedColorForTarget[pTemplateIDForTarget]) {
                processWiseMeasureWiseSelectedColorForTarget[pTemplateIDForTarget] = {};
            }
            if (!processWiseMeasureWiseSelectedColorForTarget[pTemplateIDForTarget][measureIDForTarget]) {
                processWiseMeasureWiseSelectedColorForTarget[pTemplateIDForTarget][measureIDForTarget] = {};
            }

            processWiseMeasureWiseSelectedColorForTarget[pTemplateIDForTarget][measureIDForTarget]['targetColorCode'] = colorCodeForTarget;

            return {
                ...state,
                processWiseMeasureWiseSelectedColor: processWiseMeasureWiseSelectedColorForTarget
            };

        case REPORT_REMOVE_SELECTED_MEASURE:
            let selectedData = Object.assign({}, state.processWiseSelectedMeasureData);
            let measureWiseAggregateRemove = Object.assign({}, state.processWiseSelectedMeasureWiseAggregate);
            let processWiseSelectedMeasureWiseRecalculateRemove = Object.assign({}, state.processWiseSelectedMeasureWiseRecalculate);

            let index = _.findIndex(selectedData[action.payload.processTemplateID], (item) => {
                return item.measureID === action.payload.measureID
            });
            selectedData[action.payload.processTemplateID].splice(index, 1);

            //Measure Aggregate Data

            measureWiseAggregateRemove[action.payload.processTemplateID] = _.omit(
                measureWiseAggregateRemove[action.payload.processTemplateID], [action.payload.measureID]
            );

            if (_.isEmpty(measureWiseAggregateRemove[action.payload.processTemplateID])) {
                measureWiseAggregateRemove = _.omit(
                    measureWiseAggregateRemove, [action.payload.processTemplateID]
                );
            }

            //Measure Aggregate Data finished

            //Recalculate Data
            processWiseSelectedMeasureWiseRecalculateRemove[action.payload.processTemplateID] = _.omit(
                processWiseSelectedMeasureWiseRecalculateRemove[action.payload.processTemplateID], [action.payload.measureID]
            );

            if (_.isEmpty(processWiseSelectedMeasureWiseRecalculateRemove[action.payload.processTemplateID])) {
                processWiseSelectedMeasureWiseRecalculateRemove = _.omit(
                    processWiseSelectedMeasureWiseRecalculateRemove, [action.payload.processTemplateID]
                );
            }
            //Recalculate Data FINISHED

            return {
                ...state,
                processWiseSelectedMeasureData: selectedData,
                processWiseSelectedMeasureWiseAggregate: measureWiseAggregateRemove,
                processWiseSelectedMeasureWiseRecalculate: processWiseSelectedMeasureWiseRecalculateRemove
            };

        case REPORT_SELECT_PROCESS_MEASURE_CHECK_OPTIONS_CHANGE:
            let assign = Object.assign({}, state.processWiseMeasureWiseCheckOptions);
        {
            let pTemplateID = action.payload.processTemplateID;
            let measureID = action.payload.measureID;

            if (!assign[pTemplateID]) {
                assign[pTemplateID] = {};
            }

            assign[pTemplateID][measureID] = action.payload.data;

        }
            return {
                ...state,
                processWiseMeasureWiseCheckOptions: assign
            };

        case REPORT_ADD_PROCESS_MEASURE_RULE:
            let processWiseMeasureWiseRules = Object.assign({}, state.processWiseMeasureWiseRules);
        {
            let pTemplateID = action.payload.processTemplateID;
            let measureID = action.payload.measureID;

            if (!processWiseMeasureWiseRules[pTemplateID]) {
                processWiseMeasureWiseRules[pTemplateID] = {};
            }

            if (!processWiseMeasureWiseRules[pTemplateID][measureID]) {
                processWiseMeasureWiseRules[pTemplateID][measureID] = [];
            }

            processWiseMeasureWiseRules[pTemplateID][measureID].push(action.payload.rule);
        }
            return {
                ...state,
                processWiseMeasureWiseRules: processWiseMeasureWiseRules
            };

        case REPORT_EDIT_PROCESS_MEASURE_RULE:
            let processWiseMeasureWiseRulesEdit = Object.assign({}, state.processWiseMeasureWiseRules);
        {
            let pTemplateID = action.payload.processTemplateID;
            let measureID = action.payload.measureID;
            let rule = action.payload.rule;
            let addedRules = processWiseMeasureWiseRulesEdit[pTemplateID][measureID];

            if (rule.uuid) {
                let index = _.findIndex(addedRules, (r) => {
                    return r.uuid == rule.uuid;
                });
                if (index != -1) {
                    processWiseMeasureWiseRulesEdit[pTemplateID][measureID][index] = rule;
                }
            } else {
                let index = _.findIndex(addedRules, (r) => {
                    return r.widgetProcessMeasureRuleID == rule.widgetProcessMeasureRuleID;
                });
                if (index != -1) {
                    processWiseMeasureWiseRulesEdit[pTemplateID][measureID][index] = rule;
                }
            }

            return {
                ...state,
                processWiseMeasureWiseRules: processWiseMeasureWiseRulesEdit
            };
        }

        case REPORT_REMOVE_PROCESS_MEASURE_RULE:
            let processWiseMeasureWiseRulesRemove = Object.assign({}, state.processWiseMeasureWiseRules);
        {
            let pTemplateID = action.payload.processTemplateID;
            let measureID = action.payload.measureID;
            let rule = action.payload.rule;

            let index;

            if (rule.uuid) {
                index = _.findIndex(processWiseMeasureWiseRulesRemove[pTemplateID][measureID], (r) => {
                    return r.uuid == rule.uuid;
                });
            } else {
                index = _.findIndex(processWiseMeasureWiseRulesRemove[pTemplateID][measureID], (r) => {
                    return r.reportTemplateProcessMeasureRuleID == rule.reportTemplateProcessMeasureRuleID;
                });
            }

            if (index != -1) {
                processWiseMeasureWiseRulesRemove[pTemplateID][measureID].splice(index, 1);
            }
        }
            return {
                ...state,
                processWiseMeasureWiseRules: processWiseMeasureWiseRulesRemove
            };

        case REPORT_ON_SELECT_FREQUENCY_ID:
            return {
                ...state,
                selectedFrequencyID: action.payload
            };

        case REPORT_LOAD_COMMON_FREQUENCY_FOR_PROCESS_SUCCESS:
            return {
                ...state,
                commonFrequency: action.payload
            };

        case REPORT_ON_CHANGE_COMMON_FREQUENCY_LIST:
            return {
                ...state,
                commonFrequencyList: action.payload
            };
        case REPORT_ON_CHANGE_AGGREGATE_TYPE:
            return {
                ...state,
                aggregateType: action.payload
            };
        case REPORT_RESET_REPORT_SCHEDULE_PARAMS:
            return {
                ...state,
                reportSchedulerParams: action.payload,
                userGroupsTempSelected: []
            };
        case REPORT_RESET_REPORT_CONTEXT:
            return {
                ...state,
                reportContext: action.payload
            };
        case REPORT_RESET_EMAIL_SENDER_TEMP_SELECTED:
            return {
                ...state,
                emailSenderTempSelected: action.payload
            };
        case REPORT_RESET_SCHEDULER_SAVED:
            return {
                ...state,
                schedulerSaved: action.payload
            };
        case REPORT_SET_REPORT_TEMPLATE_SCHEDULER_ID:
            return {
                ...state,
                reportSchedulerParams: {
                    ...state.reportSchedulerParams,
                    reportTemplateSchedulerID: action.payload
                }
            };
        case REPORT_SET_REPORT_TEMPLATE_ID:
            return {
                ...state,
                reportSchedulerParams: {
                    ...state.reportSchedulerParams,
                    reportTemplateID: action.payload
                }
            };
        case REPORT_SET_SCHEDULER_NAME:
            return {
                ...state,
                reportSchedulerParams: {
                    ...state.reportSchedulerParams,
                    schedulerName: action.payload
                }
            };
        case REPORT_SET_SCHEDULER_FROM_DATE:
            return {
                ...state,
                reportSchedulerParams: {
                    ...state.reportSchedulerParams,
                    scheduleFrom: action.payload
                }
            };
        case REPORT_SET_SCHEDULER_TO_DATE:
            return {
                ...state,
                reportSchedulerParams: {
                    ...state.reportSchedulerParams,
                    scheduleTo: action.payload
                }
            };
        case REPORT_SET_SCHEDULER_FREQUENCY:
            return {
                ...state,
                reportSchedulerParams: {
                    ...state.reportSchedulerParams,
                    frequency: action.payload
                }
            };
        case REPORT_SET_SCHEDULER_TIME:
            return {
                ...state,
                reportSchedulerParams: {
                    ...state.reportSchedulerParams,
                    time: action.payload
                }
            };
        case REPORT_SET_SCHEDULER_DATE_OF_WEEK:
            return {
                ...state,
                reportSchedulerParams: {
                    ...state.reportSchedulerParams,
                    dateOfWeek: action.payload
                }
            };
        case REPORT_SET_SCHEDULER_DATE:
            return {
                ...state,
                reportSchedulerParams: {
                    ...state.reportSchedulerParams,
                    date: action.payload
                }
            };
        case REPORT_SET_SCHEDULER_MONTH:
            return {
                ...state,
                reportSchedulerParams: {
                    ...state.reportSchedulerParams,
                    month: action.payload
                }
            };
        case REPORT_SET_SCHEDULER_STATUS:
            return {
                ...state,
                reportSchedulerParams: {
                    ...state.reportSchedulerParams,
                    status: action.payload
                }
            };
        case REPORT_SET_SCHEDULER_REPORT_EXPORT_FORMAT:
            return {
                ...state,
                reportContext: {
                    ...state.reportContext,
                    reportExportFormat: action.payload
                }
            };
        case REPORT_SET_SCHEDULER_EMAIL_DTO_LIST:
            return {
                ...state,
                reportContext: {
                    ...state.reportContext,
                    emailRecipientDTOList: action.payload
                }
            };

        case REPORT_SET_SCHEDULER_USER_GROUP_DTO_LIST:
            return {
                ...state,
                reportSchedulerParams: {
                    ...state.reportSchedulerParams,
                    userGroupIDs: action.payload.userGroupIDs,
                    userGroupDTOS: action.payload.userGroupDTOS
                },
                userGroupsTempSelected: action.payload.userGroupDTOS
            };
        case REPORT_SET_SCHEDULER_EMAIL_SENDER_TEMP_SELECTED:
            return {
                ...state,
                emailSenderTempSelected: action.payload
            };
        case REPORT_SET_SCHEDULER_SAVED_SCHEDULER:
            return {
                ...state,
                schedulerSaved: action.payload
            };
        case REPORT_SET_SCHEDULER_TOGGLE_ADD_SCHEDULER:
            return {
                ...state,
                isAddScheduler: action.payload
            };
        case REPORT_SCHEDULER_UPLOAD_SUCCESS:
            let updatedReportTemplateSchedulerID = action.payload.reportTemplateSchedulerID;
            let updateArr = _.filter(state.schedulerSaved, (schedule) => {
                if (schedule.reportTemplateSchedulerID != updatedReportTemplateSchedulerID) {
                    return schedule;
                }
            });
            return {
                ...state,
                schedulerSaved: [...updateArr, action.payload]
            };
        case REPORT_SCHEDULER_DELETE_SUCCESS:
            let reportTemplateSchedulerID = action.payload;
            let arr = _.filter(state.schedulerSaved, (schedule) => {
                if (schedule.reportTemplateSchedulerID !== reportTemplateSchedulerID) {
                    return schedule;
                }
            });

            return {
                ...state,
                selectedReportTemplate: {
                    ...state.selectedReportTemplate,
                    reportSchedulingParams: arr,
                },
                schedulerSaved: arr
            };
        case REPORT_SET_SCHEDULER_TOGGLE_SCHEDULER_NAME_PRESENT:
            return {
                ...state,
                isSchedulerNameNotPresent: action.payload
            };

        case REPORT_SET_TEMP_SELECTED_USER_GROUPS:
            return {
                ...state,
                userGroupsTempSelected: action.payload
            };

        case REPORT_USER_GROUP_MODEL_IS_OPEN:
            return {
                ...state,
                userGroupModelIsOpen: action.payload
            };

        case ADD_EDIT_WIDGET_SELECT_PROCESS_MEASURE_SET_SORTED_POSITION:
            return {
                ...state,
                sortedList: action.payload
            };

        case ADD_EDIT_WIDGET_SELECT_PROCESS_MEASURE_ENABLE_MAIN_MEASURE_SORT:
            return {
                ...state,
                enableMainMeasureSort: action.payload
            };

        case REPORT_LOAD_PROCESS_MEASURES_LIST_SUCCESS:
            let processWiseMeasureDataTmp = Object.assign({}, state.processWiseMeasureData);
            _.map(action.payload, (measures, processID) => {
                processWiseMeasureDataTmp[processID] = measures
            });
            return {
                ...state,
                processWiseMeasureData: processWiseMeasureDataTmp
            };

        case REPORT_SET_CREATE_SECTION:
            return {
                ...state,
                createdSection: action.payload
            };

        case REPORT_SET_SECTION_SELECTED_MEASURE:
            return {
                ...state,
                sectionSelectedMeasures: action.payload
            };

        case REPORT_SET_SECTION_SELECTED_MEASURE_RULE:
            return {
                ...state,
                sectionSelectedMeasureRules: action.payload
            };

        case  REPORT_SET_SECTION_SELECTED_DISPLAY_MEASURE_SORT : {

            let sectionDisplayMeasureSort = _.cloneDeep(state.sectionDisplayMeasureSort);
            let reportTemplateSectionID = action.payload.reportTemplateSectionID;

            sectionDisplayMeasureSort[reportTemplateSectionID] = action.payload.data;

            return {
                ...state,
                sectionDisplayMeasureSort: sectionDisplayMeasureSort
            };
        }

        case REPORT_SET_SECTION_SELECTED_DISPLAY_MEASURES_INIT : {
            return {
                ...state,
                sectionDisplayMeasureSort: action.payload
            };
        }

        case REPORT_SET_SECTION_SELECTED_AGGREGATE:
            return {
                ...state,
                sectionSelectedAggregate: action.payload
            };

        case REPORT_SET_SECTION_SELECTED_MEASURE_DATA:
            return {
                ...state,
                sectionSelectedMeasureData: action.payload
            };

        case REPORT_SET_SELECTED_MEASURE_AGGREGATE:

            let aggregateList = action.payload;
            let selectedMeasureWiseAggregate = Object.assign({}, state.processWiseSelectedMeasureWiseAggregate);

            _.map(aggregateList, (aggregate) => {

                const {
                    processTemplateID,
                    measureID,
                    value
                } = aggregate;

                let newValue = _.isEmpty(value) ? Constants.REPORT_AGGREGATE_TYPE_CONST.SUMMATION : value;

                if (!_.isEmpty(selectedMeasureWiseAggregate)) {
                    if (!_.isEmpty(selectedMeasureWiseAggregate[processTemplateID])) {
                        selectedMeasureWiseAggregate = {
                            ...selectedMeasureWiseAggregate,
                            [processTemplateID]: {
                                ...selectedMeasureWiseAggregate[processTemplateID],
                                [measureID]: newValue
                            }
                        }
                    } else {
                        selectedMeasureWiseAggregate = {
                            ...selectedMeasureWiseAggregate,
                            [processTemplateID]: {
                                [measureID]: newValue
                            }
                        }
                    }
                } else {
                    selectedMeasureWiseAggregate = {
                        [processTemplateID]: {
                            [measureID]: newValue
                        }
                    }
                }
            });

            return {
                ...state,
                processWiseSelectedMeasureWiseAggregate: selectedMeasureWiseAggregate
            };

        case REPORT_SET_SELECTED_MEASURE_RECALCULATE:

            let recalculateList = action.payload;
            let processWiseSelectedMeasureWiseRecalculate = Object.assign({}, state.processWiseSelectedMeasureWiseRecalculate);

            _.map(recalculateList, (recalculate) => {

                const {
                    processTemplateID,
                    measureID,
                    value
                } = recalculate;

                let recalculatePresent = processWiseSelectedMeasureWiseRecalculate
                    && processWiseSelectedMeasureWiseRecalculate[processTemplateID]
                    && processWiseSelectedMeasureWiseRecalculate[processTemplateID][measureID];

                if (recalculatePresent) {
                    processWiseSelectedMeasureWiseRecalculate[processTemplateID][measureID] = value;
                } else {
                    processWiseSelectedMeasureWiseRecalculate = _.merge(processWiseSelectedMeasureWiseRecalculate, {
                        [processTemplateID]: {
                            [measureID]: value
                        }
                    });
                }
            });

            return {
                ...state,
                processWiseSelectedMeasureWiseRecalculate: processWiseSelectedMeasureWiseRecalculate
            };

        case REPORT_SET_SELECTED_MEASURE_BEST_EVER_TYPE : {
            let processTemplateID = action.payload.processTemplateID;
            let measureID = action.payload.measureID;
            let bestEverType = action.payload.bestEverType;
            let processWiseSelectedMeasureWiseBestEver = _.cloneDeep(state.processWiseSelectedMeasureWiseBestEver);
            processWiseSelectedMeasureWiseBestEver[processTemplateID][measureID] = bestEverType;
            return {
                ...state,
                processWiseSelectedMeasureWiseBestEver
            };
        }

        case REPORT_SET_SELECTED_MEASURE_BEST_EVER_TYPE_ALL : {
            return {
                ...state,
                processWiseSelectedMeasureWiseBestEver: action.payload
            };
        }

        case REPORT_SET_SELECTED_REPORT_TYPE : {
            return {
                ...state,
                selectedReportType: action.payload.selectedReportType
            };
        }

        case REPORT_MEASURE_SELECT_MEASURES_MODAL_OPEN :
            return {
                ...state,
                showAddMeasureModal: true
            };

        case REPORT_MEASURE_SELECT_MEASURES_MODAL_CLOSE :
            return {
                ...state,
                showAddMeasureModal: false
            };

        case REPORT_MEASURE_SELECT_MEASURES :
            return {
                ...state,
                selectedMeasures: action.payload,
                sortModeEnabled: false
            };

        case REPORT_MEASURE_UPDATE_TMP_SELECTED_MEASURES :
            return {
                ...state,
                selectedMeasuresTmp: action.payload
            };

        case REPORT_MEASURE_SEARCH_SUCCESS :
            return {
                ...state,
                reportMeasures: action.payload.pageData,
                measurePageInfo: getPagedData(action.payload)
            };

        case REPORT_MEASURE_SELECT_DATA_ENTRY_MODAL_OPEN :
            return {
                ...state,
                showAddDataEntryFormModal: true,
                selectedDataEntryFormMeasureID: action.payload
            };

        case REPORT_MEASURE_SELECT_DATA_ENTRY_MODAL_CLOSE :
            return {
                ...state,
                showAddDataEntryFormModal: false,
                selectedDataEntryFormMeasureID: null
            };

        case REPORT_MEASURE_SELECT_DATA_ENTRY_FORMS : {
            let measureWiseDataEntryForms = _.cloneDeep(state.measureWiseDataEntryForms);
            let measureID = action.payload.measureID;

            let updated = Object.assign({}, measureWiseDataEntryForms, {
                [measureID]: action.payload.dataEntryForms
            });

            return {
                ...state,
                measureWiseDataEntryForms: updated
            };
        }


        case REPORT_MEASURE_UPDATE_TMP_DATA_ENTRY_FORMS :
            return {
                ...state,
                selectedDataEntryFormsTmp: action.payload
            };

        case REPORT_MEASURE_DATA_FORM_SEARCH_SUCCESS:
            return {
                ...state,
                dataFormPageInfo: getPagedData(action.payload),
                dataFormPageData: action.payload.pageData,
            };

        case REPORT_MEASURE_DATA_ENTRY_FORM_MEASURE_MODAL_OPEN :
            return {
                ...state,
                showDataEntryFormMeasureSelectBox: true,
                selectedDataEntryFormTemplateID: action.payload.processTemplateID,
                parentMeasureID: action.payload.measureID,
            };

        case REPORT_MEASURE_DATA_ENTRY_FORM_MEASURE_MODAL_CLOSE :
            return {
                ...state,
                showDataEntryFormMeasureSelectBox: false,
                selectedDataEntryFormTemplateID: null,
                parentMeasureID: null,
                dataEntryFormMeasures: [],
                dataEntryFormMeasuresTmp: []
            };

        case REPORT_MEASURE_DATA_ENTRY_FORM__MEASURES_LIST_SUCCESS : {
            return {
                ...state,
                dataEntryFormMeasures: action.payload.measures
            };
        }

        case REPORT_MEASURE_UPDATE_TMP_DATA_ENTRY_FORM_MEASURES : {
            return {
                ...state,
                dataEntryFormMeasuresTmp: action.payload
            };
        }

        case REPORT_MEASURE_UPDATE_DATA_ENTRY_FORM_MEASURES : {
            let measureWiseDataEntryFormWiseMeasures = _.cloneDeep(state.measureWiseDataEntryFormWiseMeasures);
            let processTemplateID = action.payload.processTemplateID;
            let parentMeasureID = action.payload.parentMeasureID;

            if (!measureWiseDataEntryFormWiseMeasures[parentMeasureID]) {
                measureWiseDataEntryFormWiseMeasures[parentMeasureID] = {};
            }

            measureWiseDataEntryFormWiseMeasures[parentMeasureID][processTemplateID] = action.payload.dataEntryFormMeasures;

            return {
                ...state,
                measureWiseDataEntryFormWiseMeasures: measureWiseDataEntryFormWiseMeasures
            };
        }

        case REPORT_MEASURE_UPDATE_DATA_ENTRY_FORM_MEASURE_RE_CALCULATION : {
            let data = _.cloneDeep(state.measureWiseDataEntryFormWiseReCalculateMeasures);
            let processTemplateID = action.payload.processTemplateID;
            let parentMeasureID = action.payload.parentMeasureID;
            let relevant = action.payload.relevant;

            if (!data[parentMeasureID]) {
                data[parentMeasureID] = {};
            }

            data[parentMeasureID][processTemplateID] = relevant;

            return {
                ...state,
                measureWiseDataEntryFormWiseReCalculateMeasures: data
            };
        }

        case REPORT_MEASURE_UPDATE_DATA_ENTRY_FORM_MEASURE_AGGREGATE_TYPE : {
            let data = _.cloneDeep(state.measureWiseDataEntryFormWiseMeasureAggregateType);
            let processTemplateID = action.payload.processTemplateID;
            let parentMeasureID = action.payload.parentMeasureID;
            let measureID = action.payload.measureID;
            let value = action.payload.value;

            if (!data[parentMeasureID]) {
                data[parentMeasureID] = {};
            }

            if (!data[parentMeasureID][processTemplateID]) {
                data[parentMeasureID][processTemplateID] = {};
            }

            data[parentMeasureID][processTemplateID][measureID] = value;

            return {
                ...state,
                measureWiseDataEntryFormWiseMeasureAggregateType: data
            };
        }

        case REPORT_MEASURE_UPDATE_REPORT_MEASURE_DATA_STRUCTURE : {
            const {
                selectedMeasures,
                measureWiseDataEntryForms,
                measureWiseDataEntryFormWiseMeasures,
                measureWiseDataEntryFormWiseReCalculateMeasures,
                measureWiseDataEntryFormWiseMeasureAggregateType,
            } = action.payload;
            return {
                ...state,
                selectedMeasures,
                measureWiseDataEntryForms,
                measureWiseDataEntryFormWiseMeasures,
                measureWiseDataEntryFormWiseReCalculateMeasures,
                measureWiseDataEntryFormWiseMeasureAggregateType,
                sortModeEnabled: false
            };
        }

        case REPORT_MEASURE_LOAD_MEASURES_BY_MEASURE_ID_SUCCESS : {
            let measureWiseDataEntryFormWiseMeasures = _.cloneDeep(state.measureWiseDataEntryFormWiseMeasures);
            let allMeasures = action.payload;

            _.each(_.keys(measureWiseDataEntryFormWiseMeasures), (mID) => {
                mID = parseInt(mID);
                _.each(_.keys(measureWiseDataEntryFormWiseMeasures[mID]), (dataEntryFormID) => {
                    dataEntryFormID = parseInt(dataEntryFormID);
                    let measures = measureWiseDataEntryFormWiseMeasures[mID][dataEntryFormID];
                    _.each(measures, (measure) => {
                        let measureID = measure.measureID;
                        let find = _.find(allMeasures, (i) => i.measureID === measureID);
                        if (find) {
                            measure.measureCode = find.measureCode;
                            measure.measureName = find.measureName;
                            measure.measureType = find.measureType;
                        }
                    });
                    measures = _.sortBy(measures, (x) => x.measureName);
                    measureWiseDataEntryFormWiseMeasures[mID][dataEntryFormID] = measures;
                });
            });

            return {
                ...state,
                measureWiseDataEntryFormWiseMeasures: measureWiseDataEntryFormWiseMeasures,
            };
        }

        case REPORT_MEASURE_LOAD_PROCESS_TEMPLATES_BY_ID_SUCCESS : {
            let allForms = action.payload;
            let measureWiseDataEntryForms = _.cloneDeep(state.measureWiseDataEntryForms);

            _.each(_.keys(measureWiseDataEntryForms), (mID) => {
                mID = parseInt(mID);
                _.each(measureWiseDataEntryForms[mID], (deForm) => {
                    let processTemplateID = deForm.processTemplateID;
                    let find = _.find(allForms, (i) => i.processTemplateID === processTemplateID);
                    if (find) {
                        deForm.frequencyName = find.frequency;
                        deForm.processTemplateName = find.processTemplateName;
                        deForm.processTemplateCode = find.processTemplateCode;
                    }
                });
            });

            return {
                ...state,
                measureWiseDataEntryForms: measureWiseDataEntryForms,
            };
        }

        case REPORT_MEASURE_REMOVE_DATA_ENTRY_FORM_MEASURE : {
            let {
                parentMeasureID,
                processTemplateID,
                measureID
            } = action.payload;
            let data = _.cloneDeep(state.measureWiseDataEntryFormWiseMeasures);

            console.log(data);

            let measures = data[parentMeasureID][processTemplateID];

            console.log(measures);

            let index = _.findIndex(measures, (m) => {
                return m.measureID === measureID;
            });


            measures.splice(index, 1);

            data[parentMeasureID][processTemplateID] = measures;
            return {
                ...state,
                measureWiseDataEntryFormWiseMeasures: data,
            };
        }

        case REPORT_MEASURE_REMOVE_DATA_ENTRY_FORM : {
            let {
                processTemplateID,
                measureID
            } = action.payload;
            let data = _.cloneDeep(state.measureWiseDataEntryForms);
            let dataForms = data[measureID];
            let index = _.findIndex(dataForms, (i) => i.processTemplateID === processTemplateID);
            dataForms.splice(index, 1);
            data[measureID] = dataForms;

            return {
                ...state,
                measureWiseDataEntryForms: data,
            };
        }

        case REPORT_MEASURE_REMOVE_MEASURE : {
            let {
                measureID
            } = action.payload;
            let data = _.cloneDeep(state.selectedMeasures);
            let index = _.findIndex(data, (i) => i.measureID === measureID);
            data.splice(index, 1);

            return {
                ...state,
                selectedMeasures: data,
                sortModeEnabled: false
            };
        }

        case REPORT_MEASURE_SORT : {
            return {
                ...state,
                selectedMeasures: action.payload
            }
        }

        case REPORT_MEASURE_TOGGLE_SORT : {
            return {
                ...state,
                sortModeEnabled: !state.sortModeEnabled
            }
        }

        case REPORT_MEASURE_UPDATE_MEASURE_WISE_EXPANDED : {

            let measureID = action.payload.measureID;
            let expanded = action.payload.expanded;
            let measureWiseSectionExpanded = _.cloneDeep(state.measureWiseSectionExpanded);
            measureWiseSectionExpanded[measureID] = expanded;

            return {
                ...state,
                measureWiseSectionExpanded
            }
        }

        default:
            return {
                ...state
            }
    }
}
