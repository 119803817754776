import {
    PRODUCT_ADD_CATEGORY_MODAL_CLOSE,
    PRODUCT_ADD_CATEGORY_MODAL_OPEN,
    PRODUCT_ADD_NEW_PRODUCT,
    PRODUCT_CLEAR_GET_SELECTED_CATEGORY_WISE_VARIANT_DATA,
    PRODUCT_GET_SELECTED_CATEGORY_WISE_VARIANT_DATA,
    PRODUCT_GET_SELECTED_PRODUCT,
    PRODUCT_GET_SELECTED_PRODUCT_SUCCESS,
    PRODUCT_MANIPULATE_PRODUCT_CATEGORY_DATA,
    PRODUCT_REMOVE_PRE_SELECTED_CATEGORY,
    PRODUCT_REMOVE_PRE_SELECTED_VARIANT,
    PRODUCT_REMOVE_UPDATE_CATEGORY_WISE_VARIANT,
    PRODUCT_RESET_SELECTED_PRODUCT,
    PRODUCT_SEARCH,
    PRODUCT_SEARCH_SUCCESS,
    PRODUCT_SELECT_CATEGORY_WISE_VARIANT,
    PRODUCT_UPDATE_CATEGORY_WISE_VARIANT,
    SAVE_UPDATE_PRODUCT_SUCCESS, UPLOAD_BULK_PRODUCT_SUCCESS,
} from "../actions/types";
import {getPagedData} from "../../utils/DataExtractHelper";
import * as _ from 'lodash';

const initialState = {
    pageInfo: {},
    products: [],
    selectedProduct: {},

    showAddCategoryModalState: false,
    categoryWiseVariantData: [],
    selectedCategoryWiseVariantData: {},
    selectedCategoryWiseVariantID: null,
    removedPreSelectedVariants: {},
    removedPreSelectedVariantsTemp: {},
    removedPreSelectedCategories: [],

    uploadResult : {}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case PRODUCT_SEARCH:
            return {
                ...state
            };

        case PRODUCT_SEARCH_SUCCESS:
            return {
                ...state,
                products: action.payload.pageData,
                pageInfo: getPagedData(action.payload)
            };

        case PRODUCT_GET_SELECTED_PRODUCT :
            return {
                ...state
            };

        case PRODUCT_GET_SELECTED_PRODUCT_SUCCESS:
        case SAVE_UPDATE_PRODUCT_SUCCESS:
            return {
                ...state,
                selectedProduct: action.payload,
                categoryWiseVariantData: [],
                selectedCategoryWiseVariantData: {},
                selectedCategoryWiseVariantID: null,
                removedPreSelectedVariantsTemp: {},
                removedPreSelectedVariants: {},
                removedPreSelectedCategories: []
            };

        case PRODUCT_ADD_NEW_PRODUCT :
            return {
                ...state,
                selectedProduct: action.payload,
                categoryWiseVariantData: [],
                selectedCategoryWiseVariantData: {},
                removedPreSelectedVariantsTemp: {},
                removedPreSelectedVariants: {},
                removedPreSelectedCategories: [],
            };
        case PRODUCT_RESET_SELECTED_PRODUCT:
            return {
                ...state,
                selectedProduct: action.payload
            };

        case PRODUCT_ADD_CATEGORY_MODAL_OPEN:
            return {
                ...state,
                showAddCategoryModalState: true
            };

        case PRODUCT_ADD_CATEGORY_MODAL_CLOSE:
            return {
                ...state,
                showAddCategoryModalState: false
            };

        case PRODUCT_SELECT_CATEGORY_WISE_VARIANT:
            return {
                ...state,
                categoryWiseVariantData: [...state.categoryWiseVariantData, action.payload]
            };

        case PRODUCT_UPDATE_CATEGORY_WISE_VARIANT:
            let updatable = Object.assign([], state.categoryWiseVariantData);
            updatable[state.selectedCategoryWiseVariantID] = action.payload;

            let updatedRemovePreSelected = _.cloneDeep(state.removedPreSelectedVariants);

            _.map(state.removedPreSelectedVariantsTemp, (value, key) => {
                if (updatedRemovePreSelected[key] != undefined) {
                    updatedRemovePreSelected[key] = [...updatedRemovePreSelected[key], ...value]
                } else {
                    updatedRemovePreSelected[key] = [...value]
                }
            });

            console.log(updatedRemovePreSelected);

            return {
                ...state,
                categoryWiseVariantData: updatable,
                removedPreSelectedVariants: updatedRemovePreSelected
            };

        case PRODUCT_GET_SELECTED_CATEGORY_WISE_VARIANT_DATA:
            return {
                ...state,
                selectedCategoryWiseVariantData: state.categoryWiseVariantData[action.payload],
                selectedCategoryWiseVariantID: action.payload
            };

        case PRODUCT_CLEAR_GET_SELECTED_CATEGORY_WISE_VARIANT_DATA:
            return {
                ...state,
                selectedCategoryWiseVariantData: {},
                selectedCategoryWiseVariantID: null,
                removedPreSelectedVariantsTemp: {}
            };

        case PRODUCT_MANIPULATE_PRODUCT_CATEGORY_DATA:
            return {
                ...state,
                categoryWiseVariantData: action.payload,
            };

        case PRODUCT_REMOVE_PRE_SELECTED_VARIANT:
            let removedPreSelectedVariantsTemp = Object.assign({}, state.removedPreSelectedVariantsTemp);
            const productCategoryID = action.payload.productCategoryID;
            const variant = action.payload.variant;
            if (state.removedPreSelectedVariantsTemp[productCategoryID] != undefined) {
                removedPreSelectedVariantsTemp[productCategoryID] = [...state.removedPreSelectedVariantsTemp[productCategoryID], variant];
            } else {
                removedPreSelectedVariantsTemp[productCategoryID] = [variant];
            }

            return {
                ...state,
                removedPreSelectedVariantsTemp: removedPreSelectedVariantsTemp
            };

        case PRODUCT_REMOVE_PRE_SELECTED_CATEGORY:
            return {
                ...state,
                removedPreSelectedCategories: [...state.removedPreSelectedCategories, action.payload]
            };

        case PRODUCT_REMOVE_UPDATE_CATEGORY_WISE_VARIANT:
            return {
                ...state,
                categoryWiseVariantData: action.payload
            };

        case UPLOAD_BULK_PRODUCT_SUCCESS: {
            return {
                ...state,
                uploadResult: action.payload
            };
        }

        default:
            return state;

    }
}
