import {
    PROCESS_TEMPLATE_ADD_EDIT_REMOVE_TEMP_SELECTED_MEASURES_GROUPS,
    PROCESS_TEMPLATE_ADD_EDIT_SELECT_MEASURE_GROUPS_MODAL_CLOSE,
    PROCESS_TEMPLATE_ADD_EDIT_SELECT_MEASURE_GROUPS_MODAL_OPEN,
    PROCESS_TEMPLATE_ADD_EDIT_UPDATE_SELECTED_MEASURE_GROUP,
    PROCESS_TEMPLATE_ADD_EDIT_UPDATE_TMP_SELECTED_MEASURE_GROUPS,
    PROCESS_TEMPLATE_ADD_NEW_PROCESS_TEMPLATE,
    PROCESS_TEMPLATE_ALLOW_NOTIFICATION,
    PROCESS_TEMPLATE_ALLOW_NOTIFICATION_RESET,
    PROCESS_TEMPLATE_GET_DATA_ENTRY_FORM_TEMPLATE_SUCCESS,
    PROCESS_TEMPLATE_GET_PROCESS_TEMPLATE_GET_NOTIFICATION_TEMPLATES_SUCCESS,
    PROCESS_TEMPLATE_GET_SELECTED_PROCESS_TEMPLATE,
    PROCESS_TEMPLATE_GET_SELECTED_PROCESS_TEMPLATE_SUCCESS,
    PROCESS_TEMPLATE_NOTIFICATION_PICK_USER_GROUP_MODEL_IS_OPEN,
    PROCESS_TEMPLATE_NOTIFICATION_RESET_LATE_DATA_INPUT,
    PROCESS_TEMPLATE_NOTIFICATION_SET_FREQUENCY_LOOK_BACK_INVALID,
    PROCESS_TEMPLATE_NOTIFICATION_SET_LATE_DATA_FREQUENCY_INVALID,
    PROCESS_TEMPLATE_NOTIFICATION_SET_LATE_DATA_INPUT,
    PROCESS_TEMPLATE_NOTIFICATION_USER_GROUP_TEMP_SELECTED,
    PROCESS_TEMPLATE_PAST_DATA_MISSING_EMAIL_NOTIFICATION_CONTENT,
    PROCESS_TEMPLATE_PAST_DATA_MISSING_EMAIL_NOTIFICATION_RESET,
    PROCESS_TEMPLATE_PAST_DATA_MISSING_FREQUENCY_TO_LOOK_BACK,
    PROCESS_TEMPLATE_PAST_DATA_MISSING_IS_EMAIL_NOTIFICATION,
    PROCESS_TEMPLATE_PAST_DATA_MISSING_IS_PAST_DATA_MISSING,
    PROCESS_TEMPLATE_PAST_DATA_MISSING_IS_PUSH_NOTIFICATION,
    PROCESS_TEMPLATE_PAST_DATA_MISSING_NOTIFY_FREQUNECY,
    PROCESS_TEMPLATE_PAST_DATA_MISSING_PUSH_NOTIFICATION_CONTENT,
    PROCESS_TEMPLATE_PAST_DATA_MISSING_PUSH_NOTIFICATION_RESET,
    PROCESS_TEMPLATE_PAST_DATA_MISSING_RESET,
    PROCESS_TEMPLATE_REPLICATE_ACTION_MODAL_CLOSE,
    PROCESS_TEMPLATE_REPLICATE_ACTION_MODAL_OPEN,
    PROCESS_TEMPLATE_REPLICATE_PROCESS,
    PROCESS_TEMPLATE_REPLICATE_PROCESS_SUCCESS,
    PROCESS_TEMPLATE_RESET_SELECTED_PROCESS_TEMPLATE,
    PROCESS_TEMPLATE_SEARCH,
    PROCESS_TEMPLATE_SEARCH_SUCCESS,
    SAVE_UPDATE_PROCESS_TEMPLATE_SUCCESS,
    SUB_BUSINESS_UNIT_SEARCH,
    SUB_BUSINESS_UNIT_SEARCH_SUCCESS,
} from "../actions/types";
import {getPagedData} from "../../utils/DataExtractHelper";


const initialState = {
    pageInfo: {},
    processTemplates: [],
    selectedProcessTemplates: {},
    selectedSubBusinessUnits: [],
    selectedSubBusinessUnitsTmp: [],
    selectedMeasureGroups: [],
    selectedMeasureGroupTmp: [],
    showAddSubBusinessModal: false,
    showAddMeasureGroupModal: false,
    processTemplatesReplicated: {},
    showReplicateAction: false,
    downloadedDataEntryForm: {},

    allowNotification: {
        isAllowNotification: false,
        lateDataInput: {
            frequency: '',
            isLateDataInput: false,
            lateDataNotifyTime: '',
            emailNotification: {
                isEmailNotification: false,
                content: null
            },
            pushNotification: {
                isPushNotification: false,
                content: null
            }
        },
        pastDataMissing: {
            isPastDataMissing: false,
            notifyFrequency: 'Weekly',
            frequencyLookBack: 1,
            emailNotification: {
                isEmailNotification: false,
                content: null
            },
            pushNotification: {
                isPushNotification: false,
                content: null
            }
        }
    },
    notificationModelIsOpen: false,
    userGroupsTempSelected: [],
    notificationTemplateDTOS: [],
    isFrequencyLookBackInvalid:false,
    isLateDataFrequencySetInvalid:false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case PROCESS_TEMPLATE_SEARCH:
        case PROCESS_TEMPLATE_REPLICATE_PROCESS:
            return {
                ...state
            };

        case PROCESS_TEMPLATE_SEARCH_SUCCESS:
            return {
                ...state,
                pageInfo: getPagedData(action.payload),
                processTemplates: action.payload.pageData,
            };

        case SUB_BUSINESS_UNIT_SEARCH :
            return {
                ...state,
                filteredSubBusinessUnits: null
            };

        case SUB_BUSINESS_UNIT_SEARCH_SUCCESS:

            return {
                ...state,
                filteredSubBusinessUnits: action.payload.subBusinessUnitDTOs,
            };
        case PROCESS_TEMPLATE_GET_SELECTED_PROCESS_TEMPLATE :
            return {
                ...state,
                selectedSubBusinessUnits: [],
                selectedMeasureGroups: [],
            };
        case PROCESS_TEMPLATE_GET_SELECTED_PROCESS_TEMPLATE_SUCCESS:
        case SAVE_UPDATE_PROCESS_TEMPLATE_SUCCESS:
            return {
                ...state,
                selectedProcessTemplates: action.payload,
                selectedMeasureGroups: action.payload.measureGroupIDs,
            };
        case PROCESS_TEMPLATE_RESET_SELECTED_PROCESS_TEMPLATE :
        case PROCESS_TEMPLATE_ADD_NEW_PROCESS_TEMPLATE :
            return {
                ...state,
                selectedProcessTemplates: action.payload,
                selectedSubBusinessUnits: [],
                selectedMeasureGroups: [],
                downloadedDataEntryForm: {}
            };
        case PROCESS_TEMPLATE_ADD_EDIT_UPDATE_SELECTED_MEASURE_GROUP:
            return {
                ...state,
                selectedMeasureGroups: action.payload
            };

        case PROCESS_TEMPLATE_ADD_EDIT_SELECT_MEASURE_GROUPS_MODAL_OPEN:
            return {
                ...state,
                showAddMeasureGroupModal: true

            };
        case PROCESS_TEMPLATE_ADD_EDIT_SELECT_MEASURE_GROUPS_MODAL_CLOSE:
            return {
                ...state,
                showAddMeasureGroupModal: false

            };
        case PROCESS_TEMPLATE_ADD_EDIT_UPDATE_TMP_SELECTED_MEASURE_GROUPS:
            return {
                ...state,
                selectedMeasureGroupTmp: action.payload
            };

        case PROCESS_TEMPLATE_ADD_EDIT_REMOVE_TEMP_SELECTED_MEASURES_GROUPS:
            return {
                ...state,
                selectedMeasureGroupTmp: [],
                selectedMeasureGroups: []
            };

        case PROCESS_TEMPLATE_REPLICATE_PROCESS_SUCCESS:
            return {
                ...state,
                processTemplatesReplicated: action.payload,
                isReplicated: true,
            };
        case PROCESS_TEMPLATE_REPLICATE_ACTION_MODAL_OPEN:
            return {
                ...state,
                showReplicateAction: true
            };
        case PROCESS_TEMPLATE_REPLICATE_ACTION_MODAL_CLOSE:
            return {
                ...state,
                showReplicateAction: false,
                isReplicated: false
            };
        case PROCESS_TEMPLATE_GET_DATA_ENTRY_FORM_TEMPLATE_SUCCESS:
            return {
                ...state,
                downloadedDataEntryForm: action.payload
            };
        case PROCESS_TEMPLATE_GET_PROCESS_TEMPLATE_GET_NOTIFICATION_TEMPLATES_SUCCESS:
            return {
                ...state,
                notificationTemplateDTOS: action.payload
            };
        case PROCESS_TEMPLATE_PAST_DATA_MISSING_PUSH_NOTIFICATION_RESET:
            return {
                ...state,
                allowNotification: {
                    ...state.allowNotification,
                    pastDataMissing: {
                        ...state.allowNotification.pastDataMissing,
                        pushNotification: action.payload
                    }
                }
            };
        case PROCESS_TEMPLATE_PAST_DATA_MISSING_EMAIL_NOTIFICATION_RESET:
            return {
                ...state,
                allowNotification: {
                    ...state.allowNotification,
                    pastDataMissing: {
                        ...state.allowNotification.pastDataMissing,
                        emailNotification: action.payload
                    }
                }
            };
        case PROCESS_TEMPLATE_PAST_DATA_MISSING_RESET:
            return {
                ...state,
                allowNotification: {
                    ...state.allowNotification,
                    pastDataMissing: action.payload
                }
            };
        case PROCESS_TEMPLATE_ALLOW_NOTIFICATION_RESET:
            action.payload.lateDataInput.frequency = state.allowNotification.lateDataInput.frequency;
            return {
                ...state,
                allowNotification: action.payload,
                userGroupsTempSelected: []
            };
        case PROCESS_TEMPLATE_ALLOW_NOTIFICATION:
            return {
                ...state,
                allowNotification: {
                    ...state.allowNotification,
                    isAllowNotification: action.payload
                }
            };
        case PROCESS_TEMPLATE_PAST_DATA_MISSING_IS_PAST_DATA_MISSING:
            return {
                ...state,
                allowNotification: {
                    ...state.allowNotification,
                    pastDataMissing: {
                        ...state.allowNotification.pastDataMissing,
                        isPastDataMissing: action.payload
                    }
                }
            };
        case PROCESS_TEMPLATE_PAST_DATA_MISSING_NOTIFY_FREQUNECY:
            return {
                ...state,
                allowNotification: {
                    ...state.allowNotification,
                    pastDataMissing: {
                        ...state.allowNotification.pastDataMissing,
                        notifyFrequency: action.payload
                    }
                }
            };
        case PROCESS_TEMPLATE_PAST_DATA_MISSING_FREQUENCY_TO_LOOK_BACK:
            return {
                ...state,
                allowNotification: {
                    ...state.allowNotification,
                    pastDataMissing: {
                        ...state.allowNotification.pastDataMissing,
                        frequencyLookBack: action.payload
                    }
                }
            };
        case PROCESS_TEMPLATE_PAST_DATA_MISSING_IS_EMAIL_NOTIFICATION:
            return {
                ...state,
                allowNotification: {
                    ...state.allowNotification,
                    pastDataMissing: {
                        ...state.allowNotification.pastDataMissing,
                        emailNotification: {
                            ...state.allowNotification.pastDataMissing.emailNotification,
                            isEmailNotification: action.payload
                        }
                    }
                }
            };
        case PROCESS_TEMPLATE_PAST_DATA_MISSING_EMAIL_NOTIFICATION_CONTENT:
            return {
                ...state,
                allowNotification: {
                    ...state.allowNotification,
                    pastDataMissing: {
                        ...state.allowNotification.pastDataMissing,
                        emailNotification: {
                            ...state.allowNotification.pastDataMissing.emailNotification,
                            content: action.payload
                        }
                    }
                }
            };
        case PROCESS_TEMPLATE_PAST_DATA_MISSING_IS_PUSH_NOTIFICATION:
            return {
                ...state,
                allowNotification: {
                    ...state.allowNotification,
                    pastDataMissing: {
                        ...state.allowNotification.pastDataMissing,
                        pushNotification: {
                            ...state.allowNotification.pastDataMissing.pushNotification,
                            isPushNotification: action.payload
                        }
                    }
                }
            };
        case PROCESS_TEMPLATE_PAST_DATA_MISSING_PUSH_NOTIFICATION_CONTENT:
            return {
                ...state,
                allowNotification: {
                    ...state.allowNotification,
                    pastDataMissing: {
                        ...state.allowNotification.pastDataMissing,
                        pushNotification: {
                            ...state.allowNotification.pastDataMissing.pushNotification,
                            content: action.payload
                        }
                    }
                }
            };
        case PROCESS_TEMPLATE_NOTIFICATION_PICK_USER_GROUP_MODEL_IS_OPEN:
            return {
                ...state,
                notificationModelIsOpen: action.payload
            };
        case PROCESS_TEMPLATE_NOTIFICATION_USER_GROUP_TEMP_SELECTED:
            return {
                ...state,
                userGroupsTempSelected: action.payload
            };
        case PROCESS_TEMPLATE_NOTIFICATION_SET_LATE_DATA_INPUT:
            return {
                ...state,
                allowNotification: {
                    ...state.allowNotification,
                    lateDataInput: action.payload
                }
            };
        case PROCESS_TEMPLATE_NOTIFICATION_RESET_LATE_DATA_INPUT:
            action.payload.frequency = state.allowNotification.lateDataInput.frequency;
            return {
                ...state,
                allowNotification: {
                    ...state.allowNotification,
                    lateDataInput: action.payload
                }
            };
        case PROCESS_TEMPLATE_NOTIFICATION_SET_FREQUENCY_LOOK_BACK_INVALID:
            return {
                ...state,
                isFrequencyLookBackInvalid:action.payload
            };
        case PROCESS_TEMPLATE_NOTIFICATION_SET_LATE_DATA_FREQUENCY_INVALID:
            return {
                ...state,
                isLateDataFrequencySetInvalid:action.payload
            };
        default :
            return state;
    }
}
