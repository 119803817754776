import {
    FREQUENCY_DATA_GET_ALL_FREQUENCY_FAILED,
    FREQUENCY_DATA_GET_ALL_FREQUENCY_SUCCESS,
    FREQUENCY_DATA_GET_ALL_FREQUENCY,

} from "../actions/types";

const initialState = {
    frequencyList: [],
};

export default (state = initialState, action) => {
    switch (action.type) {

        case FREQUENCY_DATA_GET_ALL_FREQUENCY:
            return {
                ...state,
            };
        case FREQUENCY_DATA_GET_ALL_FREQUENCY_SUCCESS:
            return {
                ...state,
                frequencyList: action.payload
            };
        default:
            return state;
    }
}
