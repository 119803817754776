import {
    SAVE_UPDATE_WASTE_SUCCESS,
    WASTE_GET_SELECTED_WASTE_SUCCESS,
    WASTE_REST,
    WASTE_SEARCH,
    WASTE_SEARCH_SUCCESS,
    WASTE_UPDATE_WASTE_CAUSE_SUB_CATEGORIES
} from "../actions/types";
import {getPagedData} from "../../utils/DataExtractHelper";

const initialState = {
    pageInfo: {},
    wasts: [],
    selectedWaste: {},
    addedWasteCauseSubCategories: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case WASTE_SEARCH:
            return {
                ...state
            };

        case WASTE_SEARCH_SUCCESS:
            return {
                ...state,
                wasts: action.payload.pageData,
                pageInfo: getPagedData(action.payload)
            };

        case WASTE_UPDATE_WASTE_CAUSE_SUB_CATEGORIES:
            return {
                ...state,
                addedWasteCauseSubCategories: action.payload
            };

        case SAVE_UPDATE_WASTE_SUCCESS:
        case WASTE_GET_SELECTED_WASTE_SUCCESS:
            return {
                ...state,
                selectedWaste: action.payload,
                addedWasteCauseSubCategories: action.payload.wasteCauseSubCategories
            };

        case WASTE_REST:
            return {
                pageInfo: {},
                wasts: [],
                selectedWaste: {},
                addedWasteCauseSubCategories: []
            };

        default:
            return state;
    }
}
