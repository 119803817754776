import {
    MASTER_DATA_GET_ALL_COMPANIES,
    MASTER_DATA_GET_ALL_COMPANIES_FAILED,
    MASTER_DATA_GET_ALL_COMPANIES_SUCCESS,
    MASTER_DATA_GET_COMPANY_VICE_MEASURE_UNIT_CATEGORY,
    MASTER_DATA_GET_COMPANY_VICE_MEASURE_UNIT_CATEGORY_FAILED,
    MASTER_DATA_GET_COMPANY_VICE_MEASURE_UNIT_CATEGORY_SUCCESS,
    MASTER_DATA_GET_DATA_FORM_VARIABLES,
    MASTER_DATA_GET_DATA_FORM_VARIABLES_FAILED,
    MASTER_DATA_GET_DATA_FORM_VARIABLES_SUCCESS,
    MASTER_DATA_GET_DOWN_TIME_CAUSE_CATEGORY_WISE_SUBCATEGORIES_DATA,
    MASTER_DATA_GET_DOWN_TIME_CAUSE_CATEGORY_WISE_SUBCATEGORIES_DATA_FAILED,
    MASTER_DATA_GET_DOWN_TIME_CAUSE_CATEGORY_WISE_SUBCATEGORIES_DATA_SUCCESS,
    MASTER_DATA_GET_ELIGIBLE_COMPANY,
    MASTER_DATA_GET_ELIGIBLE_COMPANY_FAILED,
    MASTER_DATA_GET_ELIGIBLE_COMPANY_SUCCESS,
    MASTER_DATA_GET_FACTORY_DOWN_TIME_CATEGORIES,
    MASTER_DATA_GET_FACTORY_DOWN_TIME_CATEGORIES_FAILED,
    MASTER_DATA_GET_FACTORY_DOWN_TIME_CATEGORIES_SUCCESS,
    MASTER_DATA_GET_FACTORY_WASTE_CATEGORIES,
    MASTER_DATA_GET_FACTORY_WASTE_CATEGORIES_FAILED,
    MASTER_DATA_GET_FACTORY_WASTE_CATEGORIES_SUCCESS,
    MASTER_DATA_GET_MATH_OPERATORS,
    MASTER_DATA_GET_MATH_OPERATORS_FAILED,
    MASTER_DATA_GET_MATH_OPERATORS_SUCCESS,
    MASTER_DATA_GET_MEASURE_UNIT_CATEGORY_VICE_MEASURE_UNIT,
    MASTER_DATA_GET_MEASURE_UNIT_CATEGORY_VICE_MEASURE_UNIT_FAILED,
    MASTER_DATA_GET_MEASURE_UNIT_CATEGORY_VICE_MEASURE_UNIT_SUCCESS,
    MASTER_DATA_GET_PRIVILEGES,
    MASTER_DATA_GET_PRIVILEGES_FAILED,
    MASTER_DATA_GET_PRIVILEGES_SUCCESS,
    MASTER_DATA_GET_PROCESS_FREQUENCIES,
    MASTER_DATA_GET_PROCESS_FREQUENCIES_FAILED,
    MASTER_DATA_GET_PROCESS_FREQUENCIES_SUCCESS,
    MASTER_DATA_GET_PRODUCT_CATEGORY_WISE_VARIANTS,
    MASTER_DATA_GET_PRODUCT_CATEGORY_WISE_VARIANTS_FAILED,
    MASTER_DATA_GET_PRODUCT_CATEGORY_WISE_VARIANTS_SUCCESS,
    MASTER_DATA_GET_USER_ROLES,
    MASTER_DATA_GET_USER_ROLES_FAILED,
    MASTER_DATA_GET_USER_ROLES_SUCCESS,
    MASTER_DATA_GET_WASTE_CATEGORY_WISE_SUBCATEGORIES,
    MASTER_DATA_GET_WASTE_CATEGORY_WISE_SUBCATEGORIES_FAILED,
    MASTER_DATA_GET_WASTE_CATEGORY_WISE_SUBCATEGORIES_SUCCESS,
    MASTER_DATA_GET_WIDGET_RULE_OPERATORS,
    MASTER_DATA_GET_WIDGET_RULE_OPERATORS_FAILED,
    MASTER_DATA_GET_WIDGET_RULE_OPERATORS_SUCCESS,
    MASTER_DATA_GET_WIDGET_TYPES,
    MASTER_DATA_GET_WIDGET_TYPES_FAILED,
    MASTER_DATA_GET_WIDGET_TYPES_SUCCESS,
    MASTER_DATA_SET_SELECTED_LANGUAGE,
    STANDARD_COMPANY_VICE_MEASURE_UNIT_CATEGORY,
    STANDARD_COMPANY_VICE_MEASURE_UNIT_CATEGORY_FAILED,
    STANDARD_COMPANY_VICE_MEASURE_UNIT_CATEGORY_SUCCESS,
    STANDARD_MEASURE_UNIT_CATEGORY_VICE_MEASURE_UNIT,
    STANDARD_MEASURE_UNIT_CATEGORY_VICE_MEASURE_UNIT_FAILED,
    STANDARD_MEASURE_UNIT_CATEGORY_VICE_MEASURE_UNIT_SUCCESS
} from "./types";
import Constants from "../../utils/Constants";
import * as _ from 'lodash';
import {hasPrivilege} from "../../utils/PrivilegeHelper";

export const getPrivileges = () => {
    return {
        type: MASTER_DATA_GET_PRIVILEGES,
        payload: {
            request: {
                method: 'GET',
                attachAuthToHeader: true,
                url: `/api/role/getSystemPrivileges`,
                headers: {
                    showLoading: true,
                },
            },
            options: {
                onSuccess({getState, dispatch, response}) {
                    dispatch({
                        type: MASTER_DATA_GET_PRIVILEGES_SUCCESS,
                        payload: response.data.result
                    });
                },
                onError({getState, dispatch, error}) {
                    dispatch({
                        type: MASTER_DATA_GET_PRIVILEGES_FAILED,
                        payload: error
                    });
                },
            }
        }
    };
};


export const getEligibleCompanies = (loadOptions) => {

    if (!Constants.MASTER_DATA[Constants.MASTER_DATA_KEYS.LOAD_OPTION_WISE_USER_ELIGIBLE_COMPANIES]) {
        Constants.MASTER_DATA[Constants.MASTER_DATA_KEYS.LOAD_OPTION_WISE_USER_ELIGIBLE_COMPANIES] = {};
    }

    let optionWiseEligibleCompanies = Constants.MASTER_DATA[Constants.MASTER_DATA_KEYS.LOAD_OPTION_WISE_USER_ELIGIBLE_COMPANIES];
    if (!loadOptions) {
        if (!_.isEmpty(optionWiseEligibleCompanies)) {
            let eligibleCompanies = optionWiseEligibleCompanies[_.keys(optionWiseEligibleCompanies)[0]];
            return {
                type: MASTER_DATA_GET_ELIGIBLE_COMPANY_SUCCESS,
                payload: Object.assign([], eligibleCompanies)
            }
        }
    } else {
        let key = getStringFromCompanyLoadOptions(loadOptions);
        if (optionWiseEligibleCompanies[key]) {
            return {
                type: MASTER_DATA_GET_ELIGIBLE_COMPANY_SUCCESS,
                payload: Object.assign([], optionWiseEligibleCompanies[key])
            }
        }
    }

    return {
        type: MASTER_DATA_GET_ELIGIBLE_COMPANY,
        payload: {
            request: {
                method: 'POST',
                attachAuthToHeader: true,
                url: `api/company/getEligibleCompanies`,
                data: JSON.stringify({
                    getAllCompanies: hasPrivilege(Constants.PRIVILEGE_CODES.IHANZ_ADMIN_CONFIGURATION_ALLOW_ALL_COMPANIES_FOR_USER),
                    eligibleCompanyIDs: Constants.LOGGED_IN_USER.companies,
                    loadOptions: loadOptions
                }),
                headers: {
                    showLoading: true,
                },
            },
            options: {
                onSuccess({getState, dispatch, response}) {
                    let key = getStringFromCompanyLoadOptions(loadOptions);
                    Constants.MASTER_DATA[Constants.MASTER_DATA_KEYS.LOAD_OPTION_WISE_USER_ELIGIBLE_COMPANIES][key] = response.data.result.eligibleCompanies;
                    dispatch({
                        type: MASTER_DATA_GET_ELIGIBLE_COMPANY_SUCCESS,
                        payload: response.data.result.eligibleCompanies
                    });
                },
                onError({getState, dispatch, error}) {
                    dispatch({
                        type: MASTER_DATA_GET_ELIGIBLE_COMPANY_FAILED,
                        payload: error
                    })
                }

            }
        }
    };
};

export const getAllCompanies = (loadOptions) => {

    if (!loadOptions) {
        if (Constants.MASTER_DATA[Constants.MASTER_DATA_KEYS.ALL_COMPANIES]) {
            return {
                type: MASTER_DATA_GET_ALL_COMPANIES_SUCCESS,
                payload: Object.assign([], Constants.MASTER_DATA[Constants.MASTER_DATA_KEYS.ALL_COMPANIES])
            }
        }
    }

    return {
        type: MASTER_DATA_GET_ALL_COMPANIES,
        payload: {
            request: {
                method: 'POST',
                attachAuthToHeader: true,
                url: `api/company/getEligibleCompanies`,
                data: JSON.stringify({
                    getAllCompanies: true,
                    loadOptions: loadOptions
                }),
                headers: {
                    showLoading: true,
                },
            },
            options: {
                onSuccess({getState, dispatch, response}) {
                    dispatch({
                        type: MASTER_DATA_GET_ALL_COMPANIES_SUCCESS,
                        payload: response.data.result.eligibleCompanies
                    });
                },
                onError({getState, dispatch, error}) {
                    dispatch({
                        type: MASTER_DATA_GET_ALL_COMPANIES_FAILED,
                        payload: error
                    })
                }

            }
        }
    };
};

export const getAllRoles = () => {
    return {
        type: MASTER_DATA_GET_USER_ROLES,
        payload: {
            request: {
                method: 'GET',
                attachAuthToHeader: true,
                url: `/api/role/getRoles`,
                headers: {
                    showLoading: true,
                },
            },
            options: {
                onSuccess({getState, dispatch, response}) {
                    dispatch({
                        type: MASTER_DATA_GET_USER_ROLES_SUCCESS,
                        payload: response.data.result
                    });
                },
                onError({getState, dispatch, error}) {
                    dispatch({
                        type: MASTER_DATA_GET_USER_ROLES_FAILED,
                        payload: error
                    });
                },
            }
        }
    };
};

export const getMathOperators = () => {

    if (Constants.MASTER_DATA[Constants.MASTER_DATA_KEYS.MATH_OPERATORS]) {
        return {
            type: MASTER_DATA_GET_MATH_OPERATORS_SUCCESS,
            payload: Object.assign([], Constants.MASTER_DATA[Constants.MASTER_DATA_KEYS.MATH_OPERATORS])
        }
    }

    return {
        type: MASTER_DATA_GET_MATH_OPERATORS,
        payload: {
            request: {
                method: 'GET',
                attachAuthToHeader: true,
                url: `api/measure/getAllExpressionData`,
                headers: {
                    showLoading: true,
                },
            },
            options: {
                onSuccess({getState, dispatch, response}) {
                    Constants.MASTER_DATA[Constants.MASTER_DATA_KEYS.MATH_OPERATORS] = response.data.result;
                    dispatch({
                        type: MASTER_DATA_GET_MATH_OPERATORS_SUCCESS,
                        payload: response.data.result
                    });
                },
                onError({getState, dispatch, error}) {
                    Constants.MASTER_DATA[Constants.MASTER_DATA_KEYS.MATH_OPERATORS] = null;
                    dispatch({
                        type: MASTER_DATA_GET_MATH_OPERATORS_FAILED,
                        payload: error
                    })
                }

            }
        }
    };
};

export const getProcessFrequencies = () => {

    if (Constants.MASTER_DATA[Constants.MASTER_DATA_KEYS.PROCESS_FREQUENCIES]) {
        return {
            type: MASTER_DATA_GET_PROCESS_FREQUENCIES_SUCCESS,
            payload: Object.assign([], Constants.MASTER_DATA[Constants.MASTER_DATA_KEYS.PROCESS_FREQUENCIES])
        }
    }
    return {
        type: MASTER_DATA_GET_PROCESS_FREQUENCIES,
        payload: {
            request: {
                method: 'GET',
                attachAuthToHeader: true,
                url: `api/process/getFrequencies`,
                headers: {
                    showLoading: true,
                },
            },
            options: {
                onSuccess({getState, dispatch, response}) {
                    Constants.MASTER_DATA[Constants.MASTER_DATA_KEYS.PROCESS_FREQUENCIES] = response.data.result;
                    dispatch({
                        type: MASTER_DATA_GET_PROCESS_FREQUENCIES_SUCCESS,
                        payload: response.data.result
                    });
                },
                onError({getState, dispatch, error}) {
                    Constants.MASTER_DATA[Constants.MASTER_DATA_KEYS.PROCESS_FREQUENCIES] = null;
                    dispatch({
                        type: MASTER_DATA_GET_PROCESS_FREQUENCIES_FAILED,
                        payload: error
                    })
                }

            }
        }
    };
};

export const getDataFormVariables = () => {

    if (Constants.MASTER_DATA[Constants.MASTER_DATA_KEYS.ALL_FORM_VARIABLES]) {
        return {
            type: MASTER_DATA_GET_DATA_FORM_VARIABLES_SUCCESS,
            payload: Object.assign([], Constants.MASTER_DATA[Constants.MASTER_DATA_KEYS.ALL_FORM_VARIABLES])
        }
    }
    return {
        type: MASTER_DATA_GET_DATA_FORM_VARIABLES,
        payload: {
            request: {
                method: 'GET',
                attachAuthToHeader: true,
                url: `api/process/getAllFormVariables`,
                headers: {
                    showLoading: true,
                },
            },
            options: {
                onSuccess({getState, dispatch, response}) {
                    Constants.MASTER_DATA[Constants.MASTER_DATA_KEYS.ALL_FORM_VARIABLES] = response.data.result;
                    dispatch({
                        type: MASTER_DATA_GET_DATA_FORM_VARIABLES_SUCCESS,
                        payload: response.data.result
                    });
                },
                onError({getState, dispatch, error}) {
                    Constants.MASTER_DATA[Constants.MASTER_DATA_KEYS.ALL_FORM_VARIABLES] = null;
                    dispatch({
                        type: MASTER_DATA_GET_DATA_FORM_VARIABLES_FAILED,
                        payload: error
                    })
                }

            }
        }
    };
};

export const getWidgetTypes = () => {

    if (Constants.MASTER_DATA[Constants.MASTER_DATA_KEYS.WIDGET_TYPES]) {
        return {
            type: MASTER_DATA_GET_WIDGET_TYPES_SUCCESS,
            payload: Object.assign([], Constants.MASTER_DATA[Constants.MASTER_DATA_KEYS.WIDGET_TYPES])
        }
    }
    return {
        type: MASTER_DATA_GET_WIDGET_TYPES,
        payload: {
            request: {
                method: 'GET',
                attachAuthToHeader: true,
                url: `api/dashboard/getWidgetTypeDTO`,
                headers: {
                    showLoading: true,
                },
            },
            options: {
                onSuccess({getState, dispatch, response}) {
                    Constants.MASTER_DATA[Constants.MASTER_DATA_KEYS.WIDGET_TYPES] = response.data.result;
                    dispatch({
                        type: MASTER_DATA_GET_WIDGET_TYPES_SUCCESS,
                        payload: response.data.result
                    });
                },
                onError({getState, dispatch, error}) {
                    Constants.MASTER_DATA[Constants.MASTER_DATA_KEYS.WIDGET_TYPES] = [];
                    dispatch({
                        type: MASTER_DATA_GET_WIDGET_TYPES_FAILED,
                        payload: error
                    })
                }

            }
        }
    };
};

export const getWidgetRuleOperators = () => {

    if (Constants.MASTER_DATA[Constants.MASTER_DATA_KEYS.WIDGET_RULE_OPERATORS]) {
        return {
            type: MASTER_DATA_GET_WIDGET_RULE_OPERATORS_SUCCESS,
            payload: Object.assign([], Constants.MASTER_DATA[Constants.MASTER_DATA_KEYS.WIDGET_RULE_OPERATORS])
        }
    }
    return {
        type: MASTER_DATA_GET_WIDGET_RULE_OPERATORS,
        payload: {
            request: {
                method: 'GET',
                attachAuthToHeader: true,
                url: `api/dashboard/getAllOperators`,
                headers: {
                    showLoading: true,
                },
            },
            options: {
                onSuccess({getState, dispatch, response}) {
                    Constants.MASTER_DATA[Constants.MASTER_DATA_KEYS.WIDGET_RULE_OPERATORS] = response.data.result;
                    dispatch({
                        type: MASTER_DATA_GET_WIDGET_RULE_OPERATORS_SUCCESS,
                        payload: response.data.result
                    });
                },
                onError({getState, dispatch, error}) {
                    Constants.MASTER_DATA[Constants.MASTER_DATA_KEYS.WIDGET_RULE_OPERATORS] = [];
                    dispatch({
                        type: MASTER_DATA_GET_WIDGET_RULE_OPERATORS_FAILED,
                        payload: error
                    })
                }

            }
        }
    };
};


export const getProductMasterCategories = () => {

    if (Constants.MASTER_DATA[Constants.MASTER_DATA_KEYS.PRODUCT_CATEGORY_WISE_VARIANTS]) {
        return {
            type: MASTER_DATA_GET_PRODUCT_CATEGORY_WISE_VARIANTS_SUCCESS,
            payload: Object.assign([], Constants.MASTER_DATA[Constants.MASTER_DATA_KEYS.PRODUCT_CATEGORY_WISE_VARIANTS])
        }
    }

    return {
        type: MASTER_DATA_GET_PRODUCT_CATEGORY_WISE_VARIANTS,
        payload: {
            request: {
                method: 'GET',
                attachAuthToHeader: true,
                url: `api/product/productMasterCategories`,
                headers: {
                    showLoading: true,
                },
            },
            options: {
                onSuccess({getState, dispatch, response}) {
                    Constants.MASTER_DATA[Constants.MASTER_DATA_KEYS.PRODUCT_CATEGORY_WISE_VARIANTS] = response.data.result;
                    dispatch({
                        type: MASTER_DATA_GET_PRODUCT_CATEGORY_WISE_VARIANTS_SUCCESS,
                        payload: response.data.result
                    });
                },
                onError({getState, dispatch, error}) {
                    Constants.MASTER_DATA[Constants.MASTER_DATA_KEYS.PRODUCT_CATEGORY_WISE_VARIANTS] = null;
                    dispatch({
                        type: MASTER_DATA_GET_PRODUCT_CATEGORY_WISE_VARIANTS_FAILED,
                        payload: error
                    })
                }

            }
        }
    };
};


export const getWasteCauseCategories = () => {

    if (Constants.MASTER_DATA[Constants.MASTER_DATA_KEYS.WASTE_CATEGORY_WISE_SUBCATEGORIES]) {
        return {
            type: MASTER_DATA_GET_WASTE_CATEGORY_WISE_SUBCATEGORIES_SUCCESS,
            payload: Object.assign([], Constants.MASTER_DATA[Constants.MASTER_DATA_KEYS.WASTE_CATEGORY_WISE_SUBCATEGORIES])
        }
    }

    return {
        type: MASTER_DATA_GET_WASTE_CATEGORY_WISE_SUBCATEGORIES,
        payload: {
            request: {
                method: 'GET',
                attachAuthToHeader: true,
                url: `api/cause/wasteCauseCategories`,
                headers: {
                    showLoading: true,
                },
            },
            options: {
                onSuccess: function ({getState, dispatch, response}) {
                    Constants.MASTER_DATA[Constants.MASTER_DATA_KEYS.WASTE_CATEGORY_WISE_SUBCATEGORIES] = response.data.result;
                    dispatch({
                        type: MASTER_DATA_GET_WASTE_CATEGORY_WISE_SUBCATEGORIES_SUCCESS,
                        payload: response.data.result
                    });
                },
                onError({getState, dispatch, error}) {
                    Constants.MASTER_DATA[Constants.MASTER_DATA_KEYS.WASTE_CATEGORY_WISE_SUBCATEGORIES] = null;
                    dispatch({
                        type: MASTER_DATA_GET_WASTE_CATEGORY_WISE_SUBCATEGORIES_FAILED,
                        payload: error
                    })
                }

            }
        }
    };
};

export const getDowntimeCauseCategories = () => {

    if (Constants.MASTER_DATA[Constants.MASTER_DATA_KEYS.DOWNTIME_CATEGORY_WISE_SUBCATEGORIES]) {
        return {
            type: MASTER_DATA_GET_DOWN_TIME_CAUSE_CATEGORY_WISE_SUBCATEGORIES_DATA_SUCCESS,
            payload: Object.assign([], Constants.MASTER_DATA[Constants.MASTER_DATA_KEYS.DOWNTIME_CATEGORY_WISE_SUBCATEGORIES])
        }
    }

    return {
        type: MASTER_DATA_GET_DOWN_TIME_CAUSE_CATEGORY_WISE_SUBCATEGORIES_DATA,
        payload: {
            request: {
                method: 'GET',
                attachAuthToHeader: true,
                url: `api/cause/downtimeCauseCategories`,
                headers: {
                    showLoading: true,
                }
            },
            options: {
                onSuccess({getState, dispatch, response}) {
                    Constants.MASTER_DATA[Constants.MASTER_DATA_KEYS.DOWNTIME_CATEGORY_WISE_SUBCATEGORIES] = response.data.result;
                    dispatch({
                        type: MASTER_DATA_GET_DOWN_TIME_CAUSE_CATEGORY_WISE_SUBCATEGORIES_DATA_SUCCESS,
                        payload: response.data.result
                    });
                },
                onError({getState, dispatch, error}) {
                    Constants.MASTER_DATA[Constants.MASTER_DATA_KEYS.DOWNTIME_CATEGORY_WISE_SUBCATEGORIES] = null;
                    dispatch({
                        type: MASTER_DATA_GET_DOWN_TIME_CAUSE_CATEGORY_WISE_SUBCATEGORIES_DATA_FAILED,
                        payload: error
                    });
                }
            }
        }
    }
};

export const getStringFromCompanyLoadOptions = (loadOptions) => {
    let str = 'DEFAULT-';
    if (loadOptions) {
        if (loadOptions.loadSupervisors) {
            str += 'A';
        }
        if (loadOptions.loadLocations) {
            str += 'B';
        }
        if (loadOptions.loadProducts) {
            str += 'C';
        }
        if (loadOptions.loadBusinessUnits) {
            str += 'D';
        }
        if (loadOptions.loadSubBusinessUnits) {
            str += 'E';
        }
        if (loadOptions.loadBusinessUnitLocations) {
            str += 'F';
        }
        if (loadOptions.loadBusinessUnitProducts) {
            str += 'G';
        }
        if (loadOptions.loadLoadProducts) {
            str += 'H';
        }
        if (loadOptions.loadSubBusinessUnitProducts) {
            str += 'I';
        }
        if (loadOptions.loadMeasureUnitCategories) {
            str += 'J';
        }
        if (loadOptions.loadSubBusinessUnitSupervisors) {
            str += 'K';
        }
        if (loadOptions.loadLocationSupervisors) {
            str += 'L';
        }
    }
    return str;
};

export const setSelectedLanguage = (lng) => {
    return {
        type : MASTER_DATA_SET_SELECTED_LANGUAGE,
        payload : lng
    };
};


export const getCompanyFactoryDownTimes = (companyID, factoryID) => {

    let key = `${companyID}-${factoryID}`;

    if(!Constants.MASTER_DATA[Constants.MASTER_DATA_KEYS.COMPANY_WISE_FACTORY_WISE_DOWNTIME_CATEGORIES]) {
        Constants.MASTER_DATA[Constants.MASTER_DATA_KEYS.COMPANY_WISE_FACTORY_WISE_DOWNTIME_CATEGORIES] = {};
    }

    if(!Constants.MASTER_DATA[Constants.MASTER_DATA_KEYS.COMPANY_WISE_FACTORY_WISE_DOWNTIME_CATEGORIES][key]) {
        Constants.MASTER_DATA[Constants.MASTER_DATA_KEYS.COMPANY_WISE_FACTORY_WISE_DOWNTIME_CATEGORIES][key] = [];
    }

    if (Constants.MASTER_DATA[Constants.MASTER_DATA_KEYS.COMPANY_WISE_FACTORY_WISE_DOWNTIME_CATEGORIES][key].length > 0) {
        return {
            type: MASTER_DATA_GET_FACTORY_DOWN_TIME_CATEGORIES_SUCCESS,
            payload: Object.assign([], Constants.MASTER_DATA[Constants.MASTER_DATA_KEYS.COMPANY_WISE_FACTORY_WISE_DOWNTIME_CATEGORIES][key])
        }
    }

    return {
        type: MASTER_DATA_GET_FACTORY_DOWN_TIME_CATEGORIES,
        payload: {
            request: {
                method: 'GET',
                attachAuthToHeader: true,
                url: `api/cause/downtimeCauseCategoriesForCompanyLocation/${companyID}/${factoryID}`,
                headers: {
                    showLoading: true,
                },
            },
            options: {
                onSuccess({getState, dispatch, response}) {
                    Constants.MASTER_DATA[Constants.MASTER_DATA_KEYS.COMPANY_WISE_FACTORY_WISE_DOWNTIME_CATEGORIES][key] = response.data.result;
                    dispatch({
                        type: MASTER_DATA_GET_FACTORY_DOWN_TIME_CATEGORIES_SUCCESS,
                        payload: response.data.result
                    });
                },
                onError({getState, dispatch, error}) {
                    Constants.MASTER_DATA[Constants.MASTER_DATA_KEYS.COMPANY_WISE_FACTORY_WISE_DOWNTIME_CATEGORIES][key] = null;
                    dispatch({
                        type: MASTER_DATA_GET_FACTORY_DOWN_TIME_CATEGORIES_FAILED,
                        payload: error
                    })
                }

            }
        }
    };
};


export const getCompanyFactoryWasteCategories = (companyID, factoryID) => {

    let key = `${companyID}-${factoryID}`;

    if(!Constants.MASTER_DATA[Constants.MASTER_DATA_KEYS.COMPANY_WISE_FACTORY_WISE_WASTE_CATEGORIES]) {
        Constants.MASTER_DATA[Constants.MASTER_DATA_KEYS.COMPANY_WISE_FACTORY_WISE_WASTE_CATEGORIES] = {};
    }

    if(!Constants.MASTER_DATA[Constants.MASTER_DATA_KEYS.COMPANY_WISE_FACTORY_WISE_WASTE_CATEGORIES][key]) {
        Constants.MASTER_DATA[Constants.MASTER_DATA_KEYS.COMPANY_WISE_FACTORY_WISE_WASTE_CATEGORIES][key] = [];
    }

    if (Constants.MASTER_DATA[Constants.MASTER_DATA_KEYS.COMPANY_WISE_FACTORY_WISE_WASTE_CATEGORIES][key].length > 0) {
        return {
            type: MASTER_DATA_GET_FACTORY_WASTE_CATEGORIES_SUCCESS,
            payload: Object.assign([], Constants.MASTER_DATA[Constants.MASTER_DATA_KEYS.COMPANY_WISE_FACTORY_WISE_WASTE_CATEGORIES][key])
        }
    }

    return {
        type: MASTER_DATA_GET_FACTORY_WASTE_CATEGORIES,
        payload: {
            request: {
                method: 'GET',
                attachAuthToHeader: true,
                url: `api/cause/wasteCauseCategoriesForCompanyLocation/${companyID}/${factoryID}`,
                headers: {
                    showLoading: true,
                },
            },
            options: {
                onSuccess({getState, dispatch, response}) {
                    Constants.MASTER_DATA[Constants.MASTER_DATA_KEYS.COMPANY_WISE_FACTORY_WISE_WASTE_CATEGORIES][key] = response.data.result;
                    dispatch({
                        type: MASTER_DATA_GET_FACTORY_WASTE_CATEGORIES_SUCCESS,
                        payload: response.data.result
                    });
                },
                onError({getState, dispatch, error}) {
                    Constants.MASTER_DATA[Constants.MASTER_DATA_KEYS.COMPANY_WISE_FACTORY_WISE_WASTE_CATEGORIES][key] = null;
                    dispatch({
                        type: MASTER_DATA_GET_FACTORY_WASTE_CATEGORIES_FAILED,
                        payload: error
                    })
                }

            }
        }
    };
};

export const getCompanyViceMeasureUnitCategories = (selectedCompanyID) => {
    return {
        type: MASTER_DATA_GET_COMPANY_VICE_MEASURE_UNIT_CATEGORY,
        payload: {
            request: {
                method: 'POST',
                attachAuthToHeader: true,
                url: `/api/company/getMeasureUnitCategoryForCompany`,
                data: JSON.stringify(selectedCompanyID),
                headers: {
                    showLoading: true
                },

            },
            options: {
                onSuccess({getState, dispatch, response}) {
                    dispatch({
                        type: MASTER_DATA_GET_COMPANY_VICE_MEASURE_UNIT_CATEGORY_SUCCESS,
                        payload: response.data.result
                    });
                },
                onError({getState, dispatch, error}) {
                    dispatch({
                        type: MASTER_DATA_GET_COMPANY_VICE_MEASURE_UNIT_CATEGORY_FAILED,
                        payload: error
                    });
                },
            }
        }
    };
};


export const getMeasureUnitCategoryViceMeasureUnits = (selectedMeasureUnitCategoryID) => {
    return {
        type: MASTER_DATA_GET_MEASURE_UNIT_CATEGORY_VICE_MEASURE_UNIT,
        payload: {
            request: {
                method: 'POST',
                attachAuthToHeader: true,
                url: `/api/company/getMeasureUnitForMeasureUnitCategory`,
                data: JSON.stringify(selectedMeasureUnitCategoryID),
                headers: {
                    showLoading: true
                },

            },
            options: {
                onSuccess({getState, dispatch, response}) {
                    dispatch({
                        type: MASTER_DATA_GET_MEASURE_UNIT_CATEGORY_VICE_MEASURE_UNIT_SUCCESS,
                        payload: response.data.result
                    });
                },
                onError({getState, dispatch, error}) {
                    dispatch({
                        type: MASTER_DATA_GET_MEASURE_UNIT_CATEGORY_VICE_MEASURE_UNIT_FAILED,
                        payload: error
                    });
                },
            }
        }
    };
};
