const Constants = {

    LATE_DATA_INPUT_DEFAULT_TIME: "18:00",

    //10 minutes
    NOTIFICATION_CHECK_TIME: 600000,

    MASTER_DATA: {},

    MASTER_DATA_KEYS: {
        ALL_COMPANIES: 'ALL_COMPANIES',
        ELIGIBLE_COMPANIES_LOAD_OPTIONS: 'ELIGIBLE_COMPANIES_LOAD_OPTIONS',
        USER_ELIGIBLE_COMPANIES: 'USER_ELIGIBLE_COMPANIES',
        LOAD_OPTION_WISE_USER_ELIGIBLE_COMPANIES: 'LOAD_OPTION_WISE_USER_ELIGIBLE_COMPANIES',
        MATH_OPERATORS: 'MATH_OPERATORS',
        PROCESS_FREQUENCIES: 'PROCESS_FREQUENCIES',
        WIDGET_TYPES: 'WIDGET_TYPES',
        WIDGET_RULE_OPERATORS: 'WIDGET_RULE_OPERATORS',
        PRODUCT_CATEGORY_WISE_VARIANTS: 'PRODUCT_CATEGORY_WISE_VARIANTS',
        ALL_FORM_VARIABLES: 'ALL_FORM_VARIABLES',
        WASTE_CATEGORY_WISE_SUBCATEGORIES: 'WASTE_CATEGORY_WISE_SUBCATEGORIES',
        DOWNTIME_CATEGORY_WISE_SUBCATEGORIES: 'DOWNTIME_CATEGORY_WISE_SUBCATEGORIES',
        COMPANY_WISE_FACTORY_WISE_DOWNTIME_CATEGORIES: 'COMPANY_WISE_FACTORY_WISE_DOWNTIME_CATEGORIES',
        COMPANY_WISE_FACTORY_WISE_WASTE_CATEGORIES: 'COMPANY_WISE_FACTORY_WISE_WASTE_CATEGORIES',
    },

    "VAR": {
        "PUBLIC_KEY":
            'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDpBBsjh/8fbZWPR870+LBZMPAs\n' +
            'mwedRN6urKpwqBItbKLtCpKC4UH9mR9cKYov/s5O7sp/U/0b0EDmWXbceeshwOCS\n' +
            '3mSkuOfA37YBE2DB8f6kXd6ceUjg8NseAXEy9UjkzMx+UYUWDfCK0tMhHoDlnBKl\n' +
            'Cljm3AA37P1UL8j0qwIDAQAB',
    },
    "TOAST_MESSAGES": {},

    ACCESS_TOKEN: null,
    REFRESH_TOKEN: null,
    LOGGED_IN_USER: null,

    "STATUS_CONST": {
        "ACT": 'ACT',
        "INA": 'INA',
    },
    "ALL_USERS_STATUS_CONST": {
        "YES": 'Y',
        "NO": 'N',
    },
    BUDGET_INCLUDE: {
        YES: 'Y',
        NO: 'N',
    },
    BUDGET_INCLUDE_VALUES: {
        YES: 'YES',
        NO: 'NO',
    },
    TARGET_INCLUDE: {
        YES: 'Y',
        NO: 'N',
    },
    TARGET_INCLUDE_VALUES: {
        YES: 'YES',
        NO: 'NO',
    },
    WIDGET_DEFAULT_COLOR_CODE: '#2A41C9',
    WIDGET_RULE_DEFAULT_COLOR_CODE: '#FF0000',
    WIDGET_DEFAULT_BUDGET_COLOR_CODE: '#2FD000',
    WIDGET_DEFAULT_TARGET_COLOR_CODE: '#7D7012',
    STATUS: {
        ACT: 'Active',
        INA: 'Inactive'
    },
    DATA_TYPE: {
        STRING: 'String',
        NUMERIC: 'Numeric',
        DECIMAL: 'Decimal',
    },
    DATA_TYPE_LIST: [
        'STRING',
        'NUMERIC',
        'DECIMAL',
    ],
    MEASURE_CATEGORY_LIST: [
        'FACTORY',
        'PRODUCT',
        'PRODUCTION_LINE'
    ],

    MEASURE_CATEGORY_CONST: {
        FACTORY: 'FACTORY',
        PRODUCT: 'PRODUCT',
        PRODUCTION_LINE: 'PRODUCTION_LINE',
    },

    MEASURE_CATEGORY: {
        FACTORY: 'Factory',
        PRODUCT: 'Product',
        PRODUCTION_LINE: 'Production Line',
    },
    TITLE: {
        MR: 'Mr',
        MRS: 'Mrs',
        MS: 'Ms',
        DR: 'Dr'
    },

    MEASURE_TYPE_CONST: {
        DIRECT: 'DIRECT',
        ADVANCE: 'ADVANCE'
    },

    MEASURE_TYPE: {
        DIRECT: 'Direct Input',
        ADVANCE: 'Calculated'
    },
    STORAGE: {
        ACCESS_TOKEN: 'IHZ_AdRftef',
        REFRESH_TOKEN: 'IHZ_ReFr54f',
        LOGGED_USER_ENC: 'IHZ_LOee3sf',
        SELECTED_ROLE_ID: 'IHZ_LPPEffer',
        SELECTED_USER_ID: 'IHZ_LYTFh4f',
        SELECTED_LOCATION_ID: 'IHZ_IUnikl',
        SELECTED_BUSINESS_UNIT_ID: 'IHZ_SIOwqe',
        SELECTED_SUB_BUSINESS_UNIT_ID: 'IHZ_JKIUq823',
        SELECTED_COMPANY_ID: 'IHZ_COMhsg14',
        SELECTED_MEASURE_ID: 'IHZ_sfjFg2hd',
        SELECTED_MEASURE_UNIT_CATEGORY_ID: 'IHZ_MUCgdf24gx',
        SELECTED_MEASURE_GROUP_ID: 'IHZ_iuYSIDa',
        SELECTED_MEASURE_UNIT_ID: 'IHZ_HGF45kF',
        SELECTED_PRODUCT_ID: 'IHZ_PghfJHG',
        SELECTED_SUPERVISOR_ID: 'IHZ_COMhsg32',
        SELECTED_PROCESS_ID: 'IHZ_IJB8iuqw',
        SELECTED_PROCESS: 'IHZ_932LJKHasd',
        SELECTED_PROCESS_TEMPLATE_ID: 'IHZ_JGj3uHU',
        SELECTED_WIDGET_ID: 'IHZ_IUnikl3FR',
        SELECTED_REPORT_TEMPLATE_ID: 'IHZ_IUnikl3PL',
        SELECTED_GENERATE_REPORT_TEMPLATE_ID: 'IHZ_IUnKFl3PL',
        REPORT_GENERATE_REQUEST_HTML_VIEW: 'IHZ_JFNnKFl3PL',
        SELECTED_USER_GROUP_ID: 'IHZ_jgugvv41',
        SELECTED_WASTE_ID: 'IHZ_Wrrredes',
        SELECTED_LABOUR_CLASSIFICATION_ID: 'IHZ_QWERT',
        SELECTED_SHIFT_ID: 'IHZ_SKBUT',
        SELECTED_STANDARD_ID: 'IHZ_HGF5698',
        SELECTED_DOWN_TIME_CAUSE_ID: 'IHZ_QWERTPOIU',
        SELECTED_DATA_FORM_CREATION_ID: 'IHZ_QWBUKYGIU',
        SELECTED_DATA_FORM_ENTRY_ID: 'IHZ_DFER%$%^3',
        SELECTED_PROJECTION_DATA_FORM_CREATION_ID: 'IHZ_QWBUKYGLU',
        SELECTED_PROJECTION_ID: 'IHZ_FCGVYuvyu',
        SAVED_USER_DASHBOARD_DATA: 'IHZ_Pde$@1$$de',
        SELECTED_ACCOUNTING_PERIOD_ID: 'IHZ___ReDE$#2',
        SELECTED_HTML_REPORT_CONTENT: 'IHZ_HTML_REPORT_CONTENT',
        SELECTED_HTML_REPORT_SELECTED_TEMPLATE: 'IHZ_SELECTED_HTML_REPORT_SELECTED_TEMPLATE',
        SELECTED_HTML_REPORT_INCLUDE_STATE: 'IHZ_SELECTED_HTML_REPORT_INCLUDE_STATE',
        USER_SELECTED_LANGUAGE : 'IHZ_eR3Giilgdc'
    },

    ELIGIBLE_USER_TYPES: {
        ALL: 'ALL',
        SELECT_USERS: 'SELECT_USERS',
        ONLY_ME: 'ONLY_ME',
    },
    PAGES: {
        home: '/',
        dashboard: '/dashboard',
        login: '/login',
        forgotPassword: '/forgot-password',
        roles: '/roles',
        roleAddEdit: '/roles/add-edit',
        users: '/users/Users/Users',
        userAddEdit: '/users/add-edit',
        locations: '/factories',
        locationAddEdit: '/factories/add-edit',
        businessUnits: '/businessUnits',
        businessUnitAddEdit: '/businessUnits/add-edit',
        subBusinessUnits: '/subBusinessUnits',
        subBusinessUnitAddEdit: '/subBusinessUnits/add-edit',
        products: '/products',
        productsAddEdit: '/products/add-edit',
        company: '/company',
        companyAddEdit: '/company/add-edit',
        measures: '/performanceMeasures',
        measureAddEdit: '/performanceMeasures/add-edit',
        measureSummery: '/performanceMeasures/summery',
        measureUnitCategory: '/unitCategory',
        measureUnitCategoryAddEdit: '/unitCategory/add-edit',
        measureGroups: '/sitePerformanceSetup',
        measureGroupAddEdit: '/sitePerformanceSetup/add-edit',
        measureUnits: '/units',
        measureUnitsAddEdit: '/units/add-edit',
        supervisorAddEdit: '/supervisors/add-edit',
        processTemplateAddEdit: '/buildDataEntryForms/add-edit',
        processDataEntryForm: '/dataEntryForms/add-edit',
        widgets: '/widgetConfigurations',
        widgetsAddEdit: '/widgetConfigurations/add-edit',
        widgetsSummery: '/widgetConfigurations/summery',
        reportTemplateAddEdit: '/reportTemplate/add-edit',
        reportSummery: '/reportTemplate/summery',
        reportGenerateScreen: '/reportGenerator/generate-screen',
        reportGenerateScreenView: '/reportGenerator/generate-screen/html-view',
        userGroupAddEdit: '/userGroups/add-edit',
        notification: '/notification',
        labourClassification: '/labour-classification',
        wasterAddEdit: '/waste/add-edit',
        labourClassificationAddEdit: '/labour-classification/add-edit',
        shiftAddEdit: '/shifts/add-edit',
        standardAddEdit: '/standards/add-edit',
        downTimeCause: '/down-time',
        downTimeCauseAddEdit: '/down-time/add-edit',
        dataFormCreationAddEdit: '/create-data-form/add-edit',
        dataEntryAddEdit: '/data-entry/add-edit',
        projectionCreationAddEdit: '/projection/add-edit',
        accountingPeriodAddEdit: '/accounting-period/add-edit',
    },

    PRIVILEGE_CODES: {
        IHANZ_SETTINGS_ROLE_VIEW: 'IHANZ.SETTINGS.ROLE.VIEW',
        IHANZ_SETTINGS_USER_VIEW: 'IHANZ.SETTINGS.USER.VIEW',
        IHANZ_SETTINGS_COMPANY_VIEW: 'IHANZ.SETTINGS.COMPANY.VIEW',
        IHANZ_SETTINGS_WEB_AUDIT_VIEW: 'IHANZ.SETTINGS.WEB.AUDIT.VIEW',
        IHANZ_BUSINESS_LOCATION_VIEW: 'IHANZ.BUSINESS.LOCATION.VIEW',
        IHANZ_BUSINESS_BUSINESS_UNIT_VIEW: 'IHANZ.BUSINESS.BUSINESS.UNIT.VIEW',
        IHANZ_BUSINESS_SUB_BUSINESS_UNIT_VIEW: 'IHANZ.BUSINESS.SUB.BUSINESS.UNIT.VIEW',
        IHANZ_BUSINESS_PRODUCT_VIEW: 'IHANZ.BUSINESS.PRODUCT.VIEW',
        IHANZ_BUSINESS_SUPERVISOR_VIEW: 'IHANZ.BUSINESS.SUPERVISOR.VIEW',
        IHANZ_BUSINESS_MEASURE_UNIT_CATEGORY_VIEW: 'IHANZ.BUSINESS.MEASURE.UNIT.CATEGORY.VIEW',
        IHANZ_BUSINESS_MEASURE_UNIT_VIEW: 'IHANZ.BUSINESS.MEASURE.UNIT.VIEW',
        IHANZ_BUSINESS_MEASURE_VIEW: 'IHANZ.BUSINESS.MEASURE.VIEW',
        IHANZ_BUSINESS_MEASURE_GROUP_VIEW: 'IHANZ.BUSINESS.MEASURE.GROUP.VIEW',
        IHANZ_BUSINESS_PROCESS_UPDATE_MEASURE_BUDGETED_VALUE: 'IHANZ.BUSINESS.PROCESS.UPDATE.MEASURE.BUDGETED.VALUE',
        IHANZ_BUSINESS_PROCESS_UPDATE_MEASURE_TARGET_VALUE: 'IHANZ.BUSINESS.PROCESS.UPDATE.MEASURE.TARGET.VALUE',
        IHANZ_BUSINESS_PROCESS_UPDATE_MEASURE_ACTUAL_VALUE: 'IHANZ.BUSINESS.PROCESS.UPDATE.MEASURE.ACTUAL.VALUE',
        IHANZ_BUSINESS_PROCESS_TEMPLATE_VIEW: 'IHANZ.BUSINESS.PROCESS.TEMPLATE.VIEW',
        IHANZ_BUSINESS_PROCESS_VIEW: 'IHANZ.BUSINESS.PROCESS.VIEW',
        IHANZ_SETTINGS_RESET_PASSWORD: 'IHANZ.SETTINGS.RESET.PASSWORD',
        IHANZ_BUSINESS_WIDGET_VIEW: 'IHANZ.BUSINESS.WIDGET.VIEW',
        IHANZ_SETTINGS_USER_GROUP_VIEW: 'IHANZ.SETTINGS.USER.GROUP.VIEW',
        IHANZ_REPORT_TEMPLATE_VIEW: 'IHANZ.REPORT.TEMPLATE.VIEW',
        IHANZ_REPORT_GENERATOR_VIEW: 'IHANZ.REPORT.GENERATOR.VIEW',

        IHANZ_SETTINGS_ROLE_ADD_EDIT: 'IHANZ.SETTINGS.ROLE.ADD.EDIT',
        IHANZ_SETTINGS_COMPANY_ADD_EDIT: 'IHANZ.SETTINGS.COMPANY.ADD.EDIT',
        IHANZ_SETTINGS_USER_ADD_EDIT: 'IHANZ.SETTINGS.USER.ADD.EDIT',
        IHANZ_BUSINESS_LOCATION_ADD_EDIT: 'IHANZ.BUSINESS.LOCATION.ADD.EDIT',
        IHANZ_BUSINESS_BUSINESS_UNIT_ADD_EDIT: 'IHANZ.BUSINESS.BUSINESS.UNIT.ADD.EDIT',
        IHANZ_BUSINESS_SUB_BUSINESS_UNIT_ADD_EDIT: 'IHANZ.BUSINESS.SUB.BUSINESS.UNIT.ADD.EDIT',
        IHANZ_BUSINESS_PRODUCT_ADD_EDIT: 'IHANZ.BUSINESS.PRODUCT.ADD.EDIT',
        IHANZ_BUSINESS_SUPERVISOR_ADD_EDIT: 'IHANZ.BUSINESS.SUPERVISOR.ADD.EDIT',
        IHANZ_BUSINESS_MEASURE_UNIT_CATEGORY_ADD_EDIT: 'IHANZ.BUSINESS.MEASURE.UNIT.CATEGORY.ADD.EDIT',
        IHANZ_BUSINESS_MEASURE_UNIT_ADD_EDIT: 'IHANZ.BUSINESS.MEASURE.UNIT.ADD.EDIT',
        IHANZ_BUSINESS_MEASURE_ADD_EDIT: 'IHANZ.BUSINESS.MEASURE.ADD.EDIT',
        IHANZ_BUSINESS_MEASURE_GROUP_ADD_EDIT: 'IHANZ.BUSINESS.MEASURE.GROUP.ADD.EDIT',
        IHANZ_SETTINGS_WEB_AUDIT_ADD_EDIT: 'IHANZ.SETTINGS.WEB.AUDIT.ADD.EDIT',
        IHANZ_BUSINESS_PROCESS_TEMPLATE_ADD_EDIT: 'IHANZ.BUSINESS.PROCESS.TEMPLATE.ADD.EDIT',
        IHANZ_BUSINESS_PROCESS_ADD_EDIT: 'IHANZ.BUSINESS.PROCESS.ADD.EDIT',
        IHANZ_BUSINESS_PROCESS_ROLLFORWARD: 'IHANZ.BUSINESS.PROCESS.ROLLFORWARD',
        IHANZ_SETTINGS_USER_GROUP_ADD_EDIT: 'IHANZ.SETTINGS.USER.GROUP.ADD.EDIT',
        IHANZ_REPORT_TEMPLATE_ADD_EDIT: 'IHANZ.REPORT.TEMPLATE.ADD.EDIT',
        IHANZ_REPORT_GENERATOR_REPORT: 'IHANZ.REPORT.GENERATOR.REPORT',
        IHANZ_BUSINESS_WIDGET_ADD_EDIT: 'IHANZ.BUSINESS.WIDGET.ADD.EDIT',
        IHANZ_BUSINESS_PROCESS_RE_CALCULATE_VALUES: 'IHANZ.BUSINESS.PROCESS.RE.CALCULATE.VALUES',

        IHANZ_REPORT_SCHEDULER_ADD_EDIT: 'IHANZ.REPORT.SCHEDULER.ADD.EDIT',

        IHANZ_DASHBOARD_ADD_EDIT: 'IHANZ.DASHBOARD.ADD.EDIT',
        IHANZ_DASHBOARD_VIEW: 'IHANZ.DASHBOARD.VIEW',

        IHANZ_CAUSES_WASTE_VIEW: 'IHANZ.CAUSES.WASTE.VIEW',
        IHANZ_CAUSES_WASTE_ADD_EDIT: 'IHANZ.CAUSES.WASTE.ADD.EDIT',

        IHANZ_CAUSES_DOWN_VIEW: 'IHANZ.CAUSES.DOWN.VIEW',
        IHANZ_CAUSES_DOWN_ADD_EDIT: 'IHANZ.CAUSES.DOWN.ADD.EDIT',

        IHANZ_BUSINESS_PROCESS_UPLOAD_DATA_ENTRY_FORM_OVERRIDE_PREVIOUS_DATA: 'IHANZ.BUSINESS.PROCESS.UPLOAD.DATA.ENTRY.FORM.OVERRIDE.PREVIOUS.DATA',
        IHANZ_BUSINESS_PROCESS_UPLOAD_DATA_ENTRY_FORM: 'IHANZ.BUSINESS.PROCESS.UPLOAD.DATA.ENTRY.FORM',

        IHANZ_ADMIN_CONFIGURATION_ALLOW_ALL_COMPANIES_FOR_USER: 'IHANZ.ADMIN.CONFIGURATION.ALLOW.ALL.COMPANIES.FOR.USER',
        IHANZ_ADMIN_CONFIGURATION_ALLOW_ALL_COMPANY_LOCATIONS_FOR_USER: 'IHANZ.ADMIN.CONFIGURATION.ALLOW.ALL.COMPANY.LOCATIONS.FOR.USER',
        IHANZ_ADMIN_CONFIGURATION_ALLOW_ALL_COMPANY_BUSINESS_UNIT_LOCATIONS_FOR_USER: 'IHANZ.ADMIN.CONFIGURATION.ALLOW.ALL.COMPANY.BUSINESS.UNIT.LOCATIONS.FOR.USER',
        IHANZ_ADMIN_CONFIGURATION_ALLOW_ALL_COMPANY_SUB_BUSINESS_UNITS_FOR_USER: 'IHANZ.ADMIN.CONFIGURATION.ALLOW.ALL.COMPANY.SUB.BUSINESS.UNITS.FOR.USER',

        IHANZ_LABOUR_CLASSIFICATION_VIEW: 'IHANZ.LABOUR.CLASSIFICATION.VIEW',
        IHANZ_LABOUR_CLASSIFICATION_ADD_EDIT: 'IHANZ.LABOUR.CLASSIFICATION.ADD.EDIT',

        IHANZ_NOTIFICATIONS_VIEW: 'IHANZ.NOTIFICATION.VIEW',

        IHANZ_STANDARDS_VIEW: 'IHANZ.STANDARDS.VIEW',
        IHANZ_STANDARDS_ADD_EDIT: 'IHANZ.STANDARDS.ADD.EDIT',

        IHANZ_BUSINESS_ACCOUNTING_PERIOD_VIEW: 'IHANZ.BUSINESS.ACCOUNTING.PERIOD.VIEW',
        IHANZ_BUSINESS_ACCOUNTING_PERIOD_ADD_EDIT: 'IHANZ.BUSINESS.ACCOUNTING.PERIOD.ADD.EDIT',

        IHANZ_SHIFTS_VIEW: 'IHANZ.SHIFTS.VIEW',
        IHANZ_SHIFTS_ADD_EDIT: 'IHANZ.SHIFTS.ADD.EDIT',

        IHANZ_PROJECTIONS_VIEW: 'IHANZ.PROJECTIONS.VIEW',
        IHANZ_PROJECTIONS_ADD_EDIT: 'IHANZ.PROJECTIONS.ADD.EDIT',

    },
    DAYS_OF_WEEK: [
        {label: "Sunday", value: 'Su'},
        {label: "Monday", value: 'Mo'},
        {label: "Tuesday", value: 'Tu'},
        {label: "Wednesday", value: 'We'},
        {label: "Thursday", value: 'Th'},
        {label: "Friday", value: 'Fr'},
        {label: "Saturday", value: 'Sa'},
    ],
    DAYS_OF_WEEK_CONST: {
        Su: "Sunday",
        Mo: "Monday",
        Tu: "Tuesday",
        We: "Wednesday",
        Th: "Thursday",
        Fr: "Friday",
        Sa: "Saturday"
    },
    DATE_TIME_FORMAT: "DD/MM/YYYY HH:mm:ss",
    DATE_TIME_FORMAT_NO_SECONDS: "DD/MM/YYYY HH:mm",
    DATE_FORMAT: "DD/MM/YYYY",
    TIME_FORMAT: "HH:mm:ss",
    TIME_FORMAT_NO_SECONDS: "HH:mm",
    YEAR_FORMAT: "YYYY",

    WIDGET_TYPES_CONST: {
        BAR: "BAR_CHART",
        AREA: "AREA_CHART",
        LINE: "LINE_CHART",
        PIE: "PIE_CHART",
        TABLE: "TABLE_CHART",
        LINE_BAR: "LINE_BAR_CHART",
        AREA_BAR: "AREA_BAR_CHART",
        SCATTER: "SCATTER_CHART",
        DOWNTIME: "DOWNTIME_REPORT",
        WASTE: "WASTE_REPORT",
        GIVEAWAY: "GIVEAWAY_REPORT"
    },

    TREND_LINE_AVERAGE_TYPE: {
        TRAILING: "TRAILING",
        CENTERED: "CENTERED",
    },
    TREND_LINE_TYPE: {
        MOVING_AVERAGE: "MOVING_AVERAGE",
        LEANER: "LEANER"
    },

    TREND_LINE_TYPE_ARRAY: [
        'MOVING_AVERAGE',
        'LEANER',
    ],
    TREND_LINE_TYPE_NAMES: {
        MOVING_AVERAGE: "Moving Average",
        LEANER: "Linear"
    },
    TREND_LINE_AVERAGE_TYPE_ARRAY: [
        'TRAILING',
        'CENTERED',
    ],
    TREND_LINE_AVERAGE_TYPE_NAMES: {
        TRAILING: "Trailing",
        CENTERED: "Centered"
    },

    WIDGET_MEASURE_COMPARISON_VALUE_TYPE_CONST: {
        TARGET: 'TARGET',
        BUDGET: 'BUDGET',
        CUSTOM_VALUE: 'CUSTOM_VALUE',
        MEASURE: 'MEASURE',
    },

    WIDGET_MEASURE_COMPARISON_VALUE_TYPE: {
        TARGET: 'Target Value',
        BUDGET: 'Budget Value',
        CUSTOM_VALUE: 'Custom Value',
        MEASURE: 'Performance Measure',
    },

    WIDGET_MEASURE_COMPARISON_VALUE_TYPE_DESC: {
        TARGET: 'Compared against the measure target value',
        BUDGET: 'Compared against the measure budget value',
        CUSTOM_VALUE: 'Compared against the custom value ',
        MEASURE: 'Compared against a measure',
    },

    WIDGET_USER_TYPE: {
        ALL: 'All users',
        SELECTED_USERS: 'Selected Users',
        ONLY_MY: 'Only me'
    },

    REPORT_USER_TYPE: {
        ALL: 'All users',
        SELECTED_USERS: 'Selected Users',
        ONLY_MY: 'Only me'
    },

    REPORT_MEASURE_COMPARISON_VALUE_TYPE_CONST: {
        TARGET: 'TARGET',
        BUDGET: 'BUDGET',
        CUSTOM_VALUE: 'CUSTOM_VALUE',
        MEASURE: 'MEASURE',
    },

    REPORT_MEASURE_COMPARISON_VALUE_TYPE: {
        TARGET: 'Target Value',
        BUDGET: 'Budget Value',
        CUSTOM_VALUE: 'Custom Value',
        MEASURE: 'Performance Measure',
    },

    REPORT_MEASURE_COMPARISON_VALUE_TYPE_DESC: {
        TARGET: 'Compared against the measure target value',
        BUDGET: 'Compared against the measure budget value',
        CUSTOM_VALUE: 'Compared against the custom value ',
        MEASURE: 'Compared against a measure',
    },

    ACCESS_LEVEL_CONST: {
        VIEW: 'View',
        EDIT: 'Edit'
    },
    ACCESS_LEVEL_CONST_ARRAY: [
        'VIEW',
        'EDIT'
    ],

    REPORT_OUTPUT_TYPE: {
        HTML: 'HTML',
        XLS: 'XLS',
        PDF: 'PDF'
    },

    REPORT_EXPORT_TYPE: {
        VIEW: 'VIEW',
        DOWNLOAD: 'DOWNLOAD',
        EMAIL: 'EMAIL'
    },

    Report_Export_Format_CONST: {
        HTML: "HTML",
        PDF_SHOW: "PDF_SHOW",
        PDF_SHOW_INLINE: "PDF_SHOW_INLINE",
        PDF_DOWNLOAD: "PDF_DOWNLOAD",
        PDF_ZIP_EMAIL: "PDF_ZIP_EMAIL",
        CSV: "CSV",
        CSV_ZIP_EMAIL: "CSV_ZIP_EMAIL",
        EMAIL_REPORT: "EMAIL_REPORT",
        XLS: "XLS",
        XLS_ZIP_EMAIL: "XLS_ZIP_EMAIL"
    },

    REPORT_AGGREGATE_TYPE: {
        SUMMATION: "Summation",
        AVERAGE: "Average"
    },

    REPORT_AGGREGATE_TYPE_ARRAY: [
        "SUMMATION",
        "AVERAGE"
    ],

    REPORT_AGGREGATE_TYPE_CONST: {
        SUMMATION: "SUMMATION",
        AVERAGE: "AVERAGE"
    },

    NOTIFICATION_CODES: {
        PAST_DATA_MISSING: "PAST_DATA_MISSING",
        LATE_DATA_INPUT: "LATE_DATA_INPUT"
    },
    FREQUENCY_TYPE: {
        HOURLY: "HOURLY",
        DAILY: "DAILY",
        WEEKLY: "WEEKLY",
        MONTHLY: "MONTHLY",
        ANNUALLY: "ANNUALLY"
    },
    NOTIFICATION_STATUS: {
        PENDING: "PENDING",
        ACKNOWLEDGE: "ACKNOWLEDGE"
    },
    NOTIFICATION_STATUS_MAPPING: {
        PENDING: "Unseen",
        ACKNOWLEDGE: "Seen"
    },
    NOTIFICATION_STATUS_ARRAY: [
        "PENDING",
        "ACKNOWLEDGE"
    ],
    NOTIFICATION_NAME: {
        PAST_DATA_MISSING: "Past data missing",
        LATE_DATA_INPUT: "Late data input"
    },
    NOTIFICATION_NAME_ARRAY: [
        "PAST_DATA_MISSING",
        "LATE_DATA_INPUT"
    ],
    PASSWORD_UPDATE_ACTION: {
        UPDATE: "UPDATE",
        RESET: "RESET",
        FORGET: "FORGET"
    },
    COMPANY_TYPE: {
        Y: 'Parent',
        N: 'Subsidiary'
    },
    COMPANY_TYPE_CONST: {
        "Y": 'Y',
        "N": 'N'
    },
    USER_MULTI_SELECT_MAPPING: {
        COMPANIES: "companies",
        FACTORIES: "locations",
        DEPARTMENTS: "businessUnitLocations",
        PRODUCTION_LINES: "subBusinessUnits",
        DATA_ENTRY_FORMS: "dataEntryForms",
    },

    AUTO_SUGGEST_THEME: {
        container: 'react-autosuggest__container',
        containerOpen: 'react-autosuggest__container--open',
        input: 'react-autosuggest__input',
        inputOpen: 'react-autosuggest__input--open',
        inputFocused: 'react-autosuggest__input--focused',
        suggestionsContainer: 'react-autosuggest__suggestions-container',
        suggestionsContainerOpen: 'react-autosuggest__suggestions-container--open',
        suggestionsList: 'react-autosuggest__suggestions-list',
        suggestion: 'react-autosuggest__suggestion',
        suggestionFirst: 'react-autosuggest__suggestion--first',
        suggestionHighlighted: 'react-autosuggest__suggestion--highlighted',
        sectionContainer: 'react-autosuggest__section-container',
        sectionContainerFirst: 'react-autosuggest__section-container--first',
        sectionTitle: 'react-autosuggest__section-title'
    },
    NEW_MAPPINGS: {
        FACTORY: "location",
        PRODUCTION_LINE: "subBusinessUnit"
    },
    REPORT_CATEGORY_ARRAY: [
        'FACTORY',
        'PRODUCT',
        'PRODUCTION_LINE',
    ],

    REPORT_CATEGORY_CONST: {
        FACTORY: 'FACTORY',
        PRODUCT: 'PRODUCT',
        PRODUCTION_LINE: 'PRODUCTION_LINE',
    },

    REPORT_CATEGORY_NAMES: {
        FACTORY: 'Factory',
        PRODUCT: 'Product',
        PRODUCTION_LINE: 'Production Line',
    },

    REPORT_TYPE_CONST: {
        DWOR_REPORT: 'DWOR_REPORT',
        DWOR_SHIFT_REPORT: 'DWOR_SHIFT_REPORT',
        KPI_REPORT: 'KPI_REPORT',
    },

    REPORT_TYPE_ARRAY: [
        'DWOR_REPORT',
        'DWOR_SHIFT_REPORT',
        'KPI_REPORT',
    ],

    REPORT_TYPE_NAMES: {
        DWOR_REPORT: 'DWOR Report',
        DWOR_SHIFT_REPORT: 'DWOR Shift Report',
        KPI_REPORT: 'KPI Report',
    },

    REPORT_TYPE_NAMES_REPORT: {
        DWOR_REPORT: 'DWOR',
        DWOR_SHIFT_REPORT: 'DWOR Shift',
        KPI_REPORT: 'KPI',
    },

    KPI_REPORT_PERIOD_TYPE_ARRAY: [
        'WEEK',
        'PERIOD',
        'YEAR'
    ],

    KPI_REPORT_PERIOD_TYPE_NAMES: {
        YEAR: 'Year',
        PERIOD: 'Period',
        WEEK: 'Week',
    },

    KPI_REPORT_PERIOD_TYPE: {
        YEAR: 'YEAR',
        PERIOD: 'PERIOD',
        WEEK: 'WEEK',
    },

    KPI_REPORT_BEST_EVER_TYPE_ARRAY: [
        'MAXIMUM',
        'MINIMUM',
    ],
    KPI_REPORT_BEST_EVER_TYPE_NAMES: {
        MAXIMUM: 'Maximum',
        MINIMUM: 'Minimum',
    },
    KPI_REPORT_BEST_EVER_TYPE: {
        MAXIMUM: 'MAXIMUM',
        MINIMUM: 'MINIMUM',
    },

    FORM_VARIABLES_CONST: {
        START_TIME: 'START_TIME',
        DURATION: 'DURATION',
        END_TIME: 'END_TIME',
        PRODUCT: 'PRODUCT',
        SHIFT: 'SHIFT',
        PRODUCTION_LINE: 'PRODUCTION_LINE',
        TEAM_LEAD: 'TEAM_LEAD',
        VARIANT: 'VARIANT',
        DOWNTIME_CAUSE: 'DOWNTIME_CAUSE',
        WASTE_CAUSE: 'WASTE_CAUSE',
    },
    WIDGET_CATEGORY_ARRAY: [
        'NORMAL',
        'DOWNTIME_REPORT',
        'WASTAGE_REPORT',
    ],
    WIDGET_CATEGORY_NAMES: {
        NORMAL: 'Normal',
        DOWNTIME_REPORT: 'Downtime Report',
        WASTAGE_REPORT: 'Wastage Report',
    },
    WIDGET_CATEGORY_CONST: {
        NORMAL: 'NORMAL',
        DOWNTIME_REPORT: 'DOWNTIME_REPORT',
        WASTAGE_REPORT: 'WASTAGE_REPORT',
    },
    CUSTOM_WIDGET_VIEW_MODE_CONST: {
        ITEM_CATEGORY_BREAKDOWN_WITH_DATE_AND_CATEGORY: 'ITEM_CATEGORY_BREAKDOWN_WITH_DATE_AND_CATEGORY',
        ITEM_CATEGORY_BREAKDOWN_WITH_DATE: 'ITEM_CATEGORY_BREAKDOWN_WITH_DATE',
        ITEM_CATEGORY_BREAKDOWN: 'ITEM_CATEGORY_BREAKDOWN',
        ITEM_SUB_CATEGORY_BREAKDOWN: 'ITEM_SUB_CATEGORY_BREAKDOWN',
        ITEM_CATEGORY_BREAKDOWN_FOR_TOTAL: 'ITEM_CATEGORY_BREAKDOWN_FOR_TOTAL',
    },
    CUSTOM_WIDGET_VIEW_MODE_ARRAY: [
        'ITEM_CATEGORY_BREAKDOWN_WITH_DATE_AND_CATEGORY',
        'ITEM_CATEGORY_BREAKDOWN_WITH_DATE',
        'ITEM_CATEGORY_BREAKDOWN',
        'ITEM_SUB_CATEGORY_BREAKDOWN',
        'ITEM_CATEGORY_BREAKDOWN_FOR_TOTAL',
    ],
    CUSTOM_WIDGET_VIEW_MODE_NAMES: {
        ITEM_CATEGORY_BREAKDOWN_WITH_DATE_AND_CATEGORY: 'Date and Category View',
        ITEM_CATEGORY_BREAKDOWN_WITH_DATE: 'Date View',
        ITEM_CATEGORY_BREAKDOWN: 'Category View',
        ITEM_SUB_CATEGORY_BREAKDOWN: 'Sub Category View',
        ITEM_CATEGORY_BREAKDOWN_FOR_TOTAL: 'Total View',
    },

    BE_WIDGET_VIEW_MODES: {
        DATE_VIEW: 'DATE_VIEW',
        DATE_CATEGORY_VIEW: 'DATE_CATEGORY_VIEW',
        DATE_SUB_CATEGORY_VIEW: 'DATE_SUB_CATEGORY_VIEW',
        CATEGORY_VIEW: 'CATEGORY_VIEW',
        SUB_CATEGORY_VIEW: 'SUB_CATEGORY_VIEW',
    },

    DOWNTIME_WIDGET_FILTERS_ARRAY: [
        'VIEW_MODE',
        'FROM_DATE',
        'TO_DATE',
        'SHOW_TARGET',
        'SHOW_BUDGET',
        'XAXIS',
        'STACKED'
    ],
    DOWNTIME_WIDGET_FILTERS_CONST: {
        VIEW_MODE: 'VIEW_MODE',
        FROM_DATE: 'FROM_DATE',
        TO_DATE: 'TO_DATE',
        SHOW_TARGET: 'SHOW_TARGET',
        SHOW_BUDGET: 'SHOW_BUDGET',
        XAXIS: 'XAXIS',
        STACKED: 'STACKED',
    },

    DOWNTIME_WIDGET_FILTERS_NAME: {
        VIEW_MODE: 'View Mode',
        FROM_DATE: 'From Date',
        TO_DATE: 'To Date',
        SHOW_TARGET: 'Show Target',
        SHOW_BUDGET: 'Show Budget',
        XAXIS: 'X Axis Filter',
        STACKED: 'Stacked Chart',
    },

    DOWNTIME_WIDGET_XAXIS_ARRAY: [
        'downTimeWastageCategory',
        'downTimeWastageSubCategory',
    ],
    DOWNTIME_WIDGET_XAXIS_CONST: {
        downTimeWastageCategory: 'downTimeWastageCategory',
        downTimeWastageSubCategory: 'downTimeWastageSubCategory',
    },

    DOWNTIME_WIDGET_XAXIS_NAME: {
        downTimeWastageCategory: 'Down Time Category',
        downTimeWastageSubCategory: 'Down Time Sub Category',
    },

    YES_NO_CONST: {
        YES: "YES",
        NO: "NO",
    },

    YES_NO_NAME: {
        YES: "Yes",
        NO: "No",
    },

    YES_NO_ARRAY: [
        'YES',
        'NO',
    ],

    WIDGET_CUSTOM_FILTER_ARRAY: [
        'SHOW_POINT_VALUE',
        'SHOW_X_AXIS_LINE_PRODUCT',
        'SHOW_X_AXIS_LINE_PRODUCT_SEPARATOR',
        'SHOW_X_AXIS_FREQUENCY',
        'SHOW_X_AXIS_FREQUENCY_SEPARATOR',
    ],

    WIDGET_CUSTOM_FILTER_CONST: {
        SHOW_POINT_VALUE: 'SHOW_POINT_VALUE',
        SHOW_X_AXIS_LINE_PRODUCT: 'SHOW_X_AXIS_LINE_PRODUCT',
        SHOW_X_AXIS_LINE_PRODUCT_SEPARATOR: 'SHOW_X_AXIS_LINE_PRODUCT_SEPARATOR',
        SHOW_X_AXIS_FREQUENCY: 'SHOW_X_AXIS_FREQUENCY',
        SHOW_X_AXIS_FREQUENCY_SEPARATOR: 'SHOW_X_AXIS_FREQUENCY_SEPARATOR',
    },

    WIDGET_CUSTOM_FILTER_NAME: {
        SHOW_POINT_VALUE: 'Show Point Value',
        SHOW_X_AXIS_LINE_PRODUCT: 'Show X Axis Line / Product',
        SHOW_X_AXIS_LINE_PRODUCT_SEPARATOR: 'Show X Axis Line / Product Separator',
        SHOW_X_AXIS_FREQUENCY: 'Show X Axis Frequency',
        SHOW_X_AXIS_FREQUENCY_SEPARATOR: 'Show X Axis Frequency Separator',
    },

    STANDARD_UPLOAD_TYPE_CONST: {
        STANDARDS: 'STANDARDS',
        STANDARDS_VALUES: 'STANDARDS_VALUES',
    },
    MEASURE_LEVEL_CONST: {
        LEVEL0: 'LEVEL0',
        LEVEL1: 'LEVEL1',
        LEVEL2: 'LEVEL2',
        LEVEL3: 'LEVEL3',
        LEVEL4: 'LEVEL4',
        LEVEL5: 'LEVEL5',
        LEVEL6: 'LEVEL6',
        LEVEL7: 'LEVEL7',
    },

    MEASURE_LEVEL_VALUE: {
        LEVEL0: 0,
        LEVEL1: 1,
        LEVEL2: 2,
        LEVEL3: 3,
        LEVEL4: 4,
        LEVEL5: 5,
        LEVEL6: 6,
        LEVEL7: 7,
    },

    HOT_KEY_CONST: {
        DOWNTIME: "alt+shift+d",
        WASTE: "alt+shift+w"
    },

    LANGUAGE : {
        ENGLISH : 'en',
        GERMAN : 'de',
    },

    LANGUAGE_WORD : {
        en : 'English',
        de : 'German',
    },
};

export default Constants;
