import {
    LABOUR_CLASSIFICATION_ADD_EMPLOYEE_COUNTS_MODAL_CLOSE,
    LABOUR_CLASSIFICATION_ADD_EMPLOYEE_COUNTS_MODAL_OPEN,
    LABOUR_CLASSIFICATION_ADD_NEW_LABOUR_CLASSIFICATION,
    LABOUR_CLASSIFICATION_GET_SELECTED_LABOUR_CLASSIFICATION,
    LABOUR_CLASSIFICATION_GET_SELECTED_LABOUR_CLASSIFICATION_SUCCESS, LABOUR_CLASSIFICATION_RESET_DATA,
    LABOUR_CLASSIFICATION_RESET_SELECTED_LABOUR_CLASSIFICATION,
    LABOUR_CLASSIFICATION_SEARCH,
    LABOUR_CLASSIFICATION_SEARCH_SUCCESS,
    SAVE_UPDATE_LABOUR_CLASSIFICATION_SUCCESS,
    SELECTED_LABOUR_CLASSIFICATION_SEARCH,
    SELECTED_LABOUR_CLASSIFICATION_SEARCH_SUCCESS
} from "../actions/types";
import {getPagedData} from "../../utils/DataExtractHelper";

const initialState = {
    pageInfo: {},
    labourClassifications: [],
    selectedPageInfo: {},
    selectedLabourClassificationsPageData: [],
    selectedLabourClassifications: {},
    savedEmployeeDetail: {},
    showEmployeeCountAddModal: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LABOUR_CLASSIFICATION_SEARCH:
            return {
                ...state
            };
        case LABOUR_CLASSIFICATION_SEARCH_SUCCESS:
            return {
                ...state,
                labourClassifications: action.payload.pageData,
                pageInfo: getPagedData(action.payload)
            };
        case SELECTED_LABOUR_CLASSIFICATION_SEARCH:
            return {
                ...state
            };
        case SELECTED_LABOUR_CLASSIFICATION_SEARCH_SUCCESS:
            return {
                ...state,
                selectedLabourClassificationsPageData: action.payload.pageData,
                selectedPageInfo: getPagedData(action.payload)
            };
        case LABOUR_CLASSIFICATION_ADD_NEW_LABOUR_CLASSIFICATION:
            return {
                ...state,
                selectedLabourClassifications: action.payload
            };
        case LABOUR_CLASSIFICATION_GET_SELECTED_LABOUR_CLASSIFICATION:
            return {
                ...state
            };

        case LABOUR_CLASSIFICATION_GET_SELECTED_LABOUR_CLASSIFICATION_SUCCESS:
            return {
                ...state,
                selectedLabourClassifications: action.payload
            };
        case  SAVE_UPDATE_LABOUR_CLASSIFICATION_SUCCESS:
            return {
                ...state,
                selectedLabourClassifications: action.payload
                // savedEmployeeDetail:action.payload

            };
        case LABOUR_CLASSIFICATION_ADD_EMPLOYEE_COUNTS_MODAL_OPEN:
            return {
                ...state,
                showEmployeeCountAddModal: true
            };
        case LABOUR_CLASSIFICATION_ADD_EMPLOYEE_COUNTS_MODAL_CLOSE:
            return {
                ...state,
                showEmployeeCountAddModal: false
            };
        case LABOUR_CLASSIFICATION_RESET_SELECTED_LABOUR_CLASSIFICATION:
            return {
                ...state,
                selectedLabourClassifications: action.payload
            };
        case LABOUR_CLASSIFICATION_RESET_DATA:
            return {
                ...state,
                selectedPageInfo: {},
                selectedLabourClassificationsPageData: [],
                selectedLabourClassifications: {},
            };

        default:
            return state;
    }
}
