import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {restoreLoginState} from '../../redux/actions/AuthAction';
import {setSelectedLanguage} from '../../redux/actions/MasterDataAction';
import {loadingSubs} from "../../utils/LoaderHelper";
import LoadingComponent from "../../views/common/LoadingComponent";
import {getI18n} from 'react-i18next';
import Constants from "../../utils/Constants";
import {decrypt, encryptNumber} from "../../utils/EncryptionHelper";

class IhanzLayout extends Component {

    state = {
        showLoading: false
    };

    componentDidMount() {
        this.props.restoreLoginState();
        loadingSubs(this.onLoadingChange);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.user != prevProps.user) {
            const {setSelectedLanguage} = this.props;
            let selectedLngDec = null;
            let selectedLng = localStorage.getItem(Constants.STORAGE.USER_SELECTED_LANGUAGE);
            if (selectedLng && selectedLng != "null") {
                selectedLngDec = decrypt(selectedLng);
            }

            if (!selectedLngDec) {
                if (this.props.user) {
                    selectedLngDec = this.props.user.preferredLanguage;
                    localStorage.setItem(Constants.STORAGE.USER_SELECTED_LANGUAGE, encryptNumber(selectedLngDec));
                } else {
                    selectedLngDec = Constants.LANGUAGE.ENGLISH;
                }
            }

            getI18n().changeLanguage(selectedLngDec);
            setSelectedLanguage(selectedLngDec);
        }
    }

    onLoadingChange = (isLoading) => {
        this.setState({
            showLoading: isLoading
        });
    };

    render() {
        return (
            <Fragment>
                {
                    this.state.showLoading && (
                        <LoadingComponent/>
                    )
                }
                {this.props.children}
                {/*<WebSocketNotifier auth={this.props.auth}/>*/}
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    user: state.auth.user
});

const mapDispatchToProps = {
    restoreLoginState,
    setSelectedLanguage
};

export default connect(mapStateToProps, mapDispatchToProps)(IhanzLayout);
