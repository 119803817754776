import jwt from 'jsonwebtoken';

export const isUserLoggedIn = (accessToken, refreshToken) => {

    if (!accessToken || !refreshToken) {
        return false;
    }

    const accessTokenExpiry = jwt.decode(accessToken).exp;
    const refreshTokenExpiry = jwt.decode(refreshToken).exp;
    const now = new Date();
    return (now.getTime() < accessTokenExpiry * 1000)
        || (now.getTime() < refreshTokenExpiry * 1000);
};
