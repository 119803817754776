import {
    SAVE_UPDATE_SUB_BU_SUCCESS,
    SUB_BU_ADD_EDIT_SELECT_SUPERVISOR_MODAL_CLOSE,
    SUB_BU_ADD_EDIT_SELECT_SUPERVISOR_MODAL_OPEN,
    SUB_BU_ADD_EDIT_UPDATE_SELECTED_SUPERVISOR,
    SUB_BU_ADD_EDIT_UPDATE_TMP_SELECTED_SUPERVISORS,
    SUB_BU_ADD_NEW_SUB_BU,
    SUB_BU_GET_SELECTED_SUB_BU,
    SUB_BU_GET_SELECTED_SUB_BU_SUCCESS,
    SUB_BU_RESET_SELECTED_SUB_BU,
    SUB_BU_SEARCH,
    SUB_BU_SEARCH_SUCCESS,
    SUB_BU_ADD_EDIT_REMOVE_TMP_SELECTED_SUPERVISORS
} from '../actions/types';
import {getPagedData} from '../../utils/DataExtractHelper';


const initialState = {
    pageInfo: {},
    subBusinessUnits: [],
    selectedSubBusinessUnit: {},
    selectedSupervisors: [],
    selectedSupervisorsTmp: [],
    showAddSupervisorModal: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SUB_BU_SEARCH :
            return {
                ...state
            };
        case SUB_BU_SEARCH_SUCCESS :
            return {
                ...state,
                subBusinessUnits: action.payload.pageData,
                pageInfo: getPagedData(action.payload)
            };

        case SUB_BU_GET_SELECTED_SUB_BU :
            return {
                ...state,
                selectedSupervisors: [],
            };

        case SUB_BU_GET_SELECTED_SUB_BU_SUCCESS :
        case SAVE_UPDATE_SUB_BU_SUCCESS :
            return {
                ...state,
                selectedSubBusinessUnit: action.payload,
                selectedSupervisors: action.payload.supervisorDTOs
            };
        case SUB_BU_RESET_SELECTED_SUB_BU :
        case SUB_BU_ADD_NEW_SUB_BU :
            return {
                ...state,
                selectedSubBusinessUnit: action.payload,
                selectedSupervisors: [],
            };
        case SUB_BU_ADD_EDIT_UPDATE_SELECTED_SUPERVISOR:
            return {
                ...state,
                selectedSupervisors: action.payload
            };

        case SUB_BU_ADD_EDIT_SELECT_SUPERVISOR_MODAL_OPEN:
            return {
                ...state,
                showAddSupervisorModal: true
            };

        case SUB_BU_ADD_EDIT_SELECT_SUPERVISOR_MODAL_CLOSE :
            return {
                ...state,
                showAddSupervisorModal: false
            };

        case SUB_BU_ADD_EDIT_UPDATE_TMP_SELECTED_SUPERVISORS :
            return {
                ...state,
                selectedSupervisorsTmp: action.payload
            };
        case SUB_BU_ADD_EDIT_REMOVE_TMP_SELECTED_SUPERVISORS :
            return {
                ...state,
                selectedSupervisors: [],
                selectedSupervisorsTmp: []
            };
        default :
            return state;
    }
}
