import {
    ROLE_ADD_NEW_ROLE,
    ROLE_GET_SELECTED_ROLE,
    ROLE_GET_SELECTED_ROLE_SUCCESS,
    ROLE_SEARCH,
    ROLE_SEARCH_SUCCESS,
    SAVE_UPDATE_ROLE_SUCCESS
} from '../actions/types';
import {getPagedData} from '../../utils/DataExtractHelper';


const initialState = {
    pageInfo: {},
    roles: [],
    selectedRole: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ROLE_SEARCH :
            return {
                ...state
            };

        case ROLE_SEARCH_SUCCESS :
            return {
                ...state,
                roles: action.payload.pageData,
                pageInfo: getPagedData(action.payload)
            };

        case ROLE_GET_SELECTED_ROLE :
            return {
                ...state
            };

        case ROLE_GET_SELECTED_ROLE_SUCCESS :
        case SAVE_UPDATE_ROLE_SUCCESS :
            return {
                ...state,
                selectedRole: action.payload
            };

        case ROLE_ADD_NEW_ROLE :
            return {
                ...state,
                selectedRole: action.payload
            };

        default :
            return state;
    }
}
