import {
    ACCOUNTING_PERIOD_BULK_UPLOAD_SUCCESS,
    ACCOUNTING_PERIOD_GET_SELECTED_ACCOUNTING_PERIOD_SUCCESS,
    ACCOUNTING_PERIOD_SAVE_BASE_SUCCESS,
    ACCOUNTING_PERIOD_SEARCH_ACCOUNTING_PERIODS,
    ACCOUNTING_PERIOD_SEARCH_ACCOUNTING_PERIODS_SUCCESS,
    ACCOUNTING_PERIOD_SET_SELECTED_PERIOD,
    ACCOUNTING_PERIOD_SET_SELECTED_WEEK,
    ACCOUNTING_PERIOD_TOGGLE_WEEK_ADD_EDIT
} from "../actions/types";
import {getPagedData} from "../../utils/DataExtractHelper";


const initialState = {
    accountingPageInfo: {},
    accountingPeriods: [],

    accountPeriodDTO: {},
    selectedWeek: {},
    showWeekAddEdit: false,

    uploadResult : {},
};

export default (state = initialState, action) => {
    switch (action.type) {

        case ACCOUNTING_PERIOD_SEARCH_ACCOUNTING_PERIODS_SUCCESS : {
            return {
                ...state,
                accountingPeriods: action.payload.pageData,
                accountingPageInfo: getPagedData(action.payload)
            }
        }

        case ACCOUNTING_PERIOD_SAVE_BASE_SUCCESS : {
            return {
                ...state,
                accountPeriodDTO: action.payload
            };
        }

        case ACCOUNTING_PERIOD_TOGGLE_WEEK_ADD_EDIT : {
            return {
                ...state,
                showWeekAddEdit: action.payload
            }
        }

        case ACCOUNTING_PERIOD_GET_SELECTED_ACCOUNTING_PERIOD_SUCCESS : {
            return {
                ...state,
                accountPeriodDTO: action.payload
            };
        }

        case ACCOUNTING_PERIOD_SET_SELECTED_PERIOD : {
            return {
                ...state,
                accountPeriodDTO: action.payload
            }
        }

        case ACCOUNTING_PERIOD_SET_SELECTED_WEEK : {
            return {
                ...state,
                selectedWeek: action.payload
            }
        }

        case ACCOUNTING_PERIOD_BULK_UPLOAD_SUCCESS : {
            return {
                ...state,
                uploadResult: action.payload
            };
        }

        default : {
            return {
                ...state
            };
        }
    }
}
