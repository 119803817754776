import * as _ from 'lodash';
import {Constants} from "./index";

export const hasAnyPrivilege = (privileges) => {
    if (!privileges) {
        return false;
    }

    let loggedInUser = Constants.LOGGED_IN_USER;

    if (!loggedInUser) {
        return false;
    }

    for (let i = 0; i < loggedInUser.privileges.length; i++) {
        if (_.indexOf(privileges, loggedInUser.privileges[i]) != -1) {
            return true;
        }
    }

    return false;
};

export const hasPrivilege = (privilege) => {
    if (!privilege) {
        return false;
    }

    let loggedInUser = Constants.LOGGED_IN_USER;

    if (!loggedInUser) {
        return false;
    }

    return _.indexOf(loggedInUser.privileges, privilege) != -1;
};
