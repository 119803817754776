import {
    SUPERVISOR_SEARCH,
    SUPERVISOR_SEARCH_SUCCESS,
    SUPERVISOR_GET_SELECTED_SUPERVISOR,
    SUPERVISOR_GET_SELECTED_SUPERVISOR_SUCCESS,
    SUPERVISOR_SAVE_UPDATE_SUCCESS,
    SUPERVISOR_ADD_NEW_SUPERVISOR,
    SUPERVISOR_RESET_SELECTED_SUPERVISOR,
    SUB_BUSINESS_UNIT_ADD_EDIT_SELECT_SUB_BUSINESS_UNIT_MODAL_OPEN,
    SUPERVISOR_ADD_EDIT_UPDATE_SELECTED_SUB_BUSINESS_UNIT,
    SUPERVISOR_ADD_EDIT_UPDATE_TMP_SELECTED_SUB_BUSINESS_UNIT,
    SUPERVISOR_ADD_EDIT_SELECT_SUB_BUSINESS_UNIT_MODAL_CLOSE,
    SUB_BU_RESET_SELECTED_SUB_BU,
    SUPERVISOR_ADD_EDIT_REMOVE_TMP_SELECTED_SUB_BUSINESS_UNIT,
} from "../actions/types";
import {getPagedData} from "../../utils/DataExtractHelper";

const initialState = {
    pageInfo: {},
    supervisors: [],
    selectedSupervisors : {},

    selectedSubBusinessUnits: [],
    selectedSubBusinessUnitsTmp: [],
    showAddSubBusinessModal: false
};

export default (state = initialState,action) => {
    switch (action.type) {
        case SUPERVISOR_SEARCH :
            return {
                ...state
            };

        case SUPERVISOR_SEARCH_SUCCESS :
            return {
                ...state,
                supervisors : action.payload.pageData,
                pageInfo : getPagedData(action.payload)
            };

        case SUPERVISOR_GET_SELECTED_SUPERVISOR :
            return {
                ...state,
                selectedSubBusinessUnits: []
            };
        case SUPERVISOR_GET_SELECTED_SUPERVISOR_SUCCESS :
        case SUPERVISOR_SAVE_UPDATE_SUCCESS :
            return {
                ...state,
                selectedSupervisors: action.payload,
                selectedSubBusinessUnits: action.payload.subBusinessUnits
            };

        case SUB_BU_RESET_SELECTED_SUB_BU :
        case SUPERVISOR_ADD_NEW_SUPERVISOR :
            return {
                ...state,
                selectedSupervisors: action.payload,
                selectedSubBusinessUnits:[]
            };
        case SUPERVISOR_RESET_SELECTED_SUPERVISOR :
            return {
                ...state,
                selectedSupervisors: action.payload
            };
        case SUPERVISOR_ADD_EDIT_UPDATE_SELECTED_SUB_BUSINESS_UNIT :
            return {
                ...state,
                selectedSubBusinessUnits: action.payload
            };
        case SUB_BUSINESS_UNIT_ADD_EDIT_SELECT_SUB_BUSINESS_UNIT_MODAL_OPEN :
            return {
                ...state,
                showAddSubBusinessModal: true
            };
        case SUPERVISOR_ADD_EDIT_REMOVE_TMP_SELECTED_SUB_BUSINESS_UNIT :
            return {
                ...state,
                selectedSubBusinessUnits: [],
                selectedSubBusinessUnitsTmp: []
            };
        case SUPERVISOR_ADD_EDIT_UPDATE_TMP_SELECTED_SUB_BUSINESS_UNIT :
            return {
                ...state,
                selectedSubBusinessUnitsTmp: action.payload
            };
        case SUPERVISOR_ADD_EDIT_SELECT_SUB_BUSINESS_UNIT_MODAL_CLOSE :
            return {
                ...state,
                showAddSubBusinessModal: false
            };
        default :
            return state;
    }
}
