import {
    MASTER_DATA_GET_ALL_COMPANIES,
    MASTER_DATA_GET_ALL_COMPANIES_SUCCESS,
    MASTER_DATA_GET_COMPANY_VICE_MEASURE_UNIT_CATEGORY,
    MASTER_DATA_GET_COMPANY_VICE_MEASURE_UNIT_CATEGORY_SUCCESS,
    MASTER_DATA_GET_DATA_FORM_VARIABLES,
    MASTER_DATA_GET_DATA_FORM_VARIABLES_SUCCESS,
    MASTER_DATA_GET_DOWN_TIME_CAUSE_CATEGORY_WISE_SUBCATEGORIES_DATA,
    MASTER_DATA_GET_DOWN_TIME_CAUSE_CATEGORY_WISE_SUBCATEGORIES_DATA_SUCCESS,
    MASTER_DATA_GET_ELIGIBLE_COMPANY,
    MASTER_DATA_GET_ELIGIBLE_COMPANY_SUCCESS,
    MASTER_DATA_GET_FACTORY_DOWN_TIME_CATEGORIES,
    MASTER_DATA_GET_FACTORY_DOWN_TIME_CATEGORIES_SUCCESS,
    MASTER_DATA_GET_FACTORY_WASTE_CATEGORIES_SUCCESS,
    MASTER_DATA_GET_MATH_OPERATORS_SUCCESS,
    MASTER_DATA_GET_MEASURE_UNIT_CATEGORY_VICE_MEASURE_UNIT,
    MASTER_DATA_GET_MEASURE_UNIT_CATEGORY_VICE_MEASURE_UNIT_SUCCESS,
    MASTER_DATA_GET_PRIVILEGES,
    MASTER_DATA_GET_PRIVILEGES_SUCCESS,
    MASTER_DATA_GET_PROCESS_FREQUENCIES,
    MASTER_DATA_GET_PROCESS_FREQUENCIES_SUCCESS,
    MASTER_DATA_GET_PRODUCT_CATEGORY_WISE_VARIANTS,
    MASTER_DATA_GET_PRODUCT_CATEGORY_WISE_VARIANTS_SUCCESS,
    MASTER_DATA_GET_USER_ROLES,
    MASTER_DATA_GET_USER_ROLES_SUCCESS,
    MASTER_DATA_GET_WASTE_CATEGORY_WISE_SUBCATEGORIES,
    MASTER_DATA_GET_WASTE_CATEGORY_WISE_SUBCATEGORIES_SUCCESS,
    MASTER_DATA_GET_WIDGET_RULE_OPERATORS,
    MASTER_DATA_GET_WIDGET_RULE_OPERATORS_SUCCESS,
    MASTER_DATA_GET_WIDGET_TYPES,
    MASTER_DATA_GET_WIDGET_TYPES_SUCCESS, MASTER_DATA_SET_SELECTED_LANGUAGE,
    STANDARD_COMPANY_VICE_MEASURE_UNIT_CATEGORY_SUCCESS,
    STANDARD_MEASURE_UNIT_CATEGORY_VICE_MEASURE_UNIT_SUCCESS
} from "../actions/types";
import Constants from "../../utils/Constants";

const initialState = {
    privileges: [],
    eligibleCompanies: [],
    roles: [],
    allCompanies: [],
    mathOperators: [],
    processFrequencies: [],
    widgetTypes: [],
    widgetRuleOperators: [],
    productCategoryWiseVariants: [],
    formDataVariables: [],
    wasteCategoryWiseSubCategories: [],
    downtimeCategoryWiseSubCategories: [],
    companyFactoryWiseDownTimeCategories: [],
    companyFactoryWiseWasteCategories: [],
    companyViceMeasureUnitCategories: {},
    measureUnitCategoryViceMeasureUnit: {},
    language: Constants.LANGUAGE.ENGLISH,
};

export default (state = initialState, action) => {
    switch (action.type) {

        case MASTER_DATA_GET_PRIVILEGES :
        case MASTER_DATA_GET_USER_ROLES:
        case MASTER_DATA_GET_ELIGIBLE_COMPANY :
        case MASTER_DATA_GET_ALL_COMPANIES :
        case MASTER_DATA_GET_PROCESS_FREQUENCIES :
        case MASTER_DATA_GET_WIDGET_TYPES :
        case MASTER_DATA_GET_WIDGET_RULE_OPERATORS:
        case MASTER_DATA_GET_PRODUCT_CATEGORY_WISE_VARIANTS:
        case MASTER_DATA_GET_DATA_FORM_VARIABLES:
        case MASTER_DATA_GET_WASTE_CATEGORY_WISE_SUBCATEGORIES:
        case MASTER_DATA_GET_DOWN_TIME_CAUSE_CATEGORY_WISE_SUBCATEGORIES_DATA:
        case MASTER_DATA_GET_FACTORY_DOWN_TIME_CATEGORIES:
        case MASTER_DATA_GET_COMPANY_VICE_MEASURE_UNIT_CATEGORY :
        case MASTER_DATA_GET_MEASURE_UNIT_CATEGORY_VICE_MEASURE_UNIT :
            return state;

        case MASTER_DATA_GET_PRIVILEGES_SUCCESS :
            return {
                ...state,
                privileges: action.payload
            };

        case MASTER_DATA_GET_ELIGIBLE_COMPANY_SUCCESS :
            return {
                ...state,
                eligibleCompanies: action.payload
            };

        case MASTER_DATA_GET_USER_ROLES_SUCCESS :
            return {
                ...state,
                roles: action.payload
            };

        case MASTER_DATA_GET_ALL_COMPANIES_SUCCESS :
            return {
                ...state,
                allCompanies: action.payload
            };

        case MASTER_DATA_GET_MATH_OPERATORS_SUCCESS :
            return {
                ...state,
                mathOperators: action.payload
            };

        case MASTER_DATA_GET_PROCESS_FREQUENCIES_SUCCESS :
            return {
                ...state,
                processFrequencies: action.payload
            };

        case MASTER_DATA_GET_WIDGET_TYPES_SUCCESS :
            return {
                ...state,
                widgetTypes: action.payload
            };

        case MASTER_DATA_GET_WIDGET_RULE_OPERATORS_SUCCESS :
            return {
                ...state,
                widgetRuleOperators: action.payload
            };

        case MASTER_DATA_GET_PRODUCT_CATEGORY_WISE_VARIANTS_SUCCESS :
            return {
                ...state,
                productCategoryWiseVariants: action.payload
            };

        case MASTER_DATA_GET_DATA_FORM_VARIABLES_SUCCESS :
            return {
                ...state,
                formDataVariables: action.payload
            };

        case MASTER_DATA_GET_WASTE_CATEGORY_WISE_SUBCATEGORIES_SUCCESS :
            return {
                ...state,
                wasteCategoryWiseSubCategories: action.payload
            };

        case MASTER_DATA_GET_DOWN_TIME_CAUSE_CATEGORY_WISE_SUBCATEGORIES_DATA_SUCCESS:
            return {
                ...state,
                downtimeCategoryWiseSubCategories: action.payLoad
            };

        case MASTER_DATA_GET_FACTORY_DOWN_TIME_CATEGORIES_SUCCESS : {
            return {
                ...state,
                companyFactoryWiseDownTimeCategories: action.payload
            };
        }

        case MASTER_DATA_GET_FACTORY_WASTE_CATEGORIES_SUCCESS : {
            return {
                ...state,
                companyFactoryWiseWasteCategories: action.payload
            };
        }

        case MASTER_DATA_GET_COMPANY_VICE_MEASURE_UNIT_CATEGORY_SUCCESS:
            return {
                ...state,
                companyViceMeasureUnitCategories: action.payload
            };

        case MASTER_DATA_GET_MEASURE_UNIT_CATEGORY_VICE_MEASURE_UNIT_SUCCESS:
            return {
                ...state,
                measureUnitCategoryViceMeasureUnit: action.payload
            };

        case MASTER_DATA_SET_SELECTED_LANGUAGE : {
            return {
                ...state,
                language: action.payload
            }
        }

        default :
            return state;
    }
}
