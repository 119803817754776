import {
    ADD_EDIT_WIDGET_ADD_PROCESS_MEASURE_RULE,
    ADD_EDIT_WIDGET_CLEAR_DATA,
    ADD_EDIT_WIDGET_DATA_ENTRY_FORM__MEASURES_LIST_SUCCESS,
    ADD_EDIT_WIDGET_DATA_ENTRY_FORM_MEASURE_MODAL_CLOSE,
    ADD_EDIT_WIDGET_DATA_ENTRY_FORM_MEASURE_MODAL_OPEN,
    ADD_EDIT_WIDGET_DATA_FORM_SEARCH_SUCCESS,
    ADD_EDIT_WIDGET_EDIT_PROCESS_MEASURE_RULE,
    ADD_EDIT_WIDGET_LOAD_MEASURES_BY_MEASURE_ID_SUCCESS,
    ADD_EDIT_WIDGET_LOAD_PROCESS_MEASURES,
    ADD_EDIT_WIDGET_LOAD_PROCESS_MEASURES_SUCCESS,
    ADD_EDIT_WIDGET_LOAD_PROCESS_TEMPLATES_BY_ID_SUCCESS,
    ADD_EDIT_WIDGET_LOAD_SELECTED_WIDGET,
    ADD_EDIT_WIDGET_LOAD_SELECTED_WIDGET_PROCESS_TEMPLATES_SUCCESS,
    ADD_EDIT_WIDGET_LOAD_SELECTED_WIDGET_PROCESS_WISE_MEASURE_DATA,
    ADD_EDIT_WIDGET_LOAD_SELECTED_WIDGET_PROCESS_WISE_MEASURE_DATA_SUCCESS,
    ADD_EDIT_WIDGET_LOAD_SELECTED_WIDGET_SUCCESS,
    ADD_EDIT_WIDGET_LOAD_SELECTED_WIDGET_SUMMERY,
    ADD_EDIT_WIDGET_LOAD_SELECTED_WIDGET_SUMMERY_SUCCESS,
    ADD_EDIT_WIDGET_MANIPULATE_SELECTED_WIDGET_STATE,
    ADD_EDIT_WIDGET_ON_SELECT_CATEGORY,
    ADD_EDIT_WIDGET_ON_SELECT_COMPANY_ID,
    ADD_EDIT_WIDGET_ON_SELECT_LOCATION_ID,
    ADD_EDIT_WIDGET_PROCESS_TEMPLATE_SEARCH,
    ADD_EDIT_WIDGET_PROCESS_TEMPLATE_SEARCH_SUCCESS,
    ADD_EDIT_WIDGET_REMOVE_DATA_ENTRY_FORM,
    ADD_EDIT_WIDGET_REMOVE_DATA_ENTRY_FORM_MEASURE,
    ADD_EDIT_WIDGET_REMOVE_MEASURE,
    ADD_EDIT_WIDGET_REMOVE_PROCESS_MEASURE_RULE,
    ADD_EDIT_WIDGET_REMOVE_SELECTED_MEASURE,
    ADD_EDIT_WIDGET_REPLACE_PROCESS_MEASURE, ADD_EDIT_WIDGET_REPLICATE_WIDGET, ADD_EDIT_WIDGET_REPLICATE_WIDGET_SUCCESS,
    ADD_EDIT_WIDGET_SAVE_UPDATE_WIDGET,
    ADD_EDIT_WIDGET_SAVE_UPDATE_WIDGET_SUCCESS,
    ADD_EDIT_WIDGET_SEARCH_SUCCESS,
    ADD_EDIT_WIDGET_SELECT_AXIS_MEASURE,
    ADD_EDIT_WIDGET_SELECT_DATA_ENTRY_FORMS,
    ADD_EDIT_WIDGET_SELECT_DATA_ENTRY_MODAL_CLOSE,
    ADD_EDIT_WIDGET_SELECT_DATA_ENTRY_MODAL_OPEN,
    ADD_EDIT_WIDGET_SELECT_ELIGIBLE_USER_TYPE,
    ADD_EDIT_WIDGET_SELECT_MEASURES,
    ADD_EDIT_WIDGET_SELECT_MEASURES_MODAL_CLOSE,
    ADD_EDIT_WIDGET_SELECT_MEASURES_MODAL_OPEN,
    ADD_EDIT_WIDGET_SELECT_PROCESS_IDS,
    ADD_EDIT_WIDGET_SELECT_PROCESS_ITEMS,
    ADD_EDIT_WIDGET_SELECT_PROCESS_MEASURE,
    ADD_EDIT_WIDGET_SELECT_PROCESS_MEASURE_BUDGET_COLOR_CHANGE,
    ADD_EDIT_WIDGET_SELECT_PROCESS_MEASURE_CHECK_OPTIONS_CHANGE,
    ADD_EDIT_WIDGET_SELECT_PROCESS_MEASURE_COLOR_CHANGE,
    ADD_EDIT_WIDGET_SELECT_PROCESS_MEASURE_TARGET_COLOR_CHANGE,
    ADD_EDIT_WIDGET_SELECT_USERS,
    ADD_EDIT_WIDGET_SELECT_USERS_ADD,
    ADD_EDIT_WIDGET_SET_SELECTED_MEASURE_RECALCULATE,
    ADD_EDIT_WIDGET_SORT,
    ADD_EDIT_WIDGET_STACK_SORT,
    ADD_EDIT_WIDGET_SUB_BUSINESS_UNIT_SEARCH,
    ADD_EDIT_WIDGET_SUB_BUSINESS_UNIT_SEARCH_SUCCESS,
    ADD_EDIT_WIDGET_TOGGLE_SORT,
    ADD_EDIT_WIDGET_UPDATE_ADD_EDIT_WIDGET_DATA_STRUCTURE,
    ADD_EDIT_WIDGET_UPDATE_DATA_ENTRY_FORM_MEASURE_AGGREGATE_TYPE,
    ADD_EDIT_WIDGET_UPDATE_DATA_ENTRY_FORM_MEASURE_RE_CALCULATION,
    ADD_EDIT_WIDGET_UPDATE_DATA_ENTRY_FORM_MEASURES,
    ADD_EDIT_WIDGET_UPDATE_MEASURE_WISE_DISPLAY_IN_WIDGET, ADD_EDIT_WIDGET_UPDATE_MEASURE_WISE_DISPLAY_TREND_LINE,
    ADD_EDIT_WIDGET_UPDATE_MEASURE_WISE_EXPANDED,
    ADD_EDIT_WIDGET_UPDATE_TMP_DATA_ENTRY_FORM_MEASURES,
    ADD_EDIT_WIDGET_UPDATE_TMP_DATA_ENTRY_FORMS,
    ADD_EDIT_WIDGET_UPDATE_TMP_SELECTED_MEASURES,
    ADD_EDIT_WIDGET_UPDATE_WIDGET_INCLUDES,
    ADD_EDIT_WIDGET_USER_SEARCH,
    ADD_EDIT_WIDGET_USER_SEARCH_SUCCESS,
    WIDGET_RESET_SELECTED_WIDGET_TYPE
} from "../actions/types";
import {getPagedData} from "../../utils/DataExtractHelper";

import * as _ from 'lodash';

const initialState = {
    userPageInfo: {},
    users: [],
    eligibleUsers: [],
    processTemplates: [],
    processTemplatePageInfo: {},
    processTemplatePageFilteredSubBusinessUnits: [],

    selectedCompanyID: null,
    selectedLocationID: null,
    selectedCategory: '',
    eligibleUserType: '',
    selectedUserIDs: [],
    selectedProcessIDs: [],
    selectedProcessItems: {},
    selectedWidgetTypeID: null,
    selectedWidgetType: {},

    processWiseMeasureData: {},
    processWiseSelectedMeasureData: {},
    processWiseMeasureWiseSelectedColor: {},
    processWiseMeasureWiseCheckOptions: {},
    processWiseMeasureWiseRules: {},
    processWiseSelectedMeasureWiseRecalculate: {},
    processWiseMeasureWiseDisplayInWidget: {},
    processWiseMeasureWiseDisplayTrendLine: {},

    xAxisMeasure: '',
    yAxisMeasure: '',

    selectedWidgetInfo: {},
    selectedWidgetSummeryInfo: {},

    widgetIncludes: [],

    //Report Measure Section
    showAddMeasureModal: false,
    selectedMeasures: [],
    selectedMeasuresTmp: [],
    measurePageInfo: {},
    reportMeasures: [],

    selectedDataEntryFormMeasureID: null,
    measureWiseDataEntryForms: {},
    showAddDataEntryFormModal: false,
    selectedDataEntryFormsTmp: [],
    dataFormPageInfo: {},
    dataFormPageData: [],

    measureWiseDataEntryFormWiseMeasures: {},
    showDataEntryFormMeasureSelectBox: false,
    dataEntryFormMeasuresTmp: [],
    dataEntryFormMeasures: [],
    selectedDataEntryFormTemplateID: null,
    parentMeasureID: null,
    measureWiseDataEntryFormWiseReCalculateMeasures: {},
    measureWiseDataEntryFormWiseMeasureAggregateType: {},

    sortModeEnabled: false,
    measureWiseSectionExpanded: {},

    stackSortModeEnabled: false,
    stackSortedMeasures: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ADD_EDIT_WIDGET_USER_SEARCH :
        case ADD_EDIT_WIDGET_PROCESS_TEMPLATE_SEARCH :
        case ADD_EDIT_WIDGET_LOAD_PROCESS_MEASURES :
        case ADD_EDIT_WIDGET_SAVE_UPDATE_WIDGET :
        case ADD_EDIT_WIDGET_LOAD_SELECTED_WIDGET :
        case ADD_EDIT_WIDGET_LOAD_SELECTED_WIDGET_SUMMERY :
        case ADD_EDIT_WIDGET_LOAD_SELECTED_WIDGET_PROCESS_WISE_MEASURE_DATA :
        case ADD_EDIT_WIDGET_REPLICATE_WIDGET :
            return {
                ...state
            };
        case ADD_EDIT_WIDGET_USER_SEARCH_SUCCESS :
            return {
                ...state,
                users: action.payload.pageData,
                userPageInfo: getPagedData(action.payload)
            };

        case ADD_EDIT_WIDGET_PROCESS_TEMPLATE_SEARCH_SUCCESS:
            return {
                ...state,
                processTemplatePageInfo: getPagedData(action.payload),
                processTemplates: action.payload.pageData,
            };

        case ADD_EDIT_WIDGET_SUB_BUSINESS_UNIT_SEARCH :
            return {
                ...state,
                processTemplatePageFilteredSubBusinessUnits: []
            };

        case ADD_EDIT_WIDGET_SUB_BUSINESS_UNIT_SEARCH_SUCCESS:

            return {
                ...state,
                processTemplatePageFilteredSubBusinessUnits: action.payload.subBusinessUnitDTOs,
            };

        case ADD_EDIT_WIDGET_SELECT_ELIGIBLE_USER_TYPE :
            return {
                ...state,
                eligibleUserType: action.payload
            };

        case ADD_EDIT_WIDGET_SELECT_USERS :
            return {
                ...state,
                selectedUserIDs: action.payload
            };

        case ADD_EDIT_WIDGET_SELECT_USERS_ADD :
            return {
                ...state,
                selectedUserIDs: [...state.selectedUserIDs, ...action.payload]
            };

        case ADD_EDIT_WIDGET_SELECT_PROCESS_IDS :
            return {
                ...state,
                selectedProcessIDs: action.payload
            };

        case ADD_EDIT_WIDGET_SELECT_PROCESS_ITEMS :
            return {
                ...state,
                selectedProcessItems: action.payload
            };

        case WIDGET_RESET_SELECTED_WIDGET_TYPE :
            return {
                ...state,
                selectedWidgetTypeID: action.payload.widgetTypeId,
                selectedWidgetType: action.payload.widgetType,
            };

        case ADD_EDIT_WIDGET_LOAD_PROCESS_MEASURES_SUCCESS :
            let processWiseMeasureData = Object.assign({}, state.processWiseMeasureData);
            processWiseMeasureData[action.payload.processTemplateID] = action.payload.measures;
            return {
                ...state,
                processWiseMeasureData: processWiseMeasureData
            };

        case ADD_EDIT_WIDGET_SELECT_PROCESS_MEASURE : {
            let processTemplateID = action.payload.processTemplateID;
            let selectedMeasure = action.payload.measure;

            let processWiseSelectedMeasureData = Object.assign({}, state.processWiseSelectedMeasureData);
            let measureWiseRecalculate = Object.assign({}, state.processWiseSelectedMeasureWiseRecalculate);
            let measures = _.cloneDeep(state.processWiseSelectedMeasureData[processTemplateID]);

            if (measures) {
                let measureIDs = measures.map((measure) => measure.measureID);
                let index = _.indexOf(measureIDs, selectedMeasure.measureID);
                if (index != -1) {
                    measures.splice(index, 1);
                    //Recalculate Data
                    measureWiseRecalculate[processTemplateID] = _.omit(
                        measureWiseRecalculate[processTemplateID], [selectedMeasure.measureID]
                    );

                    if (_.isEmpty(measureWiseRecalculate[processTemplateID])) {
                        measureWiseRecalculate = _.omit(
                            measureWiseRecalculate, [processTemplateID]
                        );
                    }
                    //Recalculate Data FINISHED
                } else {
                    measures.push(selectedMeasure);

                    //Recalculate Data
                    let recalculatePresent = measureWiseRecalculate
                        && measureWiseRecalculate[processTemplateID]
                        && measureWiseRecalculate[processTemplateID][selectedMeasure.measureID];

                    if (recalculatePresent) {
                        measureWiseRecalculate[processTemplateID][selectedMeasure.measureID] = false;
                    } else {
                        measureWiseRecalculate = _.merge(measureWiseRecalculate, {
                            [processTemplateID]: {
                                [selectedMeasure.measureID]: false
                            }
                        });
                    }
                    //Recalculate Data FINISHED
                }

            } else {
                measures = [selectedMeasure];

                let recalculatePresent = measureWiseRecalculate
                    && measureWiseRecalculate[processTemplateID]
                    && measureWiseRecalculate[processTemplateID][selectedMeasure.measureID];

                if (recalculatePresent) {
                    measureWiseRecalculate[processTemplateID][selectedMeasure.measureID] = false;
                } else {
                    measureWiseRecalculate = _.merge(measureWiseRecalculate, {
                        [processTemplateID]: {
                            [selectedMeasure.measureID]: false
                        }
                    });
                }
            }

            processWiseSelectedMeasureData[processTemplateID] = measures;

            let widgetMeasureList = _.cloneDeep(state.selectedWidgetInfo.widgetProcessMeasureDTOS);
            let stackSortedMeasures = [];
            const measureList = [];
            const measureNameWiseMap = {};

            let selectedMeasureList = _.cloneDeep(measures);

            _.forEach(selectedMeasureList, val => {
                measureList.push(val);
            });

            _.forEach(measureList, val => {
                measureNameWiseMap[val.measureName] = val;
                measureNameWiseMap[val.measureName]["stackedDisplayOrder"] = 0;
            });

            _.forEach(widgetMeasureList, val => {
                if (!_.isEmpty(measureNameWiseMap[val.measureName])) {
                    measureNameWiseMap[val.measureName] = val;
                }
            });
            _.map(measureNameWiseMap, function (value, key) {
                stackSortedMeasures.push(value);
            });
            stackSortedMeasures.sort((o1, o2) => {
                return o1.stackedDisplayOrder - o2.stackedDisplayOrder;
            });

            return {
                ...state,
                stackSortedMeasures: stackSortedMeasures,
                processWiseSelectedMeasureData: processWiseSelectedMeasureData,
                processWiseSelectedMeasureWiseRecalculate: measureWiseRecalculate
            };
        }
        case ADD_EDIT_WIDGET_REPLACE_PROCESS_MEASURE :
            let data = Object.assign({}, state.processWiseSelectedMeasureData);
            data[action.payload.processTemplateID] = action.payload.measures;

            return {
                ...state,
                processWiseSelectedMeasureData: data
            };

        case ADD_EDIT_WIDGET_SELECT_PROCESS_MEASURE_COLOR_CHANGE :
            let pTemplateID = action.payload.processTemplateID;
            let measureID = action.payload.measureID;
            let colorCode = action.payload.colorCode;

            let processWiseMeasureWiseSelectedColor = Object.assign({}, state.processWiseMeasureWiseSelectedColor);
            if (!processWiseMeasureWiseSelectedColor[pTemplateID]) {
                processWiseMeasureWiseSelectedColor[pTemplateID] = {};
            }
            if (!processWiseMeasureWiseSelectedColor[pTemplateID][measureID]) {
                processWiseMeasureWiseSelectedColor[pTemplateID][measureID] = {};
            }

            processWiseMeasureWiseSelectedColor[pTemplateID][measureID]['colorCode'] = colorCode;

            return {
                ...state,
                processWiseMeasureWiseSelectedColor: processWiseMeasureWiseSelectedColor
            };

        case ADD_EDIT_WIDGET_SELECT_PROCESS_MEASURE_BUDGET_COLOR_CHANGE :
            let pTemplateIDForBudget = action.payload.processTemplateID;
            let measureIDForBudget = action.payload.measureID;
            let colorCodeForBudget = action.payload.colorCode;

            let processWiseMeasureWiseSelectedColorForBudget = Object.assign({}, state.processWiseMeasureWiseSelectedColor);
            if (!processWiseMeasureWiseSelectedColorForBudget[pTemplateIDForBudget]) {
                processWiseMeasureWiseSelectedColorForBudget[pTemplateIDForBudget] = {};
            }
            if (!processWiseMeasureWiseSelectedColorForBudget[pTemplateIDForBudget][measureIDForBudget]) {
                processWiseMeasureWiseSelectedColorForBudget[pTemplateIDForBudget][measureIDForBudget] = {};
            }

            processWiseMeasureWiseSelectedColorForBudget[pTemplateIDForBudget][measureIDForBudget]['budgetColorCode'] = colorCodeForBudget;

            return {
                ...state,
                processWiseMeasureWiseSelectedColor: processWiseMeasureWiseSelectedColorForBudget
            };

        case ADD_EDIT_WIDGET_SELECT_PROCESS_MEASURE_TARGET_COLOR_CHANGE :
            let pTemplateIDForTarget = action.payload.processTemplateID;
            let measureIDForTarget = action.payload.measureID;
            let colorCodeForTarget = action.payload.colorCode;

            let processWiseMeasureWiseSelectedColorForTarget = Object.assign({}, state.processWiseMeasureWiseSelectedColor);
            if (!processWiseMeasureWiseSelectedColorForTarget[pTemplateIDForTarget]) {
                processWiseMeasureWiseSelectedColorForTarget[pTemplateIDForTarget] = {};
            }
            if (!processWiseMeasureWiseSelectedColorForTarget[pTemplateIDForTarget][measureIDForTarget]) {
                processWiseMeasureWiseSelectedColorForTarget[pTemplateIDForTarget][measureIDForTarget] = {};
            }

            processWiseMeasureWiseSelectedColorForTarget[pTemplateIDForTarget][measureIDForTarget]['targetColorCode'] = colorCodeForTarget;

            return {
                ...state,
                processWiseMeasureWiseSelectedColor: processWiseMeasureWiseSelectedColorForTarget
            };

        case ADD_EDIT_WIDGET_SELECT_PROCESS_MEASURE_CHECK_OPTIONS_CHANGE :
            let assign = Object.assign({}, state.processWiseMeasureWiseCheckOptions);
        {
            let pTemplateID = action.payload.processTemplateID;
            let measureID = action.payload.measureID;

            if (!assign[pTemplateID]) {
                assign[pTemplateID] = {};
            }

            assign[pTemplateID][measureID] = action.payload.data;

        }
            return {
                ...state,
                processWiseMeasureWiseCheckOptions: assign
            };

        case ADD_EDIT_WIDGET_SAVE_UPDATE_WIDGET_SUCCESS :
        case ADD_EDIT_WIDGET_LOAD_SELECTED_WIDGET_SUCCESS :
        case ADD_EDIT_WIDGET_REPLICATE_WIDGET_SUCCESS: {

            let selectedWidgetInfo = action.payload;
            let widgetProcessMeasureDTOS = action.payload.widgetProcessMeasureDTOS;
            let processWiseMeasureWiseDisplayInWidget = {};
            if (widgetProcessMeasureDTOS) {
                widgetProcessMeasureDTOS.forEach((measureData) => {
                    if (!processWiseMeasureWiseDisplayInWidget[measureData.processID]) {
                        processWiseMeasureWiseDisplayInWidget[measureData.processID] = {}
                    }
                    processWiseMeasureWiseDisplayInWidget[measureData.processID][measureData.measureID] = measureData.displayInReport;
                });
            }
            let processWiseMeasureWiseDisplayTrendLine = {};
            if (widgetProcessMeasureDTOS) {
                widgetProcessMeasureDTOS.forEach((measureData) => {
                    if (!processWiseMeasureWiseDisplayTrendLine[measureData.processID]) {
                        processWiseMeasureWiseDisplayTrendLine[measureData.processID] = {}
                    }
                    processWiseMeasureWiseDisplayTrendLine[measureData.processID][measureData.measureID] = measureData.displayTrendLine;
                });
            }

            let widgetMeasureList = _.cloneDeep(widgetProcessMeasureDTOS);
            let stackSortedMeasures = [];
            const measureList = [];
            const measureNameWiseMap = {};

            _.forEach(widgetMeasureList, val => {
                measureNameWiseMap[val.measureName] = val;
            });
            _.map(measureNameWiseMap, function (value, key) {
                stackSortedMeasures.push(value);
            });
            stackSortedMeasures.sort((o1, o2) => {
                return o1.stackedDisplayOrder - o2.stackedDisplayOrder;
            });

            return {
                ...state,
                selectedWidgetInfo: selectedWidgetInfo,
                stackSortedMeasures: stackSortedMeasures,
                processWiseMeasureWiseDisplayInWidget,
                processWiseMeasureWiseDisplayTrendLine
            };
        }

        case ADD_EDIT_WIDGET_LOAD_SELECTED_WIDGET_SUMMERY_SUCCESS :
            return {
                ...state,
                selectedWidgetSummeryInfo: action.payload
            };

        case ADD_EDIT_WIDGET_ON_SELECT_COMPANY_ID :
            return {
                ...state,
                selectedCompanyID: action.payload,
                eligibleUserType: '',
                selectedUserIDs: [],
                selectedProcessIDs: [],
                selectedProcessItems: {},

                processWiseMeasureData: {},
                processWiseSelectedMeasureData: {},
                processWiseMeasureWiseSelectedColor: {},
                processWiseMeasureWiseCheckOptions: {},
                processWiseMeasureWiseRules: {},
                widgetIncludes: [],

                showAddMeasureModal: false,
                selectedMeasures: [],
                stackSortedMeasures: [],
                selectedMeasuresTmp: [],
                measurePageInfo: {},
                reportMeasures: [],

                selectedDataEntryFormMeasureID: null,
                measureWiseDataEntryForms: {},
                showAddDataEntryFormModal: false,
                selectedDataEntryFormsTmp: [],
                dataFormPageInfo: {},
                dataFormPageData: [],

                measureWiseDataEntryFormWiseMeasures: {},
                showDataEntryFormMeasureSelectBox: false,
                dataEntryFormMeasuresTmp: [],
                dataEntryFormMeasures: [],
                selectedDataEntryFormTemplateID: null,
                parentMeasureID: null,
                measureWiseDataEntryFormWiseReCalculateMeasures: {},
                measureWiseDataEntryFormWiseMeasureAggregateType: {},

                sortModeEnabled: false,
                measureWiseSectionExpanded: {},
            };

        case ADD_EDIT_WIDGET_ON_SELECT_LOCATION_ID :
            return {
                ...state,
                selectedCompanyID: state.selectedCompanyID,
                selectedLocationID: action.payload,
                eligibleUserType: state.eligibleUserType,
                selectedUserIDs: [],
                selectedProcessIDs: [],
                selectedProcessItems: {},

                processWiseMeasureData: {},
                processWiseSelectedMeasureData: {},
                processWiseMeasureWiseSelectedColor: {},
                processWiseMeasureWiseCheckOptions: {},
                processWiseMeasureWiseRules: {},
                widgetIncludes: [],
            };

        case ADD_EDIT_WIDGET_ON_SELECT_CATEGORY :
            return {
                ...state,
                selectedCompanyID: state.selectedCompanyID,
                selectedLocationID: state.selectedLocationID,
                selectedCategory: action.payload,
                eligibleUserType: state.eligibleUserType,
                selectedUserIDs: [],
                selectedProcessIDs: [],
                selectedProcessItems: {},

                processWiseMeasureData: {},
                processWiseSelectedMeasureData: {},
                processWiseMeasureWiseSelectedColor: {},
                processWiseMeasureWiseCheckOptions: {},
                processWiseMeasureWiseRules: {},
                widgetIncludes: [],
            };

        case ADD_EDIT_WIDGET_CLEAR_DATA :
            return {
                ...state,
                selectedCompanyID: null,
                selectedLocationID: null,
                selectedCategory: '',
                eligibleUserType: '',
                selectedUserIDs: [],
                selectedProcessIDs: [],
                selectedProcessItems: {},
                selectedWidgetTypeID: null,

                processWiseMeasureData: {},
                processWiseSelectedMeasureData: {},
                processWiseMeasureWiseSelectedColor: {},
                processWiseMeasureWiseCheckOptions: {},
                processWiseMeasureWiseRules: {},
                processWiseSelectedMeasureWiseRecalculate: {},
                processWiseMeasureWiseDisplayInWidget: {},
                processWiseMeasureWiseDisplayTrendLine: {},

                xAxisMeasure: '',
                yAxisMeasure: '',

                selectedWidgetInfo: {},
                selectedWidgetSummeryInfo: {},

                widgetIncludes: [],

                showAddMeasureModal: false,
                selectedMeasures: [],
                stackSortedMeasures: [],
                selectedMeasuresTmp: [],
                measurePageInfo: {},
                reportMeasures: [],

                selectedDataEntryFormMeasureID: null,
                measureWiseDataEntryForms: {},
                showAddDataEntryFormModal: false,
                selectedDataEntryFormsTmp: [],
                dataFormPageInfo: {},
                dataFormPageData: [],

                measureWiseDataEntryFormWiseMeasures: {},
                showDataEntryFormMeasureSelectBox: false,
                dataEntryFormMeasuresTmp: [],
                dataEntryFormMeasures: [],
                selectedDataEntryFormTemplateID: null,
                parentMeasureID: null,
                measureWiseDataEntryFormWiseReCalculateMeasures: {},
                measureWiseDataEntryFormWiseMeasureAggregateType: {},

                sortModeEnabled: false,
                measureWiseSectionExpanded: {},
            };

        case ADD_EDIT_WIDGET_MANIPULATE_SELECTED_WIDGET_STATE :
            return {
                ...state,
                ...action.payload
            };

        case ADD_EDIT_WIDGET_LOAD_SELECTED_WIDGET_PROCESS_TEMPLATES_SUCCESS :
            let items = {};
            action.payload.forEach((item) => {
                items[item.processTemplateID] = item
            });
            return {
                ...state,
                selectedProcessItems: items
            };

        case ADD_EDIT_WIDGET_LOAD_SELECTED_WIDGET_PROCESS_WISE_MEASURE_DATA_SUCCESS :
            return {
                ...state,
                processWiseSelectedMeasureData: action.payload
            };

        case ADD_EDIT_WIDGET_REMOVE_SELECTED_MEASURE :
            let selectedData = Object.assign({}, state.processWiseSelectedMeasureData);
            let processWiseSelectedMeasureWiseRecalculateRemove = Object.assign({}, state.processWiseSelectedMeasureWiseRecalculate);
            let index = _.findIndex(selectedData[action.payload.processTemplateID], (item) => {
                return item.measureID === action.payload.measureID
            });
            selectedData[action.payload.processTemplateID].splice(index, 1);

            //Recalculate Data
            processWiseSelectedMeasureWiseRecalculateRemove[action.payload.processTemplateID] = _.omit(
                processWiseSelectedMeasureWiseRecalculateRemove[action.payload.processTemplateID], [action.payload.measureID]
            );

            if (_.isEmpty(processWiseSelectedMeasureWiseRecalculateRemove[action.payload.processTemplateID])) {
                processWiseSelectedMeasureWiseRecalculateRemove = _.omit(
                    processWiseSelectedMeasureWiseRecalculateRemove, [action.payload.processTemplateID]
                );
            }
            //Recalculate Data FINISHED
            return {
                ...state,
                processWiseSelectedMeasureData: selectedData,
                processWiseSelectedMeasureWiseRecalculate: processWiseSelectedMeasureWiseRecalculateRemove
            };

        case ADD_EDIT_WIDGET_ADD_PROCESS_MEASURE_RULE :
            let processWiseMeasureWiseRules = Object.assign({}, state.processWiseMeasureWiseRules);
        {
            let pTemplateID = action.payload.processTemplateID;
            let measureID = action.payload.measureID;

            if (!processWiseMeasureWiseRules[pTemplateID]) {
                processWiseMeasureWiseRules[pTemplateID] = {};
            }

            if (!processWiseMeasureWiseRules[pTemplateID][measureID]) {
                processWiseMeasureWiseRules[pTemplateID][measureID] = [];
            }

            processWiseMeasureWiseRules[pTemplateID][measureID].push(action.payload.rule);
        }
            return {
                ...state,
                processWiseMeasureWiseRules: processWiseMeasureWiseRules
            };

        case ADD_EDIT_WIDGET_EDIT_PROCESS_MEASURE_RULE :
            let processWiseMeasureWiseRulesEdit = Object.assign({}, state.processWiseMeasureWiseRules);
        {
            let pTemplateID = action.payload.processTemplateID;
            let measureID = action.payload.measureID;
            let rule = action.payload.rule;
            let addedRules = processWiseMeasureWiseRulesEdit[pTemplateID][measureID];

            if (rule.uuid) {
                let index = _.findIndex(addedRules, (r) => {
                    return r.uuid == rule.uuid;
                });
                if (index != -1) {
                    processWiseMeasureWiseRulesEdit[pTemplateID][measureID][index] = rule;
                }
            } else {
                let index = _.findIndex(addedRules, (r) => {
                    return r.widgetProcessMeasureRuleID == rule.widgetProcessMeasureRuleID;
                });
                if (index != -1) {
                    processWiseMeasureWiseRulesEdit[pTemplateID][measureID][index] = rule;
                }
            }

            return {
                ...state,
                processWiseMeasureWiseRules: processWiseMeasureWiseRulesEdit
            };
        }

        case ADD_EDIT_WIDGET_REMOVE_PROCESS_MEASURE_RULE :
            let processWiseMeasureWiseRulesRemove = Object.assign({}, state.processWiseMeasureWiseRules);
        {
            let pTemplateID = action.payload.processTemplateID;
            let measureID = action.payload.measureID;
            let rule = action.payload.rule;

            let index;

            if (rule.uuid) {
                index = _.findIndex(processWiseMeasureWiseRulesRemove[pTemplateID][measureID], (r) => {
                    return r.uuid == rule.uuid;
                });
            } else {
                index = _.findIndex(processWiseMeasureWiseRulesRemove[pTemplateID][measureID], (r) => {
                    return r.widgetProcessMeasureRuleID == rule.widgetProcessMeasureRuleID;
                });
            }

            if (index != -1) {
                processWiseMeasureWiseRulesRemove[pTemplateID][measureID].splice(index, 1);
            }
        }
            return {
                ...state,
                processWiseMeasureWiseRules: processWiseMeasureWiseRulesRemove
            };

        case ADD_EDIT_WIDGET_SELECT_AXIS_MEASURE:
            let axisName = action.payload.axisName;
            return {
                ...state,
                [axisName]: action.payload.value
            };

        case ADD_EDIT_WIDGET_UPDATE_WIDGET_INCLUDES:
            return {
                ...state,
                widgetIncludes: action.payload
            };

        case ADD_EDIT_WIDGET_SET_SELECTED_MEASURE_RECALCULATE:

            let recalculateList = action.payload;
            let processWiseSelectedMeasureWiseRecalculate = Object.assign({}, state.processWiseSelectedMeasureWiseRecalculate);

            _.map(recalculateList, (recalculate) => {

                const {
                    processTemplateID,
                    measureID,
                    value
                } = recalculate;

                let recalculatePresent = processWiseSelectedMeasureWiseRecalculate
                    && processWiseSelectedMeasureWiseRecalculate[processTemplateID]
                    && processWiseSelectedMeasureWiseRecalculate[processTemplateID][measureID];

                if (recalculatePresent) {
                    processWiseSelectedMeasureWiseRecalculate[processTemplateID][measureID] = value;
                } else {
                    processWiseSelectedMeasureWiseRecalculate = _.merge(processWiseSelectedMeasureWiseRecalculate, {
                        [processTemplateID]: {
                            [measureID]: value
                        }
                    });
                }
            });

            return {
                ...state,
                processWiseSelectedMeasureWiseRecalculate: processWiseSelectedMeasureWiseRecalculate
            };

        case ADD_EDIT_WIDGET_SELECT_MEASURES_MODAL_OPEN :
            return {
                ...state,
                showAddMeasureModal: true
            };

        case ADD_EDIT_WIDGET_SELECT_MEASURES_MODAL_CLOSE :
            return {
                ...state,
                showAddMeasureModal: false
            };

        case ADD_EDIT_WIDGET_SELECT_MEASURES :
            return {
                ...state,
                selectedMeasures: action.payload,
                sortModeEnabled: false
            };

        case ADD_EDIT_WIDGET_UPDATE_TMP_SELECTED_MEASURES :
            return {
                ...state,
                selectedMeasuresTmp: action.payload
            };

        case ADD_EDIT_WIDGET_SEARCH_SUCCESS :
            return {
                ...state,
                reportMeasures: action.payload.pageData,
                measurePageInfo: getPagedData(action.payload)
            };

        case ADD_EDIT_WIDGET_SELECT_DATA_ENTRY_MODAL_OPEN :
            return {
                ...state,
                showAddDataEntryFormModal: true,
                selectedDataEntryFormMeasureID: action.payload
            };

        case ADD_EDIT_WIDGET_SELECT_DATA_ENTRY_MODAL_CLOSE :
            return {
                ...state,
                showAddDataEntryFormModal: false,
                selectedDataEntryFormMeasureID: null
            };

        case ADD_EDIT_WIDGET_SELECT_DATA_ENTRY_FORMS : {
            let measureWiseDataEntryForms = _.cloneDeep(state.measureWiseDataEntryForms);
            let measureID = action.payload.measureID;

            let updated = Object.assign({}, measureWiseDataEntryForms, {
                [measureID]: action.payload.dataEntryForms
            });

            return {
                ...state,
                measureWiseDataEntryForms: updated
            };
        }


        case ADD_EDIT_WIDGET_UPDATE_TMP_DATA_ENTRY_FORMS :
            return {
                ...state,
                selectedDataEntryFormsTmp: action.payload
            };

        case ADD_EDIT_WIDGET_DATA_FORM_SEARCH_SUCCESS:
            return {
                ...state,
                dataFormPageInfo: getPagedData(action.payload),
                dataFormPageData: action.payload.pageData,
            };

        case ADD_EDIT_WIDGET_DATA_ENTRY_FORM_MEASURE_MODAL_OPEN :
            return {
                ...state,
                showDataEntryFormMeasureSelectBox: true,
                selectedDataEntryFormTemplateID: action.payload.processTemplateID,
                parentMeasureID: action.payload.measureID,
            };

        case ADD_EDIT_WIDGET_DATA_ENTRY_FORM_MEASURE_MODAL_CLOSE :
            return {
                ...state,
                showDataEntryFormMeasureSelectBox: false,
                selectedDataEntryFormTemplateID: null,
                parentMeasureID: null,
                dataEntryFormMeasures: [],
                dataEntryFormMeasuresTmp: []
            };

        case ADD_EDIT_WIDGET_DATA_ENTRY_FORM__MEASURES_LIST_SUCCESS : {
            return {
                ...state,
                dataEntryFormMeasures: action.payload.measures
            };
        }

        case ADD_EDIT_WIDGET_UPDATE_TMP_DATA_ENTRY_FORM_MEASURES : {
            return {
                ...state,
                dataEntryFormMeasuresTmp: action.payload
            };
        }

        case ADD_EDIT_WIDGET_UPDATE_DATA_ENTRY_FORM_MEASURES : {
            let measureWiseDataEntryFormWiseMeasures = _.cloneDeep(state.measureWiseDataEntryFormWiseMeasures);
            let processTemplateID = action.payload.processTemplateID;
            let parentMeasureID = action.payload.parentMeasureID;

            if (!measureWiseDataEntryFormWiseMeasures[parentMeasureID]) {
                measureWiseDataEntryFormWiseMeasures[parentMeasureID] = {};
            }

            measureWiseDataEntryFormWiseMeasures[parentMeasureID][processTemplateID] = action.payload.dataEntryFormMeasures;

            return {
                ...state,
                measureWiseDataEntryFormWiseMeasures: measureWiseDataEntryFormWiseMeasures
            };
        }

        case ADD_EDIT_WIDGET_UPDATE_DATA_ENTRY_FORM_MEASURE_RE_CALCULATION : {
            let data = _.cloneDeep(state.measureWiseDataEntryFormWiseReCalculateMeasures);
            let processTemplateID = action.payload.processTemplateID;
            let parentMeasureID = action.payload.parentMeasureID;
            let relevant = action.payload.relevant;

            if (!data[parentMeasureID]) {
                data[parentMeasureID] = {};
            }

            data[parentMeasureID][processTemplateID] = relevant;

            return {
                ...state,
                measureWiseDataEntryFormWiseReCalculateMeasures: data
            };
        }

        case ADD_EDIT_WIDGET_UPDATE_DATA_ENTRY_FORM_MEASURE_AGGREGATE_TYPE : {
            let data = _.cloneDeep(state.measureWiseDataEntryFormWiseMeasureAggregateType);
            let processTemplateID = action.payload.processTemplateID;
            let parentMeasureID = action.payload.parentMeasureID;
            let measureID = action.payload.measureID;
            let value = action.payload.value;

            if (!data[parentMeasureID]) {
                data[parentMeasureID] = {};
            }

            if (!data[parentMeasureID][processTemplateID]) {
                data[parentMeasureID][processTemplateID] = {};
            }

            data[parentMeasureID][processTemplateID][measureID] = value;

            return {
                ...state,
                measureWiseDataEntryFormWiseMeasureAggregateType: data
            };
        }

        case ADD_EDIT_WIDGET_UPDATE_ADD_EDIT_WIDGET_DATA_STRUCTURE : {
            const {
                selectedMeasures,
                measureWiseDataEntryForms,
                measureWiseDataEntryFormWiseMeasures,
                measureWiseDataEntryFormWiseReCalculateMeasures,
                measureWiseDataEntryFormWiseMeasureAggregateType,
            } = action.payload;
            return {
                ...state,
                selectedMeasures,
                measureWiseDataEntryForms,
                measureWiseDataEntryFormWiseMeasures,
                measureWiseDataEntryFormWiseReCalculateMeasures,
                measureWiseDataEntryFormWiseMeasureAggregateType,
                sortModeEnabled: false
            };
        }

        case ADD_EDIT_WIDGET_LOAD_MEASURES_BY_MEASURE_ID_SUCCESS : {
            let measureWiseDataEntryFormWiseMeasures = _.cloneDeep(state.measureWiseDataEntryFormWiseMeasures);
            let allMeasures = action.payload;

            _.each(_.keys(measureWiseDataEntryFormWiseMeasures), (mID) => {
                mID = parseInt(mID);
                _.each(_.keys(measureWiseDataEntryFormWiseMeasures[mID]), (dataEntryFormID) => {
                    dataEntryFormID = parseInt(dataEntryFormID);
                    let measures = measureWiseDataEntryFormWiseMeasures[mID][dataEntryFormID];
                    _.each(measures, (measure) => {
                        let measureID = measure.measureID;
                        let find = _.find(allMeasures, (i) => i.measureID === measureID);
                        if (find) {
                            measure.measureCode = find.measureCode;
                            measure.measureName = find.measureName;
                            measure.measureType = find.measureType;
                        }
                    });
                    measures = _.sortBy(measures, (x) => x.measureName);
                    measureWiseDataEntryFormWiseMeasures[mID][dataEntryFormID] = measures;
                });
            });

            return {
                ...state,
                measureWiseDataEntryFormWiseMeasures: measureWiseDataEntryFormWiseMeasures,
            };
        }

        case ADD_EDIT_WIDGET_LOAD_PROCESS_TEMPLATES_BY_ID_SUCCESS : {
            let allForms = action.payload;
            let measureWiseDataEntryForms = _.cloneDeep(state.measureWiseDataEntryForms);

            _.each(_.keys(measureWiseDataEntryForms), (mID) => {
                mID = parseInt(mID);
                _.each(measureWiseDataEntryForms[mID], (deForm) => {
                    let processTemplateID = deForm.processTemplateID;
                    let find = _.find(allForms, (i) => i.processTemplateID === processTemplateID);
                    if (find) {
                        deForm.frequencyName = find.frequency;
                        deForm.processTemplateName = find.processTemplateName;
                        deForm.processTemplateCode = find.processTemplateCode;
                    }
                });
            });

            return {
                ...state,
                measureWiseDataEntryForms: measureWiseDataEntryForms,
            };
        }

        case ADD_EDIT_WIDGET_REMOVE_DATA_ENTRY_FORM_MEASURE : {
            let {
                parentMeasureID,
                processTemplateID,
                measureID
            } = action.payload;
            let data = _.cloneDeep(state.measureWiseDataEntryFormWiseMeasures);

            console.log(data);

            let measures = data[parentMeasureID][processTemplateID];

            console.log(measures);

            let index = _.findIndex(measures, (m) => {
                return m.measureID === measureID;
            });


            measures.splice(index, 1);

            data[parentMeasureID][processTemplateID] = measures;
            return {
                ...state,
                measureWiseDataEntryFormWiseMeasures: data,
            };
        }

        case ADD_EDIT_WIDGET_REMOVE_DATA_ENTRY_FORM : {
            let {
                processTemplateID,
                measureID
            } = action.payload;
            let data = _.cloneDeep(state.measureWiseDataEntryForms);
            let dataForms = data[measureID];
            let index = _.findIndex(dataForms, (i) => i.processTemplateID === processTemplateID);
            dataForms.splice(index, 1);
            data[measureID] = dataForms;

            return {
                ...state,
                measureWiseDataEntryForms: data,
            };
        }

        case ADD_EDIT_WIDGET_REMOVE_MEASURE : {
            let {
                measureID
            } = action.payload;
            let data = _.cloneDeep(state.selectedMeasures);
            let index = _.findIndex(data, (i) => i.measureID === measureID);
            data.splice(index, 1);

            let widgetMeasureList = _.cloneDeep(state.selectedWidgetInfo.widgetProcessMeasureDTOS);
            let stackSortedMeasures = [];
            const measureList = [];
            const measureNameWiseMap = {};

            let selectedMeasureList = _.cloneDeep(data);
            _.forEach(selectedMeasureList, val => {
                measureList.push(val);
            });

            _.forEach(measureList, val => {
                measureNameWiseMap[val.measureName] = val;
                measureNameWiseMap[val.measureName]["stackedDisplayOrder"] = 0;
            });

            _.forEach(widgetMeasureList, val => {
                if (!_.isEmpty(measureNameWiseMap[val.measureName])) {
                    measureNameWiseMap[val.measureName] = val;
                }
            });
            _.map(measureNameWiseMap, function (value, key) {
                stackSortedMeasures.push(value);
            });
            stackSortedMeasures.sort((o1, o2) => {
                return o1.stackedDisplayOrder - o2.stackedDisplayOrder;
            });

            return {
                ...state,
                selectedMeasures: data,
                stackSortedMeasures: stackSortedMeasures,
                sortModeEnabled: false
            };
        }

        case ADD_EDIT_WIDGET_SORT : {
            return {
                ...state,
                selectedMeasures: action.payload
            }
        }

        case ADD_EDIT_WIDGET_STACK_SORT : {
            return {
                ...state,
                stackSortedMeasures: action.payload
            }
        }

        case ADD_EDIT_WIDGET_TOGGLE_SORT : {
            return {
                ...state,
                sortModeEnabled: !state.sortModeEnabled
            }
        }

        case ADD_EDIT_WIDGET_UPDATE_MEASURE_WISE_EXPANDED : {

            let measureID = action.payload.measureID;
            let expanded = action.payload.expanded;
            let measureWiseSectionExpanded = _.cloneDeep(state.measureWiseSectionExpanded);
            measureWiseSectionExpanded[measureID] = expanded;

            return {
                ...state,
                measureWiseSectionExpanded
            }
        }

        case ADD_EDIT_WIDGET_UPDATE_MEASURE_WISE_DISPLAY_IN_WIDGET : {
            let processWiseMeasureWiseDisplayInWidget = _.cloneDeep(state.processWiseMeasureWiseDisplayInWidget);
            let pTemplateID = action.payload.processTemplateID;
            let measureID = action.payload.measureID;
            let displayInWidget = action.payload.displayInWidget;

            if (!processWiseMeasureWiseDisplayInWidget[pTemplateID]) {
                processWiseMeasureWiseDisplayInWidget[pTemplateID] = {};
            }
            processWiseMeasureWiseDisplayInWidget[pTemplateID][measureID] = displayInWidget;

            return {
                ...state,
                processWiseMeasureWiseDisplayInWidget
            };
        }


        case ADD_EDIT_WIDGET_UPDATE_MEASURE_WISE_DISPLAY_TREND_LINE : {
            let processWiseMeasureWiseDisplayTrendLine = _.cloneDeep(state.processWiseMeasureWiseDisplayTrendLine);
            let pTemplateID = action.payload.processTemplateID;
            let measureID = action.payload.measureID;
            let displayTrendLine = action.payload.displayTrendLine;

            if (!processWiseMeasureWiseDisplayTrendLine[pTemplateID]) {
                processWiseMeasureWiseDisplayTrendLine[pTemplateID] = {};
            }
            processWiseMeasureWiseDisplayTrendLine[pTemplateID][measureID] = displayTrendLine;

            return {
                ...state,
                processWiseMeasureWiseDisplayTrendLine
            };
        }

        default :
            return state;
    }
}
