import {
    ADV_MEASURE_LOAD_SELECTED_MEASURES_BY_IDS_SUCCESS,
    ADV_MEASURE_LOAD_SELECTED_STANDARDS_BY_IDS_SUCCESS,
    ADV_MEASURE_RE_POPULATE_SELECTED_MEASURE,
    ADV_MEASURE_RESET,
    ADV_MEASURE_SELECT_MEASURE_TEST_EXPRESSION_SUCCESS,
    ADV_MEASURE_SELECT_MEASURE_TEST_VALUES,
    ADV_MEASURE_SELECT_MEASURE_UPDATE_FORMULA,
    ADV_MEASURE_SELECT_MEASURE_UPDATE_NAME,
    ADV_MEASURE_SELECT_MEASURES,
    ADV_MEASURE_SELECT_OPERATOR,
    ADV_MEASURE_SELECT_STANDARD_TEST_VALUES,
    ADV_MEASURE_SELECT_STANDARDS,
    ADV_MEASURE_SELECT_STANDARDS_UPDATE_NAME,
    ADV_MEASURE_SELECTED_COMPANY_ID,
    ADV_MEASURE_SELECTED_MEASURE_CATEGORY
} from "../actions/types";

const initialState = {
    selectedMeasures: [],
    selectedMeasureNames: {},
    selectedStandards: [],
    selectedStandardNames: {},
    formula: '',
    selectedMeasureTestValues: {},
    selectedStandardTestValues: {},
    testValueResult: null,
    selectedOperator: null,
    checkedMeasureObjReCreate: [],
    checkedStandardObjReCreate: [],
    selectedCompanyID: '',
    selectedMeasureCategory: ''
};

export default (state = initialState, action) => {
    switch (action.type) {

        case ADV_MEASURE_SELECT_MEASURES :
            return {
                ...state,
                selectedMeasures: action.payload
            };

        case ADV_MEASURE_SELECT_STANDARDS :
            return {
                ...state,
                selectedStandards: action.payload
            };

        case ADV_MEASURE_SELECT_MEASURE_UPDATE_NAME :
            return {
                ...state,
                selectedMeasureNames: action.payload,
                selectedMeasureTestValues: {}
            };

        case ADV_MEASURE_SELECT_STANDARDS_UPDATE_NAME :
            return {
                ...state,
                selectedStandardNames: action.payload,
                selectedStandardTestValues: {}
            };

        case ADV_MEASURE_SELECT_MEASURE_UPDATE_FORMULA :
            return {
                ...state,
                formula: action.payload
            };

        case ADV_MEASURE_SELECT_MEASURE_TEST_VALUES :
            return {
                ...state,
                selectedMeasureTestValues: action.payload
            };

        case ADV_MEASURE_SELECT_MEASURE_TEST_EXPRESSION_SUCCESS :
            return {
                ...state,
                testValueResult: action.payload.result
            };

        case ADV_MEASURE_SELECT_OPERATOR :
            return {
                ...state,
                selectedOperator: action.payload
            };

        case ADV_MEASURE_RE_POPULATE_SELECTED_MEASURE :
            return {
                ...state,
                selectedMeasureNames: action.payload.selectedMeasureNames,
                selectedStandardNames: action.payload.selectedStandardNames,
                formula: action.payload.formula,
            };

        case ADV_MEASURE_LOAD_SELECTED_MEASURES_BY_IDS_SUCCESS :
            return {
                ...state,
                selectedMeasures: action.payload,
                checkedMeasureObjReCreate: action.payload,
            };

        case ADV_MEASURE_LOAD_SELECTED_STANDARDS_BY_IDS_SUCCESS :
            return {
                ...state,
                selectedStandards: action.payload,
                checkedStandardObjReCreate: action.payload,
            };

        case ADV_MEASURE_RESET :
            return {
                selectedMeasures: [],
                selectedMeasureNames: {},
                selectedStandardNames: {},
                selectedStandards: [],
                formula: '',
                selectedMeasureTestValues: {},
                selectedStandardTestValues: {},
                testValueResult: null,
                selectedOperator: null,
                checkedMeasureObjReCreate: [],
                checkedStandardObjReCreate: [],
                selectedCompanyID: '',
                selectedMeasureCategory: ''
            };
        case ADV_MEASURE_SELECTED_COMPANY_ID:
            return {
                ...state,
                selectedCompanyID: action.payload
            };

        case ADV_MEASURE_SELECTED_MEASURE_CATEGORY:
            return {
                ...state,
                selectedMeasureCategory: action.payload
            };

        case ADV_MEASURE_SELECT_STANDARD_TEST_VALUES:
            return {
                ...state,
                selectedStandardTestValues: action.payload
            };

        default :
            return state;
    }
}
