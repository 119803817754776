import {
    PROJECTION_CLOSE_ADD_EDIT_MEASURE_VALUE_MODAL,
    PROJECTION_GET_DATA_FACTORY_PRODUCTION_LINES,
    PROJECTION_GET_DATA_FACTORY_PRODUCTION_LINES_SUCCESS,
    PROJECTION_GET_DATA_FACTORY_PRODUCTS,
    PROJECTION_GET_DATA_FACTORY_PRODUCTS_SUCCESS,
    PROJECTION_GET_PAGED_PROJECTIONS,
    PROJECTION_GET_PAGED_PROJECTIONS_SUCCESS,
    PROJECTION_GET_PROJECTION,
    PROJECTION_GET_PROJECTION_MEASURES,
    PROJECTION_GET_PROJECTION_MEASURES_SUCCESS,
    PROJECTION_GET_PROJECTION_SUCCESS,
    PROJECTION_OPEN_ADD_EDIT_MEASURE_VALUE_MODAL,
    PROJECTION_RESET_DATA,
    PROJECTION_SAVE_BASE_PROJECTION,
    PROJECTION_SAVE_BASE_PROJECTION_SUCCESS,
    PROJECTION_SAVE_PROJECTION_MEASURE,
    PROJECTION_SAVE_PROJECTION_MEASURE_SUCCESS,
    PROJECTION_SAVE_PROJECTION_MEASURE_VALUES,
    PROJECTION_SAVE_PROJECTION_MEASURE_VALUES_SUCCESS,

} from "../actions/types";
import {getPagedData} from "../../utils/DataExtractHelper";
import * as _ from 'lodash';

const initialState = {

    projectionPageInfo: {},
    projections: [],

    projectionDTO: {},
    projectionMeasures: [],

    factoryProducts: [],
    factoryProductionLines: [],

    selectedMeasures: [],

    showAddEditMeasureValueModal: false,
    addEditSelectedMeasure: {},
    addEditMeasureValue: {},

};

export default (state = initialState, action) => {
    switch (action.type) {

        case PROJECTION_GET_DATA_FACTORY_PRODUCTS :
        case PROJECTION_GET_DATA_FACTORY_PRODUCTION_LINES :
        case PROJECTION_GET_PROJECTION :
        case PROJECTION_GET_PROJECTION_MEASURES :
        case PROJECTION_SAVE_BASE_PROJECTION :
        case PROJECTION_GET_PAGED_PROJECTIONS :
        case  PROJECTION_SAVE_PROJECTION_MEASURE :
        case  PROJECTION_SAVE_PROJECTION_MEASURE_VALUES : {
            return {
                ...state
            };
        }

        case PROJECTION_GET_DATA_FACTORY_PRODUCTS_SUCCESS :
            return {
                ...state,
                factoryProducts: action.payload,
            };

        case PROJECTION_GET_DATA_FACTORY_PRODUCTION_LINES_SUCCESS :
            return {
                ...state,
                factoryProductionLines: action.payload,
            };

        case PROJECTION_GET_PROJECTION_SUCCESS :
            return {
                ...state,
                projectionDTO: action.payload,
            };

        case PROJECTION_GET_PROJECTION_MEASURES_SUCCESS :
            return {
                ...state,
                projectionMeasures: action.payload,
                selectedMeasures : action.payload
            };

        case PROJECTION_RESET_DATA : {
            return {
                ...state,
                projectionDTO: {},
                projectionMeasures: [],
                selectedMeasures: [],

                factoryProducts: [],
                factoryProductionLines: [],
                showAddEditMeasureValueModal: false,
                addEditSelectedMeasure: {},
                addEditMeasureValue: {},
            };
        }

        case PROJECTION_SAVE_BASE_PROJECTION_SUCCESS : {
            return {
                ...state,
                projectionDTO: action.payload
            }
        }

        case PROJECTION_GET_PAGED_PROJECTIONS_SUCCESS : {
            return {
                ...state,
                projections: action.payload.pageData,
                projectionPageInfo: getPagedData(action.payload)
            }
        }

        case PROJECTION_SAVE_PROJECTION_MEASURE_SUCCESS :
        case PROJECTION_SAVE_PROJECTION_MEASURE_VALUES_SUCCESS : {
            return {
                ...state,
                projectionMeasures: action.payload,
                selectedMeasures : action.payload
            };
        }

        case PROJECTION_OPEN_ADD_EDIT_MEASURE_VALUE_MODAL : {
            return {
                ...state,
                showAddEditMeasureValueModal: true,
                addEditMeasureValue: action.payload.projectionMeasureValue,
                addEditSelectedMeasure: action.payload.projectionMeasure,
            }
        }

        case PROJECTION_CLOSE_ADD_EDIT_MEASURE_VALUE_MODAL : {
            return {
                ...state,
                showAddEditMeasureValueModal: false,
                addEditSelectedMeasure: {},
                addEditMeasureValue: {}
            }
        }


        default :
            return state;
    }
}
