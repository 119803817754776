import {
    DASHBOARD_CLEAR_DATE_RANGE,
    DASHBOARD_COPY_CREATED,
    DASHBOARD_CREATED,
    DASHBOARD_GET_DASHBOARD_BY_USER_ID,
    DASHBOARD_GET_DASHBOARD_BY_USER_ID_SUCCESS,
    DASHBOARD_GET_DOWNTIME_DATA,
    DASHBOARD_GET_DOWNTIME_DATA_SUCCESS,
    DASHBOARD_GET_ELIGIBLE_USERS_SUCCESS,
    DASHBOARD_GET_FACTORY_PRODUCTION_LINES,
    DASHBOARD_GET_FACTORY_PRODUCTION_LINES_SUCCESS,
    DASHBOARD_GET_FACTORY_PRODUCTS,
    DASHBOARD_GET_FACTORY_PRODUCTS_SUCCESS,
    DASHBOARD_GET_FACTORY_SHIFTS,
    DASHBOARD_GET_FACTORY_SHIFTS_SUCCESS,
    DASHBOARD_GET_FACTORY_SUPERVISORS,
    DASHBOARD_GET_FACTORY_SUPERVISORS_SUCCESS,
    DASHBOARD_GET_SELECTED_WIDGET_SUCCESS,
    DASHBOARD_GET_SHARED_USERS_BY_DASHBOARD_ID_SUCCESS,
    DASHBOARD_GET_WASTAGE_DATA,
    DASHBOARD_GET_WASTAGE_DATA_SUCCESS,
    DASHBOARD_GET_WIDGET,
    DASHBOARD_GET_WIDGET_DATA_NEW,
    DASHBOARD_GET_WIDGET_DATA_NEW_SUCCESS,
    DASHBOARD_GET_WIDGET_DATA_SUCCESS,
    DASHBOARD_GET_WIDGET_SUCCESS,
    DASHBOARD_HIDE_FILTER_MODAL,
    DASHBOARD_ON_APPLY_FILTER_TO_WIDGET,
    DASHBOARD_RESET,
    DASHBOARD_RESET_SHARED_DASHBOARD,
    DASHBOARD_RESTORE_DASHBOARD_REDUCER,
    DASHBOARD_SAVE_DASHBOARD_REDUCER,
    DASHBOARD_SET_CACHED_DATE_RANGE_TO_REDUCER,
    DASHBOARD_SET_DASHBOARD_DATE_RANGE,
    DASHBOARD_SET_DASHBOARD_ID,
    DASHBOARD_SET_DASHBOARD_NAME,
    DASHBOARD_SET_DASHBOARD_UPDATED_WIDGET_COLOR,
    DASHBOARD_SET_LAYOUTS,
    DASHBOARD_SET_NEW_DASHBOARD_CREATED,
    DASHBOARD_SET_SELECTED_USERS,
    DASHBOARD_SHOW_FILTER_MODAL,
    DASHBOARD_TOGGLE_IS_EDITABLE,
    DASHBOARD_UPDATE_DASHBOARD_DTOS,
    DASHBOARD_UPDATE_DOWNTIME_WASTAGE_WIDGET_FILTERS,
    DASHBOARD_UPDATE_FILTER_DATES_GLOBAL,
    DASHBOARD_WIDGET_DATA_AGGREGATE,
    SAVE_UPDATE_DASHBOARD_SUCCESS
} from "../actions/types";
import {getPagedData} from "../../utils/DataExtractHelper";
import * as _ from 'lodash';
import Constants from "../../utils/Constants";

const initialState = {
    widget: [],
    userDashboardDTO: {},
    dashboardCreated: false,
    userDashboardsLoading: false,
    userDashboardDTOS: [],
    layouts: {},
    dashboardName: null,
    widgetData: [],
    dashboardDateRange: {},
    dashboardDateRangeManualChange: false,
    activeDashboardID: -1,
    isEditable: false,
    isNewDashboardCreated: false,
    isDashboardCopy: false,
    pageInfo: {},
    eligibleUsers: [],
    selectedUsers: [],
    dashboardSharedUsers: [],
    selectedWidget: {},
    updatedWidgetColors: {},

    showFilterModalStatus: false,
    showFilterModalWidgetID: null,
    aggregateWidgetIDs: {},
    widgetWiseAppliedFilters: {},
    downTimeWastageWidgetWiseAppliedMeasureFilters: {},

    factoryProducts: [],
    factoryProductionLines: [],
    factoryShifts: [],
    factorySupervisors: [],

    selectedDowntimeWidgetIDs: [],
    downTimeWidgetWiseData: {},

    selectedWasteWidgetIDs: [],
    wasteWiseData: {},

    widgetIDWiseDashboardWidgetDataMap: {},
    widgetIDWiseShowLoading: {}
};

export default (state = initialState, action) => {
    switch (action.type) {

        case DASHBOARD_GET_WIDGET:
        case DASHBOARD_GET_FACTORY_PRODUCTS :
        case DASHBOARD_GET_FACTORY_PRODUCTION_LINES :
        case DASHBOARD_GET_FACTORY_SHIFTS :
        case DASHBOARD_GET_FACTORY_SUPERVISORS :
            return {
                ...state
            };

        case DASHBOARD_GET_WIDGET_SUCCESS :
            return {
                ...state,
                widget: action.payload
            };
        case SAVE_UPDATE_DASHBOARD_SUCCESS :
            return {
                ...state,
                userDashboardDTO: action.payload
            };
        case DASHBOARD_CREATED :
            return {
                ...state,
                dashboardCreated: true
            };

        case DASHBOARD_GET_DASHBOARD_BY_USER_ID: {
            return {
                ...state,
                userDashboardsLoading: true
            }
        }
        case DASHBOARD_GET_DASHBOARD_BY_USER_ID_SUCCESS: {

            let downtimeWidgetIDs = [];
            let wasteWidgetIDs = [];
            let userDashboardDTOS = action.payload;
            let activeDashboardID = -1;
            userDashboardDTOS = _.uniqBy(userDashboardDTOS, 'userDashboardID');

            _.each(userDashboardDTOS, (userDashboard) => {
                _.each(userDashboard.userDashboardWidgetDTOS, (widgetDtos) => {
                    if (widgetDtos.widgetDTO.widgetCategory === Constants.WIDGET_CATEGORY_CONST.DOWNTIME_REPORT) {
                        downtimeWidgetIDs.push(widgetDtos.widgetDTO.widgetID);
                    } else if (widgetDtos.widgetDTO.widgetCategory === Constants.WIDGET_CATEGORY_CONST.WASTAGE_REPORT) {
                        wasteWidgetIDs.push(widgetDtos.widgetDTO.widgetID);
                    }
                });
            });

            if (userDashboardDTOS && userDashboardDTOS[0]) {
                activeDashboardID = userDashboardDTOS[0].userDashboardID;
            }

            downtimeWidgetIDs = _.uniq(downtimeWidgetIDs);
            wasteWidgetIDs = _.uniq(wasteWidgetIDs);

            return {
                ...state,
                userDashboardDTOS: userDashboardDTOS,
                selectedDowntimeWidgetIDs: downtimeWidgetIDs,
                selectedWasteWidgetIDs: wasteWidgetIDs,
                activeDashboardID: activeDashboardID,
                userDashboardsLoading: false
            };
        }

        case DASHBOARD_RESET :
            return {
                ...state,
                widget: [],
                userDashboardDTO: {},
                dashboardCreated: false,
                userDashboardsLoading: false,
                userDashboardDTOS: [],
                layouts: {},
                dashboardName: null,
                widgetData: [],
                dashboardDateRange: {},
                dashboardDateRangeManualChange: false,
                activeDashboardID: -1,
                isEditable: false,
                isNewDashboardCreated: false,
                isDashboardCopy: false,
                pageInfo: {},
                eligibleUsers: [],
                selectedUsers: [],
                dashboardSharedUsers: [],
                selectedWidget: {},
                updatedWidgetColors: {},

                showFilterModalStatus: false,
                showFilterModalWidgetID: null,
                aggregateWidgetIDs: {},
                widgetWiseAppliedFilters: {},
                downTimeWastageWidgetWiseAppliedMeasureFilters: {},

                factoryProducts: [],
                factoryProductionLines: [],
                factoryShifts: [],
                factorySupervisors: [],

                selectedDowntimeWidgetIDs: [],
                downTimeWidgetWiseData: {},

                selectedWasteWidgetIDs: [],
                wasteWiseData: {},

                widgetIDWiseDashboardWidgetDataMap: {},
                widgetIDWiseShowLoading: {}
            };
        case DASHBOARD_SET_LAYOUTS:
            return {
                ...state,
                layouts: action.payload
            };
        case DASHBOARD_UPDATE_DASHBOARD_DTOS:
            return {
                ...state,
                userDashboardDTOS: action.payload,
                userDashboardDTO: {}
            };
        case DASHBOARD_SET_DASHBOARD_NAME:
            return {
                ...state,
                dashboardName: action.payload
            };
        case DASHBOARD_GET_WIDGET_DATA_SUCCESS:
            let widgetData = {};
            widgetData = action.payload.response;
            return {
                ...state,
                widgetData: widgetData,
            };

        case DASHBOARD_ON_APPLY_FILTER_TO_WIDGET : {
            let dashboardWidgetID = action.payload.dashboardWidgetID;
            let filterData = action.payload.filterData;
            let widgetWiseAppliedFilters = _.cloneDeep(state.widgetWiseAppliedFilters);
            widgetWiseAppliedFilters[dashboardWidgetID] = filterData;

            return {
                ...state,
                widgetWiseAppliedFilters: widgetWiseAppliedFilters
            };
        }

        case DASHBOARD_UPDATE_FILTER_DATES_GLOBAL : {
            let userDashboardID = action.payload.userDashboardID;
            let widgetIDList = action.payload.widgetIDList;
            let fromDate = action.payload.fromDate;
            let toDate = action.payload.toDate;

            let widgetWiseAppliedFilters = _.cloneDeep(state.widgetWiseAppliedFilters);
            widgetIDList = widgetIDList ? widgetIDList : [];

            widgetIDList.forEach((wID) => {
                let key = userDashboardID + "_" + wID;
                let filter = widgetWiseAppliedFilters[key];
                if (filter) {
                    filter.fromDate = fromDate;
                    filter.toDate = toDate;
                }
            });

            return {
                ...state,
                widgetWiseAppliedFilters: widgetWiseAppliedFilters
            };
        }

        case DASHBOARD_SET_DASHBOARD_DATE_RANGE:
            let data = _.cloneDeep(state.dashboardDateRange);
            data[action.payload.dashboardID] = action.payload.dateRange;
            return {
                ...state,
                dashboardDateRange: data,
                dashboardDateRangeManualChange: action.payload.isManual
            };
        case DASHBOARD_SET_DASHBOARD_ID:
            return {
                ...state,
                activeDashboardID: action.payload
            };
        case DASHBOARD_TOGGLE_IS_EDITABLE:
            return {
                ...state,
                isEditable: !state.isEditable
            };
        case DASHBOARD_SET_NEW_DASHBOARD_CREATED:
            return {
                ...state,
                isNewDashboardCreated: action.payload
            };
        case DASHBOARD_COPY_CREATED:
            return {
                ...state,
                isDashboardCopy: action.payload
            };
        case DASHBOARD_GET_ELIGIBLE_USERS_SUCCESS:
            return {
                ...state,
                pageInfo: getPagedData(action.payload),
                eligibleUsers: action.payload.pageData
            };
        case DASHBOARD_SET_SELECTED_USERS:
            return {
                ...state,
                selectedUsers: action.payload
            };
        case DASHBOARD_GET_SHARED_USERS_BY_DASHBOARD_ID_SUCCESS:
            return {
                ...state,
                dashboardSharedUsers: action.payload
            };
        case DASHBOARD_GET_SELECTED_WIDGET_SUCCESS:
            return {
                ...state,
                selectedWidget: action.payload
            };
        case DASHBOARD_SET_DASHBOARD_UPDATED_WIDGET_COLOR:
            let colorData = Object.assign({}, state.updatedWidgetColors);
            colorData[action.payload.widgetID] = action.payload.updatedColorList;
            return {
                ...state,
                updatedWidgetColors: colorData
            };
        case DASHBOARD_RESET_SHARED_DASHBOARD :
            return {
                ...state,
                dashboardSharedUsers: [],
                selectedUsers: []
            };

        case DASHBOARD_SET_CACHED_DATE_RANGE_TO_REDUCER:
            return {
                ...state,
                dashboardDateRange: action.payload,
                dashboardDateRangeManualChange: false
            };

        case DASHBOARD_CLEAR_DATE_RANGE:
            return {
                ...state,
                dashboardDateRange: {}
            };

        case DASHBOARD_SHOW_FILTER_MODAL:
            return {
                ...state,
                showFilterModalStatus: true,
                showFilterModalWidgetID: action.payload
            };

        case DASHBOARD_HIDE_FILTER_MODAL:
            return {
                ...state,
                showFilterModalStatus: false,
                showFilterModalWidgetID: null
            };

        case DASHBOARD_WIDGET_DATA_AGGREGATE:
            let aggregateWidgetIDs = Object.assign({}, state.aggregateWidgetIDs);
            aggregateWidgetIDs[action.payload.widgetID] = action.payload.status;

            return {
                ...state,
                aggregateWidgetIDs: aggregateWidgetIDs
            };

        case DASHBOARD_GET_FACTORY_PRODUCTS_SUCCESS : {
            return {
                ...state,
                factoryProducts: action.payload,
            }
        }

        case DASHBOARD_GET_FACTORY_PRODUCTION_LINES_SUCCESS : {
            return {
                ...state,
                factoryProductionLines: action.payload,
            }
        }

        case DASHBOARD_GET_FACTORY_SHIFTS_SUCCESS : {
            return {
                ...state,
                factoryShifts: action.payload,
            }
        }

        case DASHBOARD_GET_FACTORY_SUPERVISORS_SUCCESS : {
            return {
                ...state,
                factorySupervisors: action.payload,
            }
        }

        case DASHBOARD_GET_DOWNTIME_DATA_SUCCESS : {
            let downTimeWidgetWiseData = _.cloneDeep(state.downTimeWidgetWiseData);
            let widgetIDWiseShowLoading = _.cloneDeep(state.widgetIDWiseShowLoading);
            let payload = action.payload.result;
            let widgetIDs = action.payload.widgetIDs;

            if (!_.isEmpty(payload.widgetWiseData)) {
                _.each(_.keys(payload.widgetWiseData), (widgetID) => {
                    downTimeWidgetWiseData[widgetID] = payload.widgetWiseData[widgetID];
                });
            } else {
                widgetIDs.forEach((wID) => {
                    downTimeWidgetWiseData[wID] = {};
                });
            }

            widgetIDs.forEach((wID) => {
                widgetIDWiseShowLoading[wID] = false;
            });

            return {
                ...state,
                downTimeWidgetWiseData: downTimeWidgetWiseData,
                widgetIDWiseShowLoading: widgetIDWiseShowLoading
            }
        }

        case DASHBOARD_GET_WASTAGE_DATA_SUCCESS : {
            let wasteWiseData = _.cloneDeep(state.wasteWiseData);
            let widgetIDWiseShowLoading = _.cloneDeep(state.widgetIDWiseShowLoading);
            let payload = action.payload.result;
            let widgetIDs = action.payload.widgetIDs;

            if (!_.isEmpty(payload.widgetWiseData)) {
                _.each(_.keys(payload.widgetWiseData), (widgetID) => {
                    wasteWiseData[widgetID] = payload.widgetWiseData[widgetID];
                });
            } else {
                widgetIDs.forEach((wID) => {
                    wasteWiseData[wID] = {};
                });
            }

            widgetIDs.forEach((wID) => {
                widgetIDWiseShowLoading[wID] = false;
            });

            return {
                ...state,
                wasteWiseData: wasteWiseData,
                widgetIDWiseShowLoading: widgetIDWiseShowLoading
            }
        }

        case DASHBOARD_GET_WIDGET_DATA_NEW :
        case DASHBOARD_GET_DOWNTIME_DATA :
        case DASHBOARD_GET_WASTAGE_DATA : {

            let request = JSON.parse(action.payload.request.data);
            let widgetIDs = request.widgetIDs;
            let widgetIDWiseShowLoading = _.cloneDeep(state.widgetIDWiseShowLoading);

            if (widgetIDs) {
                widgetIDs.forEach((wID) => {
                    widgetIDWiseShowLoading[wID] = true;
                });
            }

            return {
                ...state,
                widgetIDWiseShowLoading: widgetIDWiseShowLoading
            };
        }

        case DASHBOARD_GET_WIDGET_DATA_NEW_SUCCESS : {

            let widgetIDWiseDashboardWidgetDataMap = _.cloneDeep(state.widgetIDWiseDashboardWidgetDataMap);
            let widgetIDWiseShowLoading = _.cloneDeep(state.widgetIDWiseShowLoading);

            let widgetIDs = action.payload.widgetIDs;

            _.each(widgetIDs, (widgetID) => {
                widgetIDWiseDashboardWidgetDataMap[widgetID] = action.payload.response[widgetID];
                widgetIDWiseShowLoading[widgetID] = false;
            });

            return {
                ...state,
                widgetIDWiseDashboardWidgetDataMap: widgetIDWiseDashboardWidgetDataMap,
                widgetIDWiseShowLoading: widgetIDWiseShowLoading
            }
        }

        case DASHBOARD_SAVE_DASHBOARD_REDUCER : {

            let loggedInUser = Constants.LOGGED_IN_USER;
            if (loggedInUser) {
                let userID = loggedInUser.userID;
                if (userID) {
                    localStorage.setItem(Constants.STORAGE.SAVED_USER_DASHBOARD_DATA, JSON.stringify({
                        userID: userID,
                        dashboardDateRange: state.dashboardDateRange,
                        activeDashboardID: state.activeDashboardID,
                        widgetWiseAppliedFilters: state.widgetWiseAppliedFilters,
                        downTimeWastageWidgetWiseAppliedMeasureFilters: state.downTimeWastageWidgetWiseAppliedMeasureFilters,
                    }));
                }
            }

            return {
                ...state
            }
        }

        case DASHBOARD_RESTORE_DASHBOARD_REDUCER : {
            let loggedInUser = Constants.LOGGED_IN_USER;
            let newState = state;
            if (loggedInUser) {
                let userID = loggedInUser.userID;
                if (userID) {
                    let data = localStorage.getItem(Constants.STORAGE.SAVED_USER_DASHBOARD_DATA);
                    if (data) {
                        let parsedData = JSON.parse(data);
                        if (parsedData.userID === userID) {
                            delete parsedData.userID;
                            newState = Object.assign({}, _.cloneDeep(newState), parsedData);
                        }
                    }
                }
            }

            return {
                ...newState
            }
        }

        case DASHBOARD_UPDATE_DOWNTIME_WASTAGE_WIDGET_FILTERS : {

            const {userDashboardID, widgetID, selectedMeasureData} = action.payload;
            let clone = _.cloneDeep(state.downTimeWastageWidgetWiseAppliedMeasureFilters);

            let key = userDashboardID + '_' + widgetID;
            clone[key] = selectedMeasureData;

            return {
                ...state,
                downTimeWastageWidgetWiseAppliedMeasureFilters: clone,
            }
        }

        default:
            return state;
    }
};
