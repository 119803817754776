const Messages = {
    MEASURE_ADD_SUCCESS: "Performance Measure Save/Updated",
    BUSINESS_UNIT_ADD_SUCCESS: "Department Save/Updated",
    COMPANY_ADD_SUCCESS: "Company Save/Updated",
    LOCATION_ADD_SUCCESS: "Location Save/Updated",
    ROLE_ADD_SUCCESS: "Role Save/Updated",
    USER_ADD_SUCCESS: "User Save/Updated",
    MEASURE_UNIT_CATEGORY_ADD_SUCCESS: "Unit Category Save/Updated",
    MEASURE_GROUP_ADD_SUCCESS: "Site Performance Set-up Save/Updated",
    PRODUCT_ADD_SUCCESS: "Product Save/Updated",
    PRODUCT_BULK_UPLOAD_SUCCESS: "Product Bulk upload success",
    MEASURE_BULK_UPLOAD_SUCCESS: "Measure Bulk upload success",
    MEASURE_UNIT_ADD_SUCCESS: "Unit Save/Update",
    SUB_BUSINESS_UNIT_ADD_SUCCESS: "Production Line Save/Updated",
    SUPERVISOR_ADD_SUCCESS: "Supervisor Save/Updated",
    PROCESS_TEMPLATE_ADD_SUCCESS: "Build Data Entry Form Save/Updated",
    PROCESS_TEMPLATE_REPLICATE_SUCCESS: "Build Data Entry Form Replicated",
    PROCESS_DATA_ADD_UPDATE_SUCCESS: "Data Entry Form Data Inputs Save/Updated successfully",
    PROCESS_TARGET_AND_BUDGET_VALUE_ROLL_FORWARD: "Data Entry Form target and budget value updated successfully",
    USER_RESET_PASSWORD: "Password Reset Successfully",
    ADD_EDIT_WIDGET: "Widget Configuration Successfully Saved/Updated",
    ADD_EDIT_REPLICATE_WIDGET: "Widget Configuration Successfully Replicated",
    DASHBOARD_CREATED_SUCCESS: "Dashboard Created Successfully",
    NO_DATA_FOUND: "No data found",
    DASHBOARD_SHARED_SUCCESS: "Dashboard Shared Successfully",
    REPORT_CREATE_UPDATE_BASIC_REPORT_TEMPLATE: "Report Template Successfully Saved / Updated",
    PROCESS_DATA_ENTRY_FORM_UPLOAD: "Data Entry Form Uploaded Successfully. Refresh For Updated Data",
    REPORT_GENERATE_DOWNLOAD_XLS_OR_PDF: "Generated Report Downloaded Successfully",
    REPORT_GENERATE_XLS_OR_PDF_ERROR: "No data in selected date range",
    REPORT_GENERATE_VIEW_HTML: "Generated Report HTML View Success",
    REPORT_GENERATE_VIEW_HTML_ERROR: "No data in selected date range",
    REPORT_GENERATE_VIEW_PDF: "Generated Report PDF View Success",
    USER_GROUP_ADD_SUCCESS: "User Group Save/Update",
    REPORT_SCHEDULER_DELETED: "Scheduler Deleted Successfully",
    REPORT_SCHEDULER_CREATE_UPDATE: "Scheduler Save/Update Successfully",
    NOTIFICATION_UPDATE_SUCCESS: "Notification Status Updated Successfully",
    USER_CHANGE_PASSWORD_SUCCESS: "Changed Password Successfully",
    USER_NO_COMPANY_SELECT: "Please select at least one company",
    USER_NO_FACTORY_SELECT: "Please select at least one factory",
    USER_NO_DEPARTMENT_SELECT: "Please select at least one department",
    USER_NO_PRODUCTION_LINE_SELECT: "Please select at least one production line",
    LABOUR_CLASSIFICATION_ADD_SUCCESS: "Labour Classification  Saved/Updated Successfully",
    FACTORY_SCHEDULE_ADD_SUCCESS: "Shift  Saved/Updated Successfully",
    FACTORY_SHIFT_ADD_ERROR: "No shift found",
    STANDARD_BULK_UPLOAD_SUCCESS: "Standard Bulk upload success",
    DATA_ENTRY_UPLOAD_SUCCESS: "CSV Successfully uploaded. It will take few minutes to populate updated data",
    FACTORY_SHIFT_COPIED_SUCCESS: "Selected Week Successfully Copied",
    FACTORY_SHIFT_REMOVE_SUCCESS: "Selected Week Successfully Removed",
    STANDARD_DATA_ADD_SUCCESS: "Standard Data Save/Updated",
    STANDARD_ADD_SUCCESS: "Standard Save/Updated",
    DOWN_TIME_CAUSE_ADD_SUCCESS: "Down Time Cause Saved/Updated Successfully",
    DATA_FORM_ENTRY_SUCCESS: "Data Saved Successfully",
    PROJECTION_ADD_SUCCESS: "Base Projection Saved/Updated Successfully",
    WASTE_CAUSE_ADD_SUCCESS: "Waste Cause Saved Successfully",
    REPORT_GENERATION_NO_TO_DATE: "Please Select a End Date",
    DATA_FORM_CREATION_SUCCESS : 'Data Entry Form Saved / Updated'
};

export default Messages;
