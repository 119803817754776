import {
    LOG_IN,
    LOG_IN_FAILED,
    LOG_IN_FAILED_CLEAR_ALERT,
    LOG_IN_SUCCESS,
    LOG_OUT, LOG_OUT_FAILED, LOG_OUT_SUCCESS,
    RESTORE_LOGIN_DATA
} from '../actions/types';
import {decrypt, encrypt} from '../../utils/EncryptionHelper'
import Constants from '../../utils/Constants';

export const logIn = (username, password) => {
    return {
        type: LOG_IN,
        payload: {
            request: {
                method: 'POST',
                attachAuthToHeader: false,
                url: `/login`,
                data: {
                    username, password
                },
                headers: {
                    showLoading: true,
                },
            },
            options: {
                onSuccess({getState, dispatch, response}) {
                    setLogInData(response.data);
                    dispatch({
                        type: LOG_IN_SUCCESS,
                        payload: {
                            user: response.data.user,
                            accessToken: response.data.accessToken,
                            refreshToken: response.data.refreshToken,
                        }
                    });
                },
                onError({getState, dispatch, error}) {
                    removeLoginDataFromLocalStorage();
                    dispatch({
                        type: LOG_IN_FAILED,
                        payload: 'Invalid username or password !'
                    });
                },
            }
        }
    };
};

export const clearLoginError = () => {
    return {
        type: LOG_IN_FAILED_CLEAR_ALERT,
        payload: ''
    }
};

const setLogInData = (data) => {
    localStorage.setItem(Constants.STORAGE.ACCESS_TOKEN, encrypt(data.accessToken));
    localStorage.setItem(Constants.STORAGE.REFRESH_TOKEN, encrypt(data.refreshToken));
    localStorage.setItem(Constants.STORAGE.LOGGED_USER_ENC, encrypt(JSON.stringify(data.user)));

    Constants.ACCESS_TOKEN = data.accessToken;
    Constants.REFRESH_TOKEN = data.refreshToken;
    Constants.LOGGED_IN_USER = data.user;
};

export const logOut = () => {
    return {
        type: LOG_OUT,
        payload: {
            request: {
                method: 'POST',
                attachAuthToHeader: true,
                url: `api/security/log-out`,
                data: JSON.stringify({}),
                headers: {
                    showLoading: false,
                },
            },
            options: {
                onSuccess({getState, dispatch, response}) {
                    removeLoginDataFromLocalStorage();
                    return {
                        action : LOG_OUT_SUCCESS,
                        payload : ''
                    };
                },
                onError({getState, dispatch, error}) {
                    removeLoginDataFromLocalStorage();
                    console.log(error);
                    return {
                        action : LOG_OUT_FAILED,
                        payload : ''
                    };
                },
            }
        }
    };
};

export const restoreLoginState = () => {
    let accessTokenEnc = localStorage.getItem(Constants.STORAGE.ACCESS_TOKEN);
    let refreshTokenEnc = localStorage.getItem(Constants.STORAGE.REFRESH_TOKEN);
    let userEnc = localStorage.getItem(Constants.STORAGE.LOGGED_USER_ENC);

    if (accessTokenEnc && refreshTokenEnc && userEnc) {
        let user = JSON.parse(decrypt(userEnc));
        let accessToken = decrypt(accessTokenEnc);
        let refreshToken = decrypt(refreshTokenEnc);

        Constants.ACCESS_TOKEN = accessToken;
        Constants.REFRESH_TOKEN = refreshToken;
        Constants.LOGGED_IN_USER = user;

        return {
            type: RESTORE_LOGIN_DATA,
            payload: {
                user: user,
                accessToken: accessToken,
                refreshToken: refreshToken,
            }
        };
    }

    return {
        type: RESTORE_LOGIN_DATA,
        payload: {}
    };
};

export const removeLoginDataFromLocalStorage = () => {
    localStorage.removeItem(Constants.STORAGE.ACCESS_TOKEN);
    localStorage.removeItem(Constants.STORAGE.REFRESH_TOKEN);
    localStorage.removeItem(Constants.STORAGE.LOGGED_USER_ENC);

    Constants.ACCESS_TOKEN = null;
    Constants.REFRESH_TOKEN = null;
    Constants.LOGGED_IN_USER = null;
};
