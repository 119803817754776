import {
    DOWN_TIME_CAUSE_ADD_NEW_DOWN_TIME_CAUSE,
    DOWN_TIME_CAUSE_GET_SELECTED_DOWN_TIME_CAUSE,
    DOWN_TIME_CAUSE_GET_SELECTED_DOWN_TIME_CAUSE_SUCCESS,
    DOWN_TIME_CAUSE_RESET_DATA,
    DOWN_TIME_CAUSE_RESET_DOWN_TIME_CAUSE,
    DOWN_TIME_CAUSE_SEARCH,
    DOWN_TIME_CAUSE_SEARCH_SUCCESS,
    DOWN_TIME_UPDATE_DOWN_TIME_CAUSE_SUB_CATEGORIES
} from "../actions/types";
import {getPagedData} from "../../utils/DataExtractHelper";

const initialState = {
    pageInfo: {},
    downTimeCauses: [],
    selectedDownTimeCause: {},
    categoryData: [],
    addedDowntimeCauseSubCategories: [],
};

export default (state = initialState, action) => {
    switch (action.type) {

        case DOWN_TIME_CAUSE_SEARCH:
        case DOWN_TIME_CAUSE_GET_SELECTED_DOWN_TIME_CAUSE:
            return {
                ...state
            };

        case DOWN_TIME_CAUSE_SEARCH_SUCCESS:
            return {
                ...state,
                downTimeCauses: action.payload.pageData,
                pageInfo: getPagedData(action.payload)
            };

        case DOWN_TIME_CAUSE_ADD_NEW_DOWN_TIME_CAUSE:
            return {
                ...state,
                selectedDownTimeCause: {}
            };
        case DOWN_TIME_CAUSE_GET_SELECTED_DOWN_TIME_CAUSE_SUCCESS:
        case DOWN_TIME_CAUSE_RESET_DOWN_TIME_CAUSE:
            return {
                ...state,
                selectedDownTimeCause: action.payload,
                addedDowntimeCauseSubCategories: action.payload.downtimeCauseSubCategories
            };

        case DOWN_TIME_UPDATE_DOWN_TIME_CAUSE_SUB_CATEGORIES:
            return {
                ...state,
                addedDowntimeCauseSubCategories: action.payload
            };

        case DOWN_TIME_CAUSE_RESET_DATA:
            return {
                ...state,
                downTimeCauses: [],
                selectedDownTimeCause: {},
                categoryData: [],
                addedDowntimeCauseSubCategories: []
            };

        default:
            return state;

    }
};
