import {
    SAVE_UPDATE_FACTORY_OPERATION_SCHEDULE_SUCCESS,
    SHIFT_ADD_NEW_SHIFT,
    SHIFT_ADD_TIME_SLOT_MODAL_CLOSE,
    SHIFT_ADD_TIME_SLOT_MODAL_OPEN,
    SHIFT_ADD_TIME_SLOT_MODAL_OPEN_ID,
    SHIFT_APPEND_FACTORY_OPERATION_DAYS,
    SHIFT_CLEAR_DATA,
    SHIFT_GET_SELECTED_SHIFT_SUCCESS,
    SHIFT_ON_REMOVE_ADDED_TIME_DTO,
    SHIFT_SEARCH,
    SHIFT_SEARCH_SUCCESS,
    SHIFT_SET_EDIT_DATA_FOR_MODAL,
    SHIFT_SET_FACTORY_OPERATION_DAYS,
    SHIFT_SET_FACTORY_OPERATION_DAYTIME_FOR_FACTORY_OPERATION_DAYS,
    SHIFT_SET_FACTORY_OPERATION_SCHEDULE,
    SHIFT_SET_SELECTED_SHIFT,
    SHIFT_UPDATE_FACTORY_OPERATION_DAYS,
    SHIFT_UPDATE_FACTORY_OPERATION_DAYTIME_FOR_FACTORY_OPERATION_DAYS
} from "../actions/types";
import {getPagedData} from "../../utils/DataExtractHelper";

const initialState = {
    pageInfo: {},
    shifts: [],
    selectedShift: {},

    showAddTimeSlotModalState: false,
    showAddTimeSlotModalID: null,
    factoryOperationDays: [],
    factoryOperationSchedule: {},
    selectedDataForEdit: {},
    factoryOperationDayIndex: null,
    factoryOperationDayTimeDTOsIndex: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SHIFT_SEARCH:
            return {
                ...state
            };

        case SHIFT_SEARCH_SUCCESS:
            return {
                ...state,
                pageInfo: getPagedData(action.payload),
                shifts: action.payload.pageData
            };

        case SHIFT_ADD_NEW_SHIFT:
        case SHIFT_SET_SELECTED_SHIFT:
            return {
                ...state,
                selectedShift: action.payload,
                factoryOperationSchedule: {}
            };

        case SAVE_UPDATE_FACTORY_OPERATION_SCHEDULE_SUCCESS:
        case SHIFT_GET_SELECTED_SHIFT_SUCCESS:
            return {
                ...state,
                selectedShift: action.payload,
            };

        case SHIFT_ADD_TIME_SLOT_MODAL_OPEN:
            return {
                ...state,
                showAddTimeSlotModalState: true
            };

        case SHIFT_ADD_TIME_SLOT_MODAL_OPEN_ID:
            return {
                ...state,
                showAddTimeSlotModalID: action.payload
            };

        case SHIFT_ADD_TIME_SLOT_MODAL_CLOSE:
            return {
                ...state,
                showAddTimeSlotModalState: false,
                showAddTimeSlotModalID: null,
                selectedDataForEdit: {},
                factoryOperationDayIndex: null,
                factoryOperationDayTimeDTOsIndex: null
            };

        case SHIFT_SET_FACTORY_OPERATION_DAYS:
            return {
                ...state,
                factoryOperationDays: [...state.factoryOperationDays, action.payload]
            };

        case SHIFT_UPDATE_FACTORY_OPERATION_DAYS:
            return {
                ...state,
                factoryOperationDays: action.payload
            };

        case SHIFT_APPEND_FACTORY_OPERATION_DAYS:
            return {
                ...state,
                factoryOperationDays: [...state.factoryOperationDays, ...action.payload]

            };

        case SHIFT_SET_FACTORY_OPERATION_DAYTIME_FOR_FACTORY_OPERATION_DAYS:
            let factoryOperationDays = [...state.factoryOperationDays];
            let factoryOperationDayTimeDTOs = factoryOperationDays[action.payload.id].factoryOperationDayTimeDTOs;
            ++factoryOperationDays[action.payload.id].dayTimeDTOsMaxDisplayOrderNumber;
            factoryOperationDayTimeDTOs.push(action.payload.data);
            return {
                ...state,
                factoryOperationDays: factoryOperationDays
            };

        case SHIFT_CLEAR_DATA:
            return {
                ...state,
                selectedShift: {},
                factoryOperationDays: [],
                selectedDataForEdit: {},
                factoryOperationDayIndex: null,
                factoryOperationDayTimeDTOsIndex: null
            };

        case SHIFT_SET_FACTORY_OPERATION_SCHEDULE:
            return {
                ...state,
                factoryOperationSchedule: action.payload,
            };

        case SHIFT_SET_EDIT_DATA_FOR_MODAL:
            return {
                ...state,
                selectedDataForEdit: action.payload.data,
                factoryOperationDayIndex: action.payload.factoryOperationDayIndex,
                factoryOperationDayTimeDTOsIndex: action.payload.factoryOperationDayTimeDTOsIndex,
            };

        case SHIFT_UPDATE_FACTORY_OPERATION_DAYTIME_FOR_FACTORY_OPERATION_DAYS:
            let updateFactoryOperationDays = [...state.factoryOperationDays];
            let updateFactoryOperationDayTimeDTOs = updateFactoryOperationDays[state.factoryOperationDayIndex].factoryOperationDayTimeDTOs;
            updateFactoryOperationDayTimeDTOs[state.factoryOperationDayTimeDTOsIndex] = action.payload;
            return {
                ...state,
                factoryOperationDays: updateFactoryOperationDays
            };

        case SHIFT_ON_REMOVE_ADDED_TIME_DTO:
            let removeFactoryOperationDays = [...state.factoryOperationDays];
            let removeFactoryOperationDayTimeDTOs = removeFactoryOperationDays[action.payload.factoryOperationDayIndex].factoryOperationDayTimeDTOs;
            removeFactoryOperationDayTimeDTOs.splice(action.payload.factoryOperationDayTimeDTOsIndex, 1);

            return {
                ...state,
                factoryOperationDays: removeFactoryOperationDays
            };

        default:
            return state;

    }
}