import {
    STANDARD_DO_MANUAL_SEARCH,
    STANDARD_DOWNLOAD_STANDARD_VALUES_CSV_SUCCESS,
    STANDARD_GET_MAX_EFFECTIVE_DATE_SUCCESS,
    STANDARD_GET_SELECTED_STANDARD_SUCCESS,
    STANDARD_LOCATION_VICE_PRODUCT_SUCCESS,
    STANDARD_LOCATION_VICE_SUB_BUSINESS_UNIT_SUCCESS,
    STANDARD_RESET_STANDARD_DATA_SCREEN,
    STANDARD_RESET_STANDARD_SCREEN,
    STANDARD_SAVE_UPDATE_STANDARD_DATA_SUCCESS,
    STANDARD_SAVE_UPDATE_STANDARD_SUCCESS,
    STANDARD_SEARCH_STANDARD_DATA_SUCCESS,
    STANDARD_SEARCH_STANDARD_SUCCESS,
    STANDARD_SET_SELECTED_STANDARD,
    STANDARD_SET_SELECTED_STANDARD_DATA,
    STANDARD_SHOW_HIDE_ADD_EDIT_MODAL,
    UPLOAD_BULK_STANDARD_SUCCESS,
    UPLOAD_BULK_STANDARD_VALUES_SUCCESS
} from "../actions/types";
import {getPagedData} from "../../utils/DataExtractHelper";

const initialState = {
    selectedStandardDTO: {},
    selectedStandardDataDTO: {},

    locationViceProducts: [],
    locationViceSubBusinessUnits: [],

    standardPageInfo: {},
    standards: [],
    standardDataPageInfo: {},
    standardData: [],

    maxEffectiveDate: '',

    showAddEditModal: false,
    doManualSearch: '',

    downloadCSVData: {},

    uploadResult: {}

};

export default (state = initialState, action) => {
    switch (action.type) {
        case STANDARD_SEARCH_STANDARD_SUCCESS:
            return {
                ...state,
                standards: action.payload.pageData,
                standardPageInfo: getPagedData(action.payload)
            };
        case STANDARD_SEARCH_STANDARD_DATA_SUCCESS:
            return {
                ...state,
                standardData: action.payload.pageData,
                standardDataPageInfo: getPagedData(action.payload)
            };
        case STANDARD_SAVE_UPDATE_STANDARD_SUCCESS:
            return {
                ...state,
                selectedStandardDTO: action.payload
            };

        case STANDARD_LOCATION_VICE_SUB_BUSINESS_UNIT_SUCCESS:
            return {
                ...state,
                locationViceSubBusinessUnits: action.payload
            };
        case STANDARD_LOCATION_VICE_PRODUCT_SUCCESS:
            return {
                ...state,
                locationViceProducts: action.payload
            };
        case STANDARD_GET_MAX_EFFECTIVE_DATE_SUCCESS:
            return {
                ...state,
                maxEffectiveDate: action.payload
            };
        case STANDARD_RESET_STANDARD_DATA_SCREEN:
            return {
                ...state,
                maxEffectiveDate: '',
                selectedStandardDataDTO: {}
            };
        case STANDARD_RESET_STANDARD_SCREEN:
            return {
                ...state,
                selectedStandardDTO: {},
            };
        case STANDARD_SET_SELECTED_STANDARD_DATA:
            return {
                ...state,
                selectedStandardDataDTO: action.payload
            };
        case STANDARD_SET_SELECTED_STANDARD:
            return {
                ...state,
                selectedStandardDTO: action.payload
            };
        case STANDARD_GET_SELECTED_STANDARD_SUCCESS:
            return {
                ...state,
                selectedStandardDTO: action.payload
            };

        case STANDARD_SHOW_HIDE_ADD_EDIT_MODAL : {
            return {
                ...state,
                showAddEditModal: action.payload
            };
        }

        case STANDARD_SAVE_UPDATE_STANDARD_DATA_SUCCESS : {
            return {
                ...state,
                showAddEditModal: false
            }
        }

        case STANDARD_DO_MANUAL_SEARCH : {
            return {
                ...state,
                doManualSearch: action.payload
            }
        }

        case STANDARD_DOWNLOAD_STANDARD_VALUES_CSV_SUCCESS:
            return {
                ...state,
                downloadCSVData: action.payload
            };

        case UPLOAD_BULK_STANDARD_SUCCESS :
        case UPLOAD_BULK_STANDARD_VALUES_SUCCESS : {
            return {
                ...state,
                uploadResult: action.payload
            };
        }

        default :
            return state;
    }
}
