import React, {Component} from 'react';
import {HashRouter, Route, Switch} from 'react-router-dom';
import './App.scss';
import {Provider} from 'react-redux';
import store from './redux/store';
import IhanzLayout from "./containers/DefaultLayout/IhanzLayout";
import Constants from './utils/Constants';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './i18n/config';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

const Login = React.lazy(() => import('./views/Pages/Login'));
const Register = React.lazy(() => import('./views/Pages/Register'));
const ForgotPassword = React.lazy(() => import('./views/Pages/ForgotPassword'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));

toast.configure({
    autoClose: 3000,
    draggable: false,
    //etc you get the idea
});

class App extends Component {
    render() {
        return (
            <Provider store={store}>
                <IhanzLayout>
                    <HashRouter>
                        <React.Suspense fallback={loading()}>
                            <Switch>
                                <Route exact path={Constants.PAGES.login} name="Login Page"
                                       render={props => <Login {...props}/>}/>
                                <Route exact path="/register" name="Register Page"
                                       render={props => <Register {...props}/>}/>
                                <Route exact path={Constants.PAGES.forgotPassword} name="Forgot Password Page"
                                       render={props => <ForgotPassword {...props}/>}/>
                                <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>}/>
                                <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>}/>
                                <Route path="/" name="Home" render={props => <DefaultLayout {...props}/>}/>
                            </Switch>
                        </React.Suspense>
                    </HashRouter>
                </IhanzLayout>
            </Provider>
        );
    }
}

export default App;
