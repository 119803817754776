import {
    REPORT_GENERATE_DATA_CLEAR,
    REPORT_GENERATE_DOWNLOAD_REPORT,
    REPORT_GENERATE_DOWNLOAD_REPORT_SUCCESS,
    REPORT_GENERATE_EMAIL_REPORT,
    REPORT_GENERATE_EMAIL_REPORT_SUCCESS,
    REPORT_GENERATE_HTML_REPORT,
    REPORT_GENERATE_HTML_REPORT_SUCCESS,
    REPORT_GENERATE_ON_EMAIL_DETAIL_SET,
    REPORT_GENERATE_ON_EXPORT_TYPE_SELECT,
    REPORT_GENERATE_ON_OUTPUT_TYPE_SELECT,
    REPORT_GENERATE_REQUEST_FACTORY_SHIFTS_SUCCESS,
    REPORT_GENERATE_REQUEST_KPI_REPORT_JSON_SUCCESS,
    REPORT_GENERATE_REQUEST_REPORT_JSON_SUCCESS,
    REPORT_GENERATE_SET_EMAIL_TO_TEMP,
    REPORT_GENERATE_SET_INCLUDES_VIEW_DATA,
    REPORT_GENERATE_SET_TEMP_SELECTED_USER_GROUPS,
    REPORT_GENERATE_USER_GROUP_MODEL_IS_OPEN,
    REPORT_GENERATE_XLSX_CONTENT_CLEAR,
    REPORT_GENERATE_XLSX_CONTENT_READY
} from "../actions/types";
import Constants from "../../utils/Constants";
import _ from "lodash";
import Messages from "../../utils/Messages";
import {toast} from "react-toastify";
import {translateString} from "../../utils/LanguageUtil";

const initialState = {
    includesState: {},
    outputType: '',
    exportType: '',
    emailDetails: {},
    downloadReportURL: '',
    generatedReportViewHtml: '',
    generatedReportEmail: '',
    emailSenderTempSelected: [],

    userGroupsTempSelected: [],
    userGroupModelIsOpen: false,

    reportContentJson: {},
    reportXLSXDownloadContentJson: {},

    reportContentKPIJson: {},

    factoryShifts: [],
    selectedShiftNames: ''
};


export default (state = initialState, action) => {
    switch (action.type) {
        case REPORT_GENERATE_DOWNLOAD_REPORT:
        case REPORT_GENERATE_HTML_REPORT:
        case REPORT_GENERATE_EMAIL_REPORT:
            return {
                ...state
            };

        case REPORT_GENERATE_SET_INCLUDES_VIEW_DATA:
            return {
                ...state,
                includesState: action.payload
            };

        case REPORT_GENERATE_DATA_CLEAR:
            return {
                includesState: {},
                outputType: Constants.REPORT_OUTPUT_TYPE.HTML,
                exportType: Constants.REPORT_EXPORT_TYPE.VIEW,
                emailDetails: {},
                downloadReportURL: '',
                generatedReportViewHtml: '',
                generatedReportEmail: '',
                emailSenderTempSelected: [],
                userGroupsTempSelected: [],
                userGroupModelIsOpen: false
            };

        case REPORT_GENERATE_ON_OUTPUT_TYPE_SELECT:
            return {
                ...state,
                outputType: action.payload
            };

        case REPORT_GENERATE_ON_EXPORT_TYPE_SELECT:
            return {
                ...state,
                exportType: action.payload
            };

        case REPORT_GENERATE_ON_EMAIL_DETAIL_SET:
            return {
                ...state,
                emailDetails: action.payload
            };

        case REPORT_GENERATE_DOWNLOAD_REPORT_SUCCESS:
            if (!_.isEmpty(action.payload.data)) {
                toast.success(translateString(action.payload.message))
            } else {
                toast.error(translateString(Messages.REPORT_GENERATE_XLS_OR_PDF_ERROR));
            }
            return {
                ...state,
                downloadReportURL: action.payload.data
            };

        case REPORT_GENERATE_HTML_REPORT_SUCCESS:
            if (!_.isEmpty(action.payload)) {
                toast.success(translateString(Messages.REPORT_GENERATE_VIEW_HTML))
            } else {
                toast.error(translateString(Messages.REPORT_GENERATE_VIEW_HTML_ERROR));
            }
            return {
                ...state,
                generatedReportViewHtml: action.payload
            };

        case REPORT_GENERATE_EMAIL_REPORT_SUCCESS:
            return {
                ...state,
                generatedReportEmail: action.payload
            };
        case REPORT_GENERATE_SET_EMAIL_TO_TEMP:
            return {
                ...state,
                emailSenderTempSelected: action.payload
            };
        case REPORT_GENERATE_SET_TEMP_SELECTED_USER_GROUPS:
            return {
                ...state,
                userGroupsTempSelected: action.payload
            };
        case REPORT_GENERATE_USER_GROUP_MODEL_IS_OPEN:
            return {
                ...state,
                userGroupModelIsOpen: action.payload
            };

        case REPORT_GENERATE_REQUEST_REPORT_JSON_SUCCESS : {
            return {
                ...state,
                reportContentJson: action.payload.result,
                selectedShiftNames: action.payload.selectedShiftNames
            }
        }

        case REPORT_GENERATE_REQUEST_KPI_REPORT_JSON_SUCCESS : {
            return {
                ...state,
                reportContentKPIJson: action.payload
            }
        }

        case REPORT_GENERATE_XLSX_CONTENT_READY : {
            return {
                ...state,
                reportXLSXDownloadContentJson: action.payload
            }
        }

        case REPORT_GENERATE_XLSX_CONTENT_CLEAR : {
            return {
                ...state,
                reportXLSXDownloadContentJson: {}
            }
        }

        case REPORT_GENERATE_REQUEST_FACTORY_SHIFTS_SUCCESS : {
            return {
                ...state,
                factoryShifts: action.payload
            }
        }

        default:
            return {
                ...state
            }
    }
}
