import {
    MEASURE_UNIT_ADD_NEW_MEASURE_UNIT,
    MEASURE_UNIT_GET_SELECTED_MEASURE_UNIT,
    MEASURE_UNIT_GET_SELECTED_MEASURE_UNIT_SUCCESS,
    MEASURE_UNIT_PAGE_SEARCH,
    MEASURE_UNIT_PAGE_SEARCH_SUCCESS,
    SAVE_UPDATE_MEASURE_UNIT_SUCCESS,
    MEASURE_UNIT_SEARCH,
    MEASURE_UNIT_SEARCH_SUCCESS
} from "../actions/types";

import {getPagedData} from "../../utils/DataExtractHelper";

const initialState = {
    measureUnits: [],
    filteredMeasureUnits: [],
    pageInfo: {},
    selectedMeasureUnit: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case MEASURE_UNIT_PAGE_SEARCH :
            return {
                ...state
            };

        case MEASURE_UNIT_PAGE_SEARCH_SUCCESS:
            return {
                ...state,
                measureUnits: action.payload.pageData,
                pageInfo: getPagedData(action.payload)
            };

        case MEASURE_UNIT_SEARCH :
            return {
                ...state,
                filteredMeasureUnits: null
            };

        case MEASURE_UNIT_SEARCH_SUCCESS:

            return {
                ...state,
                filteredMeasureUnits: action.payload.measureUnitDTOs,
            };

        case MEASURE_UNIT_GET_SELECTED_MEASURE_UNIT:
            return {
                ...state,
            };

        case MEASURE_UNIT_GET_SELECTED_MEASURE_UNIT_SUCCESS:
        case SAVE_UPDATE_MEASURE_UNIT_SUCCESS:
            return {
                ...state,
                selectedMeasureUnit: action.payload
            };

        case MEASURE_UNIT_ADD_NEW_MEASURE_UNIT:
            return {
                ...state,
                selectedMeasureUnit: action.payload
            };

        default:
            return state
    }
}
