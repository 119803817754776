import * as _ from 'lodash';

import {
    PROCESS_DATA_CALCULATE_ADV_MEASURE_DATA_SUCCESS,
    PROCESS_DATA_INPUTS_SAVE_UPDATE,
    PROCESS_DATA_INPUTS_SAVE_UPDATE_SUCCESS,
    PROCESS_DATA_PRV_NEXT_PROCESS_DATA_GET_SUCCESS,
    PROCESS_DATA_TARGET_BUDGET_ROLL_FORWARD_SUCCESS,
    PROCESS_LOAD_DATA_ENTRY_FORM,
    PROCESS_LOAD_DATA_ENTRY_FORM_SUCCESS, PROCESS_RESET_SELECTED_PROCESS,
    PROCESS_SET_SELECTED_PROCESS, PROCESS_TEMPLATE_RESET_SELECTED_PROCESS_TEMPLATE,
} from "../actions/types";

const initialState = {
    selectedProcess: {},
    selectedTab: {},
    previousTabs: [],
    nextTabs: [],
    allTabsMap: {},
    advancedMeasureResultMap: {}
};

export default (state = initialState, action) => {
    switch (action.type) {

        case PROCESS_DATA_INPUTS_SAVE_UPDATE:
        case PROCESS_LOAD_DATA_ENTRY_FORM:
            return {
                ...state
            };
        case PROCESS_LOAD_DATA_ENTRY_FORM_SUCCESS:
            return {
                ...state,
                selectedTab: action.payload.selectedTab,
                previousTabs: action.payload.previousTabs,
                allTabsMap: action.payload.allTabsMap,
                nextTabs: action.payload.nextTabs,
                processDataEntryFormTabs: action.payload.processDataEntryFormTabs,
            };

        case PROCESS_DATA_PRV_NEXT_PROCESS_DATA_GET_SUCCESS :

            let nextPrevTabMap = Object.assign({}, state.allTabsMap,action.payload.allTabsMap);
            return {
                ...state,
                ...action.payload,
                allTabsMap: nextPrevTabMap
            };

        case PROCESS_DATA_INPUTS_SAVE_UPDATE_SUCCESS:
            let frequencyCode = action.payload.frequencyValue;
            state.allTabsMap[frequencyCode] = action.payload;
            let allMap = Object.assign({}, state.allTabsMap);

            return {
                ...state,
                allTabsMap: allMap,
            };
        case PROCESS_DATA_TARGET_BUDGET_ROLL_FORWARD_SUCCESS:
            let updatedTabs = action.payload.allTabsMap;
            let dataMap = Object.assign({}, state.allTabsMap, updatedTabs);
            return {
                ...state,
                allTabsMap: dataMap,
            };

        case PROCESS_SET_SELECTED_PROCESS:
            return {
                ...state,
                selectedProcess: action.payload
            };
        case PROCESS_RESET_SELECTED_PROCESS:
            return {
                selectedProcess: {},
                selectedTab: {},
                previousTabs: [],
                nextTabs: [],
                allTabsMap: {},
                advancedMeasureResultMap: {}
            };

        case PROCESS_DATA_CALCULATE_ADV_MEASURE_DATA_SUCCESS:
            let result = _.cloneDeep(state.advancedMeasureResultMap);
            result[action.payload.tabName] = {};
            result[action.payload.tabName][action.payload.measureID] = action.payload.data;

            return {
                ...state,
                advancedMeasureResultMap: result
            };
        default:
            return state;
    }
}
