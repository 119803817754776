import {
    BUSINESS_UNIT_ADD_NEW_BUSINESS_UNIT,
    BUSINESS_UNIT_GET_SELECTED_BUSINESS_UNIT,
    BUSINESS_UNIT_GET_SELECTED_BUSINESS_UNIT_SUCCESS,
    BUSINESS_UNIT_PRODUCT_SEARCH,
    BUSINESS_UNIT_PRODUCT_SEARCH_SUCCESS,
    BUSINESS_UNIT_SEARCH,
    BUSINESS_UNIT_SEARCH_SUCCESS,
    SAVE_UPDATE_BUSINESS_UNIT_SUCCESS,
} from '../actions/types';
import {getPagedData} from '../../utils/DataExtractHelper';


const initialState = {
    pageInfo: {},
    businessUnits: [],
    selectedBusinessUnit: {},
    businessUnitProducts: [],
    selectedProducts:[]
};

export default (state = initialState, action) => {
    switch (action.type) {
        case BUSINESS_UNIT_SEARCH :
            return {
                ...state
            };

        case BUSINESS_UNIT_SEARCH_SUCCESS :
            return {
                ...state,
                businessUnits: action.payload.pageData,
                pageInfo: getPagedData(action.payload)
            };
        case BUSINESS_UNIT_PRODUCT_SEARCH:
            return {
                ...state
            };

        case BUSINESS_UNIT_PRODUCT_SEARCH_SUCCESS:
            return {
                ...state,
                businessUnitProducts: action.payload.pageData,
                pageInfo: getPagedData(action.payload)
            };

        case BUSINESS_UNIT_GET_SELECTED_BUSINESS_UNIT :
            return {
                ...state
            };

        case BUSINESS_UNIT_GET_SELECTED_BUSINESS_UNIT_SUCCESS :
        case SAVE_UPDATE_BUSINESS_UNIT_SUCCESS :
            return {
                ...state,
                selectedBusinessUnit: action.payload
            };

        case BUSINESS_UNIT_ADD_NEW_BUSINESS_UNIT :
            return {
                ...state,
                selectedBusinessUnit: action.payload
            };

        default :
            return state;
    }
}
