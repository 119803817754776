import {
    MEASURE_UNIT_CATEGORY_ADD_NEW_MEASURE_UNIT_CATEGORY,
    MEASURE_UNIT_CATEGORY_GET_SELECTED_MEASURE_UNIT_CATEGORY,
    MEASURE_UNIT_CATEGORY_GET_SELECTED_MEASURE_UNIT_CATEGORY_SUCCESS,
    MEASURE_UNIT_CATEGORY_SEARCH,
    MEASURE_UNIT_CATEGORY_SEARCH_SUCCESS,
    SAVE_UPDATE_MEASURE_UNIT_CATEGORY_SUCCESS
} from "../actions/types";

import {getPagedData} from '../../utils/DataExtractHelper';


const initialState = {
    pageInfo: {},
    measureUnitCategory: [],
    selectedMeasureUnitCategory: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case MEASURE_UNIT_CATEGORY_SEARCH :
            return {
                ...state
            };

        case MEASURE_UNIT_CATEGORY_SEARCH_SUCCESS:
            return {
                ...state,
                measureUnitCategory: action.payload.pageData,
                pageInfo: getPagedData(action.payload)
            };

        case MEASURE_UNIT_CATEGORY_GET_SELECTED_MEASURE_UNIT_CATEGORY:
            return {
                ...state
            };

        case MEASURE_UNIT_CATEGORY_GET_SELECTED_MEASURE_UNIT_CATEGORY_SUCCESS:
        case SAVE_UPDATE_MEASURE_UNIT_CATEGORY_SUCCESS:
            return {
                ...state,
                selectedMeasureUnitCategory: action.payload
            };

        case MEASURE_UNIT_CATEGORY_ADD_NEW_MEASURE_UNIT_CATEGORY:
            return {
                ...state,
                selectedMeasureUnitCategory: action.payload
            };
        default:
            return state
    }
}
