import {
    MEASURE_GROUP_ADD_EDIT_REMOVE_TMP_SELECTED_MEASURES,
    MEASURE_GROUP_ADD_EDIT_SELECT_MEASURES_MODAL_CLOSE,
    MEASURE_GROUP_ADD_EDIT_SELECT_MEASURES_MODAL_OPEN,
    MEASURE_GROUP_ADD_EDIT_UPDATE_SELECTED_MEASURES,
    MEASURE_GROUP_ADD_EDIT_UPDATE_TMP_SELECTED_MEASURES,
    MEASURE_GROUP_ADD_NEW_MEASURE_GROUP,
    MEASURE_GROUP_GET_LOAD_MEASURE_GROUPS,
    MEASURE_GROUP_GET_LOAD_MEASURE_GROUPS_SUCCESS,
    MEASURE_GROUP_GET_SELECTED_MEASURE_GROUP,
    MEASURE_GROUP_GET_SELECTED_MEASURE_GROUP_SUCCESS,
    MEASURE_GROUP_MEASURE_ORDER_CHANGED,
    MEASURE_GROUP_SEARCH,
    MEASURE_GROUP_SEARCH_SUCCESS,
    MEASURE_GROUP_SELECT_MEASURE_CATEGORY,
    MEASURE_GROUP_SELECTED_MEASURE_GROUP_RESET,
    MEASURE_RESET_SELECTED_MEASURE,
    SAVE_UPDATE_MEASURE_GROUP_SUCCESS,
} from '../actions/types';
import {getPagedData} from '../../utils/DataExtractHelper';


const initialState = {
    pageInfo: {},
    measureGroups: [],
    selectedMeasureGroup: {},
    measureGroupsList: [],
    selectedMeasures: [],
    selectedMeasuresTmp: [],
    showAddMeasureModal: false,
    selectedMeasureCategory: '',
    isMeasureListChanged: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case MEASURE_GROUP_SEARCH :
            return {
                ...state
            };

        case MEASURE_GROUP_SEARCH_SUCCESS :
            return {
                ...state,
                measureGroups: action.payload.pageData,
                pageInfo: getPagedData(action.payload)
            };

        case MEASURE_GROUP_GET_SELECTED_MEASURE_GROUP :
            return {
                ...state,
                selectedMeasures: []
            };

        case MEASURE_GROUP_GET_SELECTED_MEASURE_GROUP_SUCCESS :
        case SAVE_UPDATE_MEASURE_GROUP_SUCCESS :
            return {
                ...state,
                selectedMeasureGroup: action.payload,
                selectedMeasures: action.payload.measureDTOs,
                selectedMeasureCategory: action.payload.measureCategory
            };

        case MEASURE_RESET_SELECTED_MEASURE :
        case MEASURE_GROUP_ADD_NEW_MEASURE_GROUP :
            return {
                ...state,
                selectedMeasureGroup: action.payload,
                selectedMeasures: [],
                selectedMeasureCategory: ''
            };

        case MEASURE_GROUP_ADD_EDIT_UPDATE_SELECTED_MEASURES :
            return {
                ...state,
                selectedMeasures: action.payload
            };

        case MEASURE_GROUP_ADD_EDIT_SELECT_MEASURES_MODAL_OPEN :
            return {
                ...state,
                showAddMeasureModal: true
            };

        case MEASURE_GROUP_ADD_EDIT_SELECT_MEASURES_MODAL_CLOSE :
            return {
                ...state,
                showAddMeasureModal: false
            };

        case MEASURE_GROUP_ADD_EDIT_UPDATE_TMP_SELECTED_MEASURES :
            return {
                ...state,
                selectedMeasuresTmp: action.payload
            };

        case MEASURE_GROUP_ADD_EDIT_REMOVE_TMP_SELECTED_MEASURES :
            return {
                ...state,
                selectedMeasures: [],
                selectedMeasuresTmp: []
            };

        case MEASURE_GROUP_GET_LOAD_MEASURE_GROUPS:
            return {
                ...state,
            };
        case MEASURE_GROUP_GET_LOAD_MEASURE_GROUPS_SUCCESS:
            return {
                ...state,
                measureGroupsList: action.payload
            };
        case MEASURE_GROUP_SELECTED_MEASURE_GROUP_RESET:
            return {
                ...state,
                selectedMeasureGroup: {},
                selectedMeasureCategory: ''
            };

        case MEASURE_GROUP_SELECT_MEASURE_CATEGORY:
            return {
                ...state,
                selectedMeasureCategory: action.payload
            };

        case MEASURE_GROUP_MEASURE_ORDER_CHANGED : {
            return {
                ...state,
                selectedMeasures: action.payload,
                isMeasureListChanged: true,
            };
        }

        default :
            return state;
    }
}
