import {
    DATA_ENTRY_ADD_NEW_DOWNTIME_ROW,
    DATA_ENTRY_ADD_NEW_DOWNTIME_ROW_SET,
    DATA_ENTRY_ADD_NEW_FORM_ROW,
    DATA_ENTRY_ADD_NEW_WASTAGE_ROW,
    DATA_ENTRY_ADD_NEW_WASTAGE_ROW_SET,
    DATA_ENTRY_DOWNLOAD_ENTRY_CSV_SUCCESS,
    DATA_ENTRY_GET_FACTORY_PRODUCTION_LINES,
    DATA_ENTRY_GET_FACTORY_PRODUCTION_LINES_SUCCESS,
    DATA_ENTRY_GET_FACTORY_PRODUCTS,
    DATA_ENTRY_GET_FACTORY_PRODUCTS_CATEGORIES,
    DATA_ENTRY_GET_FACTORY_PRODUCTS_CATEGORIES_SUCCESS,
    DATA_ENTRY_GET_FACTORY_PRODUCTS_SUCCESS,
    DATA_ENTRY_GET_FACTORY_SHIFTS,
    DATA_ENTRY_GET_FACTORY_SHIFTS_SUCCESS,
    DATA_ENTRY_GET_FACTORY_SUPERVISORS,
    DATA_ENTRY_GET_FACTORY_SUPERVISORS_SUCCESS,
    DATA_ENTRY_GET_TEMPLATE,
    DATA_ENTRY_GET_TEMPLATE_SUCCESS,
    DATA_ENTRY_LOAD_INIT,
    DATA_ENTRY_LOAD_INIT_SUCCESS,
    DATA_ENTRY_REMOVE_NEW_DOWNTIME_ROW,
    DATA_ENTRY_REMOVE_NEW_FORM_ROW,
    DATA_ENTRY_REMOVE_NEW_WASTAGE_ROW,
    DATA_ENTRY_RESET_DATA,
    DATA_ENTRY_SAVE_TAB,
    DATA_ENTRY_SAVE_TAB_SUCCESS,
    DATA_ENTRY_SET_SELECTED_FREQUENCY,
    DATA_ENTRY_UPDATE_DOWNTIME_ROW,
    DATA_ENTRY_UPDATE_MEASURE_VALUE,
    DATA_ENTRY_UPDATE_VARIABLE_VALUE,
    DATA_ENTRY_UPDATE_WASTAGE_ROW
} from "../actions/types";

import * as _ from 'lodash';
import {
    getDownTimeWastageInputConfig,
    getSingleDownTimeInputConfig,
    getSingleWastageInputConfig
} from "../../utils/DataEntryFormHelper";

const initialState = {
    dataEntryForm: {},
    frequencyWiseInitFormData: {},
    frequencyWiseDataEntryValues: {},
    allFrequencies: [],
    selectedFrequency: '',
    dataDirty: false,
    factoryProducts: [],
    factoryProductionLines: [],
    factoryShifts: [],
    factorySupervisors: [],
    factoryProductCategories: {},
    downloadCSVData: {},
    frequencyWiseDownTimeWastageInputConfig: {},
    frequencyWiseProcessDataWiseRemovedDownTimes: {},
    frequencyWiseProcessDataWiseRemovedWastage: {},
    trackDowntime: "N",
    trackWastage: "N"
};

export default (state = initialState, action) => {
    switch (action.type) {

        case DATA_ENTRY_LOAD_INIT :
        case DATA_ENTRY_GET_TEMPLATE :
        case DATA_ENTRY_GET_FACTORY_PRODUCTS :
        case DATA_ENTRY_GET_FACTORY_PRODUCTION_LINES :
        case DATA_ENTRY_GET_FACTORY_SHIFTS :
        case DATA_ENTRY_GET_FACTORY_SUPERVISORS :
        case DATA_ENTRY_SAVE_TAB:
        case DATA_ENTRY_GET_FACTORY_PRODUCTS_CATEGORIES :
            return state;

        case DATA_ENTRY_GET_TEMPLATE_SUCCESS :
            return {
                ...state,
                dataEntryForm: action.payload,
            };

        case DATA_ENTRY_LOAD_INIT_SUCCESS : {

            let frequency = action.payload['frequencyValue'];
            let data = _.cloneDeep(action.payload['data']);

            let copy = _.cloneDeep(state.frequencyWiseInitFormData);
            let frequencyWiseInitFormDataUpdated = Object.assign({}, copy, {
                [frequency]: data
            });

            let updatedAllFrequencies = Object.assign([], state.allFrequencies);
            if (_.indexOf(updatedAllFrequencies, frequency) == -1) {
                updatedAllFrequencies.push(frequency);
            }

            let config = data.processDataDTOList;
            let updatedConfig = Object.assign({}, state.frequencyWiseDataEntryValues, {
                [frequency]: _.cloneDeep(config)
            });

            let downTimeWastageInputConfig = getDownTimeWastageInputConfig(config);
            let frequencyWiseDownTimeWastageInputConfig = Object.assign({}, state.frequencyWiseDownTimeWastageInputConfig, {
                [frequency]: downTimeWastageInputConfig
            });

            let trackDowntime = data.processTemplateDTO?.trackDowntime === "Y";
            let trackWastage = data.processTemplateDTO?.trackWastage === "Y";

            return {
                ...state,
                frequencyWiseInitFormData: frequencyWiseInitFormDataUpdated,
                frequencyWiseDataEntryValues: updatedConfig,
                allFrequencies: updatedAllFrequencies,
                selectedFrequency: frequency,
                frequencyWiseDownTimeWastageInputConfig: frequencyWiseDownTimeWastageInputConfig,
                frequencyWiseProcessDataWiseRemovedDownTimes: {},
                frequencyWiseProcessDataWiseRemovedWastage: {},
                trackDowntime: trackDowntime,
                trackWastage: trackWastage
            };
        }

        case DATA_ENTRY_SET_SELECTED_FREQUENCY :
            return {
                ...state,
                selectedFrequency: action.payload
            };

        case DATA_ENTRY_UPDATE_MEASURE_VALUE : {
            let frequency = action.payload['frequency'];
            let displayOrder = action.payload['displayOrder'];
            let itemIndex = displayOrder - 1;
            let measureID = action.payload['measureID'];
            let value = action.payload['value'];
            let freqWiseData = Object.assign([], state.frequencyWiseDataEntryValues[frequency]);

            let processDataMeasures = freqWiseData[itemIndex].processDataMeasures;

            if (!processDataMeasures) {
                processDataMeasures = [];
            }

            let index = _.findIndex(processDataMeasures, (item) => item.measureID == measureID);
            let item = {};
            if (index != -1) {
                item = processDataMeasures[index];
                item.actualValue = value;
            } else {
                item = {
                    measureID: measureID,
                    actualValue: value
                };

                processDataMeasures.push(item);
            }

            freqWiseData[itemIndex].processDataMeasures = processDataMeasures;

            let updated = Object.assign({}, state.frequencyWiseDataEntryValues, {
                [frequency]: freqWiseData
            });

            return {
                ...state,
                frequencyWiseDataEntryValues: updated,
                dataDirty: true
            };
        }


        case DATA_ENTRY_UPDATE_VARIABLE_VALUE : {

            let frequency = action.payload['frequency'];
            let displayOrder = action.payload['displayOrder'];
            let itemIndex = displayOrder - 1;
            let variableCode = action.payload['variableCode'];
            let value = action.payload['value'];

            let freqWiseData = Object.assign([], state.frequencyWiseDataEntryValues[frequency]);

            let processDataVariable = freqWiseData[itemIndex].processDataVariable;

            if (!processDataVariable) {
                processDataVariable = {};
            }

            if (variableCode == 'START_TIME') {
                processDataVariable.startDateTimeStr = value;
            }

            if (variableCode == 'END_TIME') {
                processDataVariable.endDateTimeStr = value;
            }

            if (variableCode == 'DURATION') {
                processDataVariable.duration = value;
            }

            if (variableCode == 'PRODUCT') {
                processDataVariable.locationProductID = value;
                processDataVariable.productCategoryID = '';
                processDataVariable.productSubCategoryID = '';
            }

            if (variableCode == 'TEAM_LEAD') {
                processDataVariable.supervisorID = value;
            }

            if (variableCode == 'SHIFT') {
                processDataVariable.factoryShiftID = value;
            }

            if (variableCode == 'PRODUCTION_LINE') {
                processDataVariable.subBusinessUnitID = value;
            }

            if (variableCode == 'VARIANT_1') {
                processDataVariable.productCategoryID = value;
                if (!value) {
                    processDataVariable.productSubCategoryID = "";
                }
            }

            if (variableCode == 'VARIANT_2') {
                processDataVariable.productSubCategoryID = value;
            }

            freqWiseData[itemIndex].processDataVariable = processDataVariable;

            let updated = Object.assign({}, state.frequencyWiseDataEntryValues, {
                [frequency]: freqWiseData
            });

            return {
                ...state,
                frequencyWiseDataEntryValues: updated,
                dataDirty: true
            };
        }


        case DATA_ENTRY_ADD_NEW_FORM_ROW : {
            let frequency = action.payload['frequency'];
            let newDisplayOrder = action.payload['newDisplayOrder'];

            let freqWiseData = Object.assign([], state.frequencyWiseDataEntryValues[frequency]);
            let freqWiseDownTimeWastageConfig = Object.assign([], state.frequencyWiseDownTimeWastageInputConfig[frequency]);

            let newRow = _.cloneDeep(freqWiseData[0]);
            newRow.processDataMeasures.forEach((item) => {
                item.processDataMeasureID = null;
                item.actualValue = '';
                item.status = 'ACT';
            });
            newRow.processDataID = null;
            newRow.processDataVariable = null;
            newRow.status = 'ACT';
            newRow.displayOrder = newDisplayOrder;
            newRow.processDataDownTimeDTOs = [];
            newRow.processDataWastageDTOs = [];

            freqWiseData.splice((newDisplayOrder - 1), 0, newRow);

            let newOrder = 1;
            freqWiseData.forEach((item) => {
                item.displayOrder = newOrder;
                newOrder++;
            });

            let updated = Object.assign({}, state.frequencyWiseDataEntryValues, {
                [frequency]: freqWiseData
            });

            let newDownTimeWastage = {
                displayOrder: newDisplayOrder,
                downTimeData: [getSingleDownTimeInputConfig()],
                wastageData: [getSingleWastageInputConfig()]
            };
            freqWiseDownTimeWastageConfig.splice((newDisplayOrder - 1), 0, newDownTimeWastage);

            let newWastageDownTimeOrder = 1;
            freqWiseDownTimeWastageConfig.forEach((item) => {
                item.displayOrder = newWastageDownTimeOrder;
                newWastageDownTimeOrder++;
            });

            let updatedDonTimeWastage = Object.assign({}, state.frequencyWiseDownTimeWastageInputConfig, {
                [frequency]: freqWiseDownTimeWastageConfig
            });

            return {
                ...state,
                frequencyWiseDataEntryValues: updated,
                frequencyWiseDownTimeWastageInputConfig: updatedDonTimeWastage,
                dataDirty: true
            };
        }

        case DATA_ENTRY_REMOVE_NEW_FORM_ROW : {
            let frequency = action.payload['frequency'];
            let displayOrder = action.payload['displayOrder'];

            let freqWiseData = _.clone(state.frequencyWiseDataEntryValues[frequency]);
            let freqWiseDownTimeWastageConfig = _.clone(state.frequencyWiseDownTimeWastageInputConfig[frequency]);

            freqWiseData.splice((displayOrder - 1), 1);
            freqWiseDownTimeWastageConfig.splice((displayOrder - 1), 1);

            let newOrder = 1;
            freqWiseData.forEach((item) => {
                item.displayOrder = newOrder;
                newOrder++;
            });

            let updated = Object.assign({}, state.frequencyWiseDataEntryValues, {
                [frequency]: freqWiseData
            });

            let newWastageDownTimeOrder = 1;
            freqWiseDownTimeWastageConfig.forEach((item) => {
                item.displayOrder = newWastageDownTimeOrder;
                newWastageDownTimeOrder++;
            });

            let updatedDonTimeWastage = Object.assign({}, state.frequencyWiseDownTimeWastageInputConfig, {
                [frequency]: freqWiseDownTimeWastageConfig
            });

            return {
                ...state,
                frequencyWiseDataEntryValues: updated,
                frequencyWiseDownTimeWastageInputConfig: updatedDonTimeWastage,
                dataDirty: true
            };
        }

        case DATA_ENTRY_ADD_NEW_DOWNTIME_ROW : {

            let {frequency, currentDisplayOrder, newIndex} = action.payload;
            let freqWiseDownTimeWastageConfig = _.clone(state.frequencyWiseDownTimeWastageInputConfig[frequency]);
            let frequencyWiseDataEntryValue = _.clone(state.frequencyWiseDataEntryValues[frequency]);

            let downTimeConfig = freqWiseDownTimeWastageConfig[currentDisplayOrder - 1].downTimeData;
            downTimeConfig.splice((newIndex), 0, getSingleDownTimeInputConfig());

            let updatedDonTimeWastage = Object.assign({}, state.frequencyWiseDownTimeWastageInputConfig, {
                [frequency]: freqWiseDownTimeWastageConfig
            });

            let downTimeValues = frequencyWiseDataEntryValue[currentDisplayOrder - 1].processDataDownTimeDTOs;

            let newDisplayOrder = downTimeValues.length;
            let copy = _.cloneDeep(downTimeValues[0]);
            let updatedValue = Object.assign({}, copy, {
                processDataDownTimeID: '',
                displayOrder: newDisplayOrder,
                downTimeCauseCategoryID: '',
                downTimeCauseCategoryName: '',
                downtimeCauseSubCategoryID: '',
                downTimeSubCategoryName: '',
                timeUnit: '',
                duration: '',
                remark: '',
                status: 'ACT',

            });
            downTimeValues.splice((newIndex), 0, updatedValue);

            let updated = Object.assign({}, state.frequencyWiseDataEntryValues, {
                [frequency]: frequencyWiseDataEntryValue
            });

            return {
                ...state,
                frequencyWiseDownTimeWastageInputConfig: updatedDonTimeWastage,
                frequencyWiseDataEntryValues: updated,
            };
        }

        case DATA_ENTRY_ADD_NEW_DOWNTIME_ROW_SET : {

            let {frequency, currentDisplayOrder, oldIndex, count} = action.payload;
            let freqWiseDownTimeWastageConfig = _.clone(state.frequencyWiseDownTimeWastageInputConfig[frequency]);
            let frequencyWiseDataEntryValue = _.clone(state.frequencyWiseDataEntryValues[frequency]);

            let downTimeConfig = freqWiseDownTimeWastageConfig[currentDisplayOrder - 1].downTimeData;
            let updatedDonTimeWastage = Object.assign({}, state.frequencyWiseDownTimeWastageInputConfig);

            let downTimeValues = frequencyWiseDataEntryValue[currentDisplayOrder - 1].processDataDownTimeDTOs;
            let copy = _.cloneDeep(downTimeValues[0]);

            let updated = Object.assign({}, state.frequencyWiseDataEntryValues);

            let newDisplayOrder = downTimeValues.length;
            for (let i = 0; i < count; i++) {
                downTimeConfig.splice((oldIndex + 1 + i), 0, getSingleDownTimeInputConfig());
                updatedDonTimeWastage = Object.assign({}, updatedDonTimeWastage, {
                    [frequency]: freqWiseDownTimeWastageConfig
                });
                let updatedValue = Object.assign({}, copy, {
                    processDataDownTimeID: '',
                    displayOrder: newDisplayOrder + i,
                    downTimeCauseCategoryID: '',
                    downTimeCauseCategoryName: '',
                    downtimeCauseSubCategoryID: '',
                    downTimeSubCategoryName: '',
                    timeUnit: '',
                    duration: '',
                    remark: '',
                    status: 'ACT',

                });
                downTimeValues.splice((oldIndex + 1 + i), 0, updatedValue);
                updated = Object.assign({}, updated, {
                    [frequency]: frequencyWiseDataEntryValue
                });
            }

            return {
                ...state,
                frequencyWiseDownTimeWastageInputConfig: updatedDonTimeWastage,
                frequencyWiseDataEntryValues: updated,
            };
        }

        case DATA_ENTRY_REMOVE_NEW_DOWNTIME_ROW : {

            let {frequency, currentDisplayOrder, index, processDataID, processDataDownTimeID} = action.payload;
            let freqWiseDownTimeWastageConfig = _.clone(state.frequencyWiseDownTimeWastageInputConfig[frequency]);
            let frequencyWiseDataEntryValue = _.clone(state.frequencyWiseDataEntryValues[frequency]);
            let processDataWiseRemovedDownTimeIDs = _.clone(state.frequencyWiseProcessDataWiseRemovedDownTimes[frequency]);
            processDataWiseRemovedDownTimeIDs = processDataWiseRemovedDownTimeIDs ? processDataWiseRemovedDownTimeIDs : {};

            let downTimeConfig = freqWiseDownTimeWastageConfig[currentDisplayOrder - 1].downTimeData;
            downTimeConfig.splice((index), 1);

            let updatedDonTimeWastage = Object.assign({}, state.frequencyWiseDownTimeWastageInputConfig, {
                [frequency]: freqWiseDownTimeWastageConfig
            });

            let downTimeValues = frequencyWiseDataEntryValue[currentDisplayOrder - 1].processDataDownTimeDTOs;

            downTimeValues.splice((index), 1);

            let updated = Object.assign({}, state.frequencyWiseDataEntryValues, {
                [frequency]: frequencyWiseDataEntryValue
            });

            if (processDataID && processDataDownTimeID) {
                if (!processDataWiseRemovedDownTimeIDs[processDataID]) {
                    processDataWiseRemovedDownTimeIDs[processDataID] = [];
                }
                processDataWiseRemovedDownTimeIDs[processDataID].push(processDataDownTimeID);
            }

            let updatedIDs = Object.assign({}, state.frequencyWiseProcessDataWiseRemovedDownTimes, {
                [frequency]: processDataWiseRemovedDownTimeIDs
            });

            return {
                ...state,
                frequencyWiseDownTimeWastageInputConfig: updatedDonTimeWastage,
                frequencyWiseProcessDataWiseRemovedDownTimes: updatedIDs,
                frequencyWiseDataEntryValues: updated,
                dataDirty: true
            };
        }

        case DATA_ENTRY_UPDATE_DOWNTIME_ROW : {

            let {frequency, currentDisplayOrder, index, name, value} = action.payload;
            let frequencyWiseDataEntryValue = _.clone(state.frequencyWiseDataEntryValues[frequency]);
            let downTimeValues = frequencyWiseDataEntryValue[currentDisplayOrder - 1].processDataDownTimeDTOs;

            let updateValue = downTimeValues[index];

            let newDisplayOrder = downTimeValues.length;
            if (!updateValue) {
                downTimeValues[index] = {
                    processDataDownTimeID: '',
                    displayOrder: newDisplayOrder,
                    downTimeCauseCategoryID: '',
                    downTimeCauseCategoryName: '',
                    downtimeCauseSubCategoryID: '',
                    downTimeSubCategoryName: '',
                    timeUnit: '',
                    duration: '',
                    remark: '',
                    status: 'ACT',
                };
                updateValue = downTimeValues[index];
            }

            if (name == 'DOWN_TIME_1') {
                updateValue.downTimeCauseCategoryID = value;
            }
            if (name == 'DOWN_TIME_2') {
                updateValue.downtimeCauseSubCategoryID = value;
            }
            if (name == 'DOWN_TIME_3') {
                updateValue.duration = value;
            }
            if (name == 'DOWN_TIME_4') {
                updateValue.remark = value;
            }

            let updated = Object.assign({}, state.frequencyWiseDataEntryValues, {
                [frequency]: frequencyWiseDataEntryValue
            });

            return {
                ...state,
                frequencyWiseDataEntryValues: updated,
                dataDirty: true
            };
        }

        //_________________________________________________

        case DATA_ENTRY_ADD_NEW_WASTAGE_ROW : {

            let {frequency, currentDisplayOrder, newIndex} = action.payload;
            let freqWiseDownTimeWastageConfig = _.clone(state.frequencyWiseDownTimeWastageInputConfig[frequency]);
            let frequencyWiseDataEntryValue = _.clone(state.frequencyWiseDataEntryValues[frequency]);

            let wastageConfig = freqWiseDownTimeWastageConfig[currentDisplayOrder - 1].wastageData;
            wastageConfig.splice((newIndex), 0, getSingleWastageInputConfig());

            let updatedDonTimeWastage = Object.assign({}, state.frequencyWiseDownTimeWastageInputConfig, {
                [frequency]: freqWiseDownTimeWastageConfig
            });

            let wastageValues = frequencyWiseDataEntryValue[currentDisplayOrder - 1].processDataWastageDTOs;

            let newDisplayOrder = wastageValues.length;
            let copy = _.cloneDeep(wastageValues[0]);
            let updatedValue = Object.assign({}, copy, {
                processDataWastageID: '',
                displayOrder: newDisplayOrder,
                wasteCauseCategoryID: '',
                wasteCauseCategoryName: '',
                wastageSubCategoryID: '',
                wasteCauseSubCategoryName: '',
                weightUnit: '',
                weight: '',
                remark: '',
                status: 'ACT',

            });
            wastageValues.splice((newIndex), 0, updatedValue);

            let updated = Object.assign({}, state.frequencyWiseDataEntryValues, {
                [frequency]: frequencyWiseDataEntryValue
            });

            return {
                ...state,
                frequencyWiseDownTimeWastageInputConfig: updatedDonTimeWastage,
                frequencyWiseDataEntryValues: updated,
            };
        }

        case DATA_ENTRY_ADD_NEW_WASTAGE_ROW_SET : {

            let {frequency, currentDisplayOrder, oldIndex, count} = action.payload;
            let freqWiseDownTimeWastageConfig = _.clone(state.frequencyWiseDownTimeWastageInputConfig[frequency]);
            let frequencyWiseDataEntryValue = _.clone(state.frequencyWiseDataEntryValues[frequency]);

            let wastageConfig = freqWiseDownTimeWastageConfig[currentDisplayOrder - 1].wastageData;

            let updatedDonTimeWastage = Object.assign({}, state.frequencyWiseDownTimeWastageInputConfig);

            let wastageValues = frequencyWiseDataEntryValue[currentDisplayOrder - 1].processDataWastageDTOs;
            let copy = _.cloneDeep(wastageValues[0]);

            let updated = Object.assign({}, state.frequencyWiseDataEntryValues);

            let newDisplayOrder = wastageValues.length;

            for (let i = 0; i < count; i++) {
                wastageConfig.splice((oldIndex + 1 + i), 0, getSingleWastageInputConfig());
                updatedDonTimeWastage = Object.assign({}, updatedDonTimeWastage, {
                    [frequency]: freqWiseDownTimeWastageConfig
                });

                let updatedValue = Object.assign({}, copy, {
                    processDataWastageID: '',
                    displayOrder: newDisplayOrder + i,
                    wasteCauseCategoryID: '',
                    wasteCauseCategoryName: '',
                    wastageSubCategoryID: '',
                    wasteCauseSubCategoryName: '',
                    weightUnit: '',
                    weight: '',
                    remark: '',
                    status: 'ACT',

                });
                wastageValues.splice((oldIndex + 1 + i), 0, updatedValue);

                updated = Object.assign({}, updated, {
                    [frequency]: frequencyWiseDataEntryValue
                });
            }

            return {
                ...state,
                frequencyWiseDownTimeWastageInputConfig: updatedDonTimeWastage,
                frequencyWiseDataEntryValues: updated,
            };
        }

        case DATA_ENTRY_REMOVE_NEW_WASTAGE_ROW : {

            let {frequency, currentDisplayOrder, index, processDataID, processDataWastageID} = action.payload;
            let freqWiseDownTimeWastageConfig = _.clone(state.frequencyWiseDownTimeWastageInputConfig[frequency]);
            let frequencyWiseDataEntryValue = _.clone(state.frequencyWiseDataEntryValues[frequency]);
            let processDataWiseRemovedWastageIDs = _.clone(state.frequencyWiseProcessDataWiseRemovedWastage[frequency]);
            processDataWiseRemovedWastageIDs = processDataWiseRemovedWastageIDs ? processDataWiseRemovedWastageIDs : {};

            let wastageConfig = freqWiseDownTimeWastageConfig[currentDisplayOrder - 1].wastageData;
            wastageConfig.splice((index), 1);

            let updatedDonTimeWastage = Object.assign({}, state.frequencyWiseDownTimeWastageInputConfig, {
                [frequency]: freqWiseDownTimeWastageConfig
            });

            let wastageValues = frequencyWiseDataEntryValue[currentDisplayOrder - 1].processDataWastageDTOs;

            wastageValues.splice((index), 1);

            let updated = Object.assign({}, state.frequencyWiseDataEntryValues, {
                [frequency]: frequencyWiseDataEntryValue
            });

            if (processDataID && processDataWastageID) {
                if (!processDataWiseRemovedWastageIDs[processDataID]) {
                    processDataWiseRemovedWastageIDs[processDataID] = [];
                }
                processDataWiseRemovedWastageIDs[processDataID].push(processDataWastageID);
            }

            let updatedIDs = Object.assign({}, state.frequencyWiseProcessDataWiseRemovedWastage, {
                [frequency]: processDataWiseRemovedWastageIDs
            });

            return {
                ...state,
                frequencyWiseDownTimeWastageInputConfig: updatedDonTimeWastage,
                frequencyWiseProcessDataWiseRemovedWastage: updatedIDs,
                frequencyWiseDataEntryValues: updated,
                dataDirty: true
            };
        }

        case DATA_ENTRY_UPDATE_WASTAGE_ROW : {

            let {frequency, currentDisplayOrder, index, name, value} = action.payload;
            let frequencyWiseDataEntryValue = _.clone(state.frequencyWiseDataEntryValues[frequency]);
            let wastageValues = frequencyWiseDataEntryValue[currentDisplayOrder - 1].processDataWastageDTOs;

            let updateValue = wastageValues[index];

            let newDisplayOrder = wastageValues.length;
            if (!updateValue) {
                wastageValues[index] = {
                    processDataWastageID: '',
                    displayOrder: newDisplayOrder,
                    wasteCauseCategoryID: '',
                    wasteCauseCategoryName: '',
                    wastageSubCategoryID: '',
                    wasteCauseSubCategoryName: '',
                    weightUnit: '',
                    weight: '',
                    remark: '',
                    status: 'ACT',
                };
                updateValue = wastageValues[index];
            }

            if (name == 'WASTAGE_1') {
                updateValue.wasteCauseCategoryID = value;
            }
            if (name == 'WASTAGE_2') {
                updateValue.wastageSubCategoryID = value;
            }
            if (name == 'WASTAGE_3') {
                updateValue.weight = value;
            }
            if (name == 'WASTAGE_4') {
                updateValue.remark = value;
            }

            let updated = Object.assign({}, state.frequencyWiseDataEntryValues, {
                [frequency]: frequencyWiseDataEntryValue
            });

            return {
                ...state,
                frequencyWiseDataEntryValues: updated,
                dataDirty: true
            };
        }


        case DATA_ENTRY_GET_FACTORY_PRODUCTS_SUCCESS : {
            return {
                ...state,
                factoryProducts: action.payload,
            }
        }

        case DATA_ENTRY_GET_FACTORY_PRODUCTION_LINES_SUCCESS : {
            return {
                ...state,
                factoryProductionLines: action.payload,
            }
        }

        case DATA_ENTRY_GET_FACTORY_SHIFTS_SUCCESS : {
            return {
                ...state,
                factoryShifts: action.payload,
            }
        }

        case DATA_ENTRY_GET_FACTORY_SUPERVISORS_SUCCESS : {
            return {
                ...state,
                factorySupervisors: action.payload,
            }
        }

        case DATA_ENTRY_SAVE_TAB_SUCCESS : {

            let frequency = action.payload.frequencyValue;
            let freqWiseData = action.payload.processDataDTOList;

            let updated = Object.assign({}, _.cloneDeep(state.frequencyWiseDataEntryValues), {
                [frequency]: freqWiseData
            });

            return {
                ...state,
                frequencyWiseDataEntryValues: updated,
                dataDirty: false
            };
        }

        case DATA_ENTRY_GET_FACTORY_PRODUCTS_CATEGORIES_SUCCESS : {
            return {
                ...state,
                factoryProductCategories: action.payload,
            }
        }

        case DATA_ENTRY_DOWNLOAD_ENTRY_CSV_SUCCESS:
            return {
                ...state,
                downloadCSVData: action.payload
            };

        case DATA_ENTRY_RESET_DATA : {
            return {
                ...state,
                dataEntryForm: {},
                frequencyWiseInitFormData: {},
                frequencyWiseDataEntryValues: {},
                allFrequencies: [],
                selectedFrequency: '',
                dataDirty: false,
                factoryProducts: [],
                factoryProductionLines: [],
                factoryShifts: [],
                factorySupervisors: [],
                factoryProductCategories: {},
                downloadCSVData: {},
                frequencyWiseDownTimeWastageInputConfig: {},
                frequencyWiseProcessDataWiseRemovedDownTimes: {}
            };
        }

        default :
            return state;
    }
}
